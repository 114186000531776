import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import * as snippet from "app/main/tables/datatables/datatables.snippetcode";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FoeService } from '@foe/services/foe.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-only-consultaions',
  templateUrl: './only-consultaions.component.html',
  styleUrls: ['./only-consultaions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class OnlyConsultaionsComponent implements OnInit {

  selectedPatientFeedback: any;
  feedbackUpdateLoading = new BehaviorSubject(false);
  backendValidations: any;
  public search_text: any;
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  isShown: boolean = false;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;

  onlyConsultListFilterForm = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_name: [],
    fmobile: [],
    purpose: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  toggleShow() {
    this.isShown = !this.isShown;
  }

  public feedbackStatus = [
    { id: 1, value: "confirm", text: "Confirm" },
    { id: 2, value: "hold", text: "Hold" },
  ];

  // Feeback Submit Form
  feedbackSubmitForm: FormGroup = this.fb.group({
    feedback_status: [null, Validators.required],
    remarks: ["", [Validators.required, Validators.pattern('\\s?\\S+(?: \\S+)*\\s?'), Validators.maxLength(500)]],
  });

  get feedback_status() {
    return this.feedbackSubmitForm.get("feedback_status");
  }

  get remarks() {
    return this.feedbackSubmitForm.get("remarks");
  }

  submitfbForm() {
    this.feedbackSubmitForm.markAllAsTouched();
    if (this.feedbackSubmitForm.valid) {
      this.feedbackUpdateLoading.next(true);
      this.feedbackSubmitForm.value.patient_id = this.selectedPatientFeedback.patient_id;
      this.feedbackSubmitForm.value.id = this.selectedPatientFeedback.id;
      this.feedbackSubmitForm.value.purpose = 'OC';
      this._service.submitFeedBackForm(this.feedbackSubmitForm.value)
        .pipe(finalize(() => this.feedbackUpdateLoading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.feedbackSubmitForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closesubmitfbForm();
            this.getOnlyConsultationsList();
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.feedbackSubmitForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  closesubmitfbForm() {
    this.feedbackSubmitForm.reset();
    this.modalService.dismissAll();
  }

  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  public exportCSVData = [];

  public onlyConsultationsList: any[] = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _service: FoeService,
    public _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private notifyService: NotificationService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getOnlyConsultationsList();

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.onlyConsultListFilterForm.value.page = 1;
      this.getOnlyConsultationsList();
    });
  }

  /* Get Reg PaymentsListing */
  getOnlyConsultationsList() {
    this.onlyConsultListFilterForm.value.perpage = this.basicSelectedOption;
    this.onlyConsultListFilterForm.value.fsearch_text = this.search_text;
    this.onlyConsultListFilterForm.value.purpose = "OC";

    this._service.getRegPaymentListing(this.onlyConsultListFilterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        this.onlyConsultationsList = data['reg_oc_list'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.onlyConsultListFilterForm.value.fpatient_unique_id || this.onlyConsultListFilterForm.value.fpatient_name || this.onlyConsultListFilterForm.value.fmobile || this.onlyConsultListFilterForm.value.purpose) {
      return false;
    } else {
      return true;
    }
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getOnlyConsultationsList();
  }

  /* Reset Filter */
  resetFilter() {
    this.onlyConsultListFilterForm.reset();
    this.getOnlyConsultationsList();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.onlyConsultListFilterForm.value.perpage = this.basicSelectedOption;
    this.onlyConsultListFilterForm.value.page = 1;
    this.getOnlyConsultationsList();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.onlyConsultListFilterForm.value.page = $event.page;
    this.getOnlyConsultationsList();
  }

  /* Redirect CaseSheet */
  caseSheet(patientId) {
        let link = environment.subdirUrl + '/foe/home/followups/case-sheet/' + patientId;
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  modalOpen(modal, row: any = '') {
    this.selectedPatientFeedback = row;
    this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true,
    });
  }
}
