<ng-template #ccPhPatients let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">PH CC Patient Details From ({{firstDay | date: 'dd-MM-yyyy'}}) To
            ({{currentDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>REF No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient Name</th>
                            <th>Address</th>
                            <th>SMS Code</th>
                            <th>PROB</th>
                            <th>Doctor Name</th>
                            <th>Visit Date</th>
                            <th>OC/REG</th>
                            <th>DUR</th>
                            <th>Pack</th>
                            <th>T-Paid</th>
                            <th>T-Due</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of patient_details;">
                            <td>{{details.ref_sno ? details.ref_sno : '-'}}</td>
                            <td>{{details.patient_unique_id ? details.patient_unique_id : '-'}}</td>
                            <td>{{details.branch_patient_id ? details.branch_patient_id : '-'}}</td>
                            <td>{{details.patient_name ? details.patient_name : '-'}}</td>
                            <td>{{details.house_no ? (details.house_no+', ' ): '-'}}
                                {{details.street ? (details.street+', ' ): '-'}}
                                {{details.district ? (details.district+', ' ): '-'}}
                                {{details.pincode ? (details.pincode): '-'}}</td>
                            <td>{{details.cc_agent_code ? details.cc_agent_code : '-'}}</td>
                            <td>{{details.treatment_for ? details.treatment_for : '-'}}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.visit_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.reg_status == 1 ? "REG" : "OC"}}</td>
                            <td>{{details.package_name}}</td>
                            <td>{{details.total_amount}}</td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.due_amount ? details.due_amount : '-'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>