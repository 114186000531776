<ng-template #achievedModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">GT Patients Details From ({{firstDay | date: 'dd-MM-yyyy'}}) To
            ({{currentDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow" style="zoom: 75%;">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Name</th>
                            <th>BR</th>
                            <th>New / Old</th>
                            <th>Doctor Name</th>
                            <th>Package</th>
                            <th>Total</th>
                            <th>Consultation</th>
                            <th>Received</th>
                            <th>Billing Date</th>
                            <th>REG Date</th>
                            <th>Expiry</th>
                            <th>Due</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of a_patient_details;  let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td><a class="dataTable-Links" style="color:blue;" (click)="caseSheet(details.patient_id)">{{details.patient_unique_id ? details.patient_unique_id : '-'}}</a></td>
                            <td>{{details.branch_patient_id ? details.branch_patient_id : '-'}}</td>
                            <td><a class="dataTable-Links" style="color:blue;" (click)="caseSheet(details.patient_id)">{{details.patient_name ? details.patient_name : '-'}}</a></td>
                            <td>{{details.branch_name}}</td>
                            <td>{{details.patient_type == 'n' ? 'New' : 'Old' }}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.package_name}}</td>
                            <td>{{details.total_amount}}</td>
                            <td></td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.billing_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.visit_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.due_amount ? details.due_amount : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">Consultation Patients Details From ({{firstDay | date:
                'dd-MM-yyyy'}}) To ({{currentDate | date: 'dd-MM-yyyy'}})
            </h4>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Patient ID</th>
                            <th>Patient Name</th>
                            <th>BR</th>
                            <th>Amount</th>
                            <th>Option</th>
                            <th>Date</th>
                            <th>Doctor</th>
                            <th>Source</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let billing of a_patient_billings; let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{billing.patient_unique_id ? billing.patient_unique_id : '-'}}
                            </td>
                            <td>{{billing.patient_name ? billing.patient_name : '-'}}</td>
                            <td>{{billing.branch_name}}</td>
                            <td>{{billing.amount ? billing.amount : '-'}}</td>
                            <td>{{billing.payment_option == 'c' ? 'Cash' :
                                billing.payment_option == 'cc' ? 'Credit-Card' :
                                billing.payment_option == 'ch' ? 'Cheque' :
                                billing.payment_option == 'o' ? 'Online' : '-'}}</td>
                            <td>{{billing.visit_date ? (billing.visit_date | date: 'dd-MM-YYYY') : '-'}}</td>
                            <td>{{billing.doctor_name ? billing.doctor_name : '-'}}</td>
                            <td>{{billing.how_came_know ? billing.how_came_know : '-'}}</td>
                            <td>{{billing.status == '0' ? 'Doc Pending' : 'Confirm'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>