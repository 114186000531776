import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'remarks-modal',
  templateUrl: './remarks-modal.component.html',
  styleUrls: ['./remarks-modal.component.scss']
})
export class RemarksModalComponent implements OnInit {

  feedBackRemarks: any;
  @ViewChild("remarksModal") remarksModal: TemplateRef<any>;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /* Open Patient Information Modal */
  openModal(remark) {
    this.feedBackRemarks = remark;
  	this.modalService.open(this.remarksModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
  	});
  }

}
