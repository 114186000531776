import { AccountsService } from '@accounts/services/accounts.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mid-gt-modal',
  templateUrl: './mid-gt-modal.component.html',
  styleUrls: ['./mid-gt-modal.component.scss']
})
export class MidGtModalComponent implements OnInit {

  @ViewChild("midGTpatientsModal") midGTpatientsModal: TemplateRef<any>;
  gtPatientDetails: any;
  gtConsultationDetails: any;
  now = new Date();
  monthFirstDate: any;
  monthLastDate: any;

  constructor(private modalService: NgbModal,
    private account_service: AccountsService) { }

  ngOnInit(): void {
    this.monthFirstDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.monthLastDate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  }
  /* Open Modal */
  openModal(billing, month_type, type) {
    console.log(billing);
    if (billing['mid_gt']) {
      let payload = { 'branch_code': billing['branch_code'], 'month_type': month_type, 'type': type };
      this.account_service.gt_patient_details(payload).subscribe(res => {
        if (res) {
          this.gtPatientDetails = res['gtPatientDetails'];
          this.gtConsultationDetails = res['gtConsultationDetails'];
          console.log(this.gtConsultationDetails);
          this.modalService.open(this.midGTpatientsModal, {
            windowClass: "modal",
            centered: true,
            size: "xl"
          });
        }
      });
    }
  }
  openSmsModal(billing, month_type, type) {
    if (billing) {
      let payload = { 'branch_code': billing.branch_code, 'month_type': month_type, 'type': type };
      this.account_service.gt_patient_details(payload).subscribe(res => {
        if (res) {
          this.gtPatientDetails = res['gtPatientDetails'];
          this.gtConsultationDetails = res['gtConsultationDetails'];
          this.modalService.open(this.midGTpatientsModal, {
            windowClass: "modal",
            centered: true,
            size: "xl"
          });
        }
      });
    }
  }
  openSmsTotalModal(month_type, type, state_id) {
      let payload = { 'branch_code': null, 'month_type': month_type, 'type': type, 'state_id': state_id};
      this.account_service.gt_patient_details(payload).subscribe(res => {
        if (res) {
          this.gtPatientDetails = res['gtPatientDetails'];
          this.gtConsultationDetails = res['gtConsultationDetails'];
          this.modalService.open(this.midGTpatientsModal, {
            windowClass: "modal",
            centered: true,
            size: "xl"
          });
        }
      });
    }
}
