<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title text-uppercase"> Target Form</h4>
                </div>
                <hr>
                <div class="card-body pb-1">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="transferForm" (ngSubmit)="transferEmployee()">
                                <div class="row">
                                  
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Branch <span class="text-danger">*</span></label>
                                            <ng-select [items]="branches" bindLabel="name" bindValue="id"
                                                placeholder="Select Branch" formControlName="branch_id"
                                                [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.branch_id">
                                                <ng-container
                                                    *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Month <span class="text-danger">*</span></label>
                                            <ng-select [items]="months" bindLabel="name" bindValue="id"
                                                placeholder="Select month" formControlName="month_id"
                                                [ngClass]="{ 'is-invalid error': errorMessages.month_id && month_id.errors && month_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.month_id">
                                                <ng-container
                                                    *ngIf="month_id.hasError(error.type) && (month_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date <span class="text-danger">*</span></label>
                                            <input formControlName="absent_date" type="date"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid error': errorMessages.absent_date && absent_date.errors && absent_date.touched }">
                                            <div
                                                *ngFor="let error of errorMessages.absent_date">
                                                <ng-container
                                                    *ngIf="absent_date.hasError(error.type) && (absent_date.touched)">
                                                    <small
                                                        class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">To Date <span class="text-danger">*</span></label>
                                            <input formControlName="joining_date" type="date"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid error': errorMessages.joining_date && joining_date.errors && joining_date.touched }">
                                            <div
                                                *ngFor="let error of errorMessages.joining_date">
                                                <ng-container
                                                    *ngIf="joining_date.hasError(error.type) && (joining_date.touched)">
                                                    <small
                                                        class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label>Gross Amount<span class="text-danger">*</span></label>
                                            <input type="text" class="form-control"  step="0.01" formControlName="gross_amt"
                                                (keypress)="allowNumbersOnly($event)"
                                                [ngClass]="{ 'is-invalid error': errorMessages.gross_amt && gross_amt.errors && gross_amt.touched }">
                                            <div *ngFor="let error of errorMessages.gross_amt">
                                                <ng-container *ngIf="gross_amt.hasError(error.type) && (gross_amt.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Gross Amount NP <span class="text-danger">*</span></label>
                                            <input formControlName="gross_amt_np" type="text" step="0.01"
                                                class="form-control" (keypress)="allowNumbersOnly($event)"
                                                [ngClass]="{ 'is-invalid error': errorMessages.gross_amt_np && gross_amt_np.errors && gross_amt_np.touched }">
                                            <div
                                                *ngFor="let error of errorMessages.gross_amt_np">
                                                <ng-container
                                                    *ngIf="gross_amt_np.hasError(error.type) && (gross_amt_np.touched)">
                                                    <small
                                                        class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Gross Amount OP <span class="text-danger">*</span></label>
                                            <input formControlName="gross_amt_op" type="text" step="0.01"
                                                class="form-control" (keypress)="allowNumbersOnly($event)"
                                                [ngClass]="{ 'is-invalid error': errorMessages.gross_amt_op && gross_amt_op.errors && gross_amt_op.touched }">
                                            <div
                                                *ngFor="let error of errorMessages.gross_amt_op">
                                                <ng-container
                                                    *ngIf="gross_amt_op.hasError(error.type) && (gross_amt_op.touched)">
                                                    <small
                                                        class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>  
                                  
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <button [disabled]="loading | async" type="submit" class="btn btn-primary">
                                            <span *ngIf="loading | async"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        <button type="reset" class="btn btn-secondary ml-1"
                                            (click)="reset()">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title text-uppercase">TARGET AMOUNT EDIT FORM</h4>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="">
                            <table class="table table-bordered text-center align-middle">
                                <thead>
                                    <tr>
                                        <th>Branch Name</th>
                                        <th>Given Target Amount</th>
                                        <th>EDIT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of tableData">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.gross_amt }}</td>
                                        <td> <a class="badge badge-light-primary" ngbTooltip="Edit" placement="left"
                                            (click)="modalOpen(editPrescriptionDetails, item)" tooltipClass="fadeInAnimation">
                                            <span>
                                               Edit
                                            </span>
                                        </a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</section>

<!-- Edit Target Form Edit Modal -->
<ng-template #editPrescriptionDetails let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">BRANCHES TARGET EDIT FORM</h4>

        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="editPrescriptionForm" (ngSubmit)="updatePrescription()">
        <!-- <form> -->
            <input type="hidden" name="id" class="form-control" value="">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">MONTH</label>
                        <input type="text" class="form-control" formControlName="news_name" readonly>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">BRANCH </label>
                        <input type="text" class="form-control" formControlName="branch_name" readonly>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">TOTAL TARGET AMOUNT</label>
                        <input type="number" class="form-control" formControlName="gross_amt">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">ASSIGNING FROM</label>
                        <input type="text" class="form-control" formControlName="assign_date" readonly>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary">Update</button>
                 <button class="btn btn-secondary ml-1" type="reset">Reset</button> 
            </div>
        </form>
    </div>
</ng-template>
<!-- / Edit Target Form Edit Modal -->