import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { GmService } from '@gm/services/gm.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { type } from 'os';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AgmService } from "../service/agm.service";


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  isShown: boolean = false;
  public loading = new BehaviorSubject(false);
  selectedStatus: any;
  data: any;
  selectedReport: any;
  totalRow = [];
  tableKeys: any;
  target: any;
  public state_id: any;

  filterForm = this._formBuilder.group({
    fvalue: [null, Validators.required],
  });

  currentDate = new Date();
  y = this.currentDate.getFullYear();
  m = this.currentDate.getMonth();
  this_m = this.currentDate.toLocaleString('en-us', { month: 'short' });

  prev_1 = new Date(this.y, this.m - 1, 1);
  prev_1_m = this.prev_1.toLocaleString('en-us', { month: 'short' });

  prev_2 = new Date(this.y, this.m - 2, 1);
  prev_2_m = this.prev_2.toLocaleString('en-us', { month: 'short' });

  prev_3 = new Date(this.y, this.m - 3, 1);
  prev_3_m = this.prev_3.toLocaleString('en-us', { month: 'short' });

  prev_4 = new Date(this.y, this.m - 4, 1);
  prev_4_m = this.prev_4.toLocaleString('en-us', { month: 'short' });

  prev_5 = new Date(this.y, this.m - 5, 1);
  prev_5_m = this.prev_5.toLocaleString('en-us', { month: 'short' });

  filterValues = [
    { id: 1, name: 'NP-AP', key: 'gt_status' },
    { id: 2, name: 'NP-TS', key: 'gt_status' },
    { id: 3, name: 'NP-KA', key: 'gt_status' },
    { id: 4, name: 'REN' },
    { id: 5, name: 'CC' },
    { id: 6, name: 'IB' },
    { id: 7, name: 'OB' },
    { id: 8, name: 'WI' },
    { id: 9, name: 'REF' },
    { id: 10, name: 'RMP' },
    { id: 11, name: 'CAMP' },
    { id: 12, name: 'ONLINE' },
    { id: 13, name: 'OC' },
    { id: 14, name: 'ACQ' },
    { id: 15, name: 'RGL' },
    { id: 16, name: 'REG' },
    { id: 17, name: 'CONS' },
    { id: 18, name: 'EXP' },
  ];

  get fvalue() { return this.filterForm.get('fvalue'); }

  errorMessages = {
    fvalue: [{ type: 'required', message: 'This field is required' }]
  }

  constructor(
    public _activatedRoute: ActivatedRoute,
    private _service: GmService,
    private _formBuilder: FormBuilder,
    private masterService: MasterDataService,
    private notifyService: NotificationService,
    private route: ActivatedRoute,
    private service: AgmService,

  ) {

  }

  ngOnInit(): void {
    this.getProfileDetails();

    this._activatedRoute.params.subscribe(routeParams => {
      this.target = routeParams.target;
      if (this.target !== 'np')
        this.getData(this.target);
      else
        this.getData("np", this.state_id, null);
    });
  }

  getData(target, state_id = null, state_code = null) {
    let target_sub = null;
    let title = "";
    let type = (target == 'np') ? 'np' : 'np6';

    let np3_list = ['ren', 'reg', 'acq', 'rgl', 'cons', 'exp']
    if (np3_list.includes(target)) {
      type = 'np3'
    }

    if (target == "ib") {
      target = "call-center";
      target_sub = "Inbound";
      title = target_sub;
    } else if (target == "ob") {
      target = "call-center";
      target_sub = "Outbound";
      title = target_sub;
    } else {
      title = target.toUpperCase();
    }

    let obj = this.selectedReport = {
      'target': target,
      'target_sub': target_sub,
      'type': type,
      'state_id': state_id,
      'state_code': state_code,
      'title': title
    };
    this._service.targetStatus(obj)
      .pipe(finalize(() => this.loading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        this.isShown = true;
        this.data = data;
        if (this.selectedReport.type == "np") {
          this.totalRow[0] = this.getTotal(data);
        } else {
          this.totalRow[1] = this.getNP6Total(data[1]);
          this.totalRow[2] = this.getNP6Total(data[2]);
          this.totalRow[3] = this.getNP6Total(data[3]);
          this.tableKeys = Object.keys(data);
        }
        // console.log(this.totalRow[0])
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });

  }

  /* Select Status */
  selectStatus(status) {
    this.selectedStatus = status.name;
  }

  /* Reset Filter */
  resetFilter() {
    this.isShown = false;
    this.filterForm.reset();
    // this.formSubmit();
  }

  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        prev_prev_np: previousValue.prev_prev_np + currentValue.prev_prev_np,
        prev_prev_reg: previousValue.prev_prev_reg + currentValue.prev_prev_reg,
        prev_prev_gt: previousValue.prev_prev_gt + currentValue.prev_prev_gt,
        prev_np: previousValue.prev_np + currentValue.prev_np,
        prev_reg: previousValue.prev_reg + currentValue.prev_reg,
        prev_gt: previousValue.prev_gt + currentValue.prev_gt,
        this_np: previousValue.this_np + currentValue.this_np,
        this_reg: previousValue.this_reg + currentValue.this_reg,
        this_gt: previousValue.this_gt + currentValue.this_gt,
      }
    });
  }

  getNP6Total(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        Jan: (isNaN(previousValue.Jan) ? 0 : previousValue.Jan) + (isNaN(currentValue.Jan) ? 0 : currentValue.Jan),
        Feb: (isNaN(previousValue.Feb) ? 0 : previousValue.Feb) + (isNaN(currentValue.Feb) ? 0 : currentValue.Feb),
        Mar: (isNaN(previousValue.Mar) ? 0 : previousValue.Mar) + (isNaN(currentValue.Mar) ? 0 : currentValue.Mar),
        Apr: (isNaN(previousValue.Apr) ? 0 : previousValue.Apr) + (isNaN(currentValue.Apr) ? 0 : currentValue.Apr),
        May: (isNaN(previousValue.May) ? 0 : previousValue.May) + (isNaN(currentValue.May) ? 0 : currentValue.May),
        Jun: (isNaN(previousValue.Jun) ? 0 : previousValue.Jun) + (isNaN(currentValue.Jun) ? 0 : currentValue.Jun),
        Jul: (isNaN(previousValue.Jul) ? 0 : previousValue.Jul) + (isNaN(currentValue.Jul) ? 0 : currentValue.Jul),
        Aug: (isNaN(previousValue.Aug) ? 0 : previousValue.Aug) + (isNaN(currentValue.Aug) ? 0 : currentValue.Aug),
        Sep: (isNaN(previousValue.Sep) ? 0 : previousValue.Sep) + (isNaN(currentValue.Sep) ? 0 : currentValue.Sep),
        Oct: (isNaN(previousValue.Oct) ? 0 : previousValue.Oct) + (isNaN(currentValue.Oct) ? 0 : currentValue.Oct),
        Nov: (isNaN(previousValue.Nov) ? 0 : previousValue.Nov) + (isNaN(currentValue.Nov) ? 0 : currentValue.Nov),
        Dec: (isNaN(previousValue.Dec) ? 0 : previousValue.Dec) + (isNaN(currentValue.Dec) ? 0 : currentValue.Dec)
      }
    });
  }
  getProfileDetails(){
    this.service.getProfileDetails().subscribe(res => {
      console.log(res['state_id']);
     this.state_id = res['state_id'];
    }, err => {
      console.log(err.error);
    });
  }
}
