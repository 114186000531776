import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Swal from "sweetalert2";

import { NotificationService } from '@core/services/notification.service';
import { ProService } from '@pro/services/pro.service';

@Component({
  selector: 'followup-drop-modal',
  templateUrl: './followup-drop-modal.component.html',
  styleUrls: ['./followup-drop-modal.component.scss']
})
export class FollowupDropModalComponent implements OnInit {

  dropInfo: any;
	@Output() reloadDataTable = new EventEmitter<any>();
  constructor(private _service: ProService,
    private notifyService: NotificationService,) { }

  ngOnInit(): void {
  }

  /* Drop Alert */
  dropAlert(drop_patient_details) {
    this.dropInfo = drop_patient_details;
    // console.log(this.dropInfo.patient_name, this.dropInfo);
    Swal.fire({
      title: "",
      html: "<div class='row existingInformation-data text-left'>" +
       "<div class='col-lg-12 col-md-12 col-xs-12 col-sm-12'>" +
        "<div class='row'>" +
         "<div class='col-lg-12 col-md-12'>" +
            "<div class='row'>" +
              "<div class='col-lg-5 col-md-6'>" +
                  "<label class='sweetAl_custom_content'>Patient ID</label>" +
              "</div>" +
              "<div class='col-lg-7 col-md-6'>" +
                "<p><b>"+ this.dropInfo.patient_unique_id +"</b></p>" +
              "</div>" + 
            "</div>" +
          "</div>" +
          "<div class='col-lg-12 col-md-12'>" +
                            "<div class='row'>" +
                                "<div class='col-lg-5 col-md-6'>" +
                                    "<label class='sweetAl_custom_content'>Patient Name</label>" +
                                "</div>" +
                                "<div class='col-lg-7 col-md-6'>" +
                                    "<p><b>"+ this.dropInfo.patient_name +"</b></p>" +
                                "</div>" +
                            "</div>" +
                        "</div>" +
        "</div>" + "</div>" +"</div>",
        input:'textarea',
        inputPlaceholder: "Reason For Drop.....",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Submit",
        customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage(
            '<i class="fa fa-info-circle"></i> The Drop Reason Field is required'
          )
        } else if(value) {
          console.log(value);
          let payload = Object();

          Object.assign(payload, {patient_id: this.dropInfo.patient_id});
          Object.assign(payload, {drop_reason: value});
          this._service.drop_patient(payload)
          .subscribe(res => {

            if (res['status'] == "success") {
              this.reloadDataTable.emit(true);
              Swal.fire({
                title: "Dropped Out!",
                text: "Successfully Droppedout the Patient Details.",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          }, err => {
            this.notifyService.showError(err['message'], err['title']);
          });
        }
      }
    })
	}
}
            