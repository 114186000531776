<ng-template #totalMidGTpatientsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">New Patients From <span *ngIf="monthFirstDate && monthLastDate">({{monthFirstDate | date:'dd-MM-YYYY'}}) To ({{monthLastDate | date:'dd-MM-YYYY'}})</span></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table *ngIf="report_view == 'all'" class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Ref No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient Name</th>
                            <th>Doctor Name</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="patientsList">
                        <tr *ngFor="let patient of patientsList; let i=index">
                            <td>{{i + 1}}</td>
                            <td>{{patient.ref_sno ? patient.ref_sno : '-'}}</td>
                            <td>{{patient.patient_unique_id ? patient.patient_unique_id : '-'}}</td>
                            <td>{{patient.branch_patient_id ? patient.branch_patient_id : '-'}}</td>
                            <td>{{patient.patient_name ? patient.patient_name : '-'}}</td>
                            <td>{{patient.doctor_name ? patient.doctor_name : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
                <table *ngIf="report_view == 'rgl' || report_view == 'acq'" class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Ref No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient Name</th>
                            <th>Doctor Name</th>
                            <th>Package Name</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="patientsList">
                        <tr *ngFor="let patient of patientsList; let i=index">
                            <td>{{i + 1}}</td>
                            <td>{{patient.ref_sno ? patient.ref_sno : '-'}}</td>
                            <td>{{patient.patient_unique_id ? patient.patient_unique_id : '-'}}</td>
                            <td>{{patient.branch_patient_id ? patient.branch_patient_id : '-'}}</td>
                            <td>{{patient.patient_name ? patient.patient_name : '-'}}</td>
                            <td>{{patient.doctor_name ? patient.doctor_name : '-'}}</td>
                            <td>{{patient.package_name ? patient.package_name : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
                <table *ngIf="report_view == 'oc' || report_view == 'con'" class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient Name</th>
                            <th>EMP Name</th>
                            <th>Free/Cost</th>
                            <th>Fee</th>
                            <th>Option</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="patientsList">
                        <tr *ngFor="let patient of patientsList; let i=index">
                            <td>{{i + 1}}</td>
                            <td>{{patient.patient_unique_id ? patient.patient_unique_id : '-'}}</td>
                            <td>{{patient.branch_patient_id ? patient.branch_patient_id : '-'}}</td>
                            <td>{{patient.patient_name ? patient.patient_name : '-'}}</td>
                            <td>{{patient.doctor_name ? patient.doctor_name : '-'}}</td>
                            <td>{{patient.consult_type == 'f' ? 'free' :
                                patient.consult_type == 'c' ? 'Cost' : '-'}}</td>
                            <td>{{patient.consultation_fee ? patient.consultation_fee : '-'}}</td>
                            <td>{{patient.payment_option == 'c' ? 'Cash' :
                                patient.payment_option == 'cc' ? 'Credit-Card' :
                                patient.payment_option == 'ch' ? 'Cheque' :
                                patient.payment_option == 'o' ? 'Online' : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

