import { CoreMenu } from '@core/types'

export const hrMenu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'hm'
  },
  {
    id: 'branch',
    title:'Branch',
    type:'item',
    icon:'columns',
    url:'branches'
  },
  {
    id:'employees',
    title: 'Employees',
    translate: 'MENU.EMPLOYEES',
    type:'item',
    icon:'users',
    url:'employees'
  },
  {
    id: 'attendance',
    title: 'Attendance',
    translate: 'MENU.ATTENDANCE',
    type:'item',
    icon:'user-check',
    url:'attendance'
  },
  // {
  //   id: 'payslips',
  //   title: 'Payslips',
  //   translate: '',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'payslipReport'
  // },
  {
    id: 'reports',
    type: 'item',
    title: 'Reports',
    translate: '',
    icon: 'file-text',
    url: 'reports'
  }
]
