<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Calling Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date - To Date</label>
                                            <ng2-flatpickr (ngModelChange)="onDateSelect()"
                                                formControlName="fdate_range" [config]="DateRangeOptions"
                                                placeholder="Pick a date range!"></ng2-flatpickr>

                                            <div *ngFor="let error of errorMessages.fdate_range">
                                                <ng-container
                                                    *ngIf="fdate_range.hasError(error.type) && (fdate_range.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Branch</label>
                                            <ng-select [items]="branches" bindLabel="name" bindValue="id"
                                                placeholder="Select Branch" formControlName="branch_id"
                                                [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.branch_id">
                                                <ng-container
                                                    *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'summary_report'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Summary Reports</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                       <tr>No data</tr>

                    </table>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'new_patient_list'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">New Patient Reports</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>BR</th>
                                <th>PID</th>
                                <th>PATIENT NAME</th>
                                <th>MOBILE</th>
                                <th>CON</th>
                                <th>TOT PK</th>
                                <th>PAID</th>
                                <th>FROM</th>
                                <th>PROB</th>
                                <th>NO OF DAYS</th>
                                <th>NV DATE</th>
                                <th>STATUS</th>
                                <th class="break_th">REMARKS</th>
                                <th>ACTION</th>
                                
                             
                        </thead>
                        <tbody>
                            <tr class="patients-reports-stats" *ngFor="let item of tableData; let i=index">
                                <td>{{ i+1 }}</td>
                                <td>{{ item.br }}</td>
                                <td>{{ item.unique_patient_id?item.unique_patient_id: '-' }}</td>
                                <td>{{ item.patient_name}}</td>
                                <td>{{ item.mobile }}</td>
                                <td>{{ item.consult_fee }}</td>
                                <td>{{ item.total_amount }}</td>
                                <td>{{ item.received_amount }}</td>
                                <td>{{ item.source }}</td>
                                <td>{{ item.treatment }}</td>
                                <td>{{ item.no_of_days_medicine }}</td>
                                <td>{{ item.nextvisitdate }}</td>
                                <td>{{ item.statusnew?item.statusnew: 'No-Call' }}</td>
                                <td class="break_th">{{ item.remarksupdate }}</td>
                                <td><div class="d-flex align-items-center">
                                    <button class="btn btn-outline-dark active border__none ml-50"
                                        (click)="caseSheet(item.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation"
                                        placement="left">
                                        <span>
                                            <i class="fa fa-address-card-o font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                 
                                    <button class="btn btn-outline-primary active border__none ml-50"
                                        (click)="editPatient.openModal(item)" ngbTooltip="Edit" tooltipClass="fadeInAnimation"
                                        placement="left">
                                        <span>
                                            <i data-feather='edit' class="font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                    <button class="btn btn-outline-info active border__none ml-50"
                                        (click)="feedBackLogModal.openModal(item.patient_id, 'Daily_follwoup')"
                                        ngbTooltip="Feedbacks Log" tooltipClass="fadeInAnimation" placement="left">
                                        <span>
                                            <i data-feather='list' class="font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                  
                                </div></td>
                            </tr>
                        </tbody>
        
                    </table>
        
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'old_patient_list'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Old Patient Reports</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>BR</th>
                                <th>BPID</th>
                                <th>PATIENT NAME</th>
                                <th>MOBILE</th>
                                <th>REG DATE</th>
                                <th>PAID AMT</th>
                                <th>N DATE</th>
                                <th>P STATUS</th>
                                <th>EMAIL</th>
                                <th>STATUS</th>
                                <th>REMARKS</th>
                                <th>ACTION</th>
                                
                             
                        </thead>
                        <tbody>
                            <tr class="patients-reports-stats" *ngFor="let item of tableData; let i=index">
                                <td>{{ i+1 }}</td>
                                <td>{{ item.br }}</td>
                                <td>{{ item.bpid?item.bpid: '-' }}</td>
                                <td>{{ item.patient_name}}</td>
                                <td>{{ item.mobile }}</td>
                                <td>{{ item.visit_date }}</td>
                                <td>{{ item.received_amount }}</td>
                                <td>{{ item.nextvisitdate }}</td>
                                <td>{{ item.package_status }}</td>
                                <td>{{ item.email }}</td>
                                <td>{{ item.statusnew }}</td>
                                <td>{{ item.remarks }}</td>
                                <td><div class="d-flex align-items-center">
                                    <button class="btn btn-outline-dark active border__none ml-50"
                                        (click)="caseSheet(item.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation"
                                        placement="left">
                                        <span>
                                            <i class="fa fa-address-card-o font-small-4">
                                            </i>
                                        </span>
                                    </button>
                              
                                    <button class="btn btn-outline-primary active border__none ml-50"
                                        (click)="editPatient.openModal(item)" ngbTooltip="Edit" tooltipClass="fadeInAnimation"
                                        placement="left">
                                        <span>
                                            <i data-feather='edit' class="font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                    <button class="btn btn-outline-info active border__none ml-50"
                                        (click)="feedBackLogModal.openModal(item.patient_id, 'Daily_follwoup')"
                                        ngbTooltip="Feedbacks Log" tooltipClass="fadeInAnimation" placement="left">
                                        <span>
                                            <i data-feather='list' class="font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                   
                                </div></td>
                            </tr>
                        </tbody>
        
                    </table>
        
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'patient_medicine_reports'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Branch Patient Report</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>MOBILE</th>
                                <th>BRANCH PID</th>
                                <th>PATIENT NAME</th>
                                <th>BR</th>
                                <th>LAST PAID DATE</th>
                                <th>DUE</th>
                                <th>No. Of. DAYS</th>
                                <th>REG. Date</th>
                                <th>DURATION</th>
                                <th>REN DATE</th>
                                <th>CONSULTANT</th>
                                <th>PROB</th>
                                <th>CASE</th>
                              
                        </thead>
                        <tbody>
                            <tr class="patients-reports-stats" *ngFor="let item of tableData; let i=index">
                                <td>{{ i+1 }}</td>
                                <td>{{ item.mobile }}</td>
                                <td>{{ item.branch_patient_id?item.branch_patient_id: '-' }}</td>
                                <td>{{ item.patient_name }}</td>
                                <td>{{ item.branch }}</td>
                                <td>{{ item.paid_date }}</td>
                                <td>{{ item.due }}</td>
                                <td>{{ item.days }}</td>
                                <td>{{ item.reg_date }}</td>
                                <td>{{ item.package }}</td>
                                <td>{{ item.ren_date }}</td>
                                <td>{{ item.doctor }}</td>
                                <td>{{ item.prob }}</td>
                                <td><div class="d-flex align-items-center">
                                    <button class="btn btn-outline-dark active border__none ml-50"
                                        (click)="caseSheet(item.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation"
                                        placement="left">
                                        <span>
                                            <i class="fa fa-address-card-o font-small-4">
                                            </i>
                                        </span>
                                    </button>
                                </div></td>
                            </tr>
                        </tbody>
        
                    </table>
        
                </div>
            </div>
        </div>
    </div>
    </section>


<!-- Feedback Modal-->
<!-- <feedback-modal (reloadDataTable)="submitForm()" #feedBackModal></feedback-modal> -->
<!-- / Feedback Modal-->

<!-- Edit Patient Details-->
<edit-patient (reloadDataTable)="submitForm()" #editPatient></edit-patient>
<!-- / Edit Patient Details -->

<!-- FeedbackLog Modal -->
<feedbacklog-modal #feedBackLogModal></feedbacklog-modal>
<!-- / FeedbackLog Modal -->

<!-- Follow Up Drop Modal -->
<followup-drop-modal (reloadDataTable)="submitForm()" #followUpDropModal></followup-drop-modal>
<!-- / Follow Up Drop Modal -->