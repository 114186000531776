import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { AuditRoutingModule } from './audit-routing.module';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuditComponent } from './audit.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { CircularComponent } from './circular/circular.component';
import { CallingComponent } from './calling/calling.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';


@NgModule({
  declarations: [LoginComponent, HomeComponent, AuditComponent, BirthdayComponent, CircularComponent, CallingComponent, DashboardComponent],
  imports: [
    CommonModule,
    AuditRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    NgxDatatableModule,
    CardSnippetModule,
    LayoutModule,
    NgSelectModule,
    SharedModule,
    ComponentsModule,
    NgbTooltipModule
  ]
})
export class AuditModule { }
