import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '@models/User';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {

  public model$ = new BehaviorSubject<Partial<User>>(null);

  constructor() { }

  public init(user) {
    this.model$.next(user);
  }

  public getName() {
    if (this.model$.value) {
      return this.model$.value['name'];
    }
  }

  public getEmail() {
    if (this.model$.value) {
      return this.model$.value['email'];
    }
  }

  public getEmployeeId() {
    if (this.model$.value) {
      return this.model$.value['emp_id'];
    }
  }

  public getBranchCode() {
    if (this.model$.value) {
      return this.model$.value['branch_code'];
    }
  }

  public getStateId() {
    if (this.model$.value) {
      return this.model$.value['state_id'];
    }
  }

  public getRoleId() {
    if (this.model$.value) {
      return this.model$.value['role_id'];
    }
  }

  public getLogo() {
    if (this.model$.value) {
      return this.model$.value['logo_url'];
    }
  }

  public getBranchAddress() {
    if (this.model$.value) {
      return this.model$.value['address'];
    }
  }

  public getIsAGM() {
    if (this.model$.value) {
      return this.model$.value['is_agm'];
    }
  }


  public isCCManager() {
    if (this.model$.value) {
      return this.model$.value['is_manager'];
    }
  }

  public getAvatar() {
    let name = this.getName();
    if (name) {
      return name.charAt(0);
    }
  }

  public isLoggedIn(): boolean {
    return this.get('id') > 0;
  }

  public get<K extends keyof User>(prop: K): Partial<User>[K] {
    return this.model$.value && this.model$.value[prop];
  }
}
