import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { ExpenditureModalComponent } from './expenditure-modal/expenditure-modal.component';
import { DropModalComponent } from './drop-modal/drop-modal.component';
import { CaseSheetComponent } from './case-sheet/case-sheet.component';
import { SharedModule } from '@gm/shared/shared.module';



@NgModule({
  declarations: [InvoiceModalComponent, CaseSheetComponent, ExpenditureModalComponent, DropModalComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    InvoiceModalComponent,
    CaseSheetComponent,
    ExpenditureModalComponent,
    DropModalComponent
  ]
})
export class ComponentsModule { }
