<!-- employeesDataRows Datatables -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">
                        <a routerLink="/audit/home/hm"><span><i data-feather="arrow-left" class="font-medium-2"></i></span></a>
                        Employees Birthday Information</h4>
                </div>
                <div class="col-6 text-right pr-50">
                   
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="birthDayFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Employee ID</label>
                                    <input formControlName="femp_unique_id" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Employee Name</label>
                                    <input formControlName="femp_name" type="text" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Designation</label>
                                    <ng-select formControlName="frole" [items]="designations" bindLabel="name" bindValue="id" placeholder="Select Designation"> </ng-select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Branch</label>
                                    <ng-select formControlName="fbranch" [items]="branches" bindLabel="name" bindValue="id" placeholder="Select Branch"> </ng-select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 align-items-center d-flex mt-50">
                                <div>
                                     <button [disabled]="filterLoading.value || checkFilterValidity()" type="submit" class="btn btn-primary">
                                     <span *ngIf="filterLoading | async" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                     <button (click)="resetFilter()" type="reset" class="btn btn-secondary ml-50">Reset</button>   
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" (keyup)="filterUpdate($event)" /></label>
                </div>
            </div>
        </div>
    <ngx-datatable
    [rows]="employeesBirthdayRows"
    [rowHeight]="90"
    class="bootstrap core-bootstrap myDatatable"
    [limit]="10"
    [columnMode]="ColumnMode.force"
    [headerHeight]="40"
    [footerHeight]="50"
    [scrollbarH]="true"
    [selectionType]="SelectionType.checkbox"
    [limit]="basicSelectedOption"
    >

    <ngx-datatable-column name="Employee ID" prop="EmpUnique_id" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Employee Name" prop="Employee_Name" [cellClass]="'word-span-el'" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Designation" prop="Designation" [cellClass]="'word-span-el'" [width]="200"></ngx-datatable-column>
    <ngx-datatable-column name="Branch" prop="Branch_Name" [width]="100"></ngx-datatable-column>
    <ngx-datatable-column name="Date of Birthday" prop="Date_of_Birth" [width]="120"></ngx-datatable-column>
    <ngx-datatable-column name="wishes" prop="Wishes" [width]="200">
        <ng-template let-Wishes="value" ngx-datatable-cell-template>
            <div class="d-flex align-items-center word-span-el">
                <span>--</span>
            </div>
        </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)" [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="curPage" [size]="basicSelectedOption" [count]="totalCount">
     </datatable-pager>
    </ng-template>
    </ngx-datatable-footer>
    </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ employeesDataRows Datatables -->