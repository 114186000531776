<!-- Reg Payments List Datatables -->
<section id="ngx-datatable-kitchen-sink" *ngIf="_activatedRoute.children.length === 0">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Only Consultations</h4>
                </div>
                <div class="col-6 text-right pr-50">
                    <!--                     <button class="btn btn-danger" (click)="todayCallsModal.openModal()"><span><i
                                data-feather="phone-call"></i>
                            Today's Calls</span></button> -->
                    <button class="btn btn-primary ml-50" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="onlyConsultListFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input formControlName="fpatient_unique_id" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input formControlName="fpatient_name" type="text" name="" id=""
                                        class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button [disabled]="filterLoading.value || checkFilterValidity()" type="submit"
                                        class="btn btn-primary">
                                        <span *ngIf="filterLoading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button (click)="resetFilter()" type="reset"
                                        class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="onlyConsultationsList" [rowHeight]="90" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-row="row" let-patient_unique_id="value" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        {{patient_unique_id ? patient_unique_id : '-'}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column prop="feedback" name="feedback" prop="feedback" [width]="">
                <ng-template let-feedback="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two" (click)="modalOpen(fullFeedbackModal, row)">
                            {{ feedback ? feedback : 'No Feed'}}
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Coming Date" prop="coming_date" [width]="">
                <ng-template let-coming_date="coming_date" ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <span>{{coming_date ? (coming_date|date:'dd-MM-yyyy') : 'No Call'}}</span>
                        <!-- <a class="feed-max-two">
                            {{coming_date ? coming_date : 'No Call'}}
                        </a> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Audit feedback" [width]="" prop="audit_feedback">
                <ng-template ngx-datatable-cell-template let-audit_feedback="value" let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two" (click)="modalOpen(fullAuditFeedbackModal, row)">
                            {{audit_feedback ? audit_feedback : 'No Feed'}}
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="150">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill text-capitalize" [ngClass]="{
                      'badge-light-success': status == 'confirm',
                      'badge-light-primary': status == 'hold'
                    }">
                        {{ status ? status : '-' }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="200" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="badge badge-light-dark" (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </a>
                        <button class="btn btn-outline-success active border__none ml-50"
                            (click)="modalOpen(feedbackModal, row)" ngbTooltip="Feedback"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Reg Payments List Datatables -->

<!-- Feedback Modal-->
<ng-template #feedbackModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Feedback</h4>
        <button type="button" class="close" (click)="closesubmitfbForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="feedbackSubmitForm" (ngSubmit)="submitfbForm()">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.patient_unique_id ?
                                        selectedPatientFeedback.patient_unique_id : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p class="person_name_modal">{{selectedPatientFeedback.patient_name ?
                                        selectedPatientFeedback.patient_name : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.mobile ? selectedPatientFeedback.mobile : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Confirm / Hold</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <div class="form-group">
                                        <ng-select [items]="feedbackStatus" bindLabel="text" bindValue="value"
                                            placeholder="Select" formControlName="feedback_status"
                                            [class.is-invalid]="feedback_status.invalid && feedback_status.touched">
                                        </ng-select>
                                        <div *ngIf="feedback_status.invalid && feedback_status.touched">
                                            <div class="text-danger" *ngIf="feedback_status.errors?.required">Feedback
                                                status is required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-1">
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <label>Reason for OC</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <div class="form-group">
                                        <textarea rows="3" class="form-control" formControlName="remarks"
                                            [class.is-invalid]="remarks.invalid && remarks.touched">
                                        </textarea>
                                        <div *ngIf="remarks.invalid && remarks.touched">
                                            <div class="text-danger" *ngIf="remarks.errors?.required">This field
                                                is required.</div>
                                            <div class="text-danger" *ngIf="remarks.errors?.pattern">
                                                This field can not contain spaces.
                                            </div>
                                            <div class="text-danger" *ngIf="remarks.errors?.maxlength">
                                                Do not enter more than 500 characters.
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="feedbackSubmitForm.controls.remarks.hasError('serverValidationError')">
                                            <small class="text-danger">{{
                                                backendValidations['remarks']
                                                }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="feedbackUpdateLoading | async" type="submit" class="btn btn-primary">
                                <span *ngIf="feedbackUpdateLoading | async"
                                    class="spinner-border spinner-border-sm mr-1"></span>Submit
                            </button>
                            <button type="reset" class="btn btn-secondary ml-50">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Feedback Modal-->

<!-- Today's Calls Modal -->
<todaycalls-modal #todayCallsModal></todaycalls-modal>
<!--/ Today's Calls Modal -->

<!-- Patient Invoice Modal-->
<invoice-modal #invoiceModal></invoice-modal>
<!-- / Patient Invoice Modal-->

<!-- Feedback Modal -->
<ng-template #fullFeedbackModal let-modal>
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="myModalLabel1">Feedback</h4> -->
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p>{{selectedPatientFeedback.feedback ? selectedPatientFeedback.feedback : 'No Feed'}}</p>
    </div>
</ng-template>
<!-- / Feedback Modal -->

<!-- Audit Feedback Modal -->
<ng-template #fullAuditFeedbackModal let-modal>
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="myModalLabel1">Feedback</h4> -->
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p>{{selectedPatientFeedback.audit_feedback ? selectedPatientFeedback.audit_feedback : 'No Feed'}}</p>
    </div>
</ng-template>
<!-- / Feedback Modal -->