import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from "environments/environment";

@Component({
  selector: 'circular-modal',
  templateUrl: './circular-modal.component.html',
  styleUrls: ['./circular-modal.component.scss']
})
export class CircularModalComponent implements OnInit {

  selectedCircular: any;
  modalRef: any;
  @ViewChild("circularModal") circularModal: TemplateRef<any>;
  file_path: any;
  constructor(private modalService: NgbModal,
    public sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
  }

  /**
   * Open Circular Modal
   */
  openModal(circular){
    this.selectedCircular = circular;
    // let path = `https://docs.google.com/gview?url=${circular.path}&embedded=true`;  useful for emdedding docx file to iframe
    this.file_path = this.sanitizer.bypassSecurityTrustResourceUrl(
      environment.apiUrl + "/" + circular.path
    );
    this.modalRef = this.modalService.open(this.circularModal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true,
      size: "lg"
    });
  }

}
