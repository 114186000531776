import { CoreMenu } from "@core/types";

export const doctorPanelMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url:'hm'
  },
  {
    id: "target",
    title: "Target",
    
    type: "section",
    icon: "maximize-2",
    children: [
      {
        id:"due list",
        title:"Due List",
        type:"item",
        icon:"disc",
        url:"targets/due-list"
      },
      {
        id:"renewal",
        title:"Renewal",
        type:"item",
        icon:"disc",
        url:"targets/renewals"
      },
      {
        id:"followup",
        title:"Follow up",
        type:"item",
        icon:"disc",
        url:"targets/followups"
      },
      {
        id:"target",
        title:"Target(This)",
        type:"item",
        icon:"disc",
        url:"targets/thismonth-target"
      },
      {
        id:"this month",
        title:"This Month",
        type:"item",
        icon:"disc",
        url:"targets/thismonth"
      },
      // {
      //   id:"sms",
      //   title:"Sms",
      //   type:"item",
      //   icon:"disc",
      //   url:"targets/day-status"
      // },
    ],
  },
  {
    id: "reports",
    title: "All Reports",
    type: "section",
    icon: "file-text",
    children: [
      {
        id: "renewal target",
        title: "Renewal Target",
        type: "item",
        icon: "disc",
        url: "reports/renewal-target",
      },
      {
        id: "referral target",
        title: "Referral Target",
        type: "item",
        icon: "disc",
        url: "reports/referral-target",
      },
      {
        id: "special target",
        title: "Special Target",
        type: "item",
        icon: "disc",
        url: "reports/special-target",
      },
      {
        id: "total status",
        title: "Doctor wise total status",
        type: "item",
        icon: "disc",
        url: "reports/doctor-wise-total-status",
      },
      {
        id: "oc to reg",
        title: "OC to Reg",
        type: "item",
        icon: "disc",
        url: "reports/oc-to-reg",
      },
      {
        id: "dropouts",
        title: "Dropouts",
        type: "item",
        icon: "disc",
        url: "reports/dropouts",
      },
      {
        id: "datewise summary",
        title: "DateWise Summary",
        type: "item",
        icon: "disc",
        url: "reports/date-wise-summary-report",
      },
      {
        id: "expenditure",
        title: "Expenditures",
        type: "item",
        icon: "disc",
        url: "reports/expenditure-report",
      },
      {
        id: "main",
        title: "Main",
        type: "item",
        icon: "disc",
        url: "reports/main-report",
      },
    ],
  },
  {
    id: "patients",
    title: "Patients",
    type: "item",
    icon: "users",
    url: "patients",
  },
  {
    id: "search",
    title: "Search",
    type: "item",
    icon: "search",
    url:"search"
  }
];
