import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '@foe/auth/login/login.component';
import { FoeGuard } from '@foe/auth/foe-guard/foe.guard';
import { FoeHomeComponent } from '@foe/foe-home/foe-home.component';
import { ForgotPasswordComponent } from '@foe/auth/forgot-password/forgot-password.component';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, canActivate: [FoeGuard]},
	{ path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [FoeGuard]},
	{ path: 'home', component: FoeHomeComponent, loadChildren: () => import('@foe/foe-home/foe-home.module').then(m => m.FoeHomeModule), canLoad: [FoeGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FrontDeskRoutingModule { }
