import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuditGuardGuard } from './auth/audit-guard/audit-guard.guard';
import { LoginComponent } from './auth/login/login.component';
import { BirthdayComponent } from './birthday/birthday.component';
import { CallingComponent } from './calling/calling.component';
import { CircularComponent } from './circular/circular.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { CaseSheetComponent } from '@accounts/components/case-sheet/case-sheet.component';


const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, canActivate: [AuditGuardGuard]},
	{ path: 'home', component: HomeComponent,  canLoad: [AuditGuardGuard],

	children: [
		{
		  path: 'hm', // child route path
		  component: DashboardComponent, // child route component that the router renders
		},
		{
		  path: 'birthday',
		  component: BirthdayComponent, // another child route component that the router renders
		},
		{
		  path: 'circular',
		  component: CircularComponent, // another child route component that the router renders
		},
		{
		  path: 'calling',
		  component: CallingComponent, // another child route component that the router renders
		},
		{ path: 'case-sheet/:id', component: CaseSheetComponent},
	  ],
},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuditRoutingModule { }
