import { Component, OnInit,ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-renewal-drop-list',
  templateUrl: './renewal-drop-list.component.html',
  styleUrls: ['./renewal-drop-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RenewalDropListComponent implements OnInit {

  public search_text: any;
  public basicSelectedOption: number = 500;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  isShown: boolean = false;
  pageHeading: any;

  toggleShow() {
    this.isShown = !this.isShown;
  }

    renewalDropListFilterForm : FormGroup = this._formBuilder.group({
      fpatient_unique_id: [null],
      fpatient_name: [null]
    });

    // Private
    private _unsubscribeAll: Subject<any>;
    private tempData = [];
  
    // public
    public rows: any;
    public selected = [];
    public kitchenSinkRows: any;
    public ColumnMode = ColumnMode;
    public expanded = {};
    public chkBoxSelected = [];
    public SelectionType = SelectionType;

   public renewalDropListData : any[] = [];

    @ViewChild(DatatableComponent) table: DatatableComponent;
    @ViewChild('tableRowDetails') tableRowDetails: any;
  
    // snippet code variables
    public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
    public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private _router: Router,
    private _service: ProService) { 
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getRenewalDropList();

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.renewalDropListFilterForm.value.page = 1;
      this.getRenewalDropList();
    });
  }

  /* Get New Patient Billing Listing */
  getRenewalDropList() {
    this.renewalDropListFilterForm.value.perpage = this.basicSelectedOption;
    this.renewalDropListFilterForm.value.fsearch_text = this.search_text;

    this._service.getRenewalDropList(this.renewalDropListFilterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        // this.noDuePaymentsData = data['noDueList'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.renewalDropListFilterForm.value.fpatient_unique_id || this.renewalDropListFilterForm.value.fpatient_name) {
      return false;
    } else {
      return true;
    }
  }

  /* Redirect CaseSheet */
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/pro/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getRenewalDropList();
  }

  /* Reset Filter */
  resetFilter() {
    this.renewalDropListFilterForm.reset();
    this.getRenewalDropList();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.renewalDropListFilterForm.value.perpage = this.basicSelectedOption;
    this.renewalDropListFilterForm.value.page = 1;
    this.getRenewalDropList();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.renewalDropListFilterForm.value.page = $event.page;
    this.getRenewalDropList();
  }

  modalOpen(modal) {
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
    });
  }

  goBack(){
    window.history.back();
  }
  
  restorePatientDetails(){
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: "You want to restore!",
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   customClass: {
    //     confirmButton: 'btn btn-primary',
    //     cancelButton: 'btn btn-danger ml-1'
    //   }
    // }).then(function (result) {
    //   if (result.value) {
    //     Swal.fire({
    //       title: 'Restored!',
    //       text: 'Successfully Restored.',
    //       icon: 'success',
    //       customClass: {
    //         confirmButton: 'btn btn-success'
    //       }
    //     });
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     Swal.fire({
    //       title: 'Cancelled',
    //       text: '',
    //       icon: 'error',
    //       customClass: {
    //         confirmButton: 'btn btn-success'
    //       }
    //     });
    //   }
    // });
  }

}
