import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FoeService } from '@foe/services/foe.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {

  selectedPatientId: any;
  public modalRef: any;
  invoiceDetails: any;
  overall_payments: any;
  consultation_fee: any;
  patient_billings: any;
  is_show: any;
  visitDetails: any;

  @ViewChild("invoiceModal") invoiceModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: FoeService) { }

  ngOnInit(): void {
  }

  ConvertToInt(val){
    val = val ? val : 0;
    return parseInt(val);
  }

  isNumber(val) {
    return !isNaN(val);
  }
  
  /* Open Patient Information Modal */
  openModal(patient_id) {
  	this.selectedPatientId = patient_id;
  	this.getPatientInvoiceData();
  	this.modalRef = this.modalService.open(this.invoiceModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
      	size: "xl",
  	});
  }
  openModal2(patient_id) {
  	this.selectedPatientId = patient_id;
  	this.getPatientVisitData();
  	this.modalRef = this.modalService.open(this.invoiceModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
      	size: "xl",
  	});
  }
  getPatientVisitData() {
  	this._service.getPatientVisitData(this.selectedPatientId)
  	.subscribe(res => {
  	    let data = res['data'];
  	    this.visitDetails = data['visit'];
        this.is_show = '2';      
  	}, err => {
  	  this.notifyService.showError(err['message'], err['title']);
  	});
  }
  /* Get Patient Invoice Data */
  getPatientInvoiceData() {
  	this._service.getPatientInvoiceData(this.selectedPatientId)
  	.subscribe(res => {
  	  if (res['status'] == 'success') {
  	    let data = res['data'];
  	    this.invoiceDetails = data['invoice'];
        if (this.invoiceDetails) {  
          this.is_show = '1';      
          this.overall_payments = this.invoiceDetails['overall_payments'];
          this.consultation_fee = this.invoiceDetails['consultation_fee'];
          this.patient_billings = this.invoiceDetails['patient_billings'];
        }
  	  }
  	}, err => {
  	  this.notifyService.showError(err['message'], err['title']);
  	});
  }
}
