<ng-template #commentsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Comments</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p class="txt-ovrflow-wrap" *ngIf="comment">{{comment}}</p>
    </div>
</ng-template>
