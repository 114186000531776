import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class FoeService {

  constructor(protected httpClient: AppHttpClient) { }
  
  dasboardData() {
    return this.httpClient.get('foeapi/get_home_content');
  }
  getPatientVisitData(patientId){
    return this.httpClient.get('foeapi/get_visit_data/' + patientId);

  }
  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('foeapi/login', credentials);
  }

  resetPassword(credentials: object) {
    return this.httpClient.post('foeapi/resetPassword', credentials);
  }

  changePassword(credentials: object) {
    return this.httpClient.post('foeapi/changePassword', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('foeapi/me');
  }

  getCircular() {
    return this.httpClient.get('foeapi/dashboard/get_circulars');
  }

  attendanceListing(filterValues: object) {
    return this.httpClient.post('foeapi/dashboard/attendance_list', filterValues);
  }

  /* R-Form Fir Form APIS */
  generateFirForm(payload: object) {
    return this.httpClient.post('foeapi/rforms/fir_form', payload)
  }

  refundForm(payload: object) {
    return this.httpClient.post('foeapi/rforms/refund_form', payload);
  }

  getBranchCCAgents() {
    return this.httpClient.get('foeapi/rforms/get_cc_agents');
  }

  addNewPatient(payload: object) {
    return this.httpClient.post('foeapi/rforms/add_new_patient', payload);
  }
  
  /* Leads APIS */
  getLeadsData(payload: object) {
    return this.httpClient.post('foeapi/leads/get-leads', payload);
  }

  todayTransfers(payload: object) {
    return this.httpClient.post('foeapi/rforms/today_transfer_report', payload);
  }

  /* R-Form OP-CO-TR API's */
  searchPatient(payload: object) {
    return this.httpClient.post('foeapi/rforms/get_patient_details', payload);
  }

  updateOldPatient(payload: object) {
    return this.httpClient.post('foeapi/rforms/old_patient_update', payload);
  }

  /* Print APIS */
  consultationListing(payload: object) {
    return this.httpClient.post('foeapi/print/consultations', payload);
  }

  firFormsListing(payload: object) {
    return this.httpClient.post('foeapi/print/fir_forms', payload);
  }

  patientsBilling(payload: object) {
    return this.httpClient.post('foeapi/print/patients_billing', payload);
  }

  /* Follow Ups Listing API */
  getRenewalCalls(payload: object) {
    return this.httpClient.post('foeapi/followups/renewals', payload);
  }

  getFollowUpsListing(payload: object) {
    return this.httpClient.post('foeapi/followups/followups', payload);
  }

  getMissedFollowUps(payload: object) {
    return this.httpClient.post('foeapi/followups/missed_followup', payload);
  }

  getDateWiseCalls(payload: object) {
    return this.httpClient.post('foeapi/followups/date_wise_old_patient_Call', payload);
  }

  getDuePaymentListing(payload: object) {
    return this.httpClient.post('foeapi/followups/due_payment_list', payload);
  }

  getPatientInvoiceData(patientId) {
    return this.httpClient.get('foeapi/get_patient_invoice/' + patientId);
  }

  getRegPaymentListing(payload: object) {
    return this.httpClient.post('foeapi/followups/reg_oc_list', payload);
  }


  submitFeedBackForm(payload: object) {
    return this.httpClient.post('foeapi/add_patient_feedback', payload);
  }

  getFeedBackLogs(payload: object) {
    return this.httpClient.post('foeapi/patient_feedbacks_log', payload);
  }

  drop_patient(payload: object) {
    return this.httpClient.post('foeapi/followups/drop_patient', payload);
  }

  getCaseSheet(patientId) {
    return this.httpClient.get('foeapi/get_casesheet/' + patientId)
  }

  /* Reports APIS */
  getBranchTransfersReports(payload: object) {
    return this.httpClient.post('foeapi/reports/transfer-patient-report', payload);
  }

  getBranchWiseReports(api, payload: object) {
    return this.httpClient.post('foeapi/' + api, payload);
  }

  getDateWiseReports(api, payload: object) {
    return this.httpClient.post('foeapi/' + api, payload);
  }

  getDropOutsManually(payload: object) {
    return this.httpClient.post('foeapi/reports/dropout-list-manually', payload);
  }

  getDropOutsAutomatic(payload: object) {
    return this.httpClient.post('foeapi/reports/dropout-list-automatically', payload);
  }

  newPatientReportListing(payload: object) {
    return this.httpClient.post('foeapi/reports/edit_report', payload);
  }

  editPatientDoctor(payload: object) {
    return this.httpClient.post('foeapi/reports/edit_summary_report', payload);
  }

  getPatientDetails(patient_id) {
    return this.httpClient.get('foeapi/get_patient_information/' + patient_id);
  }

  /* Search APIs */
  patientsListing(payload: object) {
    return this.httpClient.post('foeapi/search/search-patients', payload);
  }

  receiptsListing(payload: object) {
    return this.httpClient.post('foeapi/search/search-receipts', payload);
  }

  allDetailsListing(payload: object) {
    return this.httpClient.post('foeapi/search/search-all-details', payload);
  }

  getEmployeeList(payload: object) {
    return this.httpClient.post('foeapi/get_employee_list', payload);
  }
}
