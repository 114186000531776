import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, Validators } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { CallcenterService } from '@callcenter/services/callcenter.service';

@Component({
  selector: 'edit-leads',
  templateUrl: './edit-leads.component.html',
  styleUrls: ['./edit-leads.component.scss']
})
export class EditLeadsComponent implements OnInit {

  selectedLead: any;
  public modalRef: any;
  @ViewChild("editModal") editModal: TemplateRef<any>;
  @Output() public reloadLeads = new EventEmitter<String>();
  public loading = new BehaviorSubject(false);
  backendValidations: any;

  // Edit Lead Form
  editLeadForm = this.fb.group({
    name: [null, Validators.required],
    mobile: [null, [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]],
    email: [null, [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
    address: [null, Validators.required],
    branch_id: [null, Validators.required],
    remarks: [null, Validators.required],
    followup_date: [null, Validators.required]
  });

  get name() { return this.editLeadForm.get('name'); }
  get mobile() { return this.editLeadForm.get('mobile'); }
  get email() { return this.editLeadForm.get('email'); }
  get address() { return this.editLeadForm.get('address'); }
  get branch_id() { return this.editLeadForm.get('branch_id'); }
  get remarks() { return this.editLeadForm.get('remarks'); }
  get followup_date() { return this.editLeadForm.get('followup_date'); }

  errorMessages = {
    name: [{ type: 'required', message: 'This Field is Required' }],
    mobile: [{ type: 'required', message: 'This Field is Required' }, { type: 'pattern', message: 'This field is Invalid format' }],
    email: [{ type: 'required', message: 'This Field is Required' }, { type: 'pattern', message: 'The field is Invalid format' }],
    address: [{ type: 'required', message: 'This Field is Required' }],
    branch_id: [{ type: 'required', message: 'This Field is Required' }],
    remarks: [{ type: 'required', message: 'This Field is Required' }],
    followup_date: [{ type: 'required', message: 'This Field is Required' }],
  }

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private _service: CallcenterService,
    private notifyService: NotificationService,) { }

  ngOnInit(): void {
  }

  openModal(selectedLead) {
    this.selectedLead = selectedLead;
    if (selectedLead) {
      this.patchLeadForm(this.selectedLead);
    }
    this.modalRef = this.modalService.open(this.editModal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true,
    });
  }

  /* Patch Lead Form When Edit Action is Used */
  patchLeadForm(lead) {
    this.editLeadForm.patchValue({
      'name': lead.name,
      'mobile': lead.mobile,
      'email': lead.email,
      'address': lead.address,
      'branch_id': lead.branch_id,
      'remarks': lead.feedback,
      'appointment_date': lead.appointment_date
    });
  }

  // Edit Leads Form 
  editLeads() {
    this.editLeadForm.markAllAsTouched();
    console.log(this.editLeadForm.value);
    const invalid = [];
    const controls = this.editLeadForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    if (this.editLeadForm.valid) {
      this.loading.next(true);
      this.editLeadForm.value.id = this.selectedLead.id;
      this._service.editLead(this.editLeadForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closeLeadForm();
            this.reloadLeads.emit('reload');
          } else if (res['status'] == 'errors') {
            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.editLeadForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  closeLeadForm() {
    this.editLeadForm.reset();
    this.modalService.dismissAll();
  }

}
