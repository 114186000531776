<div *ngIf="horizontalMenu" class="navbar-header logo-align-left d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" (click)="home()">
        <span class="brand-logo">
          <img class="app-logo" src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" />
        </span>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{_currentUser.getName()}}</span><span class="user-status">{{_currentUser.getEmail()}}</span>
        </div>
        <span class="avatar"><div class="circle">{{_currentUser.getAvatar()}}</div><span class="avatar-status-online"></span></span>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a class="pl-75" ngbDropdownItem routerLinkActive="active" (click)="myPayslip()"><span [data-feather]="'file'" [class]="''"></span> My Payslips</a> -->
        <!-- <a class="pl-75" ngbDropdownItem routerLinkActive="active" (click)="changePassword()"><span [data-feather]="'lock'" [class]="''"></span> Change Password</a> -->
        <a class="pl-75" ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="''"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>