<ng-template #billingReceiptModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Billing Receipt</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus id="billingPrint">
        <center>
            <div style="border:solid 2px #0099FF;width:1000px;">
                <p align="center"><img src="{{currentUser.getLogo()}}" width="40%" border="0"></p>
                <p></p>
                <p>
                </p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">
                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;">
                                <div [innerHtml]="currentUser.getBranchAddress()"></div>
                                <!-- <table width="100%" style="font-size:13px;font-family:arial">
                                    <tbody>
                                        <tr>
                                            <td>A UNIT OF POSITIVE LIFE SCIENCES PVT.LTD 1-73/JH/4 &amp;11,Challas
                                                jahanavi arcade Above Win Vision Eye Hospitals,4th
                                                floor,Gachibowli,Hyderbad-500032,Telangana. PH:7997995008 </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                                <!-- {{ currentUser.getBranchAddress() }} -->
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td width="41%"><span style="color:#0099FF">Receipt No. :</span>
                                {{billing_details.receipt_no}} </td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> RECEIPT</b></td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span>
                                {{billing_details.billing_date ? (billing_details.billing_date | date:'dd-MM-yyyy' ) :
                                "-"}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Patients Name :</span> {{billing_details.first_name}}
                                {{billing_details.surname}}
                            </td>
                            <td><span style="color:#0099FF">Age :</span> {{billing_details.age}} </td>
                            <td><span style="color:#0099FF">Sex :</span> {{billing_details.gender == 'm' ? 'Male' :
                                billing_details.gender == 'f' ? 'Female' : 'Others'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No. :</span>{{billing_details.patient_unique_id}}/
                                {{billing_details.branch_patient_id}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Package Period :</span> {{billing_details.package_name}}
                                {{billing_details.package_name == 1 ? "Month" : "Months"}} </td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{billing_details.received_amount}}
                                /-&nbsp;&nbsp;&nbsp;(Rs. : Ns)</td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF">Payment by :</span>
                                {{billing_details.payment_option == 'c' ? 'Cash' :
                                billing_details.payment_option == 'ch' ? 'Cheque' :
                                billing_details.payment_option == 'cc' ? 'Credit Card' :
                                billing_details.payment_option == 'emi' ? 'EMI' :
                                billing_details.payment_option == 'o' ? 'Online' : '-'}}</td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == '0'">
                            <td><span style="color:#0099FF">Bank Name : </span> {{billing_details.online_bank_name}}
                            </td>
                            <td></td>
                            <td><span style="color:#0099FF">Account No :</span> {{billing_details.online_accout_no}}
                            </td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == 'ch'">
                            <td><span style="color:#0099FF">Cheque No : </span> {{billing_details.cheque_no}}</td>
                            <td></td>
                            <td><span style="color:#0099FF">Cheque Date :</span> {{billing_details.cheque_valid}}</td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == 'cc'">
                            <td><span style="color:#0099FF">Invoice No : </span> {{billing_details.invoice_no}}</td>
                            <td></td>
                            <td><span style="color:#0099FF">Card Type :</span> {{billing_details.card_type}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Fee for :</span> <i>{{billing_details.payment_for}}</i>
                            </td>
                            <td></td>
                            <td align="right"><span style="color:#0099FF">Due / Balance :</span> Rs.
                                {{billing_details.due_amount}}
                            </td>
                        </tr>

                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.

                                    *The Doctor and the clinic has given no guarantee to me about the results and
                                    duration of the treatment.<br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.</p>
                            </td>
                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;text-align:center;">
                            <td>Patient / Attendants Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center">NOTE:FOR NEW
                                APPOINTMENT CALL : 9290901010</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>
        <br>
        <hr>
        <br>
        <center>
            <div style="border:solid 2px #0099FF;width:1000px;">
                <p align="center"><img src="{{currentUser.getLogo()}}" width="40%" border="0"></p>
                <p></p>
                <p>
                </p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">
                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;">
                                <div [innerHtml]="currentUser.getBranchAddress()"></div>
                                <!-- <table width="100%" style="font-size:13px;font-family:arial">
                                    <tbody>
                                        <tr>
                                            <td>A UNIT OF POSITIVE LIFE SCIENCES PVT.LTD 1-73/JH/4 &amp;11,Challas
                                                jahanavi arcade Above Win Vision Eye Hospitals,4th
                                                floor,Gachibowli,Hyderbad-500032,Telangana. PH:7997995008 </td>
                                        </tr>
                                    </tbody>
                                </table> -->
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>
                        <tr>
                            <td width="41%"><span style="color:#0099FF">Receipt No. :</span>
                                {{billing_details.receipt_no}} </td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> RECEIPT</b></td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span>
                                {{billing_details.billing_date ? (billing_details.billing_date | date:'dd-MM-yyyy' ) :
                                "-"}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Patients Name :</span> {{billing_details.first_name}}
                                {{billing_details.surname}}
                            </td>
                            <td><span style="color:#0099FF">Age :</span> {{billing_details.age}} </td>
                            <td><span style="color:#0099FF">Sex :</span> {{billing_details.gender == 'm' ? 'Male' :
                                billing_details.gender == 'f' ? 'Female' : 'Others'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No. :</span>{{billing_details.patient_unique_id}}/
                                {{billing_details.branch_patient_id}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Package Period :</span> {{billing_details.package_name}}
                                {{billing_details.package_name == 1 ? "Month" : "Months"}} </td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{billing_details.received_amount}}
                                /-&nbsp;&nbsp;&nbsp;(Rs. : Ns)</td>
                        </tr>

                        <tr>
                            <td colspan="3"><span style="color:#0099FF">Payment by :</span>
                                {{billing_details.payment_option == 'c' ? 'Cash' :
                                billing_details.payment_option == 'ch' ? 'Cheque' :
                                billing_details.payment_option == 'cc' ? 'Credit Card' :
                                billing_details.payment_option == 'emi' ? 'EMI' :
                                billing_details.payment_option == 'o' ? 'Online' : '-'}}</td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == '0'">
                            <td><span style="color:#0099FF">Bank Name : </span> {{billing_details.online_bank_name}}
                            </td>
                            <td></td>
                            <td><span style="color:#0099FF">Account No :</span> {{billing_details.online_accout_no}}
                            </td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == 'ch'">
                            <td><span style="color:#0099FF">Cheque No : </span> {{billing_details.cheque_no}}</td>
                            <td></td>
                            <td><span style="color:#0099FF">Cheque Date :</span> {{billing_details.cheque_valid}}</td>
                        </tr>

                        <tr *ngIf="billing_details.payment_option == 'cc'">
                            <td><span style="color:#0099FF">Invoice No : </span> {{billing_details.invoice_no}}</td>
                            <td></td>
                            <td><span style="color:#0099FF">Card Type :</span> {{billing_details.card_type}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Fee for :</span> <i>{{billing_details.payment_for}}</i>
                            </td>
                            <td></td>
                            <td align="right"><span style="color:#0099FF">Due / Balance :</span> Rs.
                                {{billing_details.due_amount}}
                            </td>
                        </tr>

                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.

                                    *The Doctor and the clinic has given no guarantee to me about the results and
                                    duration of the treatment.<br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.</p>
                            </td>
                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;text-align:center;">
                            <td>Patient / Attendants Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center">NOTE:FOR NEW
                                APPOINTMENT CALL : 9290901010</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>
        <div class="text-center mt-50">
            <button class="btn btn-primary" (click)="print()">Print</button>
        </div>
    </div>
</ng-template>