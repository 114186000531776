import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import * as snippet from "app/main/tables/datatables/datatables.snippetcode";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PatientsComponent implements OnInit {

  selectedPatientFeedback: any;
  feedbackUpdateLoading = new BehaviorSubject(false);
  backendValidations: any;
  public search_text: any;
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  isShown: boolean = false;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  currentDate = new Date();

  todayPatientsFilter = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_name: [],
    fpatient_type: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  toggleShow() {
    this.isShown = !this.isShown;
  }
  public patientType = [
    { id: 1, title: "New Patient", value: "new" },
    { id: 2, title: "Old Patient", value: "old" },
  ];
  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  public todayPatientsData: any[] = [
    {
      id: 1,
      responsive_id: "",
      s_no: "1",
      p_id: "TS08-1648990289",
      name: "N Shiva Prasad",
      mobile: "9878675644",
      patient_type: "New Patient",
    },
    {
      id: 2,
      responsive_id: "",
      s_no: "2",
      p_id: "TS08-1648990289",
      name: "S Nandhan Kumar",
      mobile: "9878675644",
      patient_type: "Old patient",
    },
    {
      id: 3,
      responsive_id: "",
      s_no: "3",
      p_id: "TS08-1648990289",
      name: "M Radha",
      mobile: "9878675644",
      patient_type: "New Patient",
    },
  ];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private _service: DocService,
    public _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private notifyService: NotificationService,
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getTodaysPatient();

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.todayPatientsFilter.value.page = 1;
      this.getTodaysPatient();
    });
  }

  /* Get Today Patients */
  getTodaysPatient() {
    this.todayPatientsFilter.value.perpage = this.basicSelectedOption;
    this.todayPatientsFilter.value.fsearch_text = this.search_text;

    this._service.getTodaysPatient(this.todayPatientsFilter.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        this.todayPatientsData = data['todayPatientsList'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  getRowClass = (row) => {    
    var ToDate = new Date().getTime();
    var expDate = new Date(row.date_of_expire).getTime()
    console.log(row);
    return {
      'row-color1': row.total_due == 0,
      'row-color2': row.total_due > 0 && ToDate < expDate,
      'row-color3': row.total_due > 0 && ToDate > expDate,
    };
   }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.todayPatientsFilter.value.fpatient_unique_id || this.todayPatientsFilter.value.fpatient_name || this.todayPatientsFilter.value.fpatient_type) {
      return false;
    } else {
      return true;
    }
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getTodaysPatient();
  }

  /* Reset Filter */
  resetFilter() {
    this.todayPatientsFilter.reset();
    this.getTodaysPatient();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.todayPatientsFilter.value.perpage = this.basicSelectedOption;
    this.todayPatientsFilter.value.page = 1;
    this.getTodaysPatient();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.todayPatientsFilter.value.page = $event.page;
    this.getTodaysPatient();
  }

  /* Redirect CaseSheet */
  caseSheetUpdate(patientId) {
    let link = environment.subdirUrl + '/doctor/home/patient-case-sheet/' + patientId;
    // this._router.navigate([]).then(result => { window.open(link, '_blank'); });
    this._router.navigate([link]);
  }

  modalOpen(modal) {
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
    });
  }

  patientInfoModal(modalXL) {
    this.modalService.open(modalXL, {
      centered: true,
      size: "xl",
    });
  }
}
