<ng-template #prescriptionDetailsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Prescription Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="row dynamic-details existingInformation-data">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.patient_unique_id }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Branch Patient ID</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.branch_patient_id }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{ data.patient_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Doctor Name</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{ data.doc_name }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Gender</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.gender == 'm' ? 'Male' : (data.gender == 'f' ? 'Female' : data.gender == 'o' ? 'Others' : '-') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Age</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.age }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Drug Name</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="badge badge-success">{{ data.medicine }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Potency</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.potency }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>SD</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.single_dose }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>M</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.morning }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>A</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.afternoon }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>N</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.night }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>SOS</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.sos }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Special Medicine</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.special_med }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>No.of Days Medicine <b>(Doctor)</b></label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.no_of_days_medicines }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>No.of Days Medicine <b>(PRO/MANAGER)</b></label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.no_of_days_medicine_pro ? data.no_of_days_medicine_pro : "Not given the medicine days" }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Prescribed Date</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ data.prescribe_date }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>