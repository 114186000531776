export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      EMPLOYEES:'Employees',
      REPORTS: {
        SECTION: 'Reports',
        REPORTS: 'Reports',
        PAYSLIPS: 'Payslips'
      },
      ATTENDANCE: 'Attendance',
      SEARCH : 'Search'
      // SAMPLE: 'Sample',
      // APPS: {
      //   SECTION: 'Apps & Pages',
      //   EMAIL: 'Email',
      //   CHAT: 'Chat',
      //   TODO: 'Todo',
      //   CALENDAR: 'Calendar',
      // },
    }
  }
}
