import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { ProfileComponent } from '@global_components/profile/profile.component';
import { LayoutModule } from 'app/layout/layout.module';
import { CircularModalComponent } from './circular-modal/circular-modal.component';
import { BillingReceiptModalComponent } from './billing-receipt-modal/billing-receipt-modal.component';
import { ConsultationReceiptModalComponent } from './consultation-receipt-modal/consultation-receipt-modal.component';
import { MidGtModalComponent } from './month-report-modals/mid-gt-modal/mid-gt-modal.component';
import { CommonPatientsModalComponent } from './month-report-modals/common-patients-modal/common-patients-modal.component';
import { EmployeeSearchComponent } from './employee-search/employee-search.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { PrescriptionModalComponent } from './prescription-modal/prescription-modal.component';
import { LoaderComponent } from './loader/loader.component';
import { AuditBranchFedModalComponent } from './audit-branch-fed-modal/audit-branch-fed-modal.component';
import { AchievedUptoModalComponent } from './achieved-upto-modal/achieved-upto-modal.component';
import { CcPhPatientDetailsComponent } from './cc-ph-patient-details/cc-ph-patient-details.component';
import { ConsultationModalComponent } from './consultation-modal/consultation-modal.component';

@NgModule({
  declarations: [ProfileComponent,ConsultationModalComponent, CircularModalComponent, BillingReceiptModalComponent, ConsultationReceiptModalComponent, MidGtModalComponent, CommonPatientsModalComponent, EmployeeSearchComponent, InvoiceModalComponent, PrescriptionModalComponent, LoaderComponent, AuditBranchFedModalComponent, AchievedUptoModalComponent, CcPhPatientDetailsComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    LayoutModule
  ],exports: [
    CircularModalComponent,
    BillingReceiptModalComponent,
    ConsultationReceiptModalComponent,
    MidGtModalComponent,
    CommonPatientsModalComponent,
    EmployeeSearchComponent,
    InvoiceModalComponent,
    PrescriptionModalComponent,
    LoaderComponent,
    AuditBranchFedModalComponent,
    AchievedUptoModalComponent,
    CcPhPatientDetailsComponent
  ]
})
export class GlobalComponentsModule { }
