import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from '@services/current-user/current-user.service';

@Component({
  selector: 'billing-receipt-modal',
  templateUrl: './billing-receipt-modal.component.html',
  styleUrls: ['./billing-receipt-modal.component.scss']
})
export class BillingReceiptModalComponent implements OnInit {

  @ViewChild("billingReceiptModal") billingReceiptModal: TemplateRef<any>;
  billing_details: any;
  constructor(private modalService: NgbModal,
    private currentUser: CurrentUserService
  ) { }

  ngOnInit(): void {

  }

  /* Open Modal */
  openModal(billing) {
    this.billing_details = billing;
    this.modalService.open(this.billingReceiptModal, {
      windowClass: "modal",
      centered: true,
      size: "xl",
    });
  }
  /* Print Billing Recipt*/
  print() {
    const printContent = document.getElementById("billingPrint");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
