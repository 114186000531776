import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '@core/services/notification.service';
import { ProService } from '@pro/services/pro.service';

@Component({
  selector: 'feedbacklog-modal',
  templateUrl: './feedbacklog-modal.component.html',
  styleUrls: ['./feedbacklog-modal.component.scss']
})
export class FeedbacklogModalComponent implements OnInit {

  public feedbacklog: any;
  public patient_id: any;
  public modalRef: any;
  public purpose: any;
	@ViewChild("feedBackLogModal") feedBackLogModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: ProService) { }

  ngOnInit(): void {
  }

  /* Open FeedBack Modal */
  openModal(patient_id, purpose) {
    this.patient_id = patient_id;
    this.purpose = purpose;
    this.getFeedBackLogs();
  	this.modalRef = this.modalService.open(this.feedBackLogModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
  	});
  }

  /* Get FeedBackLogs */
  getFeedBackLogs() {
    let payload = Object();
    Object.assign(payload, {id: this.patient_id});
    Object.assign(payload, {purpose: this.purpose});
    this._service.getFeedBackLogs(payload)
    .subscribe(res => {
      if (res['status'] == 'success') {
        let data = res['data'];
        this.feedbacklog = data;
        console.log(this.feedbacklog);
      }
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });
  }

  /* Get Foe Short Name */
  getShortName(name) {
    return name.split(' ').map(n => n[0]).join('');
  }
}
