import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PharmacyGuard } from '@pharmacy/auth/pharmacy-guard/pharmacy.guard';
import { LoginComponent } from '@pharmacy/auth/login/login.component';
import { PharmacyHomeComponent } from '@pharmacy/pharmacy-home/pharmacy-home.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [PharmacyGuard] },
  { path: 'home', component: PharmacyHomeComponent, loadChildren: () => import('@pharmacy/pharmacy-home/pharmacy-home.module').then(m => m.PharmacyHomeModule), canLoad: [PharmacyGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PharmacyRoutingModule { }
