import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FoeService } from '@foe/services/foe.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'todaycalls-modal',
  templateUrl: './todaycalls-modal.component.html',
  styleUrls: ['./todaycalls-modal.component.scss']
})
export class TodaycallsModalComponent implements OnInit {

  todayCalls: any;
	@ViewChild("todayCallsModal") todayCallsModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: FoeService) { }

  ngOnInit(): void {
  }

  /* Open TodayCalls Modal */
  openModal() {
    this.getTodayCalls();
  	this.modalService.open(this.todayCallsModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
  	});
  }

  /* Get Todays Calls */
  getTodayCalls() {
    let payload = Object();
    Object.assign(payload, {'frenewal_type': 'to_day'});
    this._service.getRenewalCalls(payload)
    .subscribe(res => {
      this.todayCalls = res['data']['renewals_followUps'];
      console.log(this.todayCalls);
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });
  }
}
