import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'global-prescription-modal',
  templateUrl: './prescription-modal.component.html',
  styleUrls: ['./prescription-modal.component.scss']
})
export class PrescriptionModalComponent implements OnInit {

  @ViewChild("prescriptionDetailsModal") prescriptionDetailsModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    public masterService: MasterDataService
  ) { }
  data: any;
  ngOnInit(): void {
  }

  /* Open Modal */
  openModal(id, api: any) {
    this.masterService.prescriptionDetails(api, id)
      .subscribe(res => {
        if (res['data']) {
          this.data = res['data'];
          console.log(this.data);
          this.modalService.open(this.prescriptionDetailsModal, {
            windowClass: "modal",
            centered: true,
            size: "xl",
          });
        }
      })
  }

}
