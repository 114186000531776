import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'achieved-modal',
  templateUrl: './achieved-modal.component.html',
  styleUrls: ['./achieved-modal.component.scss']
})
export class AchievedModalComponent implements OnInit {

  patient_details: any;
  a_patient_details: any;
  a_patient_billings: any;

  currentDate = new Date();
  y = this.currentDate.getFullYear();
  m = this.currentDate.getMonth();
  firstDay = new Date(this.y, this.m, 1);
  public modalRef: any;
  @ViewChild("achievedModal") achievedModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: ProService,
    private _router: Router
    ) { }

  ngOnInit(): void {
  }

  openModal(){
    let obj = { fdate: 'this_month'};
    this._service.achievedTargetDetails(obj)
    .subscribe(res => {
      let data = res['data'];
      let achievedUpto = data['achievedUpto'];
      if (achievedUpto) {
        this.a_patient_billings = achievedUpto['patient_billings'];
        this.a_patient_details = achievedUpto['patient_details'];
        if (this.a_patient_billings && this.a_patient_details) {
          this.modalService.open(this.achievedModal, {
            centered: true,
            size: 'xl'
          });
        }
      }
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });
  }
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/pro/home/reports/case-sheet/' + patientId;
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

}
