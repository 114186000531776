import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';
import { BehaviorSubject } from 'rxjs';
import { State } from '@models/State';
import { Role } from '@models/Role';
import { Branch } from '@models/Branch';
import { LeaveType } from '@models/LeaveType';
import { NotificationService } from '@core/services/notification.service';
import { Employee } from '@models/Employee';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  public statesModel$ = new BehaviorSubject<Partial<State>>(null);
  public rolesModel$ = new BehaviorSubject<Partial<Role>>(null);
  public branchModel$ = new BehaviorSubject<Partial<Branch>>(null);
  public employeeModel$ = new BehaviorSubject<Partial<Employee>>(null);
  public leaveTypesModel$ = new BehaviorSubject<Partial<LeaveType>>(null);

  constructor(protected httpClient: AppHttpClient,
    private notifyService: NotificationService) { }

  /* Initiate States */
  public initStates(states) {
    this.statesModel$.next(states);
  }

  /* Get Initiated States */
  public getStates() {
    return this.statesModel$.value;
  }

  /* Initiate Roles */
  public initDesignations(roles) {
    this.rolesModel$.next(roles);
  }

  /* Get Initiated Roles/Designations */
  public getDesignations() {
    return this.rolesModel$.value;
  }

  /* Initiate Branches */
  public initBranches(branches) {
    this.branchModel$.next(branches);
  }

  /* Get Initiated Branches */
  public getBranches() {
    return this.branchModel$.value;
  }

  /* Initiate Leave Types */
  public initLeaveTypes(leave_types) {
    this.leaveTypesModel$.next(leave_types);
  }

  /* Get Leave Types */
  public getLeaveTypes() {
    return this.leaveTypesModel$.value;
  }

  public masterData(start_api) {
    this.httpClient.get(start_api + '/masterData').subscribe(res => {
      if (res['status'] = "success") {
        let data = res['data'];
        this.initStates(data['states']);
        this.initDesignations(data['roles']);
        this.initBranches(data['branches']);
        this.initLeaveTypes(data['leave_types']);
      }
    }, err => {

    });
  }

  /* Search Employee Using Unique Id */
  public searchEmployee(val) {
    return this.httpClient.get('api/employeSearch/' + val);
  }

  /* Get Circulars */
  public getCircular(start_api) {
    return this.httpClient.get(start_api + '/get_circulars');
  }
  public getDoctor(branch_id) {
    return this.httpClient.get('gmapi/employeeName/' + branch_id);
  }

  /* Get Roles */
  public getRoles(start_api) {
    return this.httpClient.get(start_api + '/get_roles');
  }

  public mstMedicines(start_api) {
    return this.httpClient.get(start_api + '/get_medicines');
  }

  public mstPotencies(start_api) {
    return this.httpClient.get(start_api + '/get_potencies');
  }

  public mstPharmacyitems(start_api) {
    return this.httpClient.get(start_api + '/get_pharmacy_items');
  }

  public employeeSearch(start_api, val) {
    return this.httpClient.get(start_api + '/employeSearch/' + val);
  }

  /** Billing Receipt */
  public billingReceipt(id, api) {
    return this.httpClient.get(`${api}/billing-receipt/${id}`);
  }

  /** Billing Receipt */
  public consultationReceipt(id, api) {
    return this.httpClient.get(`${api}/consultation-receipt/${id}`);
  }

  public getPatientInvoiceData(api, patientId) {
    return this.httpClient.get(`${api}/get_patient_invoice/${patientId}`);
  }

  /** Get Prescription By ID  */
  public prescriptionDetails(api, id) {
    return this.httpClient.get(`${api}/get_prescription/${id}`);
  }

  public auditFeedbackReport(api, id) {
    return this.httpClient.get(`${api}/auditFeedbackReport/${id}`);
  }

  public achievedTargetDetails(payload, api) {
    return this.httpClient.post(`${api}/target/achieved-upto`, payload);
  }

  // Call-center Manager PH Status
  public ccPhPatients(payload, api) {
    return this.httpClient.post(`${api}/ph-status/patients-list`, payload);
  }

  public onlinePatients(payload, api) {
    return this.httpClient.post(`${api}/online/patients-list`, payload);
  }

}
