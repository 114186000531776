<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo-->
        <a class="brand-logo" href="javascript:void(0);"></a>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <div class="col-lg-8 d-none d-lg-flex align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" src='assets/images/pages/login-v2.svg' alt="Login" />
            </div>
        </div>
        <!-- /Left Text-->

        <!-- Login-->
        <div class="col-lg-4 d-flex align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <img src="assets/images/logo/positivehomeopathy_logo.jpg" alt="brand-logo" class="img-fluid" />
                <h2 class="card-title font-weight-bold text-center mb-1 mt-1">Call Center Login</h2>

                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                    <div class="alert-body">
                        <p>{{ error }}</p>
                    </div>
                </ngb-alert>

                <form [formGroup]="loginForm" class="auth-login-form auth-form" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label class="form-label" for="login-email">Employee Id<span
                                    class="text-danger">*</span></label>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input type="text" formControlName="unique_id" class="form-control form-control-merge"
                                [ngClass]="{ 'is-invalid error': submitted && f.unique_id.errors }"
                                placeholder="Employee Id*" aria-describedby="login-unique_id" tabindex="2" />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i data-feather="user"
                                        class="font-small-4"></i></span>
                            </div>
                        </div>
                        <div *ngIf="submitted && f.unique_id.errors" class="invalid-feedback"
                            [ngClass]="{ 'd-block': submitted && f.unique_id.errors }">
                            <div *ngIf="f.unique_id.errors.required">Employee Id is required</div>
                        </div>
                    </div>


                    <div class="form-group">
                        <div class="d-flex justify-content-between">
                            <label for="login-password">Password<span class="text-danger">*</span></label>
                        </div>
                        <div class="input-group input-group-merge form-password-toggle">
                            <input [type]="passwordTextType ? 'text' : 'password'" formControlName="password"
                                class="form-control form-control-merge" placeholder="Enter your password"
                                aria-describedby="login-password" tabindex="2"
                                [ngClass]="{ 'is-invalid error': (submitted || f.password.touched) && f.password.errors }" />
                            <div class="input-group-append">
                                <span class="input-group-text cursor-pointer"><i class="feather font-small-4" [ngClass]="{
                                    'icon-eye-off': passwordTextType,
                                    'icon-eye': !passwordTextType}" (click)="togglePasswordTextType()"></i></span>
                            </div>
                        </div>
                        <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback"
                            [ngClass]="{ 'd-block': (submitted || f.password.touched) && f.password.errors }">
                            <div *ngIf="f.password.errors.required">Password is required.</div>
                        </div>
                    </div>

                    <button [disabled]="loading | async" class="btn btn-primary btn-block" tabindex="4" rippleEffect>
                        <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1"></span>Sign in
                    </button>
                </form>
            </div>
        </div>
        <!-- /Login-->
    </div>
</div>