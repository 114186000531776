import { CoreMenu } from "@core/types";

export const auditMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "hm",
  },
  {
    id: "birthday",
    title: "Birthday",
    type: "item",
    icon: "file-plus",
    url: "birthday",
  },
  {
    id: "circular",
    title: "Circulars",
    type: "item",
    icon: "file-plus",
    url: "circular",
  },
  {
    id: "calling",
    title: "Calling",
    type: "item",
    icon: "file-plus",
    url: "calling",
  },
  
];
