<!-- Prescription Details -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Prescription Details&nbsp;({{currentDate | date: 'dd-MM-yyyy'}})&nbsp;
                        New - ({{count_new}}) & Old - ({{count_old}})</h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-foursquare" (click)="modalOpen(medicinesList)"><span><i
                                class="fas fa-capsules"></i>
                            Medicines</span></button>
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="filterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input type="text" class="form-control" formControlName="fpatient_unique_id">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input type="text" formControlName="fpatient_name" id="" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Type</label>
                                    <ng-select [items]="patientType" bindLabel="title" bindValue="value"
                                        formControlName="fpatient_type" placeholder="Select"></ng-select>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button type="submit" class="btn btn-primary"
                                        [disabled]="filterLoading.value || checkFilterValidity()">
                                        <span *ngIf="filterLoading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button type="reset" class="btn btn-secondary ml-50"
                                        (click)="resetFilter()">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption" (activate)="onActivate($event)"
            (select)="onSelect($event)">
            <ngx-datatable-column name="Actions" prop="status" [width]="150">
                <ng-template let-status="value" ngx-datatable-cell-template let-row="row">
                    <span *ngIf="status == 1">Delivered</span>
                    <div *ngIf="status == 0" class="d-flex align-items-center">
                        <a class="badge badge-light-primary" ngbTooltip="Edit" placement="left"
                            (click)="modalOpen(editPrescriptionDetails, row)" tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='edit' class="font-small-4">
                                </i>
                            </span>
                        </a>
                        <a class="badge badge-light-success ml-50" ngbTooltip="Prescription"
                            tooltipClass="fadeInAnimation" placement="left"
                            (click)="showPrescriptionModal(patientInfoModal, row)">
                            <span>
                                <i data-feather='file-text' class="font-small-4">
                                </i>
                            </span>
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template ngx-datatable-header-template let-column="column">
                    <span title="Patient ID" class="mt-25">PID</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BPID" prop="branch_patient_id" [width]="200">
                <ng-template ngx-datatable-header-template let-column="column">
                    <span title="Branch Patient ID" class="mt-25">BPID</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Type" prop="patient_type" [width]="150">
                <ng-template let-patient_type="value" ngx-datatable-cell-template>
                    <span>{{patient_type ? patient_type : "-"}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="No.of Days" prop="no_of_days_medicines" [width]="180">
                <ng-template let-no_of_days_medicines="value" ngx-datatable-cell-template let-row="row">
                    <span>{{row.no_of_days_medicines ? row.no_of_days_medicines :
                        row.no_of_days_medicines_pro}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Ren Date" prop="date_of_expire" [width]="150">
                <ng-template let-date_of_expire="value" ngx-datatable-cell-template>
                    <span>{{date_of_expire ? (date_of_expire | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="doctor" prop="doc_name" [width]="200">
                <ng-template let-doc_name="value" ngx-datatable-cell-template>
                    <span>{{doc_name ? doc_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="time" prop="visiting_date" [width]="150">
                <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                    <span>{{visiting_date ? (visiting_date | date: 'hh:mm:ss a') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Prescription Details -->

<!-- Medicines List Modal -->
<ng-template #medicinesList let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Medicines</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>Medicine Name/Item Name</th>
                            <th>Potency</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let medicine of branch_medicines">
                            <td>{{ medicine.medicine_name }}</td>
                            <td>{{ medicine.potency }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Medicines List Modal -->

<!-- Edit Prescription Details Modal -->
<ng-template #editPrescriptionDetails let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Edit</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="editPrescriptionForm" (ngSubmit)="updatePrescription()">
            <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">Patient ID</label>
                        <input type="text" class="form-control" formControlName="patient_unique_id" readonly>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">Patient Name</label>
                        <input type="text" class="form-control" formControlName="patient_name" readonly>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">REG NO</label>
                        <input type="text" class="form-control" formControlName="branch_patient_id" readonly>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">Update NO. of Days</label>
                        <input type="text" formControlName="no_of_days_medicines" class="form-control">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <div class="form-group">
                        <label for="">Update Next Visit Date</label>
                        <input type="date" class="form-control" [min]="currentDateValidation"
                            formControlName="next_visit_date">
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <button class="btn btn-primary">Update</button>
                <!-- <button class="btn btn-secondary ml-1" type="reset">Reset</button> -->
            </div>
        </form>
    </div>
</ng-template>
<!-- / Edit Prescription Details Modal -->

<!-- Patient Info Modal-->
<ng-template #patientInfoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Prescription</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="row existingInformation-data">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <form>
                        <div class="row ">
                            <div class="col-lg-12 col-md-12">
                                <div class="row ">
                                    <div class="col-lg-5 col-md-6">
                                        <label>Patient ID</label>
                                    </div>
                                    <div class="col-lg-7 col-md-6">
                                        <p>{{selectedRecord.patient_unique_id}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="row ">
                                    <div class="col-lg-5 col-md-6">
                                        <label>Patient Name</label>
                                    </div>
                                    <div class="col-lg-7 col-md-6">
                                        <p class="txt-ovrflow-wrap">{{selectedRecord.patient_name}}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="row ">
                                    <div class="col-lg-5 col-md-6">
                                        <label>No. of days Medicines</label>
                                    </div>
                                    <div class="col-lg-7 col-md-6">
                                        <p>{{selectedRecord.no_of_days_medicine_pro ?
                                            selectedRecord.no_of_days_medicine_pro
                                            : selectedRecord.no_of_days_medicines}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>Medicine / Item Name</th>
                            <th>Potency</th>
                            <th>Single Dose</th>
                            <th>M</th>
                            <th>A</th>
                            <th>N</th>
                            <th>SOS</th>
                            <th>Special Medicine</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{selectedRecord.medicine_name}}</td>
                            <td>{{selectedRecord.potency}}</td>
                            <td>{{selectedRecord.single_dose}}</td>
                            <td>{{selectedRecord.morning}}</td>
                            <td>{{selectedRecord.afternoon}}</td>
                            <td>{{selectedRecord.night}}</td>
                            <td>{{selectedRecord.sos}}</td>
                            <td class="bg-light">{{selectedRecord.specialmed}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-lg-12 col-12">
                    <div class="demo-inline-spacing">
                        <div class="custom-control custom-checkbox mt-0">
                            <input type="checkbox" id="one" [(ngModel)]="received" class="custom-control-input" />
                            <label class="custom-control-label" for="one">Received</label>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-12 text-center my-50">
                    <button class="btn btn-primary" (click)="medicine_received()">Submit</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Patient Info Modal-->