import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect/core-ripple-effect.directive';
import { TextareaAutoresizeDirective } from '@core/directives/textarea-autoresize/textarea-autoresize.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective,TextareaAutoresizeDirective],
  exports: [RippleEffectDirective, FeatherIconDirective, TextareaAutoresizeDirective]
})
export class CoreDirectivesModule {}
