<!-- Patient Info Modal-->
<ng-template #invoiceModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Income
            <span *ngIf="patient_billings && patient_billings[0]">( {{ patient_billings[0]['patient_unique_id'] ?
                patient_billings[0]['patient_unique_id'] : '-' }} )</span>
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus style="zoom: 80%;">
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle">
                <thead>
                    <tr>
                        <th>Patient ID</th>
                        <th>BPID</th>
                        <th>Patient Name</th>
                        <th>Source</th>
                        <th>Treatment Duration</th>
                        <th>Doctor Name</th>
                        <th>Total Amount</th>
                        <th>Received Amount</th>
                        <th>Due Amount</th>
                        <th>Billing Date</th>
                        <th>Payment Option</th>
                        <th>Cheque No</th>
                        <th>Bank</th>
                        <th>Cheque Valid</th>
                        <th>Bank</th>
                        <th>Discount</th>
                        <th>Package Status</th>
                        <th>Increase Amount</th>
                        <th>Decrease Amount</th>
                        <th>Patient Type</th>
                        <th>Renewal Date</th>
                        <th>OP Date</th>
                        <th>Case</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let billing of patient_billings;">
                        <td>{{billing.patient_unique_id ? billing.patient_unique_id : '-'}}</td>
                        <td>{{billing.branch_patient_id ? billing.branch_patient_id : '-'}}</td>
                        <td>{{billing.patient_name ? billing.patient_name : '-'}}</td>
                        <td>{{billing.source ? billing.source : '-'}}</td>
                        <td>{{billing.treatment_duration ? (isNumber(billing.treatment_duration) ?
                            (billing.treatment_duration+' Months'):billing.treatment_duration):
                            '-'}}</td>
                        <td>{{billing.doc_name}}</td>
                        <td>{{billing.total_amount}}</td>
                        <td>{{billing.received_amount}}</td>
                        <td>{{billing.due_amount}}</td>
                        <td>{{billing.billing_date | date:'dd-MM-YYYY'}}</td>
                        <td>{{ billing.payment_option == 'c' ? 'Cash' :
                            billing.payment_option == 'cc' ? 'Credit-Card' :
                            billing.payment_option == 'ch' ? 'Cheque' :
                            billing.payment_option == 'o' ? 'Online' : '-'}}</td>
                        <td>{{billing.payment_option == 'ch' ? billing.cheque_no : 'No'}}</td>
                        <td>{{billing.bankname ? billing.bankname : "No"}}</td>
                        <td>{{billing.payment_option == 'ch' ? billing.cheque_valid : 'No'}}</td>
                        <td>{{billing.bankname ? billing.bankname : 'No'}}</td>
                        <td>{{billing.discount ? billing.discount : 'No'}}</td>
                        <td>{{billing.package_status ? billing.package_status : 'No'}}</td>
                        <td>{{billing.increase_amount ? billing.increase_amount : 'No'}}</td>
                        <td>{{billing.decrease_amount ? billing.decrease_amount : 'No'}}</td>
                        <td>{{ billing.patient_type == 'o' ? 'Online' :
                            billing.patient_type == 'n' ? 'New' :'-' }}</td>
                        <td>{{billing.renewal_date ? (billing.renewal_date | date: 'dd-MM-YYYY') : '-'}}</td>
                        <td>{{billing.op_date ? (billing.op_date | date: 'dd-MM-YYYY') : '-'}}</td>
                        <td><a class="dataTable-Link" (click)="caseSheet(billing.patient_id)">Case</a></td>
                    </tr>
                </tbody>
            </table>

            <div *ngIf="overall_payments && consultation_fee" class="row existingInformation-data v-row">
                <div class="w-50" style="margin-left: 250px;">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Consultation Amount</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{consultation_fee.consultation_fee}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Package Amount</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{overall_payments.total_pkg_amount}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Package Received Amount</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{overall_payments.total_pkg_received}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Amount Received</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{ConvertToInt(consultation_fee.consultation_fee) +
                                        ConvertToInt(overall_payments.total_pkg_received)}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Discount Amount</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{overall_payments.total_discount}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <h5>Total Due Amount</h5>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{overall_payments.total_due}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Patient Info Modal-->