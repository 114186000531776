import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrComponent } from '@hr/hr.component';
import { LoginComponent } from '@hr/auth/login/login.component';
import { ForgotPasswordComponent } from '@hr/auth/forgot-password/forgot-password.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HrRoutingModule } from './hr-routing.module';
import { LayoutModule } from 'app/layout/layout.module';
import { HrHomeComponent } from './hr-home/hr-home.component';
import { CoreDirectivesModule } from '@core/directives/directives';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [HrComponent, LoginComponent, ForgotPasswordComponent, HrHomeComponent],
  imports: [
    HrRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    LayoutModule
  ]
})
export class HrModule { }
