import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CurrentUserService } from '@services/current-user/current-user.service';
import { AuditTokenService } from 'app/modules/audit/auth/audit-token/audit-token.service';
import { AuditAuthStateService } from '../audit-token/audit-auth-state.service';
import { AuditService } from '../../service/audit.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  //  Public
  public coreConfig: any
  public loginForm: FormGroup
  public loading = new BehaviorSubject(false);
  public submitted = false
  public returnUrl: string
  public error = ''
  public passwordTextType: boolean

  // Private
  private _unsubscribeAll: Subject<any>

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _service: AuditService,
    private toastr: ToastrService,
    private token: AuditTokenService,
    private authState: AuditAuthStateService,
    private currentUser: CurrentUserService
  ) {
    this._unsubscribeAll = new Subject()

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls
  }

  /**
   * Toggle password
   */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return
    }

    if(this.loginForm.valid) {
      this.loading.next(true);
      this._service.login(this.loginForm.getRawValue())
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if(res['success'] == true){
            this.authState.setAuthState(true);
            this.currentUser.init(res['user']);
            this.token.handleData(res['access_token']);
            this._router.navigate(['audit/home']);
            this.toastr.success(res['message'], res['title']);
          }else{
            this.toastr.error(res['message'], res['title']);
          }
        }, err => {
          this.toastr.error(err.message, err.title);
        });
    }
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      unique_id:['', Validators.required],
      password: ['', Validators.required]
    })

    // get return url from route parameters or default to '/'
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/'

    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete()
  }

}
