import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'comments-modal',
  templateUrl: './comments-modal.component.html',
  styleUrls: ['./comments-modal.component.scss']
})
export class CommentsModalComponent implements OnInit {

  comment: any;
  @ViewChild("commentsModal") commentsModal: TemplateRef<any>;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  /* Open Comments Modal */
  openModal(comment) {
    this.comment = comment;
    this.modalService.open(this.commentsModal, {
      windowClass: "modal",
      centered: true,
    });
  }

}
