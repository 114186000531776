import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from '@core/services/notification.service';
import { GmService } from '@gm/services/gm.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { MasterDataService } from '@services/masterData/master-data.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-branch-wise',
  templateUrl: './branch-wise.component.html',
  styleUrls: ['./branch-wise.component.scss']
})
export class BranchWiseComponent implements OnInit {
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();

  public filterForm = this._formBuilder.group({
    freport_type: [null, Validators.required],
    fdate_range: [],
    branch_id: '',
    perpage: this.basicSelectedOption,
    page: 1
  });

  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;
  branches: any;
  fromDate: any;
  toDate: any;
  tableData: any;
  tableKeys: any;
  apTableData: any;
  tsTableData: any;
  kaTableData: any;
  totalRow = [];
  selectedReport: any;
  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  reportTypes = [
    { id: 1, report: 'MONTH WISE RENEWAL LIST', key: 'get_renewals_call'},
    { id: 2, report: 'MONTH WISE DUE LIST', key: 'total_due_list'},
    { id: 3, report: 'DROP LIST (MANUAL)', key: 'dropout-list-manually'},
    { id: 4, report: 'DROP LIST (AUTOMATIC)', key: 'dropout-list-automatically'},
    { id: 5, report: 'MISSED FOLLOWUPS', key: 'missed_followup'},
    { id: 6, report: 'DATE WISE FOLLOWUPS', key: 'datefollowup'},
    { id: 7, report: 'COURIER PRESCRIPTION DETAILS', key: 'courier_prescription', status:''},
    { id: 8, report: 'COURIER PRESCRIPTION SENT', key: 'courier_prescription', status: '1'},
    { id: 9, report: 'COURIER PRESCRIPTION NOT SENT', key: 'courier_prescription', status: '2'},
    { id: 10, report: 'CASE SHEETS STATISFY REPORT', key: 'patient_satisfaction', status: ''},
    { id: 11, report: 'CASE SHEETS FULLY SATISFIED REPORT', key: 'patient_satisfaction', status: 'FULLY SATISFIED'},
    { id: 12, report: 'CASE SHEETS OK SATISFIED REPORT', key: 'patient_satisfaction', status: 'OK SATISFIED'},
    { id: 13, report: 'CASE SHEETS NOT HAPPY REPORT', key: 'patient_satisfaction', status: 'NOT HAPPY'},
    { id: 14, report: 'New Patients', key: 'patients_list', patient_type: 'new'},
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  patient_details: any;
  a_patient_billings: any;
  a_patient_details: any;
  smsreport_total:any;
  renewalCallsData:any;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: GmService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private masterService: MasterDataService,
    private _router: Router
  ) { }

  get freport_type() { return this.filterForm.get('freport_type'); }
  get fdate_range() { return this.filterForm.get('fdate_range'); }
  get branch_id() { return this.filterForm.get('branch_id'); }

  errorMessages = {
    freport_type: [{ type: 'required', message: 'The Report Type field is required' }],
    fdate_range: [{ type: 'required', message: 'The Date Range is required' }],
    branch_id: [{ type: 'required', message: 'Please select branch' }]
  }
  ngOnInit(): void {
    this.masterService.masterData('gmapi');
   

    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });

    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {    
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.submitForm();
    });
  }


  submitForm() {
    let api = this.filterForm.value.freport_type.key;
    this._service.branchWiseReports(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe((res: any[]) => {
        let data = res['data'];
        this.tableData = null;

        this.selectedReport = this.filterForm.value.freport_type;
        if (this.selectedReport.key == 'summary_report') {
          this.tableData = data;
        }
        if (this.selectedReport.key == 'pharmacy_stock') {
          this.totalCount = data['total'];
          this.curPage = data['current_page'];
          this.tableData = data['data'];
        }
        if (this.selectedReport.key == 'biochemical_list') {
          this.totalCount = data['total'];
          this.curPage = data['current_page'];
          this.tableData = data['data'];
        }
        if (this.selectedReport.key == 'doc-wise-reports') {
          this.tableData = data;
        }
        if (this.selectedReport.key == 'sms-status') {
          this.tableData = data.dayStatus;
          console.log(this.tableData);
        }
        if (this.selectedReport.key == 'incentive-reports') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['incentiveReports'];
        }
        if (this.selectedReport.key == 'referralPatient-reports') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['referralPatientList'];
        }
        if (this.selectedReport.key == 'renewalBilling-reports') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['renewalBillReports'];
        }
        if (this.selectedReport.key == 'ocToReg-reports') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['ocToRegReport'];
        }
        if (this.selectedReport.key == 'check_status') {
           this.totalCount = data['total'];
          this.curPage = data['current_page'];
          this.tableData = data['data'];
        }
        if (this.selectedReport.key == 'get_renewals_call') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['renewalCalls'];
        }
        if (this.selectedReport.key == 'total_due_list') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['TotalDuePayment'];
        }
        if (this.selectedReport.key == 'dropout-list-manually') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['DropListManually'];
        }
        if (this.selectedReport.key == 'dropout-list-automatically') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['DropoutAutomatically'];
        }
        if (this.selectedReport.key == 'missed_followup') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['miss_followUps'];
        }
        if (this.selectedReport.key == 'datefollowup') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['getFollowups'];
        }
        if (this.selectedReport.key == 'courier_prescription') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['dateWiseCourierPrescription'];
        }
        if (this.selectedReport.key == 'branch_consultpat') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'patient_satisfaction') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['patientSatisfactionWithDoctor'];
        }
        if (this.selectedReport.key == 'branch_comments') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWiseComments'];
        }
        if (this.selectedReport.key == 'patients_list' || this.selectedReport.key == 'walkin_patients_list' || this.selectedReport.key == 'residence_patients_list' || this.selectedReport.key == 'website_patients_list'
        || this.selectedReport.key == 'callcenter_patients_list'||this.selectedReport.key == 'doc_patient' || this.selectedReport.key == 'transfer_today_patient') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'docconfirmed_patients_list') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'patient_feedback') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_trans_emp') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWiseTransferPatients'];
        }
        if (this.selectedReport.key == 'branch_ocList') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.id == '45') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_registerpat') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_duepat') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_noduepat') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_monthly') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_act') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWisePatients'];
        }
        if (this.selectedReport.key == 'branch_wise_medicine') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['medicinesTakenData'];
        }
        if (this.selectedReport.key == 'branch_wise_notmedicine') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['medicinesNotTakenData'];
        }
        if (this.selectedReport.key == 'sent_stock') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['sentStock'];
        }
        if (this.selectedReport.key == 'given_discount') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['discountPatientList'];
        }
        if (this.selectedReport.key == 'given_permission_todue_pending') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['givenDuePendingData'];
        }
        if (this.selectedReport.key == 'exitDateReport') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['exitDateReport'];
        }
        if (this.selectedReport.key == 'closing_report') {
          //  this.totalCount = data['total'];
          // this.curPage = data['page'];
          this.tableData =  data['data'];;
        }
        if (this.selectedReport.key == 'chequeReplace') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['chequesReplaceData'];
        }
        if (this.selectedReport.key == 'branch_wise_trans_emp') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWiseTransferPatients'];
        }
        if (this.selectedReport.key == 'expenditure') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['expenditureReports'];
        }
        if (this.selectedReport.key == 'sent_amount') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['branchWiseBillReport'];
        }
        if (this.selectedReport.key == 'np_billing') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['newPatientsBillingData'];
        }
        if (this.selectedReport.key == 'op_billing') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['oldPatientsBillingData'];
        }
        if (this.selectedReport.key == 'receipts') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['consultationData'];
        }
        if (this.selectedReport.key == 'voucher_sent_amount') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['expenditureVouchersData'];
        }
        if (this.selectedReport.key == 'branchwise_visit') {
          //  this.totalCount = data['total'];
          // this.curPage = data['page'];
          this.tableData = data;
        }
        if (this.selectedReport.key == 'branchwise_payslips') {
          this.tableData = data;
        }
        
      });
  }

  getRenewals() {
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.fsearch_text = this.search_text;

    // this._service.getDueMonthWise(this.filterForm.value)
    // .pipe(finalize(() => this.filterLoading.next(false)))
    // .subscribe(res => {
    //   let data = res['data'];
    //   this.renewalCallsData = data['renewalCalls'];
    //   this.totalCount = data['total'];
    //   this.curPage = data['page'];
    // }, err => {
    //   this.notifyService.showError(err['message'], err['title']);
    // });
  }

  applyFilter() {
    this.filterLoading.next(true);
    this.submitForm();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.submitForm();
  }
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.submitForm();
  }
  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.submitForm();
  }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: "range",
  };

  /* On Date Select */
  onDateSelect() {
    console.log("date");

    let dateRange = this.filterForm.value.fdate_range;
    console.log(this.filterForm.value.fdate_range);
    if (dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
    }
  }
getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        ACQ: previousValue.ACQ + currentValue.ACQ,
        CAMP: previousValue.CAMP + currentValue.CAMP,
        CASH: previousValue.CASH + currentValue.CASH,
        CC: previousValue.CC + currentValue.CC,
        CC1: previousValue.CC1 + currentValue.CC1,
        CHQ: previousValue.CHQ + currentValue.CHQ,
        CHR: previousValue.CHR + currentValue.CHR,
        CON: previousValue.CON + currentValue.CON,
        DNP: previousValue.DNP + currentValue.DNP,
        EMI: previousValue.EMI + currentValue.EMI,
        EXP: previousValue.EXP + currentValue.EXP,
        FNP: previousValue.FNP + currentValue.FNP,
        GT: previousValue.GT + currentValue.GT,
        OC: previousValue.OC + currentValue.OC,
        OL: previousValue.OL + currentValue.OL,
        ONLINE: previousValue.ONLINE + currentValue.ONLINE,
        REF: previousValue.REF + currentValue.REF,
        REG: previousValue.REG + currentValue.REG,
        RGL: previousValue.RGL + currentValue.RGL,
        RMP: previousValue.RMP + currentValue.RMP,
        WK: previousValue.WK + currentValue.WK
      }
    });
}

caseSheet(patientId) {
  let link = environment.subdirUrl + '/agm/home/case-sheet/' + patientId;
  this._router.navigate([]).then(result => { window.open(link, '_blank'); });
}

}
