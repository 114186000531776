import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class HrService {

  constructor(protected httpClient: AppHttpClient) { }

  public login(credentials: object) {
    return this.httpClient.post('hrapi/login', credentials);
  }

  public getProfileDetails() {
    return this.httpClient.get('hrapi/me');
  }

  public changePassword(credentials: object) {
    return this.httpClient.post('hrapi/changePassword', credentials);
  }

  public resetPassword(credentials: object) {
    return this.httpClient.post('hrapi/resetPassword', credentials);
  }

  /* Circular */
  public addCircular(payload: object) {
    return this.httpClient.post('hrapi/dashboard/circular/add', payload);
  }

  public deleteCircular(payload: object) {
    return this.httpClient.post('hrapi/dashboard/circular/delete', payload);
  }

  /* Branch Apis */
  public addBranch(request: object) {
    return this.httpClient.post('hrapi/branch/addBranch', request);
  }

  /* Branches Listing */
  public branchesListing(filterValues: object) {
    return this.httpClient.post('hrapi/branch/index', filterValues);
  }

  /* Transfers Listing */
  transfersListing(filterValues: object) {
    return this.httpClient.post('hrapi/reports/transferReport', filterValues);
  }

  absenteeismListing(filterValues: object) {
    return this.httpClient.post('hrapi/reports/absenteeismReport', filterValues);
  }

  /* Delete Branch */
  public deleteBranch(payload: object) {
    return this.httpClient.post('hrapi/branch/delete', payload);
  }

  /* Export All Branches */
  public exportAllBranches() {
    return this.httpClient.get('hrapi/branch/exportAllBranches');
  }

  /* Memos Listing */
  memosListing(filterValues: object) {
    return this.httpClient.post('hrapi/reports/memoReports', filterValues);
  }

  /**** Reports - Employee Listing *****/
  employeesListing(filterValues: object) {
    return this.httpClient.post('hrapi/reports/allEmployees', filterValues);
  }

  leaveReports(filterValues: object) {
    return this.httpClient.post('hrapi/reports/leavesReport', filterValues);
  }

  counsellingReport(filterValues: object) {
    return this.httpClient.post('hrapi/reports/counsellingReport', filterValues);
  }

  noDueReport(filterValues: object) {
    return this.httpClient.post('hrapi/reports/noDueReport', filterValues);
  }

  terminationReport(filterValues: object) {
    return this.httpClient.post('hrapi/reports/terminationReport', filterValues);
  }

  rejoiningReport(filterValues: object) {
    return this.httpClient.post('hrapi/reports/rejoiningReport', filterValues);
  }

  exitDateReport(filterValues: object) {
    return this.httpClient.post('hrapi/reports/exitDateReport', filterValues);
  }


  /*** Attendence APIS ***/
  uploadAttendenceReport(payload: object) {
    return this.httpClient.post('hrapi/attendance/upload_attendance_import', payload);
  }

  getAttendenceListing(filterValues: object) {
    return this.httpClient.post('hrapi/attendance/attendance_list', filterValues);
  }

  getSampleAttendance() {
    return this.httpClient.get('hrapi/attendance/get_sample_attendance');
  }

  editAttendance(payload: object) {
    return this.httpClient.post('hrapi/attendance/edit_attendance', payload);
  }

  /*** Emplyee APIS ***/
  /* Add Emplyoee */
  public addEmployee(payload: object) {
    return this.httpClient.post('hrapi/employee/addEmployee', payload);
  }

  deleteExperience(payload: object) {
    return this.httpClient.post('hrapi/employee/deleteExperience', payload);
  }

  deleteFamily(payload: object) {
    return this.httpClient.post('hrapi/employee/deleteFamily', payload);
  }

  getEmployeeById(empId) {
    return this.httpClient.get('hrapi/employee/getEmployeeById/' + empId)
  }

  addTransferForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addTransferForm', payload);
  }

  addAbsenteesimForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addAbsenteeism', payload);
  }

  addmemoForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addmemo', payload);
  }

  addcounsellingForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addcounselling', payload);
  }

  addnoDueForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addnoDue', payload);
  }

  addterminationForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addTermination', payload);
  }

  addRejoinForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addRejoinForm', payload);
  }

  addleaveForm(payload: object) {
    return this.httpClient.post('hrapi/employee/addLeaves', payload)
  }

  /* Dahsboard APIS */
  getTodayBirthDays(payload: object) {
    return this.httpClient.post('hrapi/dashboard/get_birthdays', payload);
  }

  getCircular() {
    return this.httpClient.get('hrapi/dashboard/get_circulars');
  }

  /* Feedback APIS */
  addFeedbackForm(payload: object) {
    return this.httpClient.post('hrapi/feedback/add-apply-feedback', payload);
  }

  /* Latest News APIS */
  addLatestNews(payload: object) {
    return this.httpClient.post('hrapi/dashboard/add_latest_news', payload);
  }

  /* Messages API */
  addBranchWiseMessaging(payload: object) {
    return this.httpClient.post('hrapi/messages/add_branch_wise_message', payload);
  }

  addEmployeeWiseMessaging(payload: object) {
    return this.httpClient.post('hrapi/messages/add_Employee_Wise_Message', payload);
  }

  addDesignationWiseMessages(payload: object) {
    return this.httpClient.post('hrapi/messages/add_Designation_Wise_Message', payload);
  }

  searchEmployee(val) {
    return this.httpClient.get('hrapi/employeSearch/' + val);
  }
}
