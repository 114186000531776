import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from '@services/masterData/master-data.service';
import { environment } from 'environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-achieved-upto-modal',
  templateUrl: './achieved-upto-modal.component.html',
  styleUrls: ['./achieved-upto-modal.component.scss']
})
export class AchievedUptoModalComponent implements OnInit {

  patient_details: any;
  api_type: any;
  a_patient_details: any;
  a_patient_billings: any;
  currentDate = new Date();
  y = this.currentDate.getFullYear();
  m = this.currentDate.getMonth();
  firstDay = new Date(this.y, this.m, 1);
  public modalRef: any;
  @ViewChild("achievedModal") achievedModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _router: Router,

    private _service: MasterDataService) { }

  ngOnInit(): void {
  }

  openModal(api, branch_id = null, type = null, fdate = 'this_month', fstate = null) {
    console.log(fstate)
    this.api_type = api;
    let obj = {};
    if (branch_id) {
      obj['branch_id'] = branch_id;
    }
    if (fstate) {
      obj['fstate'] = fstate;
    }
    if (fdate) {
      obj['fdate'] = fdate;
      if (fdate == 'prev_month') {
        var date = new Date();
        this.firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        this.currentDate = new Date(date.getFullYear(), date.getMonth(), 0);
      } else if (fdate == 'this_month') {
        if (type) {
          obj['type'] = type;
          if (type == 'yesterday') {
            var date = new Date();
            this.firstDay = new Date(date.setDate(date.getDate() - 1));
            this.currentDate = this.firstDay;
          } else if (type == 'today') {
            this.firstDay = new Date();
            this.currentDate = this.firstDay;
          }
        } else {
          var date = new Date();
          this.firstDay = new Date(this.y, this.m, 1);
          this.currentDate = new Date();
        }
      }
    }




    this._service.achievedTargetDetails(obj, 'gmapi')
      .subscribe(res => {
        let data = res['data'];
        let achievedUpto = data['achievedUpto'];
        if (achievedUpto) {
          this.a_patient_billings = achievedUpto['patient_billings'];
          this.a_patient_details = achievedUpto['patient_details'];
          if (this.a_patient_billings && this.a_patient_details) {
            this.modalService.open(this.achievedModal, {
              centered: true,
              size: 'xl'
            });
          }
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }
  caseSheet(patientId) {
    if(this.api_type == 'gmapi'){
      var link = environment.subdirUrl + '/gm/home/case-sheet/' + patientId;
    }else if(this.api_type == 'agmapi'){
      var link = environment.subdirUrl + '/agm/home/case-sheet/' + patientId;

    }else{
      var link = environment.subdirUrl + '/accounts/home/case-sheet/' + patientId;
    }
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }
}
