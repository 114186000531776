import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { DrcordinatorGuardGuard } from './auth/drcordinator-guard/drcordinator-guard.guard';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DoctorReportComponent } from './doctor-report/doctor-report.component';
import { DoctorDailyComponent } from './doctor-daily/doctor-daily.component';
import { TargetComponent } from './target/target.component';
import { ReportsComponent } from './reports/reports.component';
import { SearchComponent } from './search/search.component';
import { CaseSheetComponent } from './components/case-sheet/case-sheet.component';


const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, canActivate: [DrcordinatorGuardGuard]},
	{ path: 'hm', component: HomeComponent, canActivate: [DrcordinatorGuardGuard]},
  { path: 'home', component: HomeComponent,  canLoad: [DrcordinatorGuardGuard],
  children: [
		{
		  path: 'hm', // child route path
		  component: DashboardComponent, // child route component that the router renders
		},
		{
		  path: 'doctor_report', // child route path
		  component: DoctorReportComponent, // child route component that the router renders
		},
		{
		  path: 'target', // child route path
		  component: TargetComponent, // child route component that the router renders
		},
		{
		  path: 'doctor_daily', // child route path
		  component: DoctorDailyComponent, // child route component that the router renders
		},
		{
		  path: 'reports', // child route path
		  component: ReportsComponent, // child route component that the router renders
		},
		{
			path: 'search', component: SearchComponent,
			loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
		},
		{ path: 'case-sheet/:id', component: CaseSheetComponent},
		
	  ],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class DrcordinatorRoutingModule { }
