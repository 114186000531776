<section *ngIf="_activatedRoute.children.length === 0">
    <section>
        <div class="row">
            <div class="col-12">
                <div class="card mb-1">
                    <div class="card-header">
                        <h4 class="card-title">Search</h4>
                    </div>
                    <hr>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <form [formGroup]="patientFilterForm" (ngSubmit)="serarchPatient()">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Select Type</label>
                                                <ng-select [searchable]="false" formControlName="search_field"
                                                    [items]="searchType" bindLabel="title" bindValue="value"
                                                    placeholder="Select"
                                                    [ngClass]="{ 'is-invalid error': errorMessages.search_field && search_field.errors && search_field.touched }">
                                                </ng-select>
                                                <div *ngFor="let error of errorMessages.search_field">
                                                    <ng-container
                                                        *ngIf="search_field.hasError(error.type) && (search_field.touched)">
                                                        <small class="text-danger">{{error.message}}</small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Keyword</label>
                                                <input formControlName="keyword" type="text" class="form-control">
                                                <div *ngFor="let error of errorMessages.keyword">
                                                    <ng-container
                                                        *ngIf="keyword.hasError(error.type) && (keyword.touched)">
                                                        <small class="text-danger">{{error.message}}</small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                            <div>
                                                <button [disabled]="filterLoading.value" type="submit"
                                                    class="btn btn-primary">
                                                    <span *ngIf="filterLoading | async"
                                                        class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                                <button (click)="resetFilter()" type="reset"
                                                    class="btn btn-secondary ml-50">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- All Patients Data -->
    <section id="ngx-datatable-kitchen-sink" *ngIf="isShown">
        <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">

            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="d-flex align-items-center m-1">
                        <label class="d-flex align-items-center">Show
                            <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                                (ngModelChange)="listingLimit($event)">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
<option value="500">500</option>
                            </select>
                            </label>
<span>entries of <b>{{totalCount}}</b></span>
                    </div>
                </div>
                <!-- <div class="col-md-6 col-12 d-flex justify-content-md-end">
            </div> -->
            </div>

            <ngx-datatable [rows]="allPatientsData" [rowHeight]="90" class="bootstrap core-bootstrap myDatatable"
                [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
                [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                    [resizeable]="false">
                </ngx-datatable-column>
                <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                    <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                        <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200">
                    <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                        <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'"
                    [width]="200">
                </ngx-datatable-column>
                <ngx-datatable-column name="Mobile" prop="mobile" [cellClass]="'word-span-el'" [width]="200">
                </ngx-datatable-column>
                <ngx-datatable-column name="Visit Date" prop="visiting_date" [width]="">
                    <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                        <span>{{visiting_date ? (visiting_date | date:'dd-MM-yyyy') : '-'}}</span>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Actions" [width]="200" [sortable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                        <div class="d-flex align-items-center">
                            <button class="btn btn-outline-light active border__none ml-50"
                                (click)="caseSheetUpdate(row.patient_id)" ngbTooltip="Case Sheet"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='user-minus' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                            <button class="btn btn-outline-warning active border__none ml-50"
                                (click)="clinicalOBModal(clinicalobModal, row.patient_id)" ngbTooltip="Clinical Obv"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='user' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-footer>
                    <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                        let-selectedCount="selectedCount" let-offset="offset">
                        <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                            [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                            [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                            [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                        </datatable-pager>
                    </ng-template>
                </ngx-datatable-footer>
            </ngx-datatable>
        </core-card-snippet>
    </section>
    <!--/ All Patients Data -->
</section>

<!-- clinicalob Modal-->
<ng-template #clinicalobModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Information</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>Patient ID</th>
                            <th>Name</th>
                            <th>VDate</th>
                            <th>Doctor Name</th>
                            <th>Clinical OB</th>
                            <th>Treatment Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ob of clinical_ob">
                            <td>{{ ob.patient_unique_id }}</td>
                            <td>{{ ob.patient_name }}</td>
                            <td>{{ ob.vDate ? (ob.vDate | date:'dd-MM-yyyy') : '-' }}</td>
                            <td>{{ ob.doctor_name }}</td>
                            <td>{{ ob.clinical_observation }}</td>
                            <td>{{ ob.treatment_name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!-- / clinicalob Modal-->