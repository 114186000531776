import { Component, OnInit } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { HrService } from '@hr/services/hr.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup
  // Public
  public emailVar;
  public coreConfig: any;
  public loading = new BehaviorSubject(false);
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(private _coreConfigService: CoreConfigService,
    private _formBuilder: FormBuilder,
    private _service: HrService,
    private _router: Router,
    private toastr: ToastrService,) {
    this._unsubscribeAll = new Subject();

    // Configure the layout
    this._coreConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        menu: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        customizer: false,
        enableLocalStorage: false
      }
    };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resetPasswordForm.controls
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    })
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config;
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return
    }

    if (this.resetPasswordForm.valid) {
      this.loading.next(true);
      this._service.resetPassword(this.resetPasswordForm.getRawValue())
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.toastr.success(res['message'], res['title']);
            setTimeout(() => {
              this._router.navigate(['hr/login']);
            }, 1000);
          } else {
            this.toastr.error(res['message'], res['title']);
          }
        }, err => {
          this.toastr.error(err.message, err.title);
        });
    }
  }

}
