import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-desk',
  templateUrl: './front-desk.component.html',
  styleUrls: ['./front-desk.component.scss']
})
export class FrontDeskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
