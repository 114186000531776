import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from '@services/current-user/current-user.service';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'consultation-receipt-modal',
  templateUrl: './consultation-receipt-modal.component.html',
  styleUrls: ['./consultation-receipt-modal.component.scss']
})
export class ConsultationReceiptModalComponent implements OnInit {

  @ViewChild("consultationModal") consultationModal: TemplateRef<any>;
  selectedConsultation: any;
  currentRoute: any;
  constructor(private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private currentUser: CurrentUserService,
    public masterService: MasterDataService) { }

  ngOnInit(): void {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      console.log(urlPath);
      const url = urlPath[urlPath.length - 1].path;
      this.currentRoute = url;
    });
    console.log(this.currentRoute);
  }

  /* Open Modal */
  openModal(billing, api: any) {
    this.masterService.consultationReceipt(billing.patient_id, api)
    .subscribe(res => {
      if (res['data']){
        this.selectedConsultation = res['data'];
        this.modalService.open(this.consultationModal, {
          windowClass: "modal",
          centered: true,
          size: "xl",
        });
      }
    })
  }
  /* Print Billing Recipt*/
  print() {
    const printContent = document.getElementById("consultationPrint");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
