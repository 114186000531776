import { CoreMenu } from "@core/types";

export const frontDeskMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url:'hm'
  },
  {
    id: "roles",
    title: "Roles",
    translate: "",
    type: "item",
    icon: "users",
    url: "roles",
  },
    {
    id: "forms",
    type: "section",
    title: "R Form",
    translate: "",
    icon: "file",
    children: [
      {
        id: "fir-form",
        title: "FIR Form Print",
        translate: "",
        type: "item",
        icon: "disc",
        url: "r-form/fir-form",
      },
      {
        id: "Refund Form",
        title: "Refund Form",
        translate: "",
        type: "item",
        icon: "disc",
        url: "r-form/refund-form",
      },
      {
        id: "new-patient",
        title: "New Patient",
        translate: "",
        type: "item",
        icon: "disc",
        url: "r-form/new-patient-form",
      },
      {
        id: "op-co-tr",
        title: "OP-CO-TR",
        translate: "",
        type: "item",
        icon: "disc",
        url: "r-form/old-patient-form",
      },
      {
        id: "Today Transfer Patients",
        title: "Today Transfer Patients",
        translate: "",
        type: "item",
        icon: "disc",
        url: "r-form/tp",
      },
    ],
  },
  {
    id: "print",
    type: "section",
    title: "Print",
    translate: "",
    icon: "file",
    children: [
      {
        id: "consultation",
        title: "Consultation",
        translate: "",
        type: "item",
        icon: "disc",
        url: "print/consultation",
      },
      {
        id: "billing",
        title: "Billing",
        translate: "",
        type: "item",
        icon: "disc",
        url: "print/billing",
      },
      {
        id: "firform-print",
        title: "FIR Forms",
        translate: "",
        type: "item",
        icon: "disc",
        url: "print/firforms",
      },
    ],
  },
  {
    id: "followups",
    type: "section",
    title: "Followups",
    translate: "",
    icon: "refresh-cw",
    children: [      
      {
        id: "renewal",
        title: "Renewal",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/renewals",
      },

      {
        id: "followup",
        title: "Followup",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/followup",
      },

      {
        id: "missed-followups",
        title: "Missed Followups",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/missed-followups",
      },
      {
        id: "datewise-opcalls",
        title: "Datewise OP Calls",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/date-wise-calls",
      },
      {
        id: "due-calls",
        title: "Due Payments List",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/due-calls",
      },
      {
        id: "Reg-payments-list",
        title: "Reg Payments List",
        translate: "",
        type: "item",
        icon: "disc",
        url: "followups/reg-payments",
      },
    ],
  },
  {
    id: "oc",
    title: "OC",
    translate: "",
    type: "item",
    icon: "list",
    url: "only-consult",
  },
  {
    id: "reports",
    title: "Reports",
    type: "section",
    icon: "file-text",
    children: [
      {
        id: "transfer",
        title: "Transfer",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/branch-transfer",
      },
      {
        id: "due-ren",
        title: "Due-Ren-Drop-Reg Followup",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/branch-wise-report-followup",
      },
      {
        id: "dropout",
        title: "Dropouts (Manually)",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/dropout-manual",
      },
      {
        id: "dropout",
        title: "Dropouts (Automatic)",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/drop-outs",
      },
      {
        id: "summary",
        title: "Summary",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/summary-reports",
      },
      {
        id: "newedit",
        title: "New Edit",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/new-edit",
      },
      {
        id: "oldedit",
        title: "Old Edit",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/old-edit",
      },
      {
        id: "datewise",
        title: "Datewise All Reports",
        translate: "",
        type: "item",
        icon: "disc",
        url: "reports/datewise-all-reports",
      },
    ],
  },
  // {
  //   id: "target",
  //   title: "Target",
  //   translate: "",
  //   type: "section",
  //   icon: "maximize-2",
  //   children: [
  //     {
  //       id:"Transfer Amount",
  //       title:"Transfer Amount",
  //       type:"item",
  //       icon:"disc",
  //       url:"foe/targets/transfer-amount"
  //     },
  //     {
  //       id:"target",
  //       title:"Target(Pre)",
  //       type:"item",
  //       icon:"disc",
  //       url:"foe/targets/target-pre-month"
  //     },
  //     {
  //       id:"target",
  //       title:"Target(This)",
  //       type:"item",
  //       icon:"disc",
  //       url:"foe/targets/target-this-month"
  //     },
  //     {
  //       id:"status",
  //       title:"Month Status",
  //       type:"item",
  //       icon:"disc",
  //       url:"foe/targets/month-status"
  //     },
  //     {
  //       id:"status",
  //       title:"Yesterday Status",
  //       type:"item",
  //       icon:"disc",
  //       url:"foe/targets/day-status"
  //     },
  //   ],
  // },

  {
    id: "search",
    type: "section",
    title: "Search",
    icon: "search",
    children: [
      {
        id: "patients",
        title: "Patients",
        translate: "",
        type: "item",
        icon: "disc",
        url: "search/patients",
      },
      {
        id: "receipts",
        title: "Receipts",
        translate: "",
        type: "item",
        icon: "disc",
        url: "search/receipts",
      },
      {
        id: "all details",
        title: "All Details",
        translate: "",
        type: "item",
        icon: "disc",
        url: "search/all-details",
      },
    ],
  },
];
