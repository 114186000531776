<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">SMS Report - {{currentDate | date: 'dd-MM-YYYY'}} <span *ngIf="selectedState"
                            class="text-warning">({{selectedState}})</span></h4>
                </div>
                <hr>
                <div class="card-body">
                    <!-- Show Filter -->
                    <div class="row mb-1">
                        <div class="col-12">
                            <form [formGroup]="daywiseReportForm" (ngSubmit)="searchReport()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Report by Day Wise</label>
                                            <ng-select [items]="dayWise" bindLabel="title" bindValue="value"
                                                placeholder="Select" formControlName="fdate"
                                                [ngClass]="{ 'is-invalid error': errorMessages.fdate && fdate.errors && fdate.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.fdate">
                                                <ng-container
                                                    *ngIf="fdate.hasError(error.type) && (fdate.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="daywiseReportForm.controls.fdate.hasError('serverValidationError')">
                                                <small class="text-danger">{{ backendValidations['fdate'] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary">
                                                <span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Show Filter End-->
                    <div *ngIf="day_status">
                        <div class="table-responsive patient-table">
                            <table class="table table-bordered text-center align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                        <th>OPENING</th>
                                        <th>FNP</th>
                                        <th>DNP</th>
                                        <th>REG</th>
                                        <th>ACQ</th>
                                        <th>RGL</th>
                                        <th>OC</th>
                                        <th>WK</th>
                                        <th>REF</th>
                                        <th>CC</th>
                                        <th>IB</th>
                                        <th>OB</th>
                                        <th>RMP</th>
                                        <th>CAMP</th>
                                        <th>ONLINE</th>
                                        <th>GT</th>
                                        <th>CON</th>
                                        <th>CASH</th>
                                        <th>CHQ</th>
                                        <th>CC</th>
                                        <th>EMI</th>
                                        <th>OL</th>
                                        <th>CHR</th>
                                        <th>EXP</th>
                                        <th>SENT</th>
                                        <th>CLOSING</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="dayStatus">
                                   <tr *ngFor="let smsreport of dayStatus">
                                    <td>{{smsreport.branch_name}}</td>
                                    <td>{{smsreport.open}}</td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'patient_type', 'new', report_type, null)">{{smsreport.FNP}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'patient_type', 'new', report_type, null)">{{smsreport.DNP}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, null, 'reg', report_type, null)">{{smsreport.REG}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, null, 'acq', report_type, null)">{{smsreport.ACQ}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, null, 'rgl', report_type, null)">{{smsreport.RGL}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, null, 'oc', report_type, null)">{{smsreport.OC}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'walk-in', report_type, null)">{{smsreport.WK}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'ref', report_type, null)">{{smsreport.REF}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'call-center', report_type, null)">{{smsreport.CC}}</a></td>
                                    <td>{{smsreport.IB?smsreport.IB: '0'}}</td>
                                    <td>{{smsreport.OB?smsreport.OB: '0'}}</td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'rmp', report_type, null)">{{smsreport.RMP}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'camp', report_type, null)">{{smsreport.CAMP}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, 'how_came_know', 'online', report_type, null)">{{smsreport.ONLINE}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, '')">{{smsreport.GT}}</a></td>
                                    <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(smsreport, null, 'con', report_type, null)">{{smsreport.CON}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'c')">{{smsreport.CASH}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'ch')">{{smsreport.CHQ}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'cc')">{{smsreport.CC1}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'emi')">{{smsreport.EMI}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'o')">{{smsreport.OL}}</a></td>
                                    <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(smsreport, report_type, 'ch')">{{smsreport.CHR}}</a></td>
                                    <td>{{smsreport.EXP}}</td>
                                    <td>{{smsreport.sent}}</td>
                                    <td>{{smsreport.closing}}</td>
                                   </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th id="total">Total :</th>
                                        <th>{{smsreport_total.open}}</th>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('patient_type', 'new', report_type, state_id)">{{smsreport_total.FNP}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('patient_type', 'new', report_type, state_id)">{{smsreport_total.DNP}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal(null, 'reg', report_type, state_id)">{{smsreport_total.REG}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal(null, 'acq', report_type, state_id)">{{smsreport_total.ACQ}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal(null, 'rgl', report_type, state_id)">{{smsreport_total.RGL}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal( 'how_came_know', 'oc', report_type, state_id)">{{smsreport_total.OC}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('how_came_know', 'walk-in', report_type, state_id)">{{smsreport_total.WK}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('how_came_know', 'ref', report_type, state_id)">{{smsreport_total.REF}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('how_came_know', 'call-center', report_type, state_id)">{{smsreport_total.CC}}</a></td>
                                        <td>{{smsreport_total.IB?smsreport_total.IB: '0'}}</td>
                                        <td>{{smsreport_total.OB?smsreport_total.OB: '0'}}</td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('how_came_know', 'rmp', report_type, state_id)">{{smsreport_total.RMP}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal( 'how_came_know', 'camp', report_type, state_id)">{{smsreport_total.CAMP}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal('how_came_know', 'online', report_type, state_id)">{{smsreport_total.ONLINE}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, '', state_id)">{{smsreport_total.GT}}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsTotalModal(null, 'con', report_type, state_id)">{{smsreport_total.CON}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'c', state_id)">{{smsreport_total.CASH}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'ch', state_id)">{{smsreport_total.CHQ}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'cc', state_id)">{{smsreport_total.CC1}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'emi', state_id)">{{smsreport_total.EMI}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'online', state_id)">{{smsreport_total.OL}}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsTotalModal(report_type, 'ch', state_id)">{{smsreport_total.CHR}}</a></td>
                                        <td>{{smsreport_total.EXP}}</td>
                                        <td>{{smsreport_total.sent}}</td>
                                        <td>{{smsreport_total.closing}}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<mid-gt-modal #midGtPatientsModal></mid-gt-modal>

<common-patients-modal #patientModal></common-patients-modal>
