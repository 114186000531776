<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Region Wise Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date - To Date</label>
                                            <ng2-flatpickr (ngModelChange)="onDateSelect()"
                                                formControlName="fdate_range" [config]="DateRangeOptions"
                                                placeholder="Pick a date range!"></ng2-flatpickr>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'due_ren_op_np'">
        <div class="row" *ngIf="apTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">BR WISE TOTAL COLLECTION AMOUNT-AP</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>REG</th>
                                    <th>ACQ</th>
                                    <th>NP COLL</th>
                                    <th>REN COLL</th>
                                    <th>DUE COLL</th>
                                    <th>OP COLL</th>
                                    <th>TOTAL GT</th>
                                    <th>No.of<br /> OP</th>
                                    <th>No.of<br /> REF</th>
                                    <th>Total<br /> No.of REN</th>
                                    <th>REN</th>
                                    <th>Pending<br /> REN</th>
                                    <th>REN>5000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of apTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.fnp }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.acq }}</td>
                                    <td>{{ item.np_coll }}</td>
                                    <td>{{ item.ren_coll }}</td>
                                    <td>{{ item.due_coll }}</td>
                                    <td>{{ item.op_coll }}</td>
                                    <td>{{ item.total_gt }}</td>
                                    <td>{{ item.op_count }}</td>
                                    <td>{{ item.ref_count }}</td>
                                    <td>{{ item.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, item.branch_id, null, 'Renewal')">{{
                                            item.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null)">{{
                                            item.ren_pending
                                            }}</a></td>
                                    <td>{{ item.ren_count_5000 }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th>Total :</th>
                                    <td>{{ totalRow.fnp }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.acq }}</td>
                                    <td>{{ totalRow.np_coll }}</td>
                                    <td>{{ totalRow.ren_coll }}</td>
                                    <td>{{ totalRow.due_coll }}</td>
                                    <td>{{ totalRow.op_coll }}</td>
                                    <td>{{ totalRow.total_gt }}</td>
                                    <td>{{ totalRow.op_count }}</td>
                                    <td>{{ totalRow.ref_count }}</td>
                                    <td>{{ totalRow.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, null, totalRow.state_id, 'Renewal')">{{
                                            totalRow.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, null, totalRow.state_id)">{{
                                            totalRow.ren_pending
                                            }}</a></td>
                                    <td>{{ totalRow.ren_count_5000 }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="tsTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">BR WISE TOTAL COLLECTION AMOUNT-TS</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>REG</th>
                                    <th>ACQ</th>
                                    <th>NP COLL</th>
                                    <th>REN COLL</th>
                                    <th>DUE COLL</th>
                                    <th>OP COLL</th>
                                    <th>TOTAL GT</th>
                                    <th>No.of<br /> OP</th>
                                    <th>No.of<br /> REF</th>
                                    <th>Total<br /> No.of REN</th>
                                    <th>REN</th>
                                    <th>Pending<br /> REN</th>
                                    <th>REN>5000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tsTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.fnp }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.acq }}</td>
                                    <td>{{ item.np_coll }}</td>
                                    <td>{{ item.ren_coll }}</td>
                                    <td>{{ item.due_coll }}</td>
                                    <td>{{ item.op_coll }}</td>
                                    <td>{{ item.total_gt }}</td>
                                    <td>{{ item.op_count }}</td>
                                    <td>{{ item.ref_count }}</td>
                                    <td>{{ item.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, item.branch_id, null, 'Renewal')">{{
                                            item.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null)">{{
                                            item.ren_pending
                                            }}</a></td>
                                    <td>{{ item.ren_count_5000 }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th>Total :</th>
                                    <td>{{ totalRow.fnp }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.acq }}</td>
                                    <td>{{ totalRow.np_coll }}</td>
                                    <td>{{ totalRow.ren_coll }}</td>
                                    <td>{{ totalRow.due_coll }}</td>
                                    <td>{{ totalRow.op_coll }}</td>
                                    <td>{{ totalRow.total_gt }}</td>
                                    <td>{{ totalRow.op_count }}</td>
                                    <td>{{ totalRow.ref_count }}</td>
                                    <td>{{ totalRow.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, null, totalRow.state_id, 'Renewal')">{{
                                            totalRow.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, null, totalRow.state_id)">{{
                                            totalRow.ren_pending
                                            }}</a></td>
                                    <td>{{ totalRow.ren_count_5000 }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="kaTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">BR WISE TOTAL COLLECTION AMOUNT-KA</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>REG</th>
                                    <th>ACQ</th>
                                    <th>NP COLL</th>
                                    <th>REN COLL</th>
                                    <th>DUE COLL</th>
                                    <th>OP COLL</th>
                                    <th>TOTAL GT</th>
                                    <th>No.of<br /> OP</th>
                                    <th>No.of<br /> REF</th>
                                    <th>Total<br /> No.of REN</th>
                                    <th>REN</th>
                                    <th>Pending<br /> REN</th>
                                    <th>REN>5000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of kaTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.fnp }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.acq }}</td>
                                    <td>{{ item.np_coll }}</td>
                                    <td>{{ item.ren_coll }}</td>
                                    <td>{{ item.due_coll }}</td>
                                    <td>{{ item.op_coll }}</td>
                                    <td>{{ item.total_gt }}</td>
                                    <td>{{ item.op_count }}</td>
                                    <td>{{ item.ref_count }}</td>
                                    <td>{{ item.ren_total }}</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, item.branch_id, null, 'Renewal')">{{
                                            item.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null)">{{
                                            item.ren_pending
                                            }}</a></td>
                                    <td>{{ item.ren_count_5000 }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th>Total :</th>
                                    <td>{{ totalRow.fnp }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.acq }}</td>
                                    <td>{{ totalRow.np_coll }}</td>
                                    <td>{{ totalRow.ren_coll }}</td>
                                    <td>{{ totalRow.due_coll }}</td>
                                    <td>{{ totalRow.op_coll }}</td>
                                    <td>{{ totalRow.total_gt }}</td>
                                    <td>{{ totalRow.op_count }}</td>
                                    <td>{{ totalRow.ref_count }}</td>
                                    <td>{{ totalRow.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, null, totalRow.state_id, 'Renewal')">{{
                                            totalRow.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, null, totalRow.state_id)">{{
                                            totalRow.ren_pending
                                            }}</a></td>
                                    <td>{{ totalRow.ren_count_5000 }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'doc_due_ren_op_np'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTOR WISE TOTAL COLLECTION AMOUNT- {{selectedReport.state_code}}</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Doctor<br /> name</th>
                                    <th>Br</th>
                                    <th>NP COLL</th>
                                    <th>OP COLL</th>
                                    <th>GT</th>
                                    <th>REN COLL</th>
                                    <th>DUE COLL</th>
                                    <th>No.of<br /> OP</th>
                                    <th>No.of<br />NP</th>
                                    <th>REG</th>
                                    <th>REF</th>
                                    <th>Total<br /> No.of REN</th>
                                    <th>No.of REN</th>
                                    <th>Pending<br /> REN</th>
                                    <th>REN>5000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{ item.doctor_name }}</td>
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.np_coll }}</td>
                                    <td>{{ item.op_coll }}</td>
                                    <td>{{ item.total_gt }}</td>
                                    <td>{{ item.ren_coll }}</td>
                                    <td>{{ item.due_coll }}</td>
                                    <td>{{ item.op_count }}</td>
                                    <td>{{ item.fnp }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.ref }}</td>
                                    <td>{{ item.ren_total }}</td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', item.doctor_id, null, null, 'Renewal')">{{
                                            item.ren_count
                                            }}</a>
                                    </td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', item.doctor_id, null, null)">{{
                                            item.ren_pending
                                            }}
                                        </a>
                                    </td>
                                    <td>{{ item.ren_count_5000 }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th colspan="2">Total :</th>
                                    <td>{{ totalRow.np_coll }}</td>
                                    <td>{{ totalRow.op_coll }}</td>
                                    <td>{{ totalRow.total_gt }}</td>
                                    <td>{{ totalRow.ren_coll }}</td>
                                    <td>{{ totalRow.due_coll }}</td>
                                    <td>{{ totalRow.op_count }}</td>
                                    <td>{{ totalRow.fnp }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.ref_count }}</td>
                                    <td>{{ totalRow.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, null, totalRow.state_id, 'Renewal')">{{
                                            totalRow.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, null, totalRow.state_id)">{{
                                            totalRow.ren_pending
                                            }}</a></td>
                                    <td>{{ totalRow.ren_count_5000 }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="selectedReport && selectedReport.key == 'rpr_report'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">RPR Report- {{selectedReport.state_code}}</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>TNPR</th>
                                    <th>RPV</th>
                                    <th>RGL</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>REG</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>RPR</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>RPP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.tnpr }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null, 'rpv')">{{
                                            item.rpv
                                        }}</a>
                                    </td>
                                    <td>{{ item.rgl }}</td>
                                    <td>{{ item.rpamt_rgl }}</td>
                                    <td>{{ item.rpaid_rgl }}</td>
                                    <td>{{ item.rdue_rgl }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.rpamt_reg }}</td>
                                    <td>{{ item.rpaid_reg }}</td>
                                    <td>{{ item.rdue_reg }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renList, 'gmapi', null, item.branch_id, null, 'Renewal')">{{
                                            item.rpr
                                        }}</a>
                                    </td>
                                    <td>{{ item.rpamt }}</td>
                                    <td>{{ item.rpaid }}</td>
                                    <td>{{ item.rdue }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null)">{{
                                            item.rpp
                                        }}</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th>Total :</th>
                                    <td>{{ totalRow.tnpr }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, null, state_id, 'rpv')">{{ totalRow.rpv }}</a></td>
                                    <td>{{ totalRow.rgl }}</td>
                                    <td>{{ totalRow.rpamt_rgl }}</td>
                                    <td>{{ totalRow.rpaid_rgl }}</td>
                                    <td>{{ totalRow.rdue_rgl }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.rpamt_reg }}</td>
                                    <td>{{ totalRow.rpaid_reg }}</td>
                                    <td>{{ totalRow.rdue_reg }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renList, 'gmapi', null, null, state_id, 'Renewal')">{{ totalRow.rpr }}</a></td>
                                    <td>{{ totalRow.rpamt }}</td>
                                    <td>{{ totalRow.rpaid }}</td>
                                    <td>{{ totalRow.rdue }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, null, state_id, null)">{{ totalRow.rpp }}</a></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- Start Renewal target -->
    <div *ngIf="selectedReport && selectedReport.key == 'doc_renewal_target'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTORS RENEWAL TARGET REPORT - ( {{ date[0] | date: 'dd-MM-yyyy' }} ) To ( {{date[1] | date: 'dd-MM-yyyy'}} )</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch</th>
                                    <th>Doctor Name</th>
                                    <th>G-REN</th>
                                    <th>REN</th>
                                    <th>PACK</th>
                                    <th>PAID</th>
                                    <th>TARGET</th>
                                    <th>PER%</th>
                                    <th>INCENTIVES</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{item.BR}}</td>
                                    <td>{{item.doctor_name}}</td>
                                    <td>{{item.g_ren}}</td>
                                    <td>{{item.ren}}</td>
                                    <td>{{item.pack}}</td>
                                    <td>{{item.paid}}</td>
                                    <td>{{item.target}}</td>
                                    <td>{{item.per}}</td>
                                    <td>{{item.incentives}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="patients-reports-stats" *ngIf="totalRow">
                                    <td colspan="2"> Total: </td>
                                    <td>{{totalRow.g_ren}}</td>
                                    <td>{{totalRow.ren}}</td>
                                    <td>{{totalRow.pack}}</td>
                                    <td>{{totalRow.paid}}</td>
                                    <td>{{totalRow.target}}</td>
                                    <td></td>
                                    <td>{{totalRow.incentives}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Renewal target -->

    <!-- Start Refferal target -->
    <div *ngIf="selectedReport && selectedReport.key == 'doc_referral_target'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTORS REFFERAL TARGET REPORT - ( {{ date[0] | date: 'dd-MM-yyyy' }} ) To ( {{date[1] | date: 'dd-MM-yyyy'}} )</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch</th>
                                    <th>Doctor Name</th>
                                    <th>G-REN</th>
                                    <th>REN</th>
                                    <th>PACK</th>
                                    <th>PAID</th>
                                    <th>TARGET</th>
                                    <th>PER%</th>
                                    <th>INCENTIVES</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{item.BR}}</td>
                                    <td>{{item.doctor_name}}</td>
                                    <td>{{item.g_ren}}</td>
                                    <td>{{item.ren}}</td>
                                    <td>{{item.pack}}</td>
                                    <td>{{item.paid}}</td>
                                    <td>{{item.target}}</td>
                                    <td>{{item.per}}</td>
                                    <td>{{item.incentives}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="patients-reports-stats" *ngIf="totalRow">
                                    <td colspan="2"> Total: </td>
                                    <td>{{totalRow.g_ren}}</td>
                                    <td>{{totalRow.ren}}</td>
                                    <td>{{totalRow.pack}}</td>
                                    <td>{{totalRow.paid}}</td>
                                    <td>{{totalRow.target}}</td>
                                    <td></td>
                                    <td>{{totalRow.incentives}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Refferal target  -->


    <!-- START OLD BILLING STATUS -->
    <div *ngIf="selectedReport && selectedReport.key == 'old_billing_status'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title"> PATIENTS REPORTS (OLD BILLING STATUS) (FROM {{ date[0] | date: 'dd-MM-yyyy' }} ) To ( {{date[1] | date: 'dd-MM-yyyy'}} )</h4>
                    </div>
                    <hr>
                   
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>S.NO</th>	
                                    <th>BPID</th>	
                                    <th>Name</th>
                                    <th>Branch</th>
                                    <th>LV Date</th>
                                    <th>LP Date</th>
                                	<th>Due</th>
                                    <th>LP Amount</th>
                                    <th>Total pack</th> 
                                    <th>Paid</th>
                                    <th>Days</th>
                                    <th>Reg Date</th>
                                    <th>Duration</th>
                                	<th>Ren date</th>
                                    <th>Doctor</th>
                                    <th>Problem</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData, let i = index;">
                                    <td>{{ i + 1}}</td>
                                    <td>{{item.branch_patient_id}}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="caseSheet(item.patient_id)" ngbTooltip="Case Sheet"
                                        tooltipClass="fadeInAnimation">{{item.patient_name}}</a></td>
                                    <td>{{item.branch_name}}</td>
                                    <td>{{item.latest_visit_date}}</td>
                                    <td>{{item.billing_date}}</td>
                                    <td>{{item.due_amount}}</td>
                                    <td>{{item.received_amount}}</td>
                                    <td>{{item.total_amount}}</td>
                                    <td>{{item.received_amount}}</td>
                                    <td>{{item.no_of_days_medicine}}</td>
                                    <td>{{item.visit_date}}</td>
                                    <td>{{item.treatment_duration}}</td>
                                    <td>{{item.date_of_expire}}</td>
                                    <td>{{item.doctor_name}}</td>
                                    <td>{{item.treatment_for}}</td>
                                    </tr>
                            </tbody>
                           
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END OLD BILLING STATUS -->


</section>


<!-- Billing Receipt Modal -->
<!-- <app-achieved-upto-modal #achievedModal></app-achieved-upto-modal> -->
<!-- / Billing Receipt Modal -->

<!-- Renewal Patient List -->
<ng-template #renList let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Renewal List ({{fromDate | date: 'dd-MM-yyyy'}}) To
            ({{toDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>PID</th>
                            <th>PName</th>
                            <th>BR</th>
                            <th>Duration</th>
                            <th>Total<br /> Amount</th>
                            <th>Received</th>
                            <th>Due</th>
                            <th>Billing <br />Date</th>
                            <th>payment<br /> Option</th>
                            <th>Discount</th>
                            <th>Package <br />Status</th>
                            <th>Increase <br />Amount</th>
                            <th>Rect.No</th>
                            <th>Last Visit<br /> Date</th>
                            <th>LB-Doctor<br /> Name</th>
                            <th>LV-Doctor<br /> Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of patient_details; let i = index">
                            <td>{{i+1}}</td>
                            <td><a class="dataTable-Link"
                                (click)="caseSheet(details.patient_id)" ngbTooltip="Case Sheet"
                                tooltipClass="fadeInAnimation">{{details.patient_unique_id ? details.patient_unique_id : '-'}}</a></td>
                            <td>{{details.patient_name ? details.patient_name : '-'}}</td>
                            <td>{{details.branch_name}}</td>
                            <td>{{details.package_name ? (details.package_name+' Months') : '-'}} </td>
                            <td>{{details.total_amount}}</td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.due_amount}}</td>
                            <td>{{details.billing_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.payment_option}}</td>
                            <td>{{details.discount}}</td>
                            <td>{{details.package_status}}</td>
                            <td>{{details.increase_amount}}</td>
                            <td>{{details.receipt_no}}</td>
                            <td>{{details.last_visiting_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.lv_doctor_name}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<!-- Renewal Pending Patient List -->
<ng-template #renPendingList let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Renewal Pending List ({{fromDate | date: 'dd-MM-yyyy'}}) To
            ({{toDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>PID</th>
                            <th>Name</th>
                            <th>BR</th>
                            <th>Age</th>
                            <th>Duration</th>
                            <th>Date <br/>of Reg</th>
                            <th>Date <br/>of Expire</th>
                            <th>Total</th>
                            <th>LV Date</th>
                            <th>PROB</th>
                            <th>DROP</th>
                            <th>INC</th>
                            <th>Paid</th>
                            <th>LB<br/>-Doctor</th>
                            <th>LV<br/>-Doctor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of patient_details; let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{details.patient_unique_id ? details.patient_unique_id : '-'}}</td>
                            <td><a class="dataTable-Link"
                                (click)="caseSheet(details.patient_id)" ngbTooltip="Case Sheet"
                                tooltipClass="fadeInAnimation">{{details.patient_name ? details.patient_name : '-'}}</a></td>
                            <td>{{details.branch_name}}</td>
                            <td>{{details.age}}</td>
                            <td>{{details.package_name ? (details.package_name+' Months') : '-'}} </td>
                            <td>{{details.reg_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.total_amount}}</td>
                            <td>{{details.last_visiting_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.treatment_for}}</td>
                            <td>-</td>
                            <td>{{details.increase_amount}}</td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.lv_doctor_name}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<!-- Achieved up to Modal -->
<ng-template #achievedModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">GT Patients Details From ({{fromDate | date: 'dd-MM-yyyy'}}) To
            ({{toDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient Name</th>
                            <th>New / Old</th>
                            <th>Doctor Name</th>
                            <th>Package</th>
                            <th>Total</th>
                            <th>Consultation</th>
                            <th>Received</th>
                            <th>Billing Date</th>
                            <th>Expiry</th>
                            <th>Due</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of a_patient_details;  let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{details.patient_unique_id ? details.patient_unique_id : '-'}}</td>
                            <td>{{details.branch_patient_id ? details.branch_patient_id : '-'}}</td>
                            <td><a class="dataTable-Link"
                                (click)="caseSheet(details.patient_id)" ngbTooltip="Case Sheet"
                                tooltipClass="fadeInAnimation">{{details.patient_name ? details.patient_name : '-'}}</a></td>
                            <td>{{details.patient_type == 'n' ? 'New' : 'Old' }}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.package_name}}</td>
                            <td>{{details.total_amount}}</td>
                            <td></td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.billing_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.due_amount ? details.due_amount : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">Consultation Patients Details From ({{fromDate | date:
                'dd-MM-yyyy'}}) To
                ({{toDate | date: 'dd-MM-yyyy'}})
            </h4>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Patient ID</th>
                            <th>Patient Name</th>
                            <th>Amount</th>
                            <th>Option</th>
                            <th>Date</th>
                            <th>Doctor</th>
                            <th>Source</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let billing of a_patient_billings; let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{billing.patient_unique_id ? billing.patient_unique_id : '-'}}
                            </td>
                            <td>{{billing.patient_name ? billing.patient_name : '-'}}</td>
                            <td>{{billing.amount ? billing.amount : '-'}}</td>
                            <td>{{billing.payment_option == 'c' ? 'Cash' :
                                billing.payment_option == 'cc' ? 'Credit-Card' :
                                billing.payment_option == 'ch' ? 'Cheque' :
                                billing.payment_option == 'o' ? 'Online' : '-'}}</td>
                            <td>{{billing.visit_date ? (billing.visit_date | date: 'dd-MM-YYYY') : '-'}}</td>
                            <td>{{billing.doctor_name ? billing.doctor_name : '-'}}</td>
                            <td>{{billing.how_came_know ? billing.how_came_know : '-'}}</td>
                            <td>{{billing.status == '0' ? 'Doc Pending' : 'Confirm'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>

<global-invoice-modal #invoiceModal></global-invoice-modal>
