<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">Doctor Daily Reports</h4>
                </div>
                <hr>
                <!--  -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="stateFilterForm" (ngSubmit)="getDoctorDailyReport()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select State</label>
                                            <ng-select
                                                [ngClass]="{ 'is-invalid error': errorMessages.fstates && fstates.errors && fstates.touched }"
                                                formControlName="fstates" [items]="stateWise" bindLabel="title"
                                                bindValue="value" placeholder="Select State">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.fstates">
                                                <ng-container *ngIf="fstates.hasError(error.type) && (fstates.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




    <div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTOR DAILY REPORT</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>DOCTOR</th>
                                    <th>BR</th>
                                    <th>NP</th>
                                    <th>REG</th>
                                    <th>REG%</th>
                                    <th>GT</th>
                                    <th>REF</th>
                                    <th>TNP</th>
                                    <th>TREG</th>
                                    <th>TREG%</th>
                                    <th>TGT</th>
                                    <th>TREF</th>
                                    
                                  
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{ item.doctor }}</td>
                                    <td>{{ item.branch }}</td>
                                    <td>{{ item.np }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.reg_per }}</td>
                                    <td>{{ item.gt }}</td>
                                    <td>{{ item.REF }}</td>
                                    <td>{{ item.TNP }}</td>
                                    <td>{{ item.TREG }}</td>
                                    <td>{{ item.TREG_PER }}</td>
                                    <td>{{ item.TGT }}</td>
                                    <td>{{ item.TREF }}</td>
                                   
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th colspan="2">Total :</th>
                                    <td>{{ totalRow.np }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.reg_per }}</td>
                                    <td>{{ totalRow.gt }}</td>
                                    <td>{{ totalRow.REF }}</td>
                                    <td>{{ totalRow.TNP }}</td>
                                    <td>{{ totalRow.TREG }}</td>
                                    <td>{{ totalRow.TREG_PER }}</td>
                                    <td>{{ totalRow.TGT }}</td>
                                    <td>{{ totalRow.TREF }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>