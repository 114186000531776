import { Component, OnDestroy, OnInit, HostBinding, HostListener, ViewEncapsulation } from '@angular/core'
import { MediaObserver } from '@angular/flex-layout'
import { Router, ActivatedRoute } from '@angular/router';

import * as _ from 'lodash'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service'
import { CoreConfigService } from '@core/services/config.service'
import { CoreMediaService } from '@core/services/media.service'

import { CurrentUserService } from '@services/current-user/current-user.service';

// HR AUTH 
import { HrTokenService } from '@hr/auth/hr-token/hr-token.service';
import { HrAuthStateService } from '@hr/auth/hr-token/hr-auth-state.service';

// FOE AUTH
import { FoeTokenService } from '@foe/auth/foe-token/foe-token.service';
import { FoeAuthStateService } from '@foe/auth/foe-token/foe-auth-state.service';

// Docotor AUTH
import { DocTokenService } from '@doc/auth/doc-token/doc-token.service';
import { DocAuthStateService } from '@doc/auth/doc-token/doc-auth-state.service';

// Pro AUTH
import { ProTokenService } from '@pro/auth/pro-token/pro-token.service';
import { ProAuthStateService } from '@pro/auth/pro-token/pro-auth-state.service';

// Pharmacy Auth
import { PharmacyTokenService } from '@pharmacy/auth/pharmacy-token/pharmacy-token.service';
import { PharmacyAuthStateService } from '@pharmacy/auth/pharmacy-token/pharmacy-auth-state.service';
import { CallcenterAuthStateService } from '@callcenter/auth/callcenter-token/callcenter-auth-state.service';
import { CallcenterTokenService } from '@callcenter/auth/callcenter-token/callcenter-token.service';

// Accounts Auth
import { AccountsTokenService } from '@accounts/auth/accounts-token/accounts-token.service';
import { AccountsAuthStateService } from '@accounts/auth/accounts-token/accounts-auth-state.service';

// Store Manager Auth
import { StoreTokenService } from '@store/auth/store-token/store-token.service';
import { StoreAuthStateService } from '@store/auth/store-token/store-auth-state.service';
//GM Token Services
import { GmTokenService } from '@gm/auth/gm-token/gm-token.service';
import { GMAuthStateService } from '@gm/auth/gm-token/gm-auth-state.service';
//Audit Token services
import { AuditTokenService } from 'app/modules/audit/auth/audit-token/audit-token.service';
import { AuditAuthStateService } from 'app/modules/audit/auth/audit-token/audit-auth-state.service';
//doctor cordinator
import { DrcordinatorAuthStateService } from 'app/modules/drcordinator/auth/drcordinator-token/drcordinator-auth-state.service';
import { DrcordinatorTokenService } from 'app/modules/drcordinator/auth/drcordinator-token/drcordinator-token.service';
//AGM
import { AgmAuthStateService } from 'app/modules/agm/auth/agm-token/agm-auth-state.service';
import { AgmTokenService } from 'app/modules/agm/auth/agm-token/agm-token.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {

  currentRoute: string;
  public horizontalMenu: boolean
  public hiddenMenu: boolean

  public coreConfig: any
  public currentSkin: string
  public prevSkin: string

  public languageOptions: any
  public navigation: any
  public selectedLanguage: any

  @HostBinding('class.fixed-top')
  public isFixed = false

  @HostBinding('class.navbar-static-style-on-scroll')
  public windowScrolled = false

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (
      (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == 'navbar-static-top'
    ) {
      this.windowScrolled = true
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authenticationService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService,
    public _currentUser: CurrentUserService,
    private activatedRoute: ActivatedRoute,
    public _hrToken: HrTokenService,
    public _hrAuth: HrAuthStateService,
    public _foeToken: FoeTokenService,
    public _foeAuth: FoeAuthStateService,
    public _docToken: DocTokenService,
    public _docAuth: DocAuthStateService,
    public _proToken: ProTokenService,
    public _proAuth: ProAuthStateService,
    public _pharmacyToken: PharmacyTokenService,
    public _pharmacyAuth: PharmacyAuthStateService,
    public _callcenterAuth: CallcenterAuthStateService,
    public _callcenterToken: CallcenterTokenService,
    public _accountsToken: AccountsTokenService,
    public _accountsAuth: AccountsAuthStateService,
    public _storeToken: StoreTokenService,
    public _storeAuth: StoreAuthStateService,
    public _gmTOken: GmTokenService,
    public _gmAuth: GMAuthStateService,
    public _auditToken: AuditTokenService,
    public _auditAuth: AuditAuthStateService,
    public _docCordinatorToken: DrcordinatorTokenService,
    public _docCordinatorAuth: DrcordinatorAuthStateService,
    public _agmToken: AgmTokenService,
    public _agmAuth: AgmAuthStateService
  ) {

    this.activatedRoute.parent.url.subscribe((urlPath) => {
      const url = urlPath[urlPath.length - 1].path;
      this.currentRoute = url;
    });

    this.languageOptions = {
      en: {
        title: 'English',
        flag: 'us'
      },
      fr: {
        title: 'French',
        flag: 'fr'
      },
      de: {
        title: 'German',
        flag: 'de'
      },
      pt: {
        title: 'Portuguese',
        flag: 'pt'
      }
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject()
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen()
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language

    // Use the selected language id for translations
    this._translateService.use(language)
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(config => {
        this.currentSkin = config.layout.skin
      })

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem('prevSkin')

    if (this.currentSkin === 'dark') {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : 'default' } },
        { emitEvent: true }
      )
    } else {
      localStorage.setItem('prevSkin', this.currentSkin)
      this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true })
    }
  }

  /**
   * View My Payslips
   */
  viewPayslips() {

  }

  /**
   * Home 
   */
  home() {
    if (this.currentRoute == 'hr') {

      this._router.navigate([this.currentRoute + '/home']);
    } else if (this.currentRoute == 'foe') {

      this._router.navigate([this.currentRoute + '/home']);
    }
  }

  /**
   * Logout method
   */
  logout() {

    if (this.currentRoute == 'hr') {

      this._hrAuth.setAuthState(false);
      this._hrToken.removeToken();
    } else if (this.currentRoute == 'foe') {

      this._foeAuth.setAuthState(false);
      this._foeToken.removeToken();
    } else if (this.currentRoute == 'doctor') {

      this._docAuth.setAuthState(false);
      this._docToken.removeToken();
    } else if (this.currentRoute == 'pro') {

      this._proAuth.setAuthState(false);
      this._proToken.removeToken();
    } else if (this.currentRoute == 'pharmacy') {

      this._pharmacyAuth.setAuthState(false);
      this._pharmacyToken.removeToken();
    } else if (this.currentRoute == 'accounts') {

      this._accountsAuth.setAuthState(false);
      this._accountsToken.removeToken();
    }
    else if (this.currentRoute == 'callcenter') {
      this._callcenterAuth.setAuthState(false);
      this._callcenterToken.removeToken();
    }
    else if (this.currentRoute == 'store') {
      this._storeAuth.setAuthState(false);
      this._storeToken.removeToken();
    } else if (this.currentRoute == 'gm') {
      this._gmAuth.setAuthState(false);
      this._gmTOken.removeToken();
    }
    else if (this.currentRoute == 'audit') {
      this._auditAuth.setAuthState(false);
      this._auditToken.removeToken();
    }
    else if (this.currentRoute == 'drcordinator') {
      this._docCordinatorAuth.setAuthState(false);
      this._docCordinatorToken.removeToken();
    }
    else if (this.currentRoute == 'agm') {
      this._agmAuth.setAuthState(false);
      this._agmToken.removeToken();
    }
    this._router.navigate([this.currentRoute + '/login']);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    // Subscribe to the config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
      this.coreConfig = config
      this.horizontalMenu = config.layout.type === 'horizontal'
      this.hiddenMenu = config.layout.menu.hidden === true
      this.currentSkin = config.layout.skin

      // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
      if (this.coreConfig.layout.type === 'vertical') {
        setTimeout(() => {
          if (this.coreConfig.layout.navbar.type === 'fixed-top') {
            this.isFixed = true
          }
        }, 0)
      }
    })

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == 'horizontal') {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
        const isFixedTop = this._mediaObserver.isActive('bs-gt-xl')
        if (isFixedTop) {
          this.isFixed = false
        } else {
          this.isFixed = true
        }
      })
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang
    })
  }

  /**
   * View My Payslips
   */
  myPayslip() {
    this._router.navigateByUrl('/' + this.currentRoute + '/home/paySlips');
  }

  /**
   * Change My Password 
   */
  changePassword() {
    this._router.navigateByUrl('/' + this.currentRoute + '/home/changePassword');
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete()
  }
}
