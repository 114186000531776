<!-- Patient Info Modal -->
<ng-template #patientInfoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Information</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data v-row">
            <div class="col-12">
                <div class="row ">
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Patient ID</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p>{{selectedPatientFollowUp.patient_unique_id ? selectedPatientFollowUp.patient_unique_id : '-'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Name</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p class="person_name_modal">{{selectedPatientFollowUp.patient_name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Mobile</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p>{{selectedPatientFollowUp.mobile}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Email</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p>{{selectedPatientFollowUp.email}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Coming Date</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p>{{selectedPatientFollowUp.coming_date ? selectedPatientFollowUp.coming_date : '-'}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="row ">
                            <div class="col-lg-6 col-md-6">
                                <label>Previous Feedback</label>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <p>{{selectedPatientFollowUp.remarks ? selectedPatientFollowUp.remarks : '-'}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Patient Info Modal Ends -->