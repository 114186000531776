<ng-template #midGTpatientsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">GT Patients Details From <span *ngIf="monthFirstDate && monthLastDate">({{monthFirstDate | date:'dd-MM-YYYY'}}) To ({{monthLastDate | date:'dd-MM-YYYY'}})</span></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>New / Old</th>
                            <th>Patient Name</th>
                            <th>Doctor Name</th>
                            <th>Package</th>
                            <th>Total</th>
                            <th>Received</th>
                            <th>Paid Date</th>
                            <th>Due</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="gtPatientDetails">
                        <tr *ngFor="let gt of gtPatientDetails; let i=index;" >
                            <td>{{i+1}}</td>
                            <td>{{gt.patient_unique_id}}</td>
                            <td>{{gt.branch_patient_id}}</td>
                            <td>{{gt.patient_type}}</td>
                            <td>{{gt.patient_name}}</td>
                            <td>{{gt.doctor_name ? gt.doctor_name : '-'}}</td>
                            <td>{{gt.package_name ? gt.package_name : '-'}}</td>
                            <td>{{gt.total_amount ? gt.total_amount : '-'}}</td>
                            <td>{{gt.received_amount ? gt.received_amount : '-'}}</td>
                            <td>{{gt.billing_date ? (gt.billing_date | date :'dd-MM-YYYY') : '-'}}</td>
                            <td>{{gt.due_amount ? gt.due_amount : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <div class="modal-header">
            <h4 class="modal-title" id="myModalLabel1">Consultation Patients Details From <span *ngIf="monthFirstDate && monthLastDate">({{monthFirstDate | date:'dd-MM-YYYY'}}) To ({{monthLastDate | date:'dd-MM-YYYY'}})</span></h4>
        </div>
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S No</th>
                            <th>Patient ID</th>
                            <th>Patient Name</th>
                            <th>emp name</th>
                            <th>fee</th>
                            <th>option</th>
                            <th>paid date</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="gtConsultationDetails">
                        <tr *ngFor="let consult of gtConsultationDetails; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{consult.patient_unique_id ? consult.patient_unique_id : '-'}}</td>
                            <td>{{consult.patient_name ? consult.patient_name : '-'}}</td>
                            <td>Vikram jain</td>
                            <td>{{consult.consultation_fee ? consult.consultation_fee : '-'}}</td>
                            <td>{{ consult.payment_option == 'c' ? 'Cash' :
                                consult.payment_option == 'cc' ? 'Credit-Card' :
                                consult.payment_option == 'ch' ? 'Cheque' :
                                consult.payment_option == 'o' ? 'Online' : '-'}}</td>
                            <td>{{consult.created_at ? (consult.created_at | date :'dd-MM-YYYY') : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
