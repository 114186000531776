import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PharmacyService } from '@pharmacy/services/pharmacy.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'app-stock-request',
  templateUrl: './stock-request.component.html',
  styleUrls: ['./stock-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StockRequestComponent implements OnInit {

  tableData: any;
  public search_text: any;
  public basicSelectedOption: number = 500;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public mySearchControl = new FormControl();
  public requestLoading = new BehaviorSubject(false);

  medicines: any;
  pharmacy_items: any;
  selectedRow: any;
  backendValidations: any;
  modalRef: any;

  isShown: boolean = false;

  toggleShow() {
    this.isShown = !this.isShown;
  }

  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  selectedStock: any;

  allowNumbersOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  potencies = [];

  medicinesList = false;
  showMedicinesDiv(name) {
    console.log(name)

    this.resetMedicineValidations();
    if (name == 'Medicines') {
      this.requestItemsForm.controls.medicine_name.setValidators(Validators.required);
      this.requestItemsForm.controls.medicine_name.updateValueAndValidity();
      this.requestItemsForm.controls.quantity.setValidators(Validators.required);
      this.requestItemsForm.controls.quantity.updateValueAndValidity();
      this.requestItemsForm.controls.potency_id.setValidators(Validators.required);
      this.requestItemsForm.controls.potency_id.updateValueAndValidity();
      this.medicinesList = true;
    } else {
      this.requestItemsForm.controls.quantity.setValidators(Validators.required);
      this.requestItemsForm.controls.quantity.updateValueAndValidity();
      this.medicinesList = false;
    }
  }

  // Reset Medicine Validations
  resetMedicineValidations() {
    this.medicinesList = false;
    if (this.requestItemsForm.value.quantity) {
      this.requestItemsForm.controls['quantity'].reset()
    }
    this.requestItemsForm.controls.medicine_name.setValidators(null);
    this.requestItemsForm.controls.medicine_name.updateValueAndValidity();
    this.requestItemsForm.controls.quantity.setValidators(null);
    this.requestItemsForm.controls.quantity.updateValueAndValidity();
    this.requestItemsForm.controls.potency_id.setValidators(null);
    this.requestItemsForm.controls.potency_id.updateValueAndValidity();
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  // Request Medicine/Item Form
  requestItemsForm: FormGroup = this._formBuilder.group({
    id: [null],
    stock_item: [null, Validators.required],
    quantity: [null],
    medicine_name: [null],
    medicine_quantity: [null],
    potency_id: [null],
    ml_10: [null],
    ml_30: [null],
    ml_100: [null],
    received_check: [null]
  });

  get stock_item() { return this.requestItemsForm.get('stock_item') }
  get quantity() { return this.requestItemsForm.get('quantity') }
  get medicine_name() { return this.requestItemsForm.get('medicine_name') }
  get medicine_quantity() { return this.requestItemsForm.get('medicine_quantity') }
  get potency_id() { return this.requestItemsForm.get('potency_id') }
  get ml_10() { return this.requestItemsForm.get('ml_10') }
  get ml_30() { return this.requestItemsForm.get('ml_30') }
  get ml_100() { return this.requestItemsForm.get('ml_100') }
  get received_check() { return this.requestItemsForm.get('received_check') }

  errorMessages = {
    stock_item: [{ type: 'required', message: 'The Stock Item field is required' }],
    quantity: [{ type: 'required', message: 'The Quantity field is required' },
    { type: 'minlength', message: 'The Quantity Minimum length is 1' }],
    medicine_name: [{ type: 'required', message: 'The Medicine Name field is required' }],
    medicine_quantity: [{ type: 'required', message: 'This field is required' }],
    potency_id: [{ type: 'required', message: 'This Potency field is required' }],
    quantity_update: [{ type: 'required', message: 'The Quantity field is required' }],
    received_check: [{ type: 'required', message: 'This field is required' }]
  }

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    public _service: PharmacyService,
    private masterService: MasterDataService
  ) {
    this._unsubscribeAll = new Subject();
  }

  filterForm = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_name: [],
    fpatient_type: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  ngOnInit(): void {
    this.masterService.mstMedicines('pharmacyapi').subscribe(res => {
      this.medicines = res['medicines'];
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });

    this.masterService.mstPotencies('pharmacyapi').subscribe(res => {
      this.potencies = res['potencies'];
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });

    this.masterService.mstPharmacyitems('pharmacyapi').subscribe(res => {
      this.pharmacy_items = res['pharmacy_items'];
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.getStockrequests();
    });

    this.getStockrequests();
  }

  /* Get Stock requests */
  getStockrequests() {
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.fsearch_text = this.search_text;

    this._service.getStockrequests(this.filterForm.value)
      // .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        this.tableData = res['data'];
        this.totalCount = res['total'];
        this.curPage = res['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  StockRequestModal(modal, stockSelected = '') {
    console.log(stockSelected)
    this.resetMedicineValidations();
    this.requestItemsForm.controls.stock_item.enable();
    this.selectedStock = stockSelected;
    if (stockSelected) {
      this.patchStockForm(this.selectedStock); 
    } else {
      this.requestItemsForm.controls.medicine_name.enable();
      this.requestItemsForm.controls.potency_id.enable();
      this.requestItemsForm.controls.quantity.enable();
    }
    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true
    });
  }

  // Patch Stock
  patchStockForm(stock) {
    this.selectedStock = stock;
    if (stock.medicine_name) {
      this.showMedicinesDiv('Medicines');
      this.requestItemsForm.controls.stock_item.disable();
      this.requestItemsForm.controls.medicine_name.disable();
      this.requestItemsForm.controls.potency_id.disable();
      if (this.requestItemsForm.value.ml_10 || this.requestItemsForm.value.ml_30 || this.requestItemsForm.value.ml_100) {
        this.requestItemsForm.patchValue({
          quantity: Number(this.requestItemsForm.value.ml_10) + Number(this.requestItemsForm.value.ml_30) + Number(this.requestItemsForm.value.ml_100)
        });
      }
    } else {
      this.showMedicinesDiv('items');
      if (this.selectedStock.status != 1) {
        this.requestItemsForm.controls.quantity.disable();
      }
      this.requestItemsForm.controls.stock_item.disable();
    }
    this.requestItemsForm.patchValue({
      id: stock.id,
      stock_item: stock.item_id,
      medicine_name: stock.medicine_id,
      quantity: stock.quantity,
      potency_id: stock.potency_id,
      ml_10: stock.tenml,
      ml_30: stock.thirtyml,
      ml_100: stock.hundredml
    });
  }

  submitRequestItemsForm() {
    this.requestItemsForm.markAllAsTouched();
    const invalid = [];
    const controls = this.requestItemsForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid);

    if (this.requestItemsForm.valid) {
      this.requestLoading.next(true);
      this._service.newStockRequest(this.requestItemsForm.value)
        .pipe(finalize(() => this.requestLoading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.requestItemsForm.reset();
            this.getStockrequests();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalRef.close();
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              this.backendValidations = res["errorValidations"];
              this.requestItemsForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  // Update Medicine Quantity
  updateMedicineQuantity() {
      if (this.requestItemsForm.value.ml_10 || this.requestItemsForm.value.ml_30 || this.requestItemsForm.value.ml_100) {
        this.requestItemsForm.patchValue({
          'quantity': Number(this.requestItemsForm.value.ml_10) + Number(this.requestItemsForm.value.ml_30) + Number(this.requestItemsForm.value.ml_100)
        });
    }
  }

  closeRequestItemsForm() {
    this.requestItemsForm.reset();
    this.modalService.dismissAll();
    this.resetMedicineValidations();
  }

  deletestock_item(id) {
    Swal.fire({
      title: 'Are you sure, You want to Remove?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then((result) => {
      if (result.value) {
        let payload = Object();
        Object.assign(payload, { id: id });
        this._service.deleteStockRequest(payload)
          .subscribe(res => {
            this.getStockrequests();
            Swal.fire({
              title: '',
              text: 'Successfully Removed.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            });
          })
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: 'Cancelled',
          text: '',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        });
      }
    });
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.getStockrequests();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.getStockrequests();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.getStockrequests();
  }
}
