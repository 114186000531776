import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './auth/login/login.component';
import { AgmGuardGuard } from './auth/agm-guard/agm-guard.guard';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TargetComponent } from './target/target.component';
import { TargetNpComponent } from './target-np/target-np.component';
import { StatusComponent } from './status/status.component';
import { DocWiseComponent } from './doc-wise/doc-wise.component';
import { SmsReportComponent } from './sms-report/sms-report.component';
import { BranchWiseComponent } from './branch-wise/branch-wise.component';
import { MonthReportComponent } from './month-report/month-report.component';
import { RegionWiseComponent } from './region-wise/region-wise.component';
import { SearchComponent } from './search/search.component';
import { CaseSheetComponent } from './components/case-sheet/case-sheet.component';



const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, canActivate: [AgmGuardGuard]},
	{ path: 'hm', component: HomeComponent, canActivate: [AgmGuardGuard]},
  { path: 'home', component: HomeComponent,  canLoad: [AgmGuardGuard],

  children:[
	{
		path: 'hm', component: DashboardComponent, 
	},
	{
		path: 'target', component: TargetComponent, 
	},
	{
		path: 'target-np', component: TargetNpComponent, 
	},
	{
		path: 'status', component: StatusComponent, 
	},
	{
		path: 'doc-wise', component: DocWiseComponent, 
	},
	{
		path: 'sms-report', component: SmsReportComponent, 
	},
	{
		path: 'month-report', component: MonthReportComponent, 
	},
	{
		path: 'branch_wise', component: BranchWiseComponent, 
	},
	{
		path: 'region_wise', component: RegionWiseComponent, 
	},
  {
    path: 'search', component: SearchComponent,
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  { path: 'case-sheet/:id', component: CaseSheetComponent},

  ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AgmRoutingModule { }
