import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CallcenterTokenService } from './callcenter-token.service';

@Injectable({
  providedIn: 'root'
})
export class CallcenterAuthStateService {

  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn()!);
  userAuthState = this.userState.asObservable();
  constructor(public token: CallcenterTokenService) { }
  setAuthState(value: boolean){
    this.userState.next(value);
  }
}
