import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { FoeTokenService } from './foe-token.service';

@Injectable({
  providedIn: 'root',
})

export class FoeAuthStateService {
  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn()!);
  userAuthState = this.userState.asObservable();
  constructor(public token: FoeTokenService) {}
  setAuthState(value: boolean) {
    this.userState.next(value);
  }
}