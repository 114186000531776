import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'audit-branch-fed-modal',
  templateUrl: './audit-branch-fed-modal.component.html',
  styleUrls: ['./audit-branch-fed-modal.component.scss']
})
export class AuditBranchFedModalComponent implements OnInit {

  @ViewChild("auditModal") auditModal: TemplateRef<any>;
  patientFeedbacks: any;

  constructor(private modalService: NgbModal,
    public masterService: MasterDataService) { }

  ngOnInit(): void {
  }

    /* Open Modal */
    openModal(patient, api: any) {
      this.masterService.auditFeedbackReport(api, patient.id)
      .subscribe(res => {
        let data = res['data'];
        if (res['data']){
          this.patientFeedbacks = data.patient_fed;
          this.modalService.open(this.auditModal, {
            windowClass: "modal",
            centered: true,
            size: "xl",
          });
        }
      });
    }
}
