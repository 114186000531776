import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { GmRoutingModule } from './gm-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { GmComponent } from './gm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GmHomeComponent } from './gm-home/gm-home.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ChequesComponent } from './cheques/cheques.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TargetComponent } from './target/target.component';
import { ClosingComponent } from './closing/closing.component';
import { DepositsComponent } from './deposits/deposits.component';
import { CallCenterComponent } from './call-center/call-center.component';
import { TargetFormComponent } from './target-form/target-form.component';
import { ComponentsModule } from './components/components.module';
import { EditDataComponent } from './edit-data/edit-data.component';

@NgModule({
  declarations: [LoginComponent, GmComponent, GmHomeComponent, ChequesComponent, DashboardComponent, TargetComponent, ClosingComponent, DepositsComponent, CallCenterComponent, TargetFormComponent, EditDataComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    GmRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    NgSelectModule
  ]
})
export class GmModule { }
