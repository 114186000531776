import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective {

  constructor(private elementRef: ElementRef) { }
  @HostListener(':input')
  onInput() {
    this.resize();
  }
resize() {
  this.elementRef.nativeElement.style.height = '150';
  this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
}
ngOnInit() {
  if (this.elementRef.nativeElement.scrollHeight) {
    setTimeout(() => this.resize());
  }
}
}
