<div class="container-fluid roles_sec">
    <div class="roles_title">
        <h4>To view the Roles and Responsibilities of Front Office Executive - <a class="dataTable-Link" (click)="openPdf(Pdfmodal)">Click
                Here</a></h4>
    </div>
</div>

<!-- roles -->
<ng-template #Pdfmodal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Roles and Responsibilities of Front Office Executive</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <iframe [src]="file_path" width="100%" height="500px"></iframe>
    </div>
</ng-template>
<!-- / roles -->