import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@pro/shared/shared.module';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { CaseSheetComponent } from './case-sheet/case-sheet.component';
import { FollowupDropModalComponent } from './followup-drop-modal/followup-drop-modal.component';
import { FeedbacklogModalComponent } from './feedbacklog-modal/feedbacklog-modal.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';
import { FeedbackEditModalComponent } from './feedback-edit-modal/feedback-edit-modal.component';
import { FeedbackComponent } from './feedback/feedback.component';


@NgModule({
  declarations: [FeedbackComponent, FeedbackModalComponent, CaseSheetComponent, FollowupDropModalComponent, FeedbacklogModalComponent, EditPatientComponent, FeedbackEditModalComponent],
  imports: [
    CommonModule,
    SharedModule
  ], exports: [
  FeedbackModalComponent,
  FollowupDropModalComponent,
  FeedbacklogModalComponent,
  EditPatientComponent,
  FeedbackEditModalComponent,
  ]
})
export class ComponentsModule { }
