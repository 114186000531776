import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";
import * as snippet from "app/main/tables/datatables/datatables.snippetcode";
import { Subject, BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { CallcenterService } from '@callcenter/services/callcenter.service';
import { NotificationService } from '@core/services/notification.service';
import { MasterDataService } from '@services/masterData/master-data.service';
declare var $;

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeadsComponent implements OnInit {

  isShown: boolean = false;
  public filterLoading = new BehaviorSubject(false);
  toggleShow() {
    this.isShown = !this.isShown;
  }
  branches: any;
  selectedFeedback: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public basicSelectedOption: number = 500;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  public exportCSVData;
  public loading = new BehaviorSubject(false);
  backendValidations: any;
  public leadsData: any[] = [];
  action: any = '';
  public mySearchControl = new FormControl();
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;

  // Lead Filter Form
  leadsFilterForm = this.fb.group({
    fname: [],
    fmobile: [],
    ffollowup_date: [],
    fstatus: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  fbstatus = [
    { type: "Interested for an Appointment", key: "Interest", value: 1 },
    { type: "Not Interested", key: "not_interested", value: 2 },
    { type: "Followup required", key: "followup_req", value: 3 },
  ];

  // Add Lead Form
  leadForm = this.fb.group({
    name: [null, Validators.required],
    mobile: [null, [Validators.required, Validators.pattern("^[6-9][0-9]{9}$")]],
    email: [null, [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,4}$")]],
    address: [null, Validators.required],
    branch_id: [null, Validators.required],
    appointment_date: [null, Validators.required]
  });

  get name() { return this.leadForm.get('name'); }
  get mobile() { return this.leadForm.get('mobile'); }
  get email() { return this.leadForm.get('email'); }
  get address() { return this.leadForm.get('address'); }
  get branch_id() { return this.leadForm.get('branch_id'); }
  get appointment_date() { return this.leadForm.get('appointment_date'); }

  errorMessages = {
    name: [{ type: 'required', message: 'This Field is Required' }],
    mobile: [{ type: 'required', message: 'This Field is Required' }, { type: 'pattern', message: 'This field is Invalid format' }],
    email: [{ type: 'required', message: 'This Field is Required' }, { type: 'pattern', message: 'The field is Invalid format' }],
    address: [{ type: 'required', message: 'This Field is Required' }],
    branch_id: [{ type: 'required', message: 'This Field is Required' }],
    appointment_date: [{ type: 'required', message: 'This Field is Required' }],
  }

  // ADD LEADS
  addLeads() {
    this.leadForm.markAllAsTouched();
    console.log(this.leadForm.value);
    const invalid = [];
    const controls = this.leadForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }

    if (this.leadForm.valid) {
      this.loading.next(true);
      this._service.addLead(this.leadForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closeLeadForm();
            this.getLeadsData();
          } else if (res['status'] == 'errors') {
            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.leadForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  closeLeadForm() {
    this.leadForm.reset();
    this.modalService.dismissAll();
  }

  allowNumbersOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    public _activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private _service: CallcenterService,
    private notifyService: NotificationService,
    private masterService: MasterDataService
  ) {
    this._unsubscribeAll = new Subject();
    this.masterService.masterData('callCenterapi');
    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });
  }

  ngOnInit(): void {
    this.exportCSVData = this.leadsData;
    this.getLeadsData();
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.leadsFilterForm.value.page = 1;
      this.getLeadsData();
    });
  }

  /* Get Leads Data */
  getLeadsData() {
    this.leadsFilterForm.value.fsearch_text = this.search_text;
    this._service.getLeadsData(this.leadsFilterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
        this.leadsData = data['AllLeads'];
        // this.tempData = this.attendanceReportData;
      }, err => {
        this.notifyService.showError(err['message'], err['title'])
      });
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getLeadsData();
  }

  /* Reset Filter */
  resetFilter() {
    this.leadsFilterForm.reset();
    this.getLeadsData();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.leadsFilterForm.value.perpage = this.basicSelectedOption;
    this.leadsFilterForm.value.page = 1;
    this.getLeadsData();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.leadsFilterForm.value.page = $event.page;
    this.getLeadsData();
  }

  /* Open Add and Edit Leads Modal */
  modalOpen(modal, action, selectedLead: any = '') {
    this.action = action;
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      backdrop: 'static',
    });
  }

  /* FeedBack Modal */
  feedbackModal(modal, feedback) {
    this.selectedFeedback = feedback;
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
    });
  }

  feedbackModalOpen(modal) {
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      size: "lg"
    });
  }

}
