import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FeedbackComponent } from './feedback/feedback.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { RemarksModalComponent } from './remarks-modal/remarks-modal.component';
import { FeedbacklogModalComponent } from './feedbacklog-modal/feedbacklog-modal.component';
import { FollowupDropModalComponent } from './followup-drop-modal/followup-drop-modal.component';
import { TodaycallsModalComponent } from './todaycalls-modal/todaycalls-modal.component';
import { CaseSheetComponent } from './case-sheet/case-sheet.component';
import { BillingReceiptModalComponent } from './billing-receipt-modal/billing-receipt-modal.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { CoreDirectivesModule } from '@core/directives/directives';
import { VisitsModalComponent } from './visits-modal/visits-modal.component';

@NgModule({
  declarations: [FeedbackComponent, PatientInfoComponent, RemarksModalComponent, FeedbacklogModalComponent, FollowupDropModalComponent, TodaycallsModalComponent, CaseSheetComponent, BillingReceiptModalComponent, InvoiceModalComponent, VisitsModalComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    CoreDirectivesModule
  ],exports: [
  	FeedbackComponent,
  	PatientInfoComponent,
  	RemarksModalComponent,
  	FeedbacklogModalComponent,
  	FollowupDropModalComponent,
  	TodaycallsModalComponent,
    BillingReceiptModalComponent,
    InvoiceModalComponent,
    VisitsModalComponent
  ]
})
export class ComponentsModule { }
