<section>
    <div class="container">
        <div class="row v-row">
            <div class="col-lg-6 col-xl-6 col-md-6 col-12">
                <div class="card px-3 py-3 mb-0">
                    <div class="content-header">
                        <h4 class="mb-0 text-center">Change Password </h4>
                    </div>
                    <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
                        <div class="mb-1">
                            <label for="old-password">Old Password<span class="text-danger">*</span></label>
                            <div class="position-relative position-relative form-password-toggle">
                                <input formControlName="current_password" [type]="oldPwdShow ? 'text' : 'password'" class="form-control"
                                    id="old-password"
                                    aria-describedby="old-password" name="current_password" autocomplete="new-password"/>
                                <div class="secure-eye" (click)="oldPwdShow = !oldPwdShow">
                                    <span class=" cursor-pointer"><i class="feather" [ngClass]="{'icon-eye-off': oldPwdShow,'icon-eye': !oldPwdShow}"></i></span>
                                </div>
                            </div>
                            <div *ngFor= "let error of errorMessages.current_password">
                               <ng-container *ngIf="current_password.hasError(error.type) && (current_password.touched)">
                                  <small class="text-danger">{{error.message}}</small>
                               </ng-container>
                            </div>
                        </div>

                        <div class="mb-1">                        
                            <label for="reset-password-new">New Password<span class="text-danger">*</span></label>
                            <div class="position-relative form-password-toggle ">
                                <input formControlName="new_password" [type]="newPwdShow ? 'text' : 'password'" class="form-control"
                                    id="reset-password-new"
                                    aria-describedby="reset-password-new" />
                                <div class="secure-eye" (click)="newPwdShow = !newPwdShow">
                                    <span class="cursor-pointer"><i class="feather" [ngClass]="{'icon-eye-off': newPwdShow,'icon-eye': !newPwdShow}"></i></span>
                                </div>
                            </div>
                            <div *ngFor= "let error of errorMessages.new_password">
                               <ng-container *ngIf="new_password.hasError(error.type) && (new_password.touched)">
                                  <small class="text-danger">{{error.message}}</small>
                               </ng-container>
                            </div>
                        </div>

                        <div class="mb-2">
                            <label for="reset-password-confirm">Confirm Password<span class="text-danger">*</span></label>
                            <div class="position-relative form-password-toggle ">
                                <input formControlName="new_password_confirmed" [type]="confirmPwdShow ? 'text' : 'password'" class="form-control"
                                    id="reset-password-confirm"
                                    aria-describedby="reset-password-confirm" />
                                <div class="secure-eye" (click)="confirmPwdShow = !confirmPwdShow">
                                    <span class="cursor-pointer"><i class="feather" [ngClass]="{'icon-eye-off': confirmPwdShow,'icon-eye': !confirmPwdShow}"></i></span>
                                </div>
                            </div>
                            <div class="text-danger" *ngIf="changePasswordForm.hasError('notSame')"><small>{{mobile_error_message}}</small></div>
                            <div *ngFor= "let error of errorMessages.new_password_confirmed">
                               <ng-container *ngIf="new_password_confirmed.hasError(error.type) && (new_password_confirmed.touched)">
                                  <small class="text-danger">{{error.message}}</small>
                               </ng-container>
                            </div>
                        </div>

                        <button [disabled]="loading | async" class="btn btn-primary btn-block" tabindex="3" type="submit">
                        <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1"></span>Set New Password</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>