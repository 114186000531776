import { GmService } from "@gm/services/gm.service";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NotificationService } from "@core/services/notification.service";
import { MasterDataService } from "@services/masterData/master-data.service";
import { BehaviorSubject } from "rxjs";
import { finalize } from "rxjs/operators";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { $ } from "protractor";
import { AgmService } from "../service/agm.service";

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss']
})
export class TargetComponent implements OnInit {
  public targetReportForm = this._formBuilder.group({
    month_wise: [null],
  });
  public filterLoading = new BehaviorSubject(false);
  public ColumnMode = ColumnMode;
  public state_id: any;
  monthWise = [
    { title: "Previous Target", value: "previous_target" },
    { title: "This Target", value: "this_target" },
  ];

  constructor(
    private notifyService: NotificationService,
    private _formBuilder: FormBuilder,
    private masterService: MasterDataService,
    private _service: GmService,
    private service: AgmService,
    public _activatedRoute: ActivatedRoute
  ) {

  }
  prev_all_branch_table = false;
  prev_branch_table = false;
  this_all_branch_table = false;
  this_branch_table = false;

  prev_all_branch_table_data: any;
  prev_branch_table_data: any;
  this_all_branch_table_data: any;
  this_branch_table_data: any;

  this_ap_branch_table_data = [];
  this_ts_branch_table_data = [];
  this_ka_branch_table_data = [];

  this_ap_branch_table_data_total = [];
  this_ts_branch_table_data_total = [];
  this_ka_branch_table_data_total = [];

  prev_ap_branch_table_data = [];
  prev_ts_branch_table_data = [];
  prev_ka_branch_table_data = [];
  prev_online_branch_table_data = [];

  prev_ap_branch_table_data_total = [];
  prev_ts_branch_table_data_total = [];
  prev_ka_branch_table_data_total = [];
  prev_online_branch_table_data_total = [];
  get month_wise() { return this.targetReportForm.get('month_wise'); }
  ngOnInit(): void {
    this.getProfileDetails();
  }

  onSubmit() {
    this.resetData();
    this.searchReport();
  }

  resetData() {
    this.prev_all_branch_table = false;
    this.prev_branch_table = false;
    this.this_all_branch_table = false;
    this.this_branch_table = false;

    this.prev_all_branch_table_data = [];
    this.prev_branch_table_data = [];
    this.this_all_branch_table_data = [];
    this.this_branch_table_data = [];

    if (this.targetReportForm.value.month_wise == "previous_target") {
      if (this.targetReportForm.value.state_id == "all") {
        this.prev_all_branch_table = true;
      } else {
        this.prev_branch_table = true;
      }
    } else if (this.targetReportForm.value.month_wise == "this_target") {
      if (this.targetReportForm.value.state_id == "all") {
        this.this_all_branch_table = true;
      } else {
        this.this_branch_table = true;
      }
    }
  }

  searchReport() {
    this.targetReportForm.markAllAsTouched();
    if (this.targetReportForm.valid) {
      let api = "target-report";
      this.targetReportForm.value.state_id = this.state_id;

      this._service.targetReport(api, this.targetReportForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          let data = res['data'];
          // if (data['table'] == "prev_all_branch_table") {
          //   this.prev_all_branch_table_data = data;
          // } else
          if (data['table'] == "prev_branch_table") {
            if(this.state_id == 1){
            this.prev_ap_branch_table_data = data[1]['data'];
            this.prev_ap_branch_table_data_total = this.getPrevTotal(this.prev_ap_branch_table_data);
            }else if(this.state_id == 2){
            this.prev_ts_branch_table_data = data[2]['data'];
            this.prev_ts_branch_table_data_total = this.getPrevTotal(this.prev_ts_branch_table_data);
            }else if(this.state_id == 3){
            this.prev_ka_branch_table_data = data[3]['data'];
            this.prev_ka_branch_table_data_total = this.getPrevTotal(this.prev_ka_branch_table_data);
            }
          }
          //  else if (data['table'] == "this_all_branch_table") {
          //   this.this_all_branch_table_data = data['all'];
          // } 
          else if (data['table'] == "this_branch_table") {
            if(this.state_id == 1){
            this.this_ap_branch_table_data = data[1]['data'];
            this.this_ap_branch_table_data_total = this.getTotal(this.this_ap_branch_table_data);
            }else if(this.state_id == 2){
            this.this_ts_branch_table_data = data[2]['data'];
            this.this_ts_branch_table_data_total = this.getTotal(this.this_ts_branch_table_data);
            } else if(this.state_id == 3){
            this.this_ka_branch_table_data = data[3]['data'];
            this.this_ka_branch_table_data_total = this.getTotal(this.this_ka_branch_table_data);
            }
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  summaryForProp1(cells: any[]) {
    const filteredCells = cells.filter(cell => !!cell);
    let total = filteredCells.reduce((sum, cell) => (sum += parseFloat(cell)), 0);
    return total.toFixed(2);
  }

  summaryForProp() {
    return "Total";
  }

  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        today: previousValue.today + currentValue.today,
        yesterday: previousValue.yesterday + currentValue.yesterday,
        achieved_upto: previousValue.achieved_upto + currentValue.achieved_upto,
        achieved_with_emi: previousValue.achieved_with_emi + currentValue.achieved_with_emi,
        emi: previousValue.emi + currentValue.emi,
        emi_cleared: previousValue.emi_cleared + currentValue.emi_cleared,
        np_cool: previousValue.np_cool + currentValue.np_cool,
        np_pack: previousValue.np_pack + currentValue.np_pack,
        np_target_amt: previousValue.np_target_amt + currentValue.np_target_amt,
        old_cool: previousValue.old_cool + currentValue.old_cool,
        op_target_amt: previousValue.op_target_amt + currentValue.op_target_amt,
        ren_cool: previousValue.ren_cool + currentValue.ren_cool,
        act_target_amt: previousValue.act_target_amt + currentValue.act_target_amt,
      }
    });
  }

  getPrevTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {
        achieved: previousValue.achieved + currentValue.achieved,
        gross_amt: previousValue.gross_amt + currentValue.gross_amt
      }
    });
  }

  getProfileDetails(){
    this.service.getProfileDetails().subscribe(res => {
      console.log(res['state_id']);
     this.state_id = res['state_id'];
    }, err => {
      console.log(err.error);
    });
  }
}
