import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpEventType,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpErrorHandler } from '@core/http/http-error-handler.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AppHttpClient {
    static baseUrl: string = environment.apiUrl;

    constructor(protected httpClient: HttpClient,
        protected errorHandler: HttpErrorHandler,
    ) { }


    static prefixUri(uri: string) {
        // return uri; //For Local
        return `${AppHttpClient.baseUrl}/${uri}`;
    }

    public post<T>(uri: string, payload: object = null): Observable<T> {
        return this.httpClient
            .post<T>(AppHttpClient.prefixUri(uri), payload)
            .pipe(catchError(err => this.errorHandler.handle(err, uri)));
    }

    public get<T>(
        uri: string,
        params = {},
        options: object = {}
    ): Observable<T> {
        const httpParams = this.transformQueryParams(params);
        return this.httpClient
            .get<T>(AppHttpClient.prefixUri(uri), {
                params: httpParams,
                ...options,
            })
            .pipe(
                catchError(err => this.errorHandler.handle(err, uri, options))
            );
    }

    protected transformQueryParams(params: object | null) {
        let httpParams = new HttpParams();

        if (params) {
            Object.entries(params).forEach(([key, value]) => {
                httpParams = httpParams.append(key, value == null ? '' : value);
            });
        }

        return httpParams;
    }
}