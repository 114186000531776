import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ProService } from '@pro/services/pro.service';

import { NotificationService } from '@core/services/notification.service';
import { BehaviorSubject } from 'rxjs';
import { CurrentUserService } from '@services/current-user/current-user.service';

@Component({
  selector: 'app-patient-receipt',
  templateUrl: './patient-receipt.component.html',
  styleUrls: ['./patient-receipt.component.scss']
})
export class PatientReceiptComponent implements OnInit {

  private sub: any;
  patient_id: number;
  patient_info: any;
  billingsData: any;
  caseSheetData: any;
  billingDetails: any;
  currentDate = new Date();
  constructor(private modalService: NgbModal,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    private _service: ProService,
    private currentUser: CurrentUserService,
    private router: Router) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.patient_id = +params['id']
    });
    this.getCaseSheet();
  }

  /* Get CaseSheet */
  getCaseSheet() {
    this._service.getPatientInvoiceData(this.patient_id)
      .subscribe(res => {
        let data = res['data'];
        console.log(data['invoice']['patient_billings']);
        if (data['invoice']['patient_billings']) {
          let billing = data['invoice']['patient_billings'];
          this.billingDetails = billing[0];
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  goBack() {
    window.history.back();
  }

  openLargeModal(modal) {
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      backdrop: "static",
      size: "xl",
    });
  }

  /* Print Fir Form */
  print() {
    const printContent = document.getElementById("receiptModal");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}
