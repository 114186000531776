<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Profile</h4>
                </div>
                <div class="card-body pb-1">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="addEmployeeForm" autocomplete="off">
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="emp-id">Employee ID<span class="text-danger">*</span></label>
                                            <input disabled formControlName="unique_id" readonly type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Employee Name<span class="text-danger">*</span></label>
                                            <input disabled type="text" formControlName="name"
                                                class="form-control trim">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Father's Name<span class="text-danger">*</span></label>
                                            <input disabled formControlName="father_name" type="text"
                                                class="form-control trim">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Gender<span class="text-danger">*</span></label>
                                            <ng-select [readonly] formControlName="gender" [items]="genderList" bindLabel="name"
                                                bindValue="value" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Blood group<span class="text-danger">*</span></label>
                                            <ng-select disabled formControlName="blood_group" [items]="bloodGroupList"
                                                bindLabel="name" bindValue="value" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Caste<span class="text-danger">*</span></label>
                                            <ng-select disabled formControlName="caste" [items]="casteList" bindLabel="name"
                                                bindValue="value" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Marital Status<span class="text-danger">*</span></label>
                                            <ng-select disabled formControlName="marital_status" [items]="maritalStatus"
                                                bindLabel="name" bindValue="value" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Date of Birth<span class="text-danger">*</span></label>
                                            <input disabled formControlName="dob" type="date"
                                                name="DateofBirth"
                                                class="form-control ng-untouched ng-pristine ng-valid">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Date of Join<span class="text-danger">*</span></label>
                                            <input disabled formControlName="date_of_join"
                                                type="date" name="DateofJoin"
                                                class="form-control ng-untouched ng-pristine ng-valid">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Designation<span class="text-danger">*</span></label>
                                            <ng-select disabled formControlName="role_id" [items]="designations" bindValue="id"
                                                bindLabel="name" placeholder="Select Designation">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Branch<span class="text-danger">*</span></label>
                                            <ng-select disabled formControlName="branch_id" [items]="branches" bindLabel="name"
                                                bindValue="id" placeholder="Select Branch">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">ESI Number</label>
                                            <input disabled
                                                maxlength="17" 
                                                formControlName="esi_no" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">PF Number</label>
                                            <input disabled maxlength="22"
                                                formControlName="pf_no" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Phone Number<span class="text-danger">*</span></label>
                                            <input disabled
                                                maxlength="10"
                                                formControlName="phone_number" type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Email Address<span class="text-danger">*</span></label>
                                            <input disabled formControlName="email" type="email" class="form-control"
                                                autocomplete="off">

                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Passport Number</label>
                                            <input disabled formControlName="passport_no" type="text"
                                                class="form-control">

                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Passport Expiry Date</label>
                                            <input disabled formControlName="passport_expiry_date" type="date"
                                                class="form-control">

                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Photo<span class="text-danger">*</span></label>
                                            <div class="custom-file">
                                                <input disabled formControlName="photo"
                                                    accept="image/x-png,image/gif,image/jpeg" id="photo" type="file"
                                                    class="custom-file-input">

                                                <label for="customFile" class="custom-file-label">{{fileName}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Present Address<span class="text-danger">*</span></label>
                                            <textarea formControlName="present_address" id="" rows="3"
                                                class="form-control"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Permanent Address<span class="text-danger">*</span></label>
                                            <textarea formControlName="permanent_address" id="" rows="3"
                                                class="form-control"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12 ">
                                        <div class="form-group">
                                            <label for="">Resume<span class="text-danger">*</span></label>
                                            <div class="custom-file">
                                                <input disabled formControlName="resume" accept=".doc, .docx, .pdf"
                                                    id="resume" type="file" class="custom-file-input"> <label
                                                    for="customFile"
                                                    class="custom-file-label">{{resumeFileName}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <h5 class="mt-50"><strong>How did you came to know about us. Please
                                                Specify<span class="text-danger">*</span></strong></h5>
                                        <div class="demo-inline-spacing">
                                            <div class="custom-control custom-radio mt-0"
                                                *ngFor="let x of howdoyouKnowUs">
                                                <input disabled type="radio" id="{{x.value}}" name="customRadio"
                                                    formControlName="how_you_came" value="{{x.id}}"
                                                    class="custom-control-input" />
                                                <label class="custom-control-label"
                                                    for="{{x.value}}">{{x.value}}</label>
                                            </div>

                                        </div>
                                        <div class="row mb-1">
                                            <!-- Through RMP -->
                                            <div class="col-xl-12" *ngIf="activeDiv== 'ref'">
                                                <h5 throughAgentCode><strong>Through REF<span
                                                            class="text-danger mt-1">*</span></strong></h5>
                                                <div class="form-group">
                                                    <label>Employee ID<span class="text-danger">*</span></label>
                                                    <app-employe-search (selectedEmploye)="employeSelected($event)">
                                                    </app-employe-search>
                                                    <small class="text-danger">{{reffered_by_error}}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <hr>
                                <h5 class="my-1"><strong>Qualification Details</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Name of the Degree<span class="text-danger">*</span></label>
                                            <input disabled formControlName="degree_name" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">University<span class="text-danger">*</span></label>
                                            <input disabled formControlName="university" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Year of Passing<span class="text-danger">*</span></label>
                                            <input disabled formControlName="year_passing" type="date"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">% of Marks<span class="text-danger">*</span></label>
                                            <input disabled
                                                formControlName="marks" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <!--Add Family Details on click-->
                                <div class="row align-items-center">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <h5 class="my-1"><strong>Family Details</strong></h5>
                                    </div>
                                </div>
                                <div formArrayName="families">
                                    <div [formGroupName]="i" class="row"
                                        *ngFor="let family of families.controls; let i = index;">
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Name<span class="text-danger">*</span></label>
                                                <input disabled formControlName="name" type="text"
                                                    class="form-control trim">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Relationship<span class="text-danger">*</span></label>
                                                <input disabled formControlName="relationship" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Education<span class="text-danger">*</span></label>
                                                <input disabled formControlName="education" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Occupation<span class="text-danger">*</span></label>
                                                <input disabled formControlName="occupation" type="text"
                                                    class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Contact Number<span class="text-danger">*</span></label>
                                                <input disabled
                                                    maxlength="10"
                                                    formControlName="contact_no" type="number" class="form-control">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!--Add Family Details on click ends-->

                                <hr>
                                <h5 class="my-1"><strong>Salary Details</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Gross Salary<span class="text-danger">*</span></label>
                                            <input disabled
                                                formControlName="gross_salary" type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Basic<span class="text-danger">*</span></label>
                                            <input disabled readonly formControlName="basic" type="number"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">HRA<span class="text-danger">*</span></label>
                                            <input disabled readonly formControlName="hra" type="number"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">ESI<span class="text-danger">*</span></label>
                                            <input disabled formControlName="esi" type="text" class="form-control">

                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Conveyance<span class="text-danger">*</span></label>
                                            <input disabled readonly formControlName="conveyance" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Special Allowance<span class="text-danger">*</span></label>
                                            <input disabled readonly formControlName="special_allowance" type="number"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">PF Amount<span class="text-danger">*</span></label>
                                            <input disabled
                                                formControlName="pf" type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">PT<span class="text-danger">*</span></label>
                                            <input disabled formControlName="pt" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Other Deductions<span class="text-danger">*</span></label>
                                            <input disabled
                                                formControlName="otd" type="number" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Bank Name<span class="text-danger">*</span></label>
                                            <input disabled formControlName="bank_name" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Bank Account Number<span class="text-danger">*</span></label>
                                            <input disabled
                                                formControlName="bank_no" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row align-items-center">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <h5 class="my-1"><strong>Experience Details</strong></h5>
                                    </div>
                                </div>
                                <div formArrayName="experiences">
                                    <div [formGroupName]="i" class="row"
                                        *ngFor="let experience of experiences.controls; let i = index;">
                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Company Name {{ i + 1 }}<span
                                                        class="text-danger">*</span></label>
                                                <input disabled type="text" formControlName="company_name"
                                                    class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Worked From Date<span class="text-danger">*</span></label>
                                                <input disabled type="date" formControlName="worked_from"
                                                    [max]="worked_from_date" class="form-control">
                                            </div>

                                        </div>

                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Worked To Date<span class="text-danger">*</span></label>
                                                <input disabled type="date" [min]="worked_to_date"
                                                    formControlName="worked_to" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Designation<span class="text-danger">*</span></label>
                                                <input disabled type="text" formControlName="designation"
                                                    class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <h5 class="my-1"><strong>Reference Address (Should have 2 Address with the same
                                        fields)</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Reference Name<span class="text-danger">*</span></label>
                                            <input disabled formControlName="reference_name" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Contact Number<span class="text-danger">*</span></label>
                                            <input disabled
                                                maxlength="10" oninput="this.value=this.value.slice(0,this.maxLength)"
                                                formControlName="reference_contact_no" type="number"
                                                class="form-control">

                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Relation with the Person<span
                                                    class="text-danger">*</span></label>
                                            <input disabled formControlName="reference_relation" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Address<span class="text-danger">*</span></label>
                                            <textarea formControlName="reference_address" id="" rows="3"
                                                class="form-control"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <h5 class="my-1"><strong>In Case of Emergency</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Name of the person to contact<span
                                                    class="text-danger">*</span></label>
                                            <input disabled formControlName="emergency_person_name" type="text"
                                                class="form-control trim">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Contact Number<span class="text-danger">*</span></label>
                                            <input disabled
                                                maxlength="10" 
                                                type="number" class="form-control"
                                                formControlName="emergency_contact_no">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Relation with the person<span
                                                    class="text-danger">*</span></label>
                                            <input disabled formControlName="emergency_relation" type="text"
                                                class="form-control trim">
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <h5 class="my-1"><strong>Other Details</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Pan Card Number<span class="text-danger">*</span></label>
                                            <input disabled formControlName="pan_card_no" type="text"
                                                class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Address Proof Submitted<span
                                                    class="text-danger">*</span></label>
                                            <ng-select [disabled]="true" [searchable]="false" formControlName="address_proof"
                                                [items]="yesStatus" bindLabel="value" bindValue="value"
                                                placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">ID Proof Submitted<span class="text-danger">*</span></label>
                                            <ng-select disabled [searchable]="false" formControlName="id_proof"
                                                [items]="yesStatus" bindLabel="value" bindValue="value"
                                                placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Marriage Anniversary</label>
                                            <input disabled
                                                [readonly]="addEmployeeForm.value.marital_status == 'un_married'"
                                                formControlName="marriage_aniversary" type="date" class="form-control">
                                        </div>
                                    </div>
                                </div>

                                <hr>
                                <h5 class="my-1"><strong>Exit Details</strong></h5>
                                <div class="row">
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Exit Status</label>
                                            <ng-select disabled formControlName="exit_status" [items]="exitStatus"
                                                bindLabel="value" bindValue="id" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Exit Date</label>
                                            <input disabled formControlName="exit_date" type="date" placeholder="Select"
                                                class="form-control ng-untouched ng-pristine ng-valid">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>