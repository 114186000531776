import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(protected httpClient: AppHttpClient) { }

  login(credentials: object) {
    return this.httpClient.post('accountapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('accountapi/me');
  }
  getBlockCaseSheet() {
    return this.httpClient.get('accountapi/reports/block-caseSheet');
  }

  dasboardData() {
    return this.httpClient.get('accountapi/get_home_content');
  }

  /* Advances */
  addExpenditure(payload: object) {
    return this.httpClient.post('accountapi/advances/expenditure', payload);
  }
  addRent(payload: object) {
    return this.httpClient.post('accountapi/receipts/add-rent', payload);
  }

  otherTransfers(payload: object) {
    return this.httpClient.post('accountapi/voucher/other-revenue', payload);
  }

  /** Reports */
  allReports(api, payload: object) {
    return this.httpClient.post('accountapi/' + api, payload);
  }

  getExpenditure(payload: object){
    return this.httpClient.post('accountapi/reports/expenditures' +payload);
  }


  /* Cheques */
  getChequeUpload(payload: object) {
    return this.httpClient.post('accountapi/cheque/uploads', payload);
  }

  getAllCheques(payload: object) {
    return this.httpClient.post('accountapi/cheque/all', payload);
  }

  updateChequeStatus(payload: object){
    return this.httpClient.post('accountapi/cheque/update-status', payload);
  }

  getBouncedCheques(payload: object) {
    return this.httpClient.post('accountapi/cheque/cheque-bounce', payload);
  }

  getDepositedCheques(payload: object) {
    return this.httpClient.post('accountapi/cheque/cheque-deposited', payload);
  }

  getClearedCheques(payload: object) {
    return this.httpClient.post('accountapi/cheque/cheque-cleared', payload);
  }

  getChequesTakenBack(payload: object) {
    return this.httpClient.post('accountapi/cheque/cheque-taken-back', payload);
  }

  getPatientInvoiceData(patientId) {
    return this.httpClient.get('accountapi/get_patient_invoice/' + patientId);
  }
  getPatientInvoiceDataByDate(patientId, branch_id) {
    return this.httpClient.get('accountapi/getPatientInvoiceByDate/' + patientId+'/'+branch_id);
  }

  getPatientInvoiceByDate(patientId, branch_id) {
    return this.httpClient.get('accountapi/getPatientInvoiceByDatebranch/' + patientId+'/'+branch_id);
  }

  getPatientInvoiceByDateNew(patientId, branch_id) {
    return this.httpClient.get('accountapi/getPatientInvoiceByDatebranchnew/' + patientId+'/'+branch_id);
  }

  getclosingReports(payload: object){
    return this.httpClient.post('accountapi/reports/getClosingReports', payload);

  }
  updateClosing(payload: object){
    return this.httpClient.post('accountapi/reports/updateClosing', payload);

  }

  getHeadOfAccounts() {
    return this.httpClient.post('accountapi/get-head-of-account-list');
  }

  /* Receipts Services */
  billing(payload: object) {
    return this.httpClient.post('accountapi/receipts/billing', payload);
  }

  getBranchBillingReport(payload: object) {
    return this.httpClient.post('accountapi/receipts/br-billing', payload);
  }

  getHOBillingReport() {
    return this.httpClient.post('accountapi/receipts/head-office-billing');
  }

  getKCOBillingReport() {
    return this.httpClient.post('accountapi/receipts/head-office-billing');
  }

  getUnconfirmedReceipts(payload: object) {
    return this.httpClient.post('accountapi/receipts/confirmed-receipts', payload);
  }

  getConfirmedReceipts(payload: object) {
    return this.httpClient.post('accountapi/receipts/confirmed-receipts', payload);
  }

  updateReciptPaymentType(payload: object) {
    return this.httpClient.post('accountapi/receipts/updateReceiptType', payload);
  }

  /* Targets */
  getTransferPatientsReport(payload: object) {
    return this.httpClient.post('accountapi/target/transfer-patients-reports', payload);
  }

  dayStatusData(payload: object) {
    return this.httpClient.post('accountapi/target/day-status', payload);
  }

  monthStatusData(payload: object) {
    return this.httpClient.post('accountapi/target/month-status', payload);
  }
  
  /** Month Report Modals */
  gt_patient_details(payload: object) {
    return this.httpClient.post('accountapi/target/gt_patient_details', payload);
  }

  patients_list(payload: object) {
    return this.httpClient.post('accountapi/target/patients_list', payload);
  }

  targetReport(api: string, payload: object){
    return this.httpClient.post('accountapi/target/'+api, payload);
  }

  // getTargetReport(payload: object) {
  //   return this.httpClient.post('accountapi/target/transfer-patients-reports', payload);
  // }

  /* Search */
  searchEmployees(payload: object) {
    return this.httpClient.post('accountapi/search/employee-search', payload);
  }

  get_employee_info(unique_id) {
    return this.httpClient.get('accountapi/get_emp_details/' + unique_id);
  }

  searchPatients(payload: object) {
    return this.httpClient.post('accountapi/search/search-patients', payload);
  }

  getCaseSheet(patientId) {
    return this.httpClient.get('accountapi/get_casesheet/' + patientId);
  }

}
