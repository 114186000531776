import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { CaseSheetUpdateComponent } from './case-sheet-update/case-sheet-update.component';
import { SharedModule } from '@doc/shared/shared.module';
import { CaseSheetComponent } from './case-sheet/case-sheet.component';
import { RemarksModalComponent } from './remarks-modal/remarks-modal.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';

@NgModule({
  declarations: [InvoiceModalComponent, CaseSheetUpdateComponent, CaseSheetComponent, RemarksModalComponent, FeedbackComponent, EditPatientComponent],
  imports: [
    CommonModule,
    SharedModule
  ], exports: [
  	InvoiceModalComponent,
    RemarksModalComponent,
    FeedbackComponent,
    EditPatientComponent
  ]
})
export class ComponentsModule { }
