import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '@core/services/notification.service';
import { GmService } from '@gm/services/gm.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AgmService } from "../service/agm.service";

@Component({
  selector: 'app-target-np',
  templateUrl: './target-np.component.html',
  styleUrls: ['./target-np.component.scss']
})
export class TargetNpComponent implements OnInit {
  currentDate = new Date();
  tableData: any;
  totalRow = [];
  tableKeys: any;
  totalall: any;
  public state_id: any;

  constructor(
    private _service: GmService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private service: AgmService,
    ) { }

  ngOnInit(): void {
    this.getProfileDetails();

    this.gettargetnp();
  }

gettargetnp(){
  
  this._service.gettargetnp().subscribe((res: any[]) => {

    let data = res['data'];
    this.tableKeys = Object.keys(data);
    this.tableData = data;
    this.totalall = data['all'];
    this.tableKeys.forEach((key, val) => {
    this.totalRow[key] = this.getTotal(this.tableData[key]);
    });

  });
}

getTotal(data) {
  return data.reduce(function (previousValue, currentValue) {
    return {
      yesterday: previousValue.yesterday + currentValue.yesterday,
      today: previousValue.today + currentValue.today,
      act_target_amt: previousValue.act_target_amt + currentValue.act_target_amt,
      np_pack: previousValue.np_pack + currentValue.np_pack,
      achieved_upto: previousValue.achieved_upto + currentValue.achieved_upto,

    }
  });  
}
getProfileDetails(){
  this.service.getProfileDetails().subscribe(res => {
    console.log(res['state_id']);
   this.state_id = res['state_id'];
  }, err => {
    console.log(err.error);
  });
}

}
