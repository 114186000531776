import { CoreMenu } from "@core/types";

export const callcenterMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url:"hm"
  },
  {
    id: "leads",
    title: "Leads",
    type: "item",
    icon: "trending-up",
    url:"leads",
  },
];
