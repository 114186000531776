import { CoreMenu } from "@core/types";

export const drcordinatorMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "hm",
  },
  {
    id: "doctor-report",
    title: "Doctor Report",
    type: "item",
    icon: "file-plus",
    url: "doctor_report",
  },
  {
    id: "target-op-no-due",
    title: "Target OP/REN/DUE",
    type: "item",
    icon: "file-plus",
    url: "target",
  },
  {
    id: "doctor-daily",
    title: "Doctor daily",
    type: "item",
    icon: "file-plus",
    url: "doctor_daily",
  },
  {
    id: "all-reports",
    title: "All Reports",
    type: "item",
    icon: "file-plus",
    url: "reports",
  },
  {
    id: "search",
    title: "Search",
    type: "section",
    icon: "search",
    children: [
      { id: "1", title: "Employee", type: "item", icon: "disc", url: "search/employees" },
      { id: "2", title: "Patients", type: "item", icon: "disc", url: "search/patients" },
    ],
  },
  
];
