<!-- Patient Info Modal-->
<ng-template #visitsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Vitits 
        </h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle">
                <thead>
                    <tr>
                        <th>Patient ID</th>
                        <th>Patient Name</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>Visit Date</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let billing of visitDetails;">
                        <td>{{billing.patient_unique_id ? billing.patient_unique_id : '-'}}</td>
                        <td>{{billing.patient_name ? billing.patient_name : '-'}}</td>
                        <td>{{billing.mobile ? billing.mobile : '-'}}</td>
                        <td>{{billing.email ? billing.email : '-'}}</td>
                        <td>{{billing.visit_date ? billing.visit_date : '-'}}</td>
                        
                    </tr>
                </tbody>
            </table>

           
        </div>
    </div>
</ng-template>
<!-- / Patient Info Modal-->
