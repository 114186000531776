import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProService {

  constructor(protected httpClient: AppHttpClient) { }

  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('proapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('proapi/me');
  }
  getPatientVisitData(patientId){
    return this.httpClient.get('proapi/get_visit_data/' + patientId);

  }
  getAttendenceReport(payload: object) {
    return this.httpClient.post('proapi/attendance_list', payload);
  }

  dasboardData() {
    return this.httpClient.get('proapi/get_home_content');
  }

  getUpdateRegNoListing(payload: object) {
    return this.httpClient.post('proapi/home/get_update_regno_list', payload);
  }

  updateRegNo(payload: object) {
    return this.httpClient.post('proapi/home/update_regno', payload);
  }

  /* Targets APIS */
  getTotalDueList(payload: object) {
    return this.httpClient.post('proapi/target/due-payments-list', payload);
  }

  getTransferAmountListing(payload: object) {
    return this.httpClient.post('proapi/target/transfer-amount', payload);
  }

  targetPreData() {
    return this.httpClient.post('proapi/target/transfer-amount');
  }

  targetThisData() {
    return this.httpClient.post('proapi/target/branch_target');
  }

  branchBillingReport() {
    return this.httpClient.post('proapi/target/branch_billing_report');
  }

  monthStatusData() {
    return this.httpClient.post('proapi/target/month_status');
  }

  dayStatusData(payload: object) {
    return this.httpClient.post('proapi/target/day-status', payload);
  }

  achievedTargetDetails(payload) {
    return this.httpClient.post('proapi/target/achieved-upto', payload);
  }

  branchTargetPatientDetails(payload) {
    return this.httpClient.post('proapi/target/gt-patient-details', payload);
  }

  getPatientInvoiceData(patientId) {
    return this.httpClient.get('proapi/get_patient_invoice/' + patientId);
  }
  getPatientInvoiceDataByDate(payload: object) {
    return this.httpClient.post('proapi/allreports/getPatientInvoiceByDate', payload);
  }
  /* New Patient Billing Listing */
  getpatientBillings(payload: object) {
    return this.httpClient.post('proapi/newPatients/list', payload);
  }

  octoregPatientBilling(payload: object) {
    return this.httpClient.post('proapi/ocToReg/oc-to-reg', payload);
  }

  getCaseSheet(patientId) {
    return this.httpClient.get('proapi/get_casesheet/' + patientId);
  }

  updateBilling(payload: object) {
    return this.httpClient.post('proapi/billing', payload);
  }

  duePayment(payload: object) {
    return this.httpClient.post('proapi/due-payment', payload);
  }

  billingComments(payload: object) {
    return this.httpClient.post('proapi/reply', payload);
  }

  addFeedBack(payload: object) {
    return this.httpClient.post('proapi/newPatients/add-feedback', payload);
  }

  /* Old Patient Billing Listing */
  getOldPatientBilling(payload: object) {
    return this.httpClient.post('proapi/oldPatients/old-patient-list', payload);
  }

  getNoDuePayments(payload: object) {
    return this.httpClient.post('proapi/oldPatients/no-dues-list', payload);
  }

  getRenewalDropList(payload: object) {
    return this.httpClient.post('proapi/oldPatients/no-dues-list', payload);
  }

  drop_patient(payload: object) {
    return this.httpClient.post('proapi/followups/drop_patient', payload);
  }

  /* PRL */
  getprlRenewals(payload: object) {
    return this.httpClient.post('proapi/prl/get_renewals_call', payload);
  }

  getDropOutsManually(payload: object) {
    return this.httpClient.post('proapi/reports/dropout-list-manually', payload);
  }

  getDropOutsAutomatic(payload: object) {
    return this.httpClient.post('proapi/reports/dropout-list-automatically', payload);
  }

  getFollowups(payload: object) {
    return this.httpClient.post('proapi/prl/followup', payload);
  }

  restorePatient(payload: object) {
    return this.httpClient.post('proapi/prl/restore-patient', payload);
  }

  submitFeedBackForm(payload: object) {
    return this.httpClient.post('proapi/add_patient_feedback', payload);
  }

  getFeedBackLogs(payload: object) {
    return this.httpClient.post('proapi/patient_feedbacks_log', payload);
  }

  /* Reports */
  getMissingFollowups(payload: object) {
    return this.httpClient.post('proapi/reports/missed_followup', payload);
  }

  getDocRenewalTargets(payload: object) {
    return this.httpClient.post('proapi/reports/renewal-target', payload);
  }

  getDueRenDropFollowups(api: string, payload: object) {
    return this.httpClient.post('proapi/' + api, payload);
  }

  getDoctorWiseTotalStatus() {
    return this.httpClient.post('proapi/reports/doc-wise-reports');
  }

  getRegPaymentListing(payload: object) {
    return this.httpClient.post('proapi/reports/reg_oc_list', payload);
  }

  allReports(api: string, payload: object) {
    return this.httpClient.post('proapi/' + api, payload);
  }

  getOcToRegPatients(payload: object) {
    return this.httpClient.post('proapi/reports/ocToReg-report', payload);
  }

  dateWiseBilling(payload: object) {
    return this.httpClient.post('proapi/reports/dateWise-billing', payload);
  }

  dateWiseBillingStats(payload: object) {
    return this.httpClient.post('proapi/reports/dateWise-billing-stats', payload);
  }

  /* Vouchers */
  addExpenditure(payload: object) {
    return this.httpClient.post('proapi/voucher/expenditure', payload);
  }

  moneyTransfer(payload: object) {
    return this.httpClient.post('proapi/voucher/money-transfer', payload);
  }

  otherTransfers(payload: object) {
    return this.httpClient.post('proapi/voucher/other-revenue', payload);
  }

  getMonetTransferReport(payload: object) {
    return this.httpClient.post('proapi/voucher/money-transfer-report', payload);
  }

  getHeadOfAccounts() {
    return this.httpClient.post('proapi/get-head-of-account-list');
  }

  /* Search APIs */
  allDetailsListing(payload: object) {
    return this.httpClient.post('proapi/search/details-search', payload);
  }

  receiptsListing(payload: object) {
    return this.httpClient.post('proapi/search/receipt-search', payload);
  }
}
