import { CoreMenu } from "@core/types";

export const proMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url:"hm"
  },
  {
    id: "target",
    title: "Target",
    type: "section",
    icon: "maximize-2",
    children: [
      {
        id:"due list",
        title:"Total Due List",
        type:"item",
        icon:"disc",
        url:"target/total-due-list"
      },
      {
        id:"transfer amount",
        title:"Transfer Amount",
        type:"item",
        icon:"disc",
        url:"target/transfer-amount"
      },
      {
        id:"target",
        title:"Target(Pre)",
        type:"item",
        icon:"disc",
        url:"target/premonth-target"
      },
      {
        id:"target",
        title:"Target(This)",
        type:"item",
        icon:"disc",
        url:"target/thismonth-target"
      },
      {
        id:"month status",
        title:"Month Status",
        type:"item",
        icon:"disc",
        url:"target/month-status"
      },
      {
        id:"day status",
        title:"Day Status",
        type:"item",
        icon:"disc",
        url:"target/day-status"
      }
    ],
  },
  {
    id: "prl",
    title: "PRL",
    type: "section",
    icon: "file-text",
    children: [
      {
        id: "renewal",
        title: "Renewal",
        type: "item",
        icon: "disc",
        url: "prl/renewal",
      },
      {
        id: "dropouts",
        title: "Dropouts (Manually)",
        type: "item",
        icon: "disc",
        url: "prl/dropouts-manual",
      },
      {
        id: "dropouts",
        title: "Dropouts (Automatic)",
        type: "item",
        icon: "disc",
        url: "prl/dropouts-automatic",
      },
      {
        id: "followup",
        title: "Followup",
        type: "item",
        icon: "disc",
        url: "prl/followup",
      }
    ],
  },
  {
    id: "reports",
    title: "Reports",
    type: "section",
    icon: "file-text",
    children: [
      {
        id: "missing followups",
        title: "Missing Followups",
        type: "item",
        icon: "disc",
        url: "reports/missing_followups",
      },
      {
        id: "renewal target",
        title: "Renewal Target",
        type: "item",
        icon: "disc",
        url: "reports/renewal-target",
      },
      {
        id: "due-ren",
        title: "Due-Ren-Drop Followup",
        type: "item",
        icon: "disc",
        url: "reports/due-ren-drop-followup-report",
      },
      {
        id: "oc list",
        title: "OC List",
        type: "item",
        icon: "disc",
        url: "reports/oc-payments-list",
      },
      {
        id: "reg",
        title: "REG",
        type: "item",
        icon: "disc",
        url: "reports/reg-payments-list",
      },
      {
        id: "holding",
        title: "Holding",
        type: "item",
        icon: "disc",
        url: "reports/holding-oc-patients",
      },
      {
        id: "total status",
        title: "Doctor wise total status",
        type: "item",
        icon: "disc",
        url: "reports/doctor-wise-total-status",
      },
      {
        id: "oc to reg",
        title: "OC to REG",
        type: "item",
        icon: "disc",
        url: "reports/oc-to-reg-patients",
      },
      {
        id: "date wise billing",
        title: "Datewise Billing",
        type: "item",
        icon: "disc",
        url: "reports/date-wise-billing",
      },
      {
        id: "all reports",
        title: "All Reports",
        type: "item",
        icon: "disc",
        url: "reports/all-reports",
      },
    ],
  },
  {
    id: "np",
    title: "NP",
    type: "item",
    icon: "users",
    url: "new-patients-billing",
  },
  {
    id: "oc-to-reg",
    title: "OC to REG",
    type: "item",
    icon: "users",
    url: "ocToReg-patients-billing",
  },
  {
    id: "op",
    title: "OP",
    type: "item",
    icon: "users",
    url: "old-patient-billing",
  },
  {
    id: "search",
    type: "section",
    title: "Search",
    icon: "search",
    children: [
      {
        id: "receipts",
        title: "Receipts",
        type: "item",
        icon: "disc",
        url: "search/receipts",
      },
      {
        id: "details",
        title: "Details",
        type: "item",
        icon: "disc",
        url: "search/all-details",
      },
    ],
  },
  {
    id: "voucher",
    type: "section",
    title: "Voucher",
    icon: "",
    children: [
      {
        id: "expenditure",
        title: "Expenditure",
        type: "item",
        icon: "disc",
        url: "vouchers/expenditure",
      },
      {
        id: "transfer",
        title: "Transfer",
        type: "item",
        icon: "disc",
        url: "vouchers/money-transfer-report",
      },
      {
        id: "other revenue",
        title: "Other Revenue",
        type: "item",
        icon: "disc",
        url: "vouchers/other-revenue-form",
      },
      {
        id: "money transfer",
        title: "Money Transfer(Report)",
        type: "item",
        icon: "disc",
        url: "vouchers/sent-report",
      },
    ],
  },
];
