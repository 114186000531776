import { Component, ElementRef, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';
import { MasterDataService } from '@services/masterData/master-data.service';
@Component({
  selector: 'app-case-sheet-update',
  templateUrl: './case-sheet-update.component.html',
  styleUrls: ['./case-sheet-update.component.scss']
})
export class CaseSheetUpdateComponent implements OnInit {
  takenDose: Array<string> = ['SINGLE', 'M', 'A', 'N', 'SOS'];
  selectedDosesValue = ['SINGLE', 'M', 'A', 'N', 'SOS'];
  takenDoseError: boolean = true;
  mySelectValue = null;
  private sub: any;
  patient_id: number;
  caseSheetData: any;
  public loading = new BehaviorSubject(false);
  public billingReportloading = new BehaviorSubject(false);
  public prescriptionloading = new BehaviorSubject(false);
  public filterLoading = new BehaviorSubject(false);
  backendValidations: any;
  patient_info: any;
  billing_report_data: any;
  prescription_data: any;
  billingsData: any;
  due: any;
  currentDate = new Date();
  dose_required = false;
  prescriptions = [];

  /* Static Arrays */
  medicines = [];
  potencies = [];
  potencyData = [
    { id: 1, name: "200CH" },
    { id: 3, name: "30CH" },
    { id: 5, name: "1M" },
    { id: 7, name: "10M" },
    { id: 8, name: "50M" },
    { id: 9, name: "6X" }
  ];
  biochemData = [
    { id: "Calcarea Fluorica", name: "Calcarea Fluorica" },
    { id: "Calcarea Phosphorica", name: "Calcarea Phosphorica" },
    { id: "Calcerea Sulphurica", name: "Calcerea Sulphurica" },
    { id: "Ferrum Phosphoricum", name: "Ferrum Phosphoricum" },
    { id: "Kali Muriaticum", name: "Kali Muriaticum" },
    { id: "Kali Phosphoricum", name: "Kali Phosphoricum" },
    { id: "Kali Sulphuricum", name: "Kali Sulphuricum" },
    { id: "Magnesium Phosphoricum", name: "Magnesium Phosphoricum" },
    { id: "Natrum Muriaticum", name: "Natrum Muriaticum" },
    { id: "Natrum Phosphoricum", name: "Natrum Phosphoricum" },
    { id: "Natrum Sulphuricum", name: "Natrum Sulphuricum" },
    { id: "Silicea", name: "Silicea" }
  ];
  patientFeedback = [
    { id: 1, value: "Fully Satisfied" },
    { id: 2, value: "Ok Satisfied" },
    { id: 3, value: "Not Happy" }
  ];
  no_of_days_list = [
    { id: 1, name: '1 Day',value: "1" },
    { id: 2, name: '3 Days',value: "3" },
    { id: 3, name: '7 Days',value: "7" },
    { id: 4, name: '15 Days',value: "15" },
    { id: 5, name: '30 Days',value: "30" },
    { id: 6, name: '45 Days',value: "45" },
    { id: 7, name: '2 M',value: "60" },
    { id: 8, name: '3 M',value: "90" },
    { id: 9, name: '6 M',value: "180" },
    { id: 10, name: '9 M',value: "270" },
    { id: 11, name: '12 M',value: "365" },
    { id: 12, name: '1.5 Y',value: "515" },
    { id: 13, name: '1.8 Y',value: "600" },
  
  ];
  /* Form Group */
  public caseSheetForm = this.fb.group({
    cheif_complaint: ["", Validators.required],
    presenting_complaint: ["", Validators.required],
    past_history: ["", Validators.required],
    father: ["", Validators.required],
    mother: ["", Validators.required],
    pgrandfather: ["", Validators.required],
    pgrandmother: ["", Validators.required],
    mgrandfather: ["", Validators.required],
    mgrandmother: ["", Validators.required],
    brothers: ["", Validators.required],
    sisters: ["", Validators.required],
    childrens: ["", Validators.required],
    life_history_model: ["", Validators.required],
    appetite: ["", Validators.required],
    thermals: ["", Validators.required],
    thirst: ["", Validators.required],
    menstrual_history: ["", Validators.required],
    desires: ["", Validators.required],
    perspiration: ["", Validators.required],
    aversions: ["", Validators.required],
    urine: ["", Validators.required],
    sleep: ["", Validators.required],
    stools: ["", Validators.required],
    sleep_position: ["", Validators.required],
    habits: ["", Validators.required],
    dreams: ["", Validators.required],
    doc_comments: ["", Validators.required],
    investigation: ["", Validators.required],
    journey_of_disease: ["", Validators.required],
    physical_appearance: ["", Validators.required],
    speed_of_patient: ["", Validators.required],
    pqrs: ["", Validators.required],
    deasis: ["", Validators.required],
    miasm: ["", Validators.required],
    opinion_of_remedies: ["", Validators.required],
    potency: ["", Validators.required],
    biochem: [""],
    // given_presc: ["", Validators.required],
    date_of_prescription: ["", Validators.required],
    no_of_days: ["", [Validators.required]],
    followup_date: ["", Validators.required],
    prescription_comments: ["", Validators.required],
    // comments: ["", [Validators.maxLength(500), Validators.pattern('^(?!.*  ).+')]], 
    comments: [null],
    // comments: [null, Validators.required],
    medicine_name: [null, Validators.required],
    potency_id: [null, Validators.required],
    given_prescription: ["", Validators.required],
    // potency_type : [null ],
    doseTiming: this.addDoseControls(),
    // special_medicine: ["", [Validators.required, Validators.pattern('^(?!.*  ).+')]],
    special_medicine: [""],
    treatment_duration: ["", [Validators.required]],
    pro_comments: ["", [Validators.required]],
    patient_satisfaction: [null, Validators.required]

    // cheif_complaint: [""],
    // presenting_complaint: [""],
    // past_history: [""],
    // father: [""],
    // mother: [""],
    // pgrandfather: [""],
    // pgrandmother: [""],
    // mgrandfather: [""],
    // mgrandmother: [""],
    // brothers: [""],
    // sisters: [""],
    // childrens: [""],
    // life_history_model: [""],
    // appetite: [""],
    // thermals: [""],
    // thirst: [""],
    // menstrual_history: [""],
    // desires: [""],
    // perspiration: [""],
    // aversions: [""],
    // urine: [""],
    // sleep: [""],
    // stools: [""],
    // sleep_position: [""],
    // habits: [""],
    // dreams: [""],
    // doc_comments: [""],
    // investigation: [""],
    // journey_of_disease: [""],
    // physical_appearance: [""],
    // speed_of_patient: [""],
    // pqrs: [""],
    // deasis: [""],
    // miasm: [""],
    // opinion_of_remedies: [""],
    // potency: [""],
    // given_presc: [""],
    // date_of_prescription: [""],
    // no_of_days: ["", [Validators.pattern('^(?!.*  ).+')]],
    // followup_date: [""],
    // prescription_comments: [""],
    // // comments: ["", [Validators.maxLength(500), Validators.pattern('^(?!.*  ).+')]], 
    // comments: [null],
    // medicine_name: [null],
    // potency_id: [null],
    // given_prescription: [""],
    // // potency_type : [null ],
    // doseTiming: this.addDoseControls(),
    // special_medicine: ["", [Validators.pattern('^(?!.*  ).+')]],
    // // special_medicine: [""],
    // treatment_duration: ["", [Validators.pattern('^(?!.*  ).+')]],
    // pro_comments: ["", [Validators.maxLength(500), Validators.pattern('^(?!.*  ).+')]],
    // patient_satisfaction: [null]
  });

  get cheif_complaint() { return this.caseSheetForm.get('cheif_complaint') }
  get presenting_complaint() { return this.caseSheetForm.get('presenting_complaint') }
  get past_history() { return this.caseSheetForm.get('past_history') }
  get father() { return this.caseSheetForm.get('father') }
  get mother() { return this.caseSheetForm.get('mother') }
  get pgrandfather() { return this.caseSheetForm.get('pgrandfather') }
  get pgrandmother() { return this.caseSheetForm.get('pgrandmother') }
  get mgrandfather() { return this.caseSheetForm.get('mgrandfather') }
  get mgrandmother() { return this.caseSheetForm.get('mgrandmother') }
  get brothers() { return this.caseSheetForm.get('brothers') }
  get sisters() { return this.caseSheetForm.get('sisters') }
  get childrens() { return this.caseSheetForm.get('childrens') }
  get life_history_model() { return this.caseSheetForm.get('life_history_model') }
  get appetite() { return this.caseSheetForm.get('appetite') }
  get thermals() { return this.caseSheetForm.get('thermals') }
  get thirst() { return this.caseSheetForm.get('thirst') }
  get menstrual_history() { return this.caseSheetForm.get('menstrual_history') }
  get desires() { return this.caseSheetForm.get('desires') }
  get perspiration() { return this.caseSheetForm.get('perspiration') }
  get aversions() { return this.caseSheetForm.get('aversions') }
  get urine() { return this.caseSheetForm.get('urine') }
  get sleep() { return this.caseSheetForm.get('sleep') }
  get stools() { return this.caseSheetForm.get('stools') }
  get sleep_position() { return this.caseSheetForm.get('sleep_position') }
  get prescription_comments() { return this.caseSheetForm.get('prescription_comments') }
  get habits() { return this.caseSheetForm.get('habits') }
  get dreams() { return this.caseSheetForm.get('dreams') }
  get doc_comments() { return this.caseSheetForm.get('doc_comments') }
  get investigation() { return this.caseSheetForm.get('investigation') }
  get journey_of_disease() { return this.caseSheetForm.get('journey_of_disease') }
  get physical_appearance() { return this.caseSheetForm.get('physical_appearance') }
  get speed_of_patient() { return this.caseSheetForm.get('speed_of_patient') }
  get pqrs() { return this.caseSheetForm.get('pqrs') }
  get deasis() { return this.caseSheetForm.get('deasis') }
  get miasm() { return this.caseSheetForm.get('miasm') }
  get opinion_of_remedies() { return this.caseSheetForm.get('opinion_of_remedies') }
  get potency() { return this.caseSheetForm.get('potency') }
  get given_presc() { return this.caseSheetForm.get('given_presc') }
  get dateofPrescription() { return this.caseSheetForm.get('date_of_prescription') }
  get no_of_days() { return this.caseSheetForm.get('no_of_days')}
  get followupDate() { return this.caseSheetForm.get('followup_date') }
  get comments() { return this.caseSheetForm.get('comments') }
  get medicine() { return this.caseSheetForm.get('medicine_name') }
  get potency_id() { return this.caseSheetForm.get('potency_id') }
  get biochem() { return this.caseSheetForm.get('biochem') }
  get potencyType() { return this.caseSheetForm.get('potency_type') }
  get dosesArray() { return <FormArray>this.caseSheetForm.get('doseTiming') }
  get specialMedicine() { return this.caseSheetForm.get('special_medicine') }
  get patientSatisfaction() { return this.caseSheetForm.get('patient_satisfaction') }
  get treatment_duration() { return this.caseSheetForm.get('treatment_duration') }
  get given_prescription() { return this.caseSheetForm.get('given_prescription') }
  get pro_comments() { return this.caseSheetForm.get('pro_comments') }

  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private _service: DocService,
    private notifyService: NotificationService,
    private masterService: MasterDataService,
    private router: Router,
    private el: ElementRef,
    private route: ActivatedRoute) { }
  ngOnInit(): void {

   
        this.masterService.mstMedicines('doctorapi').subscribe(res => {
      this.medicines = res['medicines'];
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });

    this.masterService.mstPotencies('doctorapi').subscribe(res => {
      this.potencies = res['potencies'];
    }, err => {
      this.notifyService.showError(err['message'], err['title']);
    });

    this.sub = this.route.params.subscribe(params => {
      this.patient_id = +params['id']
    });

    this.getCaseSheet();

    this._service.getPrescription(this.patient_id)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        this.prescriptions = res['data'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }
  public compareFn(a): boolean {
    return a.value == 15;
  }
  /* Get CaseSheet */
  getCaseSheet() {
    this._service.getCaseSheet(this.patient_id)
      .subscribe(res => {
        let data = res['data'];
        if (data.casesheet) {
          this.caseSheetData = data['casesheet'];
          this.patchCaseSheet();

          this.caseSheetForm.controls.treatment_duration.setValidators(null);
          this.caseSheetForm.controls.treatment_duration.updateValueAndValidity();

          this.caseSheetForm.controls.pro_comments.setValidators(null);
          this.caseSheetForm.controls.pro_comments.updateValueAndValidity();

          this.billingsData = data['billings'];
          this.patient_info = data['patient_data'];
          if(this.billingsData){
          this.due = data['billings'][0].due;
          }else{
          this.due = 0;
          }

          console.log(data['billings'][0]);
        } else {
          this.patient_info = data['patient_data'];
          this.caseSheetForm.patchValue({
            'date_of_prescription': this.currentDate.toISOString().split("T")[0],
          });
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }
  /* Patch Case Sheet */
  patchCaseSheet() {
    let someDate = new Date();
      let result = someDate.setDate(someDate.getDate() + 15);

    this.caseSheetForm.patchValue({
      'cheif_complaint': this.caseSheetData.cheif_complaint,
      'presenting_complaint': this.caseSheetData.presenting_complaint,
      'past_history': this.caseSheetData.past_history,
      'father': this.caseSheetData.father,
      'mother': this.caseSheetData.mother,
      'followup_date': new Date(result).toISOString().split("T")[0],
      'no_of_days': 15,
      'pgrandfather': this.caseSheetData.pgrandfather,
      'pgrandmother': this.caseSheetData.pgrandmother,
      'mgrandfather': this.caseSheetData.mgrandfather,
      'mgrandmother': this.caseSheetData.mgrandmother,
      'brothers': this.caseSheetData.brothers,
      'sisters': this.caseSheetData.sisters,
      'childrens': this.caseSheetData.childrens,
      'life_history_model': this.caseSheetData.life_history_model,
      'appetite': this.caseSheetData.appetite,
      'thermals': this.caseSheetData.thermals,
      'thirst': this.caseSheetData.thirst,
      'menstrual_history': this.caseSheetData.menstrual_history,
      'desires': this.caseSheetData.desires,
      'perspiration': this.caseSheetData.perspiration,
      'aversions': this.caseSheetData.aversions,
      'urine': this.caseSheetData.urine,
      'sleep': this.caseSheetData.sleep,
      'stools': this.caseSheetData.stools,
      'sleep_position': this.caseSheetData.sleep_position,
      'habits': this.caseSheetData.habits,
      'dreams': this.caseSheetData.dreams,
      'doc_comments': this.caseSheetData.doc_comments,
      'investigation': this.caseSheetData.investigation,
      'journey_of_disease': this.caseSheetData.journey_of_disease,
      'physical_appearance': this.caseSheetData.physical_appearance,
      'speed_of_patient': this.caseSheetData.speed_of_patient,
      'pqrs': this.caseSheetData.pqrs,
      'miasm': this.caseSheetData.miasm,
      'opinion_of_remedies': this.caseSheetData.opinion_of_remedies,
      'date_of_prescription': this.currentDate.toISOString().split("T")[0],
      'given_prescription': this.caseSheetData.given_prescription,
      'pro_comments': this.caseSheetData.pro_comments,
      'prescription_comments': this.caseSheetData.prescription_comments,
      'potency' :  this.caseSheetData.potency,
      'deasis' :  this.caseSheetData.deasia,

    });
  }
  autoGrowTextZone(e) {
    // e.target.style.height = "0px";
    // e.target.style.height = (e.target.scrollHeight + 25)+"px";
    this.el.nativeElement.style.height = '0';
    this.el.nativeElement.style.height = this.el.nativeElement.scrollHeight + 'px';
}

  /* Update Case Sheet */
  CSformSubmit() {
    this.caseSheetForm.markAllAsTouched();
    if (this.patient_id) {
      const invalid = [];
      const controls = this.caseSheetForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      if (!this.caseSheetForm.value.doseTiming.includes(true)) {
        this.dose_required = true;
        this.scrollToFirstInvalidControl();
        return false;
      }
      console.log(invalid);

      if (this.caseSheetForm.valid) {
        this.loading.next(true);
        this.caseSheetForm.value.patient_id = this.patient_id;
        this._service.updateCaseSheet(this.caseSheetForm.value)
          .pipe(finalize(() => this.loading.next(false)))
          .subscribe(res => {
            if (res['status'] == 'success') {
              this.notifyService.showSuccess(res['message'], res['title']);
              this.router.navigate(['doctor/home/patients']);
            } else if (res['status'] == 'errors') {
              for (var key in res['errorValidations']) {
                let backendValidations = res["errorValidations"];
                this.backendValidations = backendValidations;
                if (this.caseSheetForm.controls[key]) {
                  this.caseSheetForm.controls[key].setErrors({ serverValidationError: true });
                }
              }
            } else if (res['status'] == 'error') {
              this.notifyService.showError(res['message'], res['title']);
            }
          }, err => {
            this.notifyService.showError(err['message'], err['title']);
          });
      } else {
        this.scrollToFirstInvalidControl();
      }
    } else {
      this.notifyService.showError('Invalid Patient Id', 'Case Sheet');
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );
    if (firstInvalidControl)
      firstInvalidControl.focus(); //without smooth behavior
  }

  addDoseControls() {
    const arr = this.takenDose.map(element => {
      return this.fb.control(true);
    });
    return this.fb.array(arr);
  }

  checkDoseControlsTouched() {
    let flg = true;
    this.dosesArray.controls.forEach(control => {
      if (control.touched) {
        flg = false;
      }
    });
    return flg;
  }

  updateFollowupDate() {
    if (this.caseSheetForm.value.no_of_days) {
      let someDate = new Date();
      let result = someDate.setDate(someDate.getDate() + Number(this.caseSheetForm.value.no_of_days));
      this.caseSheetForm.patchValue({
        'followup_date': new Date(result).toISOString().split("T")[0],
      });
    } else {
      this.caseSheetForm.patchValue({
        'followup_date': null,
      });
    }
  }

  getSelectedDosesValue() {
    this.selectedDosesValue = [];
    this.dosesArray.controls.forEach((control, i) => {
      if (control.value) {
        this.selectedDosesValue.push(this.takenDose[i]);
      }
    });
    this.dose_required = false;
    // this.takenDoseError = this.selectedDosesValue.length > 0 ? false : true;
  }
  goBack() {
    window.history.back();
  }
  /* Billing Modal & Prescription Modal */
  modalOpen(modal, modal_type) {
    if (this.patient_id) {

      /* Billing Report Modal */
      if (modal_type == 'billing_report') {
        this.billingReportloading.next(true);
        this._service.getPatientInvoiceData(this.patient_id)
          .pipe(finalize(() => this.billingReportloading.next(false)))
          .subscribe(res => {
            if (res['status'] == 'success') {
              let data = res['data'];
              let invoice = data['invoice'];
              this.billing_report_data = (data['invoice']) ? (data['invoice']['patient_billings']) : '';
              this.modalService.open(modal, {
                windowClass: "modal",
                backdrop: 'static',
                centered: true,
                size: 'xl'
              });
            } else if (res['status'] == 'error') {
              this.notifyService.showError(res['message'], res['title']);
            }
          }, err => {
            this.notifyService.showError(err['message'], err['title']);
          });

      } else if (modal_type == 'prescription_modal') {

        /* Prescription Modal */
        this.prescriptionloading.next(true);
        this._service.getPrescription(this.patient_id)
          .pipe(finalize(() => this.prescriptionloading.next(false)))
          .subscribe(res => {
            if (res['status'] == 'success') {
              this.prescription_data = res['data'];
              this.modalService.open(modal, {
                windowClass: "modal",
                backdrop: 'static',
                centered: true,
                size: 'xl'
              });
            } else if (res['status'] == 'error') {
              this.notifyService.showError(res['message'], res['title']);
            }
          }, err => {
            this.notifyService.showError(err['message'], err['title']);
          });
      }
    } else {
      this.notifyService.showError('Invalid Patient Id', 'Case Sheet');
    }
  }

  allowNumbersOnly(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  isNumber(val) {
    return !isNaN(val);
  }

  medicineNameSearch(term: string, item: any) {
    term = term.trim().toLocaleLowerCase();
    let option = item.name.trim().toLocaleLowerCase();

    return (option.substr(0, term.length) == term);
    // return item.name.trim().toLocaleLowerCase().indexOf(term) > -1 || item.countryName.toLocaleLowerCase().indexOf(term) > -1;
  }
  biochemSearch(term: string, item: any) {
    term = term.trim().toLocaleLowerCase();
    let option = item.name.trim().toLocaleLowerCase();

    return (option.substr(0, term.length) == term);
  }
}
