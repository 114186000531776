import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { HrTokenService } from '@hr/auth/hr-token/hr-token.service';

@Injectable({
  providedIn: 'root'
})
export class HrGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
    constructor(private _token: HrTokenService,
    private _router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._token.isLoggedIn()){
      if (route.routeConfig.path == "login")
      this._router.navigate(['hr/home']);
    return true;
    }else{
      if (route.routeConfig.path != "login")
      this._router.navigate(['hr/login']);
    return true;
    }
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this._token.isLoggedIn()){
      return true;
    }else{
      this._router.navigate(['hr/login']);
    }
  }
}
