import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from '@services/masterData/master-data.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'global-invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {
  @ViewChild("invoiceModal") invoiceModal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private notifyService: NotificationService,
    public masterService: MasterDataService
  ) { }

  selectedPatientId: any;
  public modalRef: any;
  invoiceDetails: any;
  overall_payments: any;
  consultation_fee: any;
  patient_billings: any;

  ngOnInit(): void {
  }

  /* Open Patient Information Modal */
  openModal(api, patient_id) {
    this.selectedPatientId = patient_id;
    this.getPatientInvoiceData(api);
    this.modalRef = this.modalService.open(this.invoiceModal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true,
      size: "xl",
    });
  }

  /* Get Patient Invoice Data */
  getPatientInvoiceData(api) {
    this.masterService.getPatientInvoiceData(api, this.selectedPatientId)
      .subscribe(res => {
        if (res['status'] == 'success') {
          let data = res['data'];
          this.invoiceDetails = data['invoice'];
          if (this.invoiceDetails) {
            this.overall_payments = this.invoiceDetails['overall_payments'];
            this.consultation_fee = this.invoiceDetails['consultation_fee'];
            this.patient_billings = this.invoiceDetails['patient_billings'];
          }
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  isNumber(val) {
    return !isNaN(val);
  }

  ConvertToInt(val){
    val = val ? val : 0;
    return parseInt(val);
  }
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/gm/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }
}
