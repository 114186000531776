import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { FrontDeskRoutingModule } from './front-desk-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { FrontDeskComponent } from './front-desk.component';
import { FoeHomeComponent } from './foe-home/foe-home.component';
import { LayoutModule } from 'app/layout/layout.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { RolesComponent } from './components/roles/roles.component';
import { RFormComponent } from './r-form/r-form.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

import { FollowupsComponent } from './followups/followups.component';
import { OnlyConsultaionsComponent } from './only-consultaions/only-consultaions.component';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { ComponentsModule } from '@foe/components/components.module';
@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, FrontDeskComponent, FoeHomeComponent, RolesComponent, RFormComponent, ChangePasswordComponent, FollowupsComponent, OnlyConsultaionsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FrontDeskRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    NgxDatatableModule,
    CardSnippetModule,
    LayoutModule,
    NgSelectModule,
    NgbTooltipModule
  ]
})
export class FrontDeskModule { }
