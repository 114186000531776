import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallcenterTokenService {

  private issuer = {
    login: environment.apiUrl + '/callCenterapi/login',
  }
  
  constructor() { }

  handleData(token:any) {
    localStorage.setItem('callcenter_auth_token', token)
  }

  getToken() {
    return localStorage.getItem('callcenter_auth_token');
  }

  // Verify the token
  payload(token:any) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  isValidToken() {
    const token = this.getToken();
    if(token){
      const payload = this.payload(token);
      if(payload){
        return Object.values(this.issuer).indexOf(payload.iss) > -1 ? true : false;
      }
    }
    else{
      return false;
    }
  }

  // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }

  // Remove token
  removeToken() {
    localStorage.removeItem('callcenter_auth_token');
  }
}
