import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  constructor(protected httpClient: AppHttpClient) { }

  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('pharmacyapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('pharmacyapi/me');
  }

  dasboardData() {
    return this.httpClient.get('pharmacyapi/get_home_content');
  }

  getAttendenceReport(payload: object) {
    return this.httpClient.post('pharmacyapi/attendance_list', payload);
  }

  /* Get Medicines list API */
  getBranchMedicines() {
    return this.httpClient.get('pharmacyapi/get_branch_medicines');
  }

  /* Get Today Prescriptions API */
  getTodayPrescriptions(payload: object) {
    return this.httpClient.post('pharmacyapi/today_prescriptions', payload);
  }

  updatePrescription(payload: object) {
    return this.httpClient.post('pharmacyapi/update_prescription', payload);
  }

  medicineReceived(payload: object) {
    return this.httpClient.post('pharmacyapi/medicine_received', payload);
  }
  /*Stock request*/
  newStockRequest(payload: object) {
    return this.httpClient.post('pharmacyapi/new_stock_request', payload);
  }

  getStockrequests(payload: object) {
    return this.httpClient.post('pharmacyapi/stock_request_list', payload);
  }

  submitUpdateStockRequest(payload: object) {
    return this.httpClient.post('pharmacyapi/update_stock_request', payload);
  }

  deleteStockRequest(payload: object) {
    return this.httpClient.post('pharmacyapi/delete_stock_request', payload);
  }

  /* Courier Prescription Details */
  getCourierPrescriptions(payload: object) {
    return this.httpClient.post('pharmacyapi/courier/courier-prescription', payload);
  }

  updateCourierStatus(payload: object) {
    return this.httpClient.post('pharmacyapi/courier/update_courier_status', payload);
  }

  /* Get Pharmacy Items Stock, Medicines Stock */
  getItemsStock(payload: object) {
    return this.httpClient.post('pharmacyapi/prescription/get-stock-item', payload);
  }

  updateItemDetails(payload: object) {
    return this.httpClient.post('pharmacyapi/prescription/edit-item-stock', payload);
  }

  getMedicinesStock(payload: object) {
    return this.httpClient.post('pharmacyapi/prescription/get-medicine-stock', payload);
  }

  addMedicineStock(payload: object) {
    return this.httpClient.post('pharmacyapi/prescription/add-medicine-stock', payload);
  }

  updateMedicineStock(payload: object) {
    return this.httpClient.post('pharmacyapi/prescription/edit-medicine-item', payload);
  }

  /* Reports */
  getSummaryReports(payload: object) {
    return this.httpClient.post('pharmacyapi/allReports/summary-reports', payload);
  }
  getrefReports(payload: object) {
    return this.httpClient.post('pharmacyapi/allReports/referral-reports', payload);
  }
  getdatewiseSummary(payload: object) {
    return this.httpClient.post('pharmacyapi/allReports/date-wise-summary', payload);
  }
  getCourier(payload: object) {
    return this.httpClient.post('pharmacyapi/allReports/courier-report', payload);
  }
}
