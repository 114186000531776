
<ng-template #editModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Edit Lead</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="editLeadForm" (ngSubmit)="editLeads()">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Name</label>
                        <input formControlName="name" type="text" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.name && name.errors && name.touched }">
                        <div *ngFor="let error of errorMessages.name">
                            <ng-container *ngIf="name.hasError(error.type) && (name.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.name.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['name'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Mobile Number</label>
                        <input formControlName="mobile" type="text" class="form-control" maxlength="10" [ngClass]="{ 'is-invalid error': errorMessages.mobile && mobile.errors && mobile.touched }">
                        <div *ngFor="let error of errorMessages.mobile">
                            <ng-container *ngIf="mobile.hasError(error.type) && (mobile.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.mobile.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['mobile'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50 mb-50">
                        <label for="">Email</label>
                        <input formControlName="email" type="email" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.email && email.errors && email.touched }">
                        <div *ngFor="let error of errorMessages.email">
                            <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.email.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['email'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Address</label>
                        <textarea formControlName="address" rows="3" class="form-control" maxlength="250" [ngClass]="{ 'is-invalid error': errorMessages.address && address.errors && address.touched }"></textarea>
                        <div *ngFor="let error of errorMessages.address">
                            <ng-container *ngIf="address.hasError(error.type) && (address.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.address.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['address'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Latest Feedback</label>
                        <textarea formControlName="remarks" rows="3"
                            class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }"></textarea>
                        <div *ngFor="let error of errorMessages.remarks">
                            <ng-container *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.remarks.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['remarks'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Followup Date</label>
                        <input formControlName="followup_date" type="date" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.followup_date && followup_date.errors && followup_date.touched }">
                        <div *ngFor="let error of errorMessages.followup_date">
                            <ng-container *ngIf="followup_date.hasError(error.type) && (followup_date.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="editLeadForm.controls.followup_date.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['followup_date'] }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <button [disabled]="loading | async" class="btn btn-primary" type="submit">
                    <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1"></span>Update
                </button>
                <button class="btn btn-secondary ml-1" type="reset">Reset</button>
            </div>
        </form>
    </div>
</ng-template>