import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  constructor(protected httpClient: AppHttpClient) { }
 /* authentication apis */
 login(credentials: object) {
  return this.httpClient.post('auditapi/login', credentials);
}
getProfileDetails() {
  return this.httpClient.get('auditapi/me');
}


docWiseReport(api: string, payload: object) {
  return this.httpClient.post('auditapi/calling/' + api, payload);
}
submitFeedBackForm(payload: object) {
  return this.httpClient.post('auditapi/add_patient_feedback', payload);
}
}
