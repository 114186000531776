import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'expenditure-modal',
  templateUrl: './expenditure-modal.component.html',
  styleUrls: ['./expenditure-modal.component.scss']
})
export class ExpenditureModalComponent implements OnInit {

  @ViewChild("expeditureModal") expeditureModal: TemplateRef<any>;
  selectedVoucher: any;
  
  constructor(private modalService: NgbModal,) { }

  ngOnInit(): void {
  }

  /* Feedback Edit Modal */
  openModal(row) {
    this.selectedVoucher = row;
    this.modalService.open(this.expeditureModal, {
      windowClass: "modal",
      backdrop: "static",
      centered: true,
    });
  }

}
