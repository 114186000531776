import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { FoeService } from '@foe/services/foe.service';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

// Do not allow white spaces
const trimValidator: ValidatorFn = (control: FormControl) => {
  if (control.value && control.value.startsWith(' ')) {
    return {
      'trimError': { message: 'control has leading whitespace' }
    };
  }
  if (control.value && control.value.endsWith(' ')) {
    return {
      'trimError': { message: 'control has trailing whitespace' }
    };
  }

  return null;
};

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public loading = new BehaviorSubject(false);
  public oldPwdShow = false;
  public newPwdShow = false;
  public confirmPwdShow = false;
  checkPasswords(group: FormGroup) {
    const new_password = group.get('new_password').value;
    const new_password_confirmed = group.get('new_password_confirmed').value;
    return new_password == new_password_confirmed ? null : { notSame: true }
  }

  changePasswordForm = this.fb.group(
  {
    current_password: ['',[Validators.required,trimValidator,Validators.minLength(6)]],
    new_password: ['',[Validators.required,trimValidator,Validators.minLength(6),Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$')]],
    new_password_confirmed: ['',[Validators.required,trimValidator,Validators.minLength(6)]]
  }, { validators: this.checkPasswords });

  get current_password(){ return this.changePasswordForm.get('current_password')}
  get new_password(){ return this.changePasswordForm.get('new_password')}
  get new_password_confirmed(){ return this.changePasswordForm.get('new_password_confirmed')}

  errorMessages = {
    current_password: [{ type:'required',message:'The Old Password field is required'},{type:'trimError',message:'Old Password field should not contain spaces'},{type: 'minlength', message: 'The Old Password minimum Length 6'}],
    new_password: [{ type:'required',message:'The New Password field is required'},{type:'trimError',message:'New Password field should not contain spaces'},{type:'pattern',message:'New Password should be 0 - 6 characters long and should contain one number,one upper and lower case character and one special character'},{type: 'minlength', message: 'The New Password minimum Length 6'}],
    new_password_confirmed: [{ type:'required',message:'The Confirmed Password field is required'},{type:'trimError',message:'Confirm Password field should not contain spaces'},{type: 'minlength', message: 'The Confirm Password minimum Length 6'}]
  }

  constructor(private fb: FormBuilder,
    private _service: FoeService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  /*
   * Change Password Method
   */
  changePassword(){
    this.changePasswordForm.markAllAsTouched();
    if(this.changePasswordForm.valid) {
      this.loading.next(true);  
      this._service.changePassword(this.changePasswordForm.getRawValue())
      .pipe(finalize(() => this.loading.next(false)))
      .subscribe(res => {
        if(res['status'] == 'success'){
          this.toastr.success(res['message'], res['title']);
          this.changePasswordForm.reset();
          console.log(this.changePasswordForm.value);
        }else if(res['status'] == "errors") {

          for(var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              // this.backendValidations = backendValidations;
              this.changePasswordForm.controls[key].setErrors({serverValidationError: true});
           }

        }else{
          this.toastr.error(res['message'], res['title']);
        }
      }, err => {
        this.toastr.error(err.message, err.title);
      });
    }
  }
}
