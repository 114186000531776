<!-- Today's Patients Data -->
<section id="ngx-datatable-kitchen-sink" *ngIf="_activatedRoute.children.length === 0">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Today's Patients - <span>({{currentDate | date:'dd-MM-yyyy'}})</span></h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="todayPatientsFilter" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Type</label>
                                    <ng-select formControlName="fpatient_type" [items]="patientType" bindLabel="title" bindValue="value" placeholder="Select"></ng-select>
                                </div>
                            </div>
                            
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input formControlName="fpatient_unique_id" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input formControlName="fpatient_name" type="text" name="" id="" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button [disabled]="filterLoading.value || checkFilterValidity()" type="submit" class="btn btn-primary">
                                        <span *ngIf="filterLoading | async" class="spinner-border spinner-border-sm mr-1"></span>Submit
                                    </button>
                                    <button (click)="resetFilter()" type="reset" class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="todayPatientsData" [rowHeight]="90" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [rowClass]="getRowClass" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">{{patient_unique_id ? patient_unique_id : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="Patient Name" prop="patient_name" [width]="200"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Type" prop="patient_type" [width]="150">
                <ng-template let-patient_type="value" ngx-datatable-cell-template let-row="row">
                        <span>{{patient_type ? patient_type : '-'}} {{ row.patient_form_type == 'courier' ? '(courier)' : ''}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="200" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div *ngIf="row.status == 0" class="d-flex align-items-center">
                        <a class="badge badge-light-dark" (click)="caseSheetUpdate(row.patient_id)"
                            ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </a>
                    </div>
                    <div *ngIf="row.status == 1" class="d-flex align-items-center" [swal]="SweetAlertWarning">
                        <a class="badge badge-light-dark"
                            ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                Visited
                            </span>
                        </a>
                    </div>
                    <!-- <div *ngIf="row.status == 1" class="d-flex align-items-center" [swal]="SweetAlertWarning">
                        <a class="badge badge-light-dark"
                            ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </a>
                    </div> -->
                      <swal
                        #SweetAlertWarning
                        title="Case Sheet!"
                        text="Patient Already Visited!"
                        [swalOptions]="{ confirmButtonClass: 'btn btn-primary' }"
                        icon="warning"
                      >
                      </swal>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>

<!-- Patient Invoice Modal-->
<invoice-modal #invoiceModal></invoice-modal>
<!-- / Patient Invoice Modal-->