import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';

import { DrcordinatorRoutingModule } from './drcordinator-routing.module';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { LayoutModule } from 'app/layout/layout.module';
import { LoginComponent } from './auth/login/login.component';

import { DrcordinatorComponent } from './drcordinator.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DoctorReportComponent } from './doctor-report/doctor-report.component';
import { TargetComponent } from './target/target.component';
import { DoctorDailyComponent } from './doctor-daily/doctor-daily.component';
import { ReportsComponent } from './reports/reports.component';
import { ComponentsModule } from './components/components.module';

@NgModule({
  declarations: [LoginComponent, HomeComponent, DrcordinatorComponent, DashboardComponent, DoctorReportComponent, TargetComponent, DoctorDailyComponent, ReportsComponent],
  imports: [
    CommonModule,
    DrcordinatorRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    NgxDatatableModule,
    CardSnippetModule,
    LayoutModule,
    NgSelectModule,
    SharedModule,
    ComponentsModule,
    NgbTooltipModule
  ]
})
export class DrcordinatorModule { }
