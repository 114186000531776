<!-- Circular design -->
<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title text-uppercase">
                    <a routerLink="/audit/home/hm">
                        <span>
                            <i data-feather="arrow-left" class="font-medium-2"></i>
                        </span>
                    </a>
                    Circulars</h4>
            </div>
            <hr>
            <div class="card-body pb-1">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-4 col-xs-12 col-sm-6" *ngFor="let circular of circulars; let i = index;">
                        <div class="section-status card">
                            <div class="img-container">
                                <img src="assets/images/img/circular_red.png"
                                    alt="">
                            </div>
                            <div class="text">
                                <h2 class="title">
                                    {{circular.name}}
                                </h2>
                            </div>
                            <div class="footer">
                                <a class="btn btn-primary mey-btn circular-btn-md">
                                    Click Here
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</div>
<!-- Circular design ends-->

<!-- <circular-modal #circularModal></circular-modal> -->