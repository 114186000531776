import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class GmService {

  constructor(protected httpClient: AppHttpClient) { }

  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('gmapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('gmapi/me');
  }

  /** HOME - APIS */
  /** TRANSFER EMPLOYEE */
  transferEmployee(payload: object) {
    return this.httpClient.post('gmapi/home/transfer_employee', payload);
  }
  addTarget(payload: object) {
    return this.httpClient.post('gmapi/home/addTarget', payload);
  }
  targetList() {
    return this.httpClient.get('gmapi/home/targetList');
  }
  updateTarget(payload: object) {
    return this.httpClient.post('gmapi/home/updateTarget', payload);
  }
  updateBilling(payload: object) {
    return this.httpClient.post('gmapi/home/updateBilling', payload);
  }
  updateConsult(payload: object) {
    return this.httpClient.post('gmapi/home/updateConsult', payload);
  }

  
  /** Employees List */
  employees_list(payload: object) {
    return this.httpClient.post('gmapi/home/employees_list', payload);
  }

  /** Birthdays List */
  birthdays(payload: object) {
    return this.httpClient.post('gmapi/home/birthdays', payload);
  }

  /** Change Password of Employee */
  changePassword(payload: object) {
    return this.httpClient.post('gmapi/home/change_password', payload);
  }

  /** Ip Change  */
  ipChange(payload: object) {
    return this.httpClient.post('gmapi/home/ip_change', payload);
  }

  /* Messages API */
  addBranchWiseMessaging(payload: object) {
    return this.httpClient.post('gmapi/home/add_branch_wise_message', payload);
  }

  addEmployeeWiseMessaging(payload: object) {
    return this.httpClient.post('gmapi/home/add_Employee_Wise_Message', payload);
  }

  addDesignationWiseMessages(payload: object) {
    return this.httpClient.post('gmapi/home/add_Designation_Wise_Message', payload);
  }


  getAttendenceListing(filterValues: object) {
    return this.httpClient.post('gmapi/home/attendance_list', filterValues);
  }

  payAttendenceListing(filterValues: object) {
    return this.httpClient.post('gmapi/vouchers/pay-attendance', filterValues);
  }

  editAttendance(payload: object) {
    return this.httpClient.post('gmapi/home/edit_attendance', payload);
  }

  /** Cheques - Upload */
  getChequeUpload(payload: object) {
    return this.httpClient.post('gmapi/cheque/uploads', payload);
  }
  getChequeNotUpload(payload: object) {
    return this.httpClient.post('gmapi/cheque/not_uploads', payload);
  }
  getTenYearPackaged(payload: object) {
    return this.httpClient.post('gmapi/cheque/ten_package', payload);
  }
  getFamilyFreePackaged(payload: object) {
    return this.httpClient.post('gmapi/cheque/family_free', payload);
  }
  getFollowups(payload: object) {
    return this.httpClient.post('gmapi/cheque/followup', payload);
  }
  getFamilyReferredPackaged(payload: object) {
    return this.httpClient.post('gmapi/cheque/family_referred', payload);
  }
  getBouncedCheques(payload: object) {
    return this.httpClient.post('gmapi/cheque/cheque-bounce', payload);
  }

  getClearedCheques(payload: object) {
    return this.httpClient.post('gmapi/cheque/cheque-cleared', payload);
  }

  /* Targets */
  getTransferPatientsReport(payload: object) {
    return this.httpClient.post('gmapi/target/transfer-patients-reports', payload);
  }

  monthStatusData(payload: object) {
    return this.httpClient.post('gmapi/target/month-status', payload);
  }

  dayStatusData(payload: object) {
    return this.httpClient.post('gmapi/target/day-status', payload);
  }

  statusReport(payload: object) {
    return this.httpClient.post('gmapi/target/day-status', payload);
  }

  /* Closing */
  getBranchBillingReport(payload: object) {
    return this.httpClient.post('gmapi/receipts/br-billing', payload);
  }

  getHOBillingReport() {
    return this.httpClient.post('gmapi/receipts/head-office-billing');
  }

  getKCOBillingReport() {
    return this.httpClient.post('gmapi/receipts/kco-billing');
  }

  targetReport(api: string, payload: object) {
    return this.httpClient.post('gmapi/target/' + api, payload);
  }
  gettargetnp(){

    return this.httpClient.post('gmapi/target/target-np');

  }
  gettargetop(){

    return this.httpClient.post('gmapi/target/target-op');

  }
  gethigherpackage(){

    return this.httpClient.post('gmapi/target/high-package');

  }
  getrefunds(){

    return this.httpClient.post('gmapi/reports/refunds-reports');

  }
  getereport(){
    return this.httpClient.post('gmapi/target/e-report');
  }
 
  docWiseReport(api: string, payload: object) {
    return this.httpClient.post('gmapi/reports/br/doctor_wise/' + api, payload);
  }
  perDocWiseReport(api: string, payload: object) {
    return this.httpClient.post('gmapi/reports/br/doc_wise/' + api, payload);
  }

  /* Call Center */
  getCCStatus() {
    return this.httpClient.post('gmapi/call-center/cc-status');
  }
  getphccstatus(payload: object){
    return this.httpClient.post('gmapi/cc/cc-status-ph-star', payload);
  }
  getCampCcstatus(){
    return this.httpClient.post('gmapi/cc/camp-status');
  }
  getDocCcstatus(){
    return this.httpClient.post('gmapi/cc/doc-on-call-status');
  }
  getFreeCcstatus(){
    return this.httpClient.post('gmapi/cc/online-on-call-status');
  }
  getCcOncallstatus(){
    return this.httpClient.post('gmapi/cc/cc-on-call-status');
  }
  getspearoncallstatus(){
    return this.httpClient.post('gmapi/cc/spearon-on-call-status');
  }
  /* Deposites */
  getUnconfirmedReceipts(payload: object) {
    return this.httpClient.post('gmapi/receipts/confirmed-receipts', payload);
  }

  getConfirmedReceipts(payload: object) {
    return this.httpClient.post('gmapi/receipts/confirmed-receipts', payload);
  }

  updateReciptPaymentType(payload: object) {
    return this.httpClient.post('gmapi/receipts/updateReceiptType', payload);
  }

  /* voucher */
  getExpenditureCo(payload: object) {
    return this.httpClient.post('gmapi/vouchers/all-expenditure-co', payload);
  }

  /* Search */

  searchEmployees(payload: object) {
    return this.httpClient.post('gmapi/search/employee-search', payload);
  }

  get_employee_info(unique_id) {
    return this.httpClient.get('gmapi/get_emp_details/' + unique_id);
  }

  searchPatients(payload: object) {
    return this.httpClient.post('gmapi/search/search-patients', payload);
  }
  searchBillings(payload: object) {
    return this.httpClient.post('gmapi/search/search-billings', payload);
  }

  getCaseSheet(patientId) {
    return this.httpClient.get('gmapi/get_casesheet/' + patientId);
  }

  //get employee details
  get_emp_details(patientId) {
    return this.httpClient.get('gmapi/get_emp_details/' + patientId);
  }

  /* Reports */
  regionWiseReports(api: string, payload: object) {
    return this.httpClient.post('gmapi/reports/br/region_wise/' + api, payload);
  }
  branchWiseReports(api: string, payload: object) {
    return this.httpClient.post('gmapi/reports/br/branch_wise/' + api, payload);
  }
  doctorsDailyReports(payload: object) {
    return this.httpClient.post('gmapi/reports/doctor-daily-reports', payload);
  }

  getDueMonthWise(payload: object) {
    return this.httpClient.post('gmapi/reports/month_wise_drop', payload);
  }
  /* AGM API's */
  agmTargetReport(api: string, payload: object) {
    return this.httpClient.post('gmapi/agm/target/' + api, payload);
  }



  getRenPendingPatientList(payload: object) {
    return this.httpClient.post('gmapi/reports/get_ren_pending_list', payload);
  }

  getRenPatientList(payload: object) {
    return this.httpClient.post('gmapi/reports/get_ren_list', payload);
  }

  getAchievedModalContent(payload: object) {
    return this.httpClient.post('gmapi/reports/get_achieved_data', payload);
  }

  targetStatus(payload: object) {
    return this.httpClient.post('gmapi/target/target_status', payload);
  }
}
