<!-- circular Modal -->
<ng-template #circularModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Circular</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <iframe [src]="file_path" width="100%" height="500px"></iframe>
    </div>
</ng-template>
<!-- / circular Modal -->