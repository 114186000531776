import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drcordinator',
  templateUrl: './drcordinator.component.html',
  styleUrls: ['./drcordinator.component.scss']
})
export class DrcordinatorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
