import { CoreMenu } from "@core/types";

export const pharmacyPanelMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: 'hm'
  },
  {
    id: "prescription",
    title: "Prescription",
    type: "item",
    icon: "file-plus",
    url: "prescription",
  },
  {
    id: "courier",
    title: "Courier",
    type: "item",
    icon: "archive",
    url: "courier",
  },
  {
    id: "pharmacy",
    title: "Pharmacy",
    type: "section",
    icon: "plus-square",
    children: [
      {
        id: "items stock",
        title: "Items Stock",
        type: "item",
        icon: "disc",
        url: "store/stock-items"
      },
      {
        id: "medicine stock",
        title: "Medicine Stock",
        type: "item",
        icon: "disc",
        url: "store/medicines-stock"
      }
    ],
  },
  {
    id: "stock request",
    title: "Stock Request",
    type: "item",
    icon: "grid",
    url: 'stock-request'
  },
  {
    id: "reports",
    title: "Reports",
    type: "section",
    icon: "file-text",
    children: [
      {
        id: "referral",
        title: "Referral",
        type: "item",
        icon: "disc",
        url: "reports/referral",
      },
      {
        id: "today summary",
        title: "Today Summary",
        type: "item",
        icon: "disc",
        url: "reports/summary-reports",
      },
      {
        id: "day wise summary",
        title: "Day Wise Summary",
        type: "item",
        icon: "disc",
        url: "reports/daywise-summary",
      },
      {
        id: "courier",
        title: "Courier",
        type: "item",
        icon: "disc",
        url: "reports/courier-reports",
      }
    ],
  },
  {
    id: "my account",
    title: "My Account",
    type: "item",
    icon: "user",
    url: 'my-account'
  }
];
