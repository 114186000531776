import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FoeService } from '@foe/services/foe.service';

@Component({
  selector: 'patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.scss']
})
export class PatientInfoComponent implements OnInit {

  selectedPatientFollowUp: any;
	@ViewChild("patientInfoModal") patientInfoModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private _service: FoeService) { }

  ngOnInit(): void {
  }

  /* Open Patient Information Modal */
  openModal(row) {
    this.selectedPatientFollowUp = row;
  	this.modalService.open(this.patientInfoModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
  	});
  }
}
