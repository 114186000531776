import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import 'hammerjs'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'

import { CoreModule } from '@core/core.module'
import { CoreCommonModule } from '@core/common.module'
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components'

import { coreConfig } from 'app/app-config'

import { AppComponent } from 'app/app.component'
import { LayoutModule } from 'app/layout/layout.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalComponentsModule } from '@global_components/global-components.module';
import { HrModule } from './modules/hr/hr.module';
import { FrontDeskModule } from './modules/front-desk/front-desk.module';
import { DoctorModule } from './modules/doctor/doctor.module';
import { ProModule } from './modules/pro/pro.module';
import { PharmacyModule } from './modules/pharmacy/pharmacy.module';
import { GmModule } from './modules/gm/gm.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './services/token/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { PolineComponent } from './poline/poline.component';
import { CallcenterModule } from './modules/callcenter/callcenter.module';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { LoaderComponent } from '@global_components/loader/loader.component';
import { AuditModule } from './modules/audit/audit.module';
import { DrcordinatorModule } from './modules/drcordinator/drcordinator.module';
import { AgmModule } from './modules/agm/agm.module';

@NgModule({
  declarations: [AppComponent, PolineComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    AgmModule,
    GlobalComponentsModule,
    HrModule,
    FrontDeskModule,
    DoctorModule,
    ProModule,
    PharmacyModule,
    CallcenterModule,
    GmModule,
    AuditModule,
    DrcordinatorModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CoreDirectivesModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      toastClass: 'toast ngx-toastr',
      closeButton: true,
      preventDuplicates: true
    }),

    //NgBootstrap
    NgbModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }