import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import {
  ColumnMode,
  DatatableComponent,
  SelectionType,
} from "@swimlane/ngx-datatable";

import { HrService } from '@hr/services/hr.service';
import { NotificationService } from '@core/services/notification.service';
import { DatatablesService } from "app/main/tables/datatables/datatables.service";
import * as snippet from "app/main/tables/datatables/datatables.snippetcode";
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BirthdayComponent implements OnInit {

  isShown: boolean = false;
  designations: any;
  branches: any;
  public filterLoading = new BehaviorSubject(false);
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public basicSelectedOption: number = 500;
  public exportCSVData = [];
  public employeesBirthdayRows: any[] = [];
  private tempData = [];
  public ColumnMode = ColumnMode;
  public SelectionType = SelectionType;

  birthDayFilterForm = this._formBuilder.group({
    femp_unique_id: [],
    femp_name: [],
    frole: [],
    fbranch: [],
    fstatus: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("tableRowDetails") tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: HrService,
    private masterService: MasterDataService,
    private notifyService: NotificationService) { 

    this.masterService.masterData('hrapi');
    this.masterService.rolesModel$.subscribe(val => {
      this.designations = val;
    });

    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });
  }

  ngOnInit(): void {
  	this.getTodayBirthDays();
  }

    /**
   * Search (filter)
   *
   * @param event
   */
  filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempData.filter(function (d) {
      return d.Employee_Name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.employeesBirthdayRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  /* Get Employee Today BirthDays */
  getTodayBirthDays(exportListing = false) {
    if (exportListing == true) {
      this.birthDayFilterForm.value.export = true;
    }
  	this._service.getTodayBirthDays(this.birthDayFilterForm.value)
    .pipe(finalize(() => this.filterLoading.next(false)))
  	.subscribe(res => {
      let data = res['data'];
       
       
        this.employeesBirthdayRows = data['employeeBirthday'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
        this.tempData = this.employeesBirthdayRows;
  	}, err => {
  		this.notifyService.showError(err['message'], err['title']);
  	});
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getTodayBirthDays();
  }

  /* Reset Filter */
  resetFilter() {
    this.birthDayFilterForm.reset();
    this.getTodayBirthDays();
  }

    /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if(this.birthDayFilterForm.value.femp_unique_id || this.birthDayFilterForm.value.femp_name || this.birthDayFilterForm.value.frole || this.birthDayFilterForm.value.fbranch) {
      return false;
    } else {
      return true;
    }
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  
}
