import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../../services/loader.service';


@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  isLoading: any;

  constructor(private loaderService: LoaderService) { 
    this.loaderService.isLoading.subscribe(val => {
      this.isLoading = val;
    });
  }

  ngOnInit(): void {
  }

}
