<!-- New Patients Billing Data -->
<section id="ngx-datatable-kitchen-sink" *ngIf="_activatedRoute.children.length === 0">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">OC to REG Patients Billing</h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="patientBillingFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input formControlName="fpatient_unique_id" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input formControlName="fpatient_name" type="text" name="" id=""
                                        class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button [disabled]="filterLoading.value || checkFilterValidity()" type="submit"
                                        class="btn btn-primary">
                                        <span *ngIf="filterLoading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button (click)="resetFilter()" type="reset"
                                        class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="oldPatientsBillingData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">{{patient_unique_id?
                            patient_unique_id : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doctor_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <div *ngIf="row.duestatus == '1' || row.duestatus == null">
                            <a *ngIf="branch_patient_id" class="badge badge-light-secondary"
                            (click)="updateBilling(row.patient_id)" ngbTooltip="Pay Due"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i class="fa fa-money font-small-4 teal-color">
                                    </i>
                                </span>
                            </a>
                            <a *ngIf="!branch_patient_id" class="badge badge-light-secondary"
                                (click)="updateBilling(row.patient_id)" ngbTooltip="Billing"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i class="fa fa-money font-small-4 teal-color">
                                    </i>
                                </span>
                            </a>
                        </div>
<!--                         <a *ngIf="row.duestatus == '0' || row.duestatus == null" class="badge badge-light-secondary"
                            ngbTooltip="Billing" tooltipClass="fadeInAnimation">
                            <span>
                                NO-DUE
                            </span>
                        </a> -->
                        <a class="badge badge-light-success ml-50" ngbTooltip="Feedback" tooltipClass="fadeInAnimation"
                            (click)="patientFBmodal.openModal(row)">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </a>
                        <a class="badge badge-light-dark ml-50" (click)="caseSheet(row.patient_id)"
                            ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation">
                            <span>
                                <i class="far fa-id-card font-small-4">
                                </i>
                            </span>
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ New Patients Billing Data -->

<!-- Patient Info Modal-->
<invoice-modal #invoiceModal></invoice-modal>
<!-- / Patient Info Modal-->

<!-- PATIENT FEEDBACK Modal -->
<feedback-modal #patientFBmodal></feedback-modal>
<!--/ PATIENT FEEDBACK Modal -->