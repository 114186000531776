import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AchievedModalComponent } from './achieved-modal/achieved-modal.component';
import { RemarksModalComponent } from './remarks-modal/remarks-modal.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import { UpdateBillingComponent } from './update-billing/update-billing.component';
import { SharedModule } from '@pro/shared/shared.module';
import { BillingCommentsComponent } from './billing-comments/billing-comments.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { CaseSheetComponent } from './case-sheet/case-sheet.component';
import { PayDueBillingComponent } from './pay-due-billing/pay-due-billing.component';
import { DropModalComponent } from './drop-modal/drop-modal.component';
import { FollowupDropModalComponent } from './followup-drop-modal/followup-drop-modal.component';
import { FeedbacklogModalComponent } from './feedbacklog-modal/feedbacklog-modal.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { VoucherReceiptComponent } from './voucher-receipt/voucher-receipt.component';
import { PatientReceiptComponent } from './patient-receipt/patient-receipt.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';
import { FeedbackEditModalComponent } from './feedback-edit-modal/feedback-edit-modal.component';
import { ExpenditureModalComponent } from './expenditure-modal/expenditure-modal.component';
import { CommentsModalComponent } from './comments-modal/comments-modal.component';

@NgModule({
  declarations: [AchievedModalComponent, PatientInfoComponent, RemarksModalComponent, InvoiceModalComponent, UpdateBillingComponent, BillingCommentsComponent, FeedbackModalComponent, CaseSheetComponent, PayDueBillingComponent, DropModalComponent, FollowupDropModalComponent, FeedbacklogModalComponent, FeedbackComponent, VoucherReceiptComponent, PatientReceiptComponent, EditPatientComponent, FeedbackEditModalComponent, ExpenditureModalComponent, CommentsModalComponent],
  imports: [
    CommonModule,
    SharedModule
  ], exports: [
  AchievedModalComponent,
  RemarksModalComponent,
  InvoiceModalComponent,
  DropModalComponent,
  FeedbackModalComponent,
  FollowupDropModalComponent,
  FeedbacklogModalComponent,
  FeedbackComponent,
  PatientInfoComponent,
  VoucherReceiptComponent,
  EditPatientComponent,
  FeedbackEditModalComponent,
  ExpenditureModalComponent,
  CommentsModalComponent
  ]
})
export class ComponentsModule { }
