<!-- Feedback Modal-->
<ng-template #feedBackModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Feedback</h4>
        <button type="button" class="close" (click)="closeModal()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="feedbackSubmitForm" (ngSubmit)="submitFeedBack()">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.patient_unique_id ? selectedPatientFeedback.patient_unique_id : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>BPID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.branch_patient_id ? selectedPatientFeedback.branch_patient_id : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p class="person_name_modal">{{selectedPatientFeedback.patient_name ? selectedPatientFeedback.patient_name : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Branch Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.branch_name ? selectedPatientFeedback.branch_name : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatientFeedback.mobile ? selectedPatientFeedback.mobile : '-'}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Status</p>
                                        <ng-select (change)="changeStatus($event)" [items]="feedbackStatus" bindLabel="value" bindValue="value" placeholder="Select" formControlName="feedback_status" [ngClass]="{ 'is-invalid error': errorMessages.feedback_status && feedback_status.errors && feedback_status.touched }">
                                        </ng-select>
                                        <div *ngFor= "let error of errorMessages.feedback_status">
                                           <ng-container *ngIf="feedback_status.hasError(error.type) && (feedback_status.touched)">
                                              <small class="text-danger">{{error.message}}</small>
                                           </ng-container>
                                        </div>
                                        <div *ngIf="feedbackSubmitForm.controls.feedback_status.hasError('serverValidationError')">
                                            <small class="text-danger">{{ backendValidations['feedback_status'] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Sub Status</p>
                                        <ng-select formControlName="feedback_sub_status" [items]="subStatus" bindLabel="value" bindValue="value" placeholder="Select" [disabled]="true/false" [ngClass]="{ 'is-invalid error': errorMessages.feedback_sub_status && feedback_sub_status.errors && feedback_sub_status.touched }">
                                        </ng-select>
                                        <div *ngFor= "let error of errorMessages.feedback_sub_status">
                                           <ng-container *ngIf="feedback_sub_status.hasError(error.type) && (feedback_sub_status.touched)">
                                              <small class="text-danger">{{error.message}}</small>
                                           </ng-container>
                                        </div>
                                        <div *ngIf="feedbackSubmitForm.controls.feedback_sub_status.hasError('serverValidationError')">
                                            <small class="text-danger">{{ backendValidations['feedback_sub_status'] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Appointment Date</p>
                                        <input onkeydown="return false" [min]="currentDate" formControlName="appointment_date" type="date" class="form-control" formControlName="appointment_date" [ngClass]="{ 'is-invalid error': errorMessages.appointment_date && appointment_date.errors && appointment_date.touched }">
                                        <div *ngFor= "let error of errorMessages.appointment_date">
                                           <ng-container *ngIf="appointment_date.hasError(error.type) && (appointment_date.touched)">
                                              <small class="text-danger">{{error.message}}</small>
                                           </ng-container>
                                        </div>
                                        <div *ngIf="feedbackSubmitForm.controls.appointment_date.hasError('serverValidationError')">
                                            <small class="text-danger">{{ backendValidations['appointment_date'] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Remarks</p>
                                        <textarea formControlName="remarks" rows="3" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }"></textarea>
                                        <div *ngFor= "let error of errorMessages.remarks">
                                           <ng-container *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                              <small class="text-danger">{{error.message}}</small>
                                           </ng-container>
                                        </div>
                                        <div *ngIf="feedbackSubmitForm.controls.remarks.hasError('serverValidationError')">
                                            <small class="text-danger">{{ backendValidations['remarks'] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" [disabled]="feedbackUpdateLoading | async" class="btn btn-primary">
                                <span *ngIf="feedbackUpdateLoading | async" class="spinner-border spinner-border-sm mr-1"></span>Submit
                            </button>
                            <button type="reset" class="btn btn-secondary ml-50">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Feedback Modal-->