import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { CallcenterGuard } from './auth/callcenter-guard/callcenter.guard';

import { CallcenterHomeComponent } from '@callcenter/callcenter-home/callcenter-home.component';

const routes: Routes = [
  { path:'', redirectTo:'login', pathMatch:'full'},
  { path:'login', component: LoginComponent, canActivate: [CallcenterGuard] },
  { path:'home', component: CallcenterHomeComponent, loadChildren: () => import('@callcenter/callcenter-home/callcenter-home.module').then(m => m.CallcenterHomeModule),  canLoad: [CallcenterGuard]  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CallcenterRoutingModule { }
