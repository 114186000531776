<!-- Remark Modal-->
<ng-template #remarksModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Remark</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p class="reusable-remark-modal">{{feedBackRemarks}}.</p>
    </div>
</ng-template>
<!-- / Remark Modal-->