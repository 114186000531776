<!-- Reply In Comments Modal -->
<ng-template #billingCommentsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Comments</h4>
        <button type="button" class="close" (click)="closeCommentsForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="commentsForm" (ngSubmit)="submitCommentsForm()">
                    <div class="row">
                        <div class="col-lg-12 col-md-1">
                            <div class="align-items-center row">
                                <div class="col-lg-5 col-md-6">
                                    <label>Doctor Comments</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <div class="form-group">
                                        <textarea rows="3" class="form-control"
                                        formControlName="doc_comments" disabled></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-1">
                            <div class="align-items-center row">
                                <div class="col-lg-5 col-md-6">
                                    <label>My Comments (PRO)</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <div class="form-group">
                                        <textarea rows="3" class="form-control"
                                        formControlName="pro_comments" [ngClass]="{ 'is-invalid error': errorMessages.pro_comments && pro_comments.errors && pro_comments.touched }"></textarea>
                                        <div *ngFor="let error of errorMessages.pro_comments">
                                            <ng-container
                                                *ngIf="pro_comments.hasError(error.type) && (pro_comments.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                        <div
                                            *ngIf="commentsForm.controls.pro_comments.hasError('serverValidationError')">
                                            <small class="text-danger">{{
                                                backendValidations['pro_comments'] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="loading | async" type="submit" class="btn btn-primary" type="submit">
                                        <span *ngIf="loading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!--/ Reply In Comments Modal -->