import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from '@global_components/profile/profile.component';
import { GmGuardGuard } from './auth/gm-guard/gm-guard.guard';
import { LoginComponent } from './auth/login/login.component';
import { GmHomeComponent } from './gm-home/gm-home.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [GmGuardGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [GmGuardGuard] },
  { path: 'home', component: GmHomeComponent, 
  loadChildren: () => import('@gm/gm-home/gm-home.module').then(m => m.GmHomeModule), canLoad: [GmGuardGuard]
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GmRoutingModule { }
