import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

import { NotificationService } from '@core/services/notification.service';
import { AccountsService } from '@accounts/services/accounts.service';

@Component({
  selector: 'app-case-sheet',
  templateUrl: './case-sheet.component.html',
  styleUrls: ['./case-sheet.component.scss']
})
export class CaseSheetComponent implements OnInit {

  medicines: any;
  caseSheetData: any;
  patient_data: any;
  doctor_feedback: any;
  patient_id: number;
  billings: any;
  feedbacks: any;
  private sub: any;
  constructor(private _service: AccountsService,
    private notifyService: NotificationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.patient_id = +params['id']
    })
    this.getCaseSheet();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /* Get CaseSheet */
  getCaseSheet() {
    this._service.getCaseSheet(this.patient_id)
      .subscribe(res => {
        let data = res['data'];
        this.caseSheetData = data['casesheet'];
        this.patient_data = data['patient_data'];
        this.doctor_feedback = data['doc_feedback'];
        this.billings = data['billings'];
        this.feedbacks = data['feedback'];
        this.medicines = data['medicine'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  goBack() {
    window.history.back();
  }
  
  isNumber(val) {
    return !isNaN(val);
  }
}
