import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DocGuard } from '@doc/auth/doc-guard/doc.guard';
import { LoginComponent } from '@doc/auth/login/login.component';
import { DocHomeComponent } from '@doc/doc-home/doc-home.component';

const routes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent, canActivate: [DocGuard]},
	{ path: 'home', component: DocHomeComponent, loadChildren: () => import('@doc/doc-home/doc-home.module').then(m => m.DocHomeModule), canLoad: [DocGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorRoutingModule { }
