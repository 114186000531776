<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <!-- <h4 class="card-title">Billing<span *ngIf="selectedState" class="text-warning"> -
                            {{selectedState}}</span></h4> -->
                </div>
                <hr>
                <div class="card-body">
                    <!-- Show Filter -->
                    <div class="row mb-1">
                        <div class="col-12">
                            <a *ngIf="state_id == 1" class="dataTable-Link" (click)="getData('np', '1', 'AP')">NP-AP</a>
                            <a *ngIf="state_id == 2" class="dataTable-Link" (click)="getData('np', '2', 'TS')">NP-TS</a>
                            <a *ngIf="state_id == 3" class="dataTable-Link" (click)="getData('np', '3', 'KA')">NP-KA</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('ren')">REN</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('call-center')">CC & ST</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('call-center')">CC</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('ib')">CC-IB</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('ob')">CC-OB</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('walk-in')">Walk-In</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('ref')">REF</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('rmp')">RMP</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('camp')">CAMP</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('online')">ONLINE</a>
                            &nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('oc')">OC</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('acq')">ACQ</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('rgl')">RGL</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('reg')">REG</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('cons')">CONS</a>&nbsp;|&nbsp;
                            <a class="dataTable-Link" (click)="getData('exp')">EXP</a>&nbsp;|&nbsp;

                        </div>
                    </div>
                    <!-- Show Filter End-->
                    <div *ngIf="selectedReport">
                        <div *ngIf="selectedReport.type == 'np'">
                            <div class="table-responsive patient-table">
                                <h4> GT STATUS({{prev_2_m}}-{{prev_1_m}}-{{this_m}}) - {{selectedReport.state_code}}
                                </h4>
                                <table class="table table-bordered text-center align-middle mb-0" *ngIf="data"  style="max-width: 750px;">
                                    <thead>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>NP</th>
                                            <th>REG</th>
                                            <th>GT</th>
                                            <th>NP</th>
                                            <th>REG</th>
                                            <th>GT</th>
                                            <th>NP</th>
                                            <th>REG</th>
                                            <th>GT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data;">
                                            <td>{{item.branch_name}}</td>
                                            <td>{{item.prev_prev_np }}</td>
                                            <td>{{item.prev_prev_reg}}</td>
                                            <td>{{item.prev_prev_gt}}</td>
                                            <td>{{item.prev_np }}</td>
                                            <td>{{item.prev_reg}}</td>
                                            <td>{{item.prev_gt}}</td>
                                            <td>{{item.this_np }}</td>
                                            <td>{{item.this_reg}}</td>
                                            <td>{{item.this_gt}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="totalRow[0]">
                                            <th>Total :</th>
                                            <td>{{ totalRow[0].prev_prev_np }}</td>
                                            <td>{{ totalRow[0].prev_prev_reg }}</td>
                                            <td>{{ totalRow[0].prev_prev_gt}}</td>
                                            <td>{{ totalRow[0].prev_np }}</td>
                                            <td>{{ totalRow[0].prev_reg}}</td>
                                            <td>{{ totalRow[0].prev_gt}}</td>
                                            <td>{{ totalRow[0].this_np }}</td>
                                            <td>{{ totalRow[0].this_reg}}</td>
                                            <td>{{ totalRow[0].this_gt}}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div *ngIf="selectedReport.type == 'np6'">
                            <div class="table-responsive patient-table mt-2" *ngFor="let key of tableKeys">
                                <h4 *ngIf="state_id == key"> {{selectedReport.title}} STATUS - {{ key == 1 ? "AP" :
                                    key == 2 ? "TS":key == 3 ? "KA" :"-" }}</h4>
                                <table *ngIf="state_id == key" class="table table-bordered text-center align-middle mb-0"
                                    style="max-width: 750px;">
                                    <thead>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>{{prev_5_m}}</th>
                                            <th>{{prev_4_m}}</th>
                                            <th>{{prev_3_m}}</th>
                                            <th>{{prev_2_m}}</th>
                                            <th>{{prev_1_m}}</th>
                                            <th>{{this_m}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data[key];">
                                            <td>{{ item.branch_name }}</td>
                                            <td>{{ item[prev_5_m] ? item[prev_5_m] : 0 }}</td>
                                            <td>{{ item[prev_4_m] ? item[prev_4_m] : 0 }}</td>
                                            <td>{{ item[prev_3_m] ? item[prev_3_m] : 0 }}</td>
                                            <td>{{ item[prev_2_m] ? item[prev_2_m] : 0 }}</td>
                                            <td>{{ item[prev_1_m] ? item[prev_1_m] : 0 }}</td>
                                            <td>{{ item[this_m] ? item[this_m] : 0 }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="totalRow[key]">
                                            <th>Total :</th>
                                            <td>{{ totalRow[key][prev_5_m] ? totalRow[key][prev_5_m] : 0 }}</td>
                                            <td>{{ totalRow[key][prev_4_m] ? totalRow[key][prev_4_m] : 0 }}</td>
                                            <td>{{ totalRow[key][prev_3_m] ? totalRow[key][prev_3_m] : 0 }}</td>
                                            <td>{{ totalRow[key][prev_2_m] ? totalRow[key][prev_2_m] : 0 }}</td>
                                            <td>{{ totalRow[key][prev_1_m] ? totalRow[key][prev_1_m] : 0 }}</td>
                                            <td>{{ totalRow[key][this_m] ? totalRow[key][this_m] : 0 }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br />
                            </div>
                        </div>
                        <div *ngIf="selectedReport.type == 'np3'">
                            <div class="table-responsive patient-table mt-2" *ngFor="let key of tableKeys">
                                <h4 *ngIf="state_id == key"> {{selectedReport.title}} STATUS - {{ key == 1 ? "AP" :
                                    key == 2 ? "TS":key == 3 ? "KA" :"-" }}</h4>
                                <table *ngIf="state_id == key" class="table table-bordered text-center align-middle mb-0"
                                    style="max-width: 750px;">
                                    <thead>
                                        <tr>
                                            <th>Branch Name</th>
                                            <th>{{prev_2_m}}</th>
                                            <th>{{prev_1_m}}</th>
                                            <th>{{this_m}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of data[key];">
                                            <td>{{ item.branch_name }}</td>
                                            <td>{{ item[prev_2_m] ? item[prev_2_m] : 0 }}</td>
                                            <td>{{ item[prev_1_m] ? item[prev_1_m] : 0 }}</td>
                                            <td>{{ item[this_m] ? item[this_m] : 0 }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr *ngIf="totalRow[key]">
                                            <th>Total :</th>
                                            <td>{{ totalRow[key][prev_2_m] ? totalRow[key][prev_2_m] : 0 }}</td>
                                            <td>{{ totalRow[key][prev_1_m] ? totalRow[key][prev_1_m] : 0 }}</td>
                                            <td>{{ totalRow[key][this_m] ? totalRow[key][this_m] : 0 }}</td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>