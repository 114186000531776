import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { Subject, BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

import { PharmacyService } from '@pharmacy/services/pharmacy.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-courier',
  templateUrl: './courier.component.html',
  styleUrls: ['./courier.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourierComponent implements OnInit {

  modalRef: any;
  public loading = new BehaviorSubject(false);
  public updateCourierStatus = this._formBuilder.group({
    remarks: [null],
    no_of_days_medicines: [null],
    full_address: [null],
    cdetails: [null, Validators.required],
    received: [null, Validators.required]
  });

  get remarks() { return this.updateCourierStatus.get('remarks'); }
  get no_of_days_medicines() { return this.updateCourierStatus.get('no_of_days_medicines'); }
  get full_address() { return this.updateCourierStatus.get('full_address'); }
  get cdetails() { return this.updateCourierStatus.get('cdetails'); }
  get received() { return this.updateCourierStatus.get('received'); }

   errorMessages = {
    cdetails: [{ type: 'required', message: 'This Field is required' }],
    received: [{ type: 'required', message: 'This field is required' }]
  }

  isShown: boolean = false;
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();

  toggleShow() {
    this.isShown = !this.isShown;
  }

  public patientType = [
    { title: "New Patient", value: "new" },
    { title: "Old Patient", value: "old" }
  ];

  courierFilterForm = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_type: [],
    fpatient_name: [],
    perpage: this.basicSelectedOption,
    page: 1
  });
  public filterLoading = new BehaviorSubject(false);

  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;
  currentDate = new Date();
  selectedRow: any;
  backendValidations: any;

  public courierdetailsData: any[] = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private _service: PharmacyService,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {

    this.getCourierData();
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.courierFilterForm.value.page = 1;
      this.getCourierData();
    });
  }

  updateCourier() {
    this.updateCourierStatus.markAllAsTouched();
    if (this.updateCourierStatus.valid) {
      this.updateCourierStatus.value.id = this.selectedRow['id'];
      this.loading.next(true);
      this._service.updateCourierStatus(this.updateCourierStatus.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.modalRef.close();
            this.getCourierData();
            this.updateCourierStatus.reset();
            this.notifyService.showSuccess(res['message'], res['title']);

          } else if (res['status'] == 'errors') {
            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.updateCourierStatus.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  /* Get LoggedIN User AttendanceReport */
  getCourierData() {
    this.courierFilterForm.value.fsearch_text = this.search_text;
    this._service.getCourierPrescriptions(this.courierFilterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        this.courierdetailsData = res['courierPrescriptions'];
        this.totalCount = res['total'];
        this.curPage = res['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title'])
      });
  }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.courierFilterForm.value.femp_unique_id || this.courierFilterForm.value.fmonth || this.courierFilterForm.value.fyear) {
      return false;
    } else {
      return true;
    }
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getCourierData();
  }

  /* Reset Filter */
  resetFilter() {
    this.courierFilterForm.reset();
    this.getCourierData();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.courierFilterForm.value.perpage = this.basicSelectedOption;
    this.courierFilterForm.value.page = 1;
    this.getCourierData();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.courierFilterForm.value.page = $event.page;
    this.getCourierData();
  }

  modalOpen(modal, row = null) {
    this.selectedRow = row;
    this.updateCourierStatus.patchValue({
      'remarks': this.selectedRow['remarks'],
      'full_address': this.selectedRow['full_address'],
      'cdetails': this.selectedRow['cdetails'],
      'no_of_days_medicines': this.selectedRow['no_of_days_medicines']
    });
    console.log(this.selectedRow)
    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      size: "lg"
    });
  }
}
