import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CurrentUserService } from '@services/current-user/current-user.service';
import { NotificationService } from '@core/services/notification.service';
import { ProService } from '@pro/services/pro.service';

@Component({
  selector: 'voucher-receipt',
  templateUrl: './voucher-receipt.component.html',
  styleUrls: ['./voucher-receipt.component.scss']
})
export class VoucherReceiptComponent implements OnInit {

  @Input() 
  public headOfAccounts(value) {
    console.log(value);
  }
  currentDateInSeconds: any;
  receiptData: any;
  receiptName: any;
  payName: any;
  voucher_type: any;
  currentDate = new Date();
  selected_head_of_account: any;
  @ViewChild("voucherReceiptModal") voucherReceiptModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    public _service: ProService,
    public currentUser: CurrentUserService) { }

  ngOnInit(): void {
    this.getHeadOfAccount();
  }

  getHeadOfAccount() {
    this._service.getHeadOfAccounts()
      .subscribe(res => {
        let data = res['data'];
        this.headOfAccounts = data['head_of_accounts'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Open Patient Information Modal */
  openModal(receiptModalData, voucher_type) {
    this.voucher_type = voucher_type;
    if (voucher_type == 'voucher_expenditure') {

      this.receiptName = 'PAYMENT VOUCHER CASH / BANK';
      this.payName = 'Pay To';
    } else if (voucher_type == 'money_transfer') {

      this.receiptName = 'MONEY TRANSFER CASH / BANK';
      this.payName = 'Transfer Through';
    } else if (voucher_type == 'other_revenue'){

      this.receiptName = 'OTHER REVENUE RECEIPT';
      this.payName = 'towards';
    }
    this.receiptData = receiptModalData;
    this.currentDateInSeconds = receiptModalData.voucher_id;
    this.selected_head_of_account = this.headOfAccounts[this.receiptData.head_of_accounts - 1];
    console.log(this.selected_head_of_account);
  	this.modalService.open(this.voucherReceiptModal, {
  		windowClass: "modal",
  		backdrop:'static',
      size: "lg",
  		centered: true,
  	});
  }

  /* Print Fir Form */
  print() {
    const printContent = document.getElementById("voucher_receipt");
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
    WindowPrt.close();
  }
}