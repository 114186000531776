<section>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="card">
                <div class="card-header d-block">
                    <div class="row">
                        <div class="col-6">
                            <h4 class="card-title text-uppercase mt-25">
                                <a (click)="goBack()"><span><i data-feather="arrow-left"
                                            class="font-medium-2"></i></span></a>
                                Patient Receipt
                            </h4>
                        </div>
                        <div class="col-6 text-right">
                            <button type="button" class="btn btn-primary"
                                (click)="openLargeModal(receiptModal)">Print</button>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="card-body" *ngIf="billingDetails">
                    <div style="border:solid 2px #0099FF;width:810px;margin:0 auto;">
                        <p align="center"><img style="width:500px;" src="https://v2.p0sitivehomeo.com/assets/images/logo/positivehomeopathy_logo.jpg" border="0"></p>
                        <p></p>
                        <p></p>
                        <p></p>
                        <table width="780" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                            bgcolor="white">
                            <tbody>
                                <tr>
                                    <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;"
                                        width="100%">
                                        <div [innerHtml]="currentUser.getBranchAddress()"></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="41%"><span style="color:#0099FF">Receipt No. :</span>
                                        {{billingDetails.receipt_no}}
                                    </td>
                                    <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> RECEIPT</b>
                                    </td>
                                    <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span> {{currentDate
                                        | date: 'dd-MM-YYYY'}}
                                    </td>
                                </tr>
                                <tr>
                                    <td><span style="color:#0099FF">Patients Name :</span>
                                        {{billingDetails.patient_name}}</td>
                                    <td>&nbsp;</td>
                                    <td><span style="color:#0099FF">Age :</span> {{billingDetails.age}} </td>
                                </tr>
                                <tr>
                                    <td><span style="color:#0099FF">Sex :</span> {{billingDetails.gender == 'm' ? 'Male'
                                        : 'Fe-Male'}} </td>
                                    <td>&nbsp;</td>
                                    <td><span style="color:#0099FF">Regn. No.
                                            :</span>{{billingDetails.branch_patient_id}}</td>
                                </tr>
                                <tr>
                                    <td><span style="color:#0099FF">Next Visit Date :</span> {{billingDetails.op_date}}
                                    </td>
                                    <td>&nbsp;</td>
                                    <td><span style="color:#0099FF">Packages(Period):</span>
                                        {{billingDetails.treatment_duration}} Months</td>
                                </tr>
                                <tr>
                                    <td><span style="color:#0099FF">Received an amount of Rs. :</span>
                                        {{billingDetails.received_amount}} /-</td>
                                    <td>&nbsp;</td>
                                    <td><span style="color:#0099FF">Due / Balance :</span> Rs.
                                        {{billingDetails.due_amount}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><span style="color:#0099FF">Payment by :</span>
                                        {{billingDetails.payment_option == 'c' ? 'Cash' :
                                        billingDetails.payment_option == 'cc' ? 'Credit-Card' :
                                        billingDetails.payment_option == 'ch' ? 'Cheque' :
                                        billing_details.payment_option == 'emi' ? 'EMI' :
                                        billingDetails.payment_option == 'o' ? 'Online' : '-'}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3"><span style="color:#0099FF">EmpName :</span>
                                        {{billingDetails.signature}}</td>
                                </tr>
                                <tr>
                                    <td colspan="3" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                        <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of
                                            joining the card includes any number of consultations with
                                            physician,medicines,courier (within india) during the duration of that
                                            particular card specified.<br>*Only the bearer can avail the facilities of
                                            the card.<br>*The card facilities are given only to the one on whose name
                                            the card is made.<br>*The fee is non transferable, non -refundable and
                                            non-extendable.<br>*Patients are strictly advised to use medicines as per
                                            attending physicians recommendation. We assume patients have the
                                            responsibility to inform the attending physician about the status of the
                                            health or any serious disorder during the course of treatment.<br> *We
                                            expect &amp; would appreciate patients to visit the clinic as per the due
                                            date of their consultations.<br>*Patients are requested to co-operate with
                                            the mode of treatment,as sometimes,the speed of recovery is slow (the time
                                            of recovery may vary).<br>*The duration of treatment and results may vary
                                            from patient.<br>*During critical emergencies patients / attendants are
                                            adviced to inform the attending physician.<br> *Case record are kept with
                                            the Doctor till the course of th treatment.<br> *All disputes are subject to
                                            Hyderabad only.E&amp;OE.</p>
                                    </td>
                                </tr>
                                <tr style="font-weight:700;font-size:13px;">
                                    <td>Patient / Attendants Signature</td>
                                    <td colspan="2" align="right">Authorized Signatory</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <hr>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3" style="font-size:21px;font-weight:700;text-align:center">NOTE:FOR
                                        NEW APPOINTMENT CALL : 9290901010</td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <hr>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Print Receipt Modal -->
<ng-template #receiptModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus id="receiptModal">
        <center>
            <div style="border:solid 2px #0099FF;width:1000px;">
                <p align="center" style="margin-top:10px;">
                    <img src="{{ currentUser.getLogo() }}" border="0">
                </p>
                <p></p>
                <p>
                </p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">
                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;" width="100%">
                                <div [innerHtml]="currentUser.getBranchAddress()"></div>
                            </td>
                        </tr>
                        <tr>
                            <td width="41%"><span style="color:#0099FF">Receipt No :</span>
                                {{billingDetails.receipt_no}} </td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> RECEIPT</b></td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span> {{currentDate | date:
                                'dd-MM-YYYY'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Patients Name :</span> {{billingDetails.patient_name}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Age :</span> {{billingDetails.age}} </td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No :&nbsp;</span>{{billingDetails.branch_patient_id}}
                            </td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Sex :</span> {{billingDetails.gender == 'm' ? 'Male' :
                                'Fe-Male'}}</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Renewal Date :&nbsp;</span>{{billingDetails.renewal_date |
                                date: 'dd-MM-YYYY'}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Package Period :</span>
                                {{billingDetails.treatment_duration}} Months</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Next Visit Date :&nbsp;</span>{{billingDetails.op_date |
                                date: 'dd-MM-YYYY'}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Payment by :</span> {{billingDetails.payment_option == 'c' ?
                                'Cash' :
                                billingDetails.payment_option == 'cc' ? 'Credit-Card' :
                                billingDetails.payment_option == 'ch' ? 'Cheque' :
                                billing_details.payment_option == 'emi' ? 'EMI' :
                                billingDetails.payment_option == 'o' ? 'Online' : '-'}}</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{billingDetails.received_amount}}
                                /-&nbsp;&nbsp;&nbsp;</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Due / Balance :</span> Rs. {{billingDetails.due_amount}}
                            </td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Emp Name :</span> {{billingDetails.signature}}</td>
                        </tr>
                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.
                                    <br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.
                                </p>
                            </td>
                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;">
                            <td>Patient / Attendants Signature</td>
                            <td>Branch Head Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center">NOTE:FOR NEW
                                APPOINTMENT CALL : 9290901010</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>

        <div id="scissors">
            <div></div>
        </div>

        <center>
            <div style="border:solid 2px #0099FF;width:1000px;">
                <p align="center" style="margin-top:10px;">
                    <img src="{{ currentUser.getLogo() }}" border="0">
                </p>
                <p></p>
                <p>
                </p>
                <p></p>
                <table width="950" style="font-family:arial;font-size:12px;font-weight:bold;" align="center"
                    bgcolor="white">
                    <tbody>
                        <tr>
                            <td colspan="3" style=" font-weight:700;font-size:13px;text-align:center;" width="100%">
                                <div [innerHtml]="currentUser.getBranchAddress()"></div>
                            </td>
                        </tr>
                        <tr>
                            <td width="41%"><span style="color:#0099FF">Receipt No :</span>
                                {{billingDetails.receipt_no}} </td>
                            <td width="31%" align="left" style="font-size:15px;color:#FF9900"><b> RECEIPT</b></td>
                            <td width="28%" colspan="3"><span style="color:#0099FF">Date :</span> {{currentDate | date:
                                'dd-MM-YYYY'}}</td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Patients Name :</span> {{billingDetails.patient_name}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Age :</span> {{billingDetails.age}} </td>
                        </tr>

                        <tr>
                            <td><span style="color:#0099FF">Regn. No :&nbsp;</span>{{billingDetails.branch_patient_id}}
                            </td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Sex :</span> {{billingDetails.gender == 'm' ? 'Male' :
                                'Fe-Male'}}</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Renewal Date :&nbsp;</span>{{billingDetails.renewal_date |
                                date: 'dd-MM-YYYY'}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Package Period :</span>
                                {{billingDetails.treatment_duration}} Months</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Next Visit Date :&nbsp;</span>{{billingDetails.op_date |
                                date: 'dd-MM-YYYY'}}</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Payment by :</span> {{billingDetails.payment_option == 'c' ?
                                'Cash' :
                                billingDetails.payment_option == 'cc' ? 'Credit-Card' :
                                billingDetails.payment_option == 'ch' ? 'Cheque' :
                                billing_details.payment_option == 'emi' ? 'EMI' :
                                billingDetails.payment_option == 'o' ? 'Online' : '-'}}</td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Received an amount of Rs. :</span>
                                {{billingDetails.received_amount}}
                                /-&nbsp;&nbsp;&nbsp;</td>
                            <td>&nbsp;</td>
                            <td><span style="color:#0099FF">Due / Balance :</span> Rs. {{billingDetails.due_amount}}
                            </td>
                        </tr>
                        <tr>
                            <td><span style="color:#0099FF">Emp Name :</span> {{billingDetails.signature}}</td>
                        </tr>
                        <tr>
                            <td colspan="4" align="center"><u><strong>TERMS &amp; CONDITIONS</strong></u>
                                <p style="font-weight:300;font-size:12px;text-align:justify;">*The facilities of joining
                                    the card includes any number of consultations with physician,medicines,courier
                                    (within india) during the duration of that particular card specified.<br>*Only the
                                    bearer can avail the facilities of the card.<br>*The card facilities are given only
                                    to the one on whose name the card is made.<br>*The fee is non transferable, non
                                    -refundable and non-extendable.<br>*Patients are strictly advised to use medicines
                                    as per attending physicians recommendation. We assume patients have the
                                    responsibility to inform the attending physician about the status of the health or
                                    any serious disorder during the course of treatment.<br>

                                    *We expect &amp; would appreciate patients to visit the clinic as per the due date
                                    of their consultations.<br>*Patients are requested to co-operate with the mode of
                                    treatment,as sometimes,the speed of recovery is slow (the time of recovery may
                                    vary).<br>*The duration of treatment and results may vary from patient.
                                    <br>*During critical emergencies patients / attendants are
                                    adviced to inform the attending physician.<br>

                                    *Case record are kept with the Doctor till the course of th treatment.<br> *All
                                    disputes are subject to Hyderabad only.E&amp;OE.
                                </p>
                            </td>
                        </tr>

                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>

                        <tr style="font-weight:700;font-size:13px;">
                            <td>Patient / Attendants Signature</td>
                            <td>Branch Head Signature</td>
                            <td colspan="2" align="right">Authorized Signatory</td>
                        </tr>

                        <tr>
                            <td colspan="3">
                                <hr>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" style="font-size:15px;font-weight:700;text-align:center">NOTE:FOR NEW
                                APPOINTMENT CALL : 9290901010</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </center>

        <div class="text-center mt-50">
            <button (click)="print()" class="btn btn-primary">Print</button>
        </div>
    </div>
</ng-template>
<!--/ Print Receipt Modal -->