import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class DrcordinatorService {
  constructor(protected httpClient: AppHttpClient) { }
 /* authentication apis */
 login(credentials: object) {
  return this.httpClient.post('drcordinatorapi/login', credentials);
}
getProfileDetails() {
  return this.httpClient.get('drcordinatorapi/me');
}


docWiseReport(api: string, payload: object) {
  return this.httpClient.post('drcordinatorapi/doctor_report/' + api, payload);
}
doctorsDailyReports(payload: object) {
  return this.httpClient.post('drcordinatorapi/doctor_report/doctor-daily-reports', payload);
}

submitFeedBackForm(payload: object) {
  return this.httpClient.post('drcordinatorapi/add_patient_feedback', payload);
}
}
