<!-- Today's Calls Modal -->
<ng-template #todayCallsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Information</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>Patient ID</th>
                            <th>Patient Name</th>
                            <th>Mobile</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let call of todayCalls;">
                            <td>{{call.patient_unique_id ? call.patient_unique_id : '-'}}</td>
                            <td>{{call.patient_name}}</td>
                            <td>{{call.mobile}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!--/ Today's Calls Modal -->