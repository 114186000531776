<section id="home-page" class="home-page">
    <div class="row">
        <div class="col-12">
            <div class="card mb-0">
                <div class="card-header d-block text-center dashboard_header">
                    <h1 style="color: red;" >Welcome <span style="color: blue;">Auditor</span> </h1>
                </div>
            </div>
        </div>
    </div>
</section>