<ng-template #expeditureModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Reports</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="row dynamic-details existingInformation-data">
                <div class="col-12">
                    <div class="row" *ngIf="selectedVoucher">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Voucher Date</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{selectedVoucher.voucher_date}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Head Of Accounts</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="txt-ovrflow-wrap">Salaries</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Pay to</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{selectedVoucher.pay_to}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Branch Name</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="badge badge-warning">{{selectedVoucher.branch_name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Amount</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{selectedVoucher.amount}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Payment Option</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="badge badge-success">Cash</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Cheque No</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Cheque Date</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>DD No</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>DD Date</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>CC No</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>CC Date</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>-</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>