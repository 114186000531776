import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, Validators } from "@angular/forms";
import { BehaviorSubject } from 'rxjs';
import { MasterDataService } from '@services/masterData/master-data.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { NotificationService } from '@core/services/notification.service';
import { HrService } from '@hr/services/hr.service';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'global-employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {

  @Output() selectedEmploye: EventEmitter<any> = new EventEmitter();
  @Output() resetSelectedEmploye: EventEmitter<any> = new EventEmitter();
  public searchControl = new FormControl('', Validators.required);
  searchEmployees = [];
  currentRoute: string;

  constructor(
    private _masterservice: MasterDataService,
    private activatedRoute: ActivatedRoute,
    private notifyService: NotificationService) {
    this.activatedRoute.parent.url.subscribe((urlPath) => {
      const url = urlPath[urlPath.length - 1].path;
      this.currentRoute = url;
    });
  }

  ngOnInit(): void {

    let start_api;
    if (this.currentRoute == 'h') {
      start_api = 'hrapi';
    } else if (this.currentRoute == 'gm') {
      start_api = 'gmapi';
    }

    this.searchControl.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(val => {
        if (val) {
          this._masterservice.employeeSearch('gmapi', val).subscribe(res => {
            if (res['status'] == 'success') {
              this.searchEmployees = res['data']['employees'];
              $("#employees").autocomplete({
                source: this.searchEmployees.map(function (val) {
                  return val['unique_id'];
                }),
                select: (event, ui) => {
                  this.getEmployeeDetails(ui.item.value)
                }
              });
            } else if (res['status'] == 'error') {
              this.notifyService.showError(res['message'], res['title']);
            }
          }, err => {
            this.notifyService.showError(err['message'], err['title']);
          });
        } else {
          this.resetSelectedEmploye.emit(true);
        }
      });
  }

  /* Get Employee Details */
  getEmployeeDetails(uniqueId) {
    this.searchEmployees.find(x => {
      if (x['unique_id'] == uniqueId) {
        this.selectedEmploye.emit(x);
      }
    });
  }
}
