import { AccountsService } from '@accounts/services/accounts.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'common-patients-modal',
  templateUrl: './common-patients-modal.component.html',
  styleUrls: ['./common-patients-modal.component.scss']
})
export class CommonPatientsModalComponent implements OnInit {

  @ViewChild("totalMidGTpatientsModal") totalMidGTpatientsModal: TemplateRef<any>;
  patientsList: any;  now = new Date();
  monthFirstDate: any;
  monthLastDate: any;
  constructor(private modalService: NgbModal,
    private account_service: AccountsService) { }
    report_view:any;
  ngOnInit(): void {
    this.monthFirstDate = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.monthLastDate = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  }

  /* Open Modal */
  openModal(row, column, column_value, report_type = null) {
    let payload = { 'column': column ,'column_value': column_value , 'branch_code': row['branch_code'], 'type': report_type};
    console.log(payload);
    this.account_service.patients_list(payload).subscribe(res => {
      this.patientsList = res['patientsList'];
      this.modalService.open(this.totalMidGTpatientsModal, {
        windowClass: "modal",
        centered: true,
        size: "xl"
      });
    });
  }
  openSmsModal(row, column= null, column_value = null, report_type = null, state_id = null) {
    let payload = { 'column': column ,'column_value': column_value , 'branch_code': row.branch_code, 'type': report_type, 'state_id': state_id};
    console.log(payload);
    this.account_service.patients_list(payload).subscribe(res => {
      this.patientsList = res['patientsList'];
      if(column_value == 'rgl' || column_value == 'acq' || column_value == 'oc' || column_value == 'con'){
        this.report_view = column_value;
      }else{
        this.report_view = 'all';
      }
      this.modalService.open(this.totalMidGTpatientsModal, {
        windowClass: "modal",
        centered: true,
        size: "xl"
      });
    });
  }
  openSmsTotalModal(column= null, column_value = null, report_type = null, state_id = null) {
    let payload = { 'column': column ,'column_value': column_value , 'branch_code': null, 'type': report_type, 'state_id': state_id};
    console.log(payload);
    this.account_service.patients_list(payload).subscribe(res => {
      this.patientsList = res['patientsList'];
      if(column_value == 'rgl' || column_value == 'acq' || column_value == 'oc' || column_value == 'con'){
        this.report_view = column_value;
      }else{
        this.report_view = 'all';
      }
      this.modalService.open(this.totalMidGTpatientsModal, {
        windowClass: "modal",
        centered: true,
        size: "xl"
      });
    });
  }
}
