import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ProHomeComponent } from '@pro/pro-home/pro-home.component';
import { ProGuard } from './auth/pro-guard/pro-guard';
import { ProfileComponent } from '@global_components/profile/profile.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [ProGuard]},
  { path: 'profile', component: ProfileComponent, canActivate: [ProGuard] },
  { path: 'home', component: ProHomeComponent, loadChildren: () => import('@pro/pro-home/pro-home.module').then(m => m.ProHomeModule), canLoad: [ProGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProRoutingModule { }
