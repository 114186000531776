<ng-template #patientFeedbackModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Feedback</h4>
        <button type="button" class="close" (click)="closesubmitfbForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="feedbackSubmitForm" (ngSubmit)="submitfbForm()">
                    <div *ngIf="patientDetails" class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{patientDetails.patient_unique_id ? patientDetails.patient_unique_id : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Branch PID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{patientDetails.branch_patient_id}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{patientDetails.patient_name}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Branch Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p class="text-danger"><b>{{patientDetails.branch_name}}</b></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-1">
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <label>Remarks</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <div class="form-group">
                                        <textarea rows="3" class="form-control" formControlName="remarks" [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }">
                                        </textarea>
                                        <div *ngFor="let error of errorMessages.remarks">
                                            <ng-container
                                                *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                        <div
                                            *ngIf="feedbackSubmitForm.controls.remarks.hasError('serverValidationError')">
                                            <small class="text-danger">{{
                                                backendValidations['remarks'] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="loading | async" type="submit" class="btn btn-primary">
                            <span *ngIf="loading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>