import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@hr/auth/login/login.component';
import { ForgotPasswordComponent } from '@hr/auth/forgot-password/forgot-password.component'; 
import { HrHomeComponent } from '@hr/hr-home/hr-home.component';
import { HrHomeModule } from '@hr/hr-home/hr-home.module';
import { HrGuard } from '@hr/auth/hr-guard/hr.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent , canActivate: [HrGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [HrGuard]},
  { path: 'home', component: HrHomeComponent,
    loadChildren: () => import('./hr-home/hr-home.module').then(m => m.HrHomeModule), canLoad: [HrGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HrRoutingModule { }
 