import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GmService } from '@gm/services/gm.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-target-form',
  templateUrl: './target-form.component.html',
  styleUrls: ['./target-form.component.scss']
})
export class TargetFormComponent implements OnInit {

  public loading = new BehaviorSubject(false);
  transferForm = this._formBuilder.group({
    month_id: ['', Validators.required],
    branch_id: [null, Validators.required],
    absent_date: [null, Validators.required],
    joining_date: [null, Validators.required],
    gross_amt: [null, Validators.required],
    gross_amt_np: [null, Validators.required],
    gross_amt_op: [null, Validators.required],
  });
  editPrescriptionForm = this._formBuilder.group({
    news_name: [""],
    id: [""],
    branch_name: [""],
    gross_amt: [""],
    assign_date: [""],
  });
  branches: any;
  tableData:any;
  public modalRef: any;
  selectedRecord:any;
  backendValidations: any;


  public months = [
    {id: 1, name: 'January'},
    {id: 2, name: 'Febuary'},
    {id: 3, name: 'March'},
    {id: 4, name: 'April'},
    {id: 5, name: 'May'},
    {id: 6, name: 'June'},
    {id: 7, name: 'July'},
    {id: 8, name: 'August'},
    {id: 9, name: 'Septmber'},
    {id: 10, name: 'October'},
    {id: 11, name: 'November'},
    {id: 12, name: 'December'},
  ];

  get month_id() { return this.transferForm.get('month_id'); }
  get branch_id() { return this.transferForm.get('branch_id'); }
  get absent_date() { return this.transferForm.get('absent_date'); }
  get joining_date() { return this.transferForm.get('joining_date'); }
  get gross_amt() { return this.transferForm.get('gross_amt'); }
  get gross_amt_np() { return this.transferForm.get('gross_amt_np'); }
  get gross_amt_op() { return this.transferForm.get('gross_amt_op'); }

  errorMessages = {
    month_id: [{ type: 'required', message: 'This Month is required' }],
    branch_id: [{ type: 'required', message: 'This Branch field is required' }],
    absent_date: [{ type: 'required', message: 'This absent date is required' }],
    joining_date: [{ type: 'required', message: 'This joining date is required' }],
    gross_amt: [{ type: 'required', message: 'This gross amount is required' }],
    gross_amt_np: [{ type: 'required', message: 'This gross amount np is required' }],
    gross_amt_op: [{ type: 'required', message: 'This gross amount op is required' }],
  };

  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private _service: GmService,
    private notifyService: NotificationService,
    private _router: Router,
    private masterService: MasterDataService) {
    this.masterService.masterData('gmapi');
    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });
  }

  ngOnInit(): void {
  // targetList
    this._service.targetList().subscribe(res => {
      this.tableData=res['TargetList'];
    });
  }

  reset() {
    this.transferForm.reset();
  }

  /** Transfer Employee */
  transferEmployee() {
    this.transferForm.markAllAsTouched();
    if (this.transferForm.valid) {
      this.loading.next(true);
      console.log(this.transferForm.value);
      this._service.addTarget(this.transferForm.value).subscribe(res => {
        this.transferForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            // this._router.navigate(['gm/home/target-form']);
            location.reload();
      });
    }
  }




  allowNumbersOnly(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar) && event.charCode != "0") {
      event.preventDefault();
    }
  }

  modalOpen(modal, row = null) {
    if (row) {
      this.selectedRecord = row;
      this.patchPrescription();
    }

    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true
    });
  }
  patchPrescription() {
    this.editPrescriptionForm.patchValue({
      'news_name': this.selectedRecord.news_name,
      'id': this.selectedRecord.id,
      'gross_amt': this.selectedRecord.gross_amt,
      'assign_date': this.selectedRecord.absent_date,
      'branch_name': this.selectedRecord.name,
    });
  }
 
  updatePrescription(){
    this.editPrescriptionForm.markAllAsTouched();
    if (this.editPrescriptionForm.valid) {
      this.loading.next(true);
      // this.editPrescriptionForm.value.id = this.selectedRecord.id;
      this._service.updateTarget(this.editPrescriptionForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.editPrescriptionForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalClose();
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          } else if (res['status'] == 'errors') {

            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.editPrescriptionForm.controls[key].setErrors({ serverValidationError: true });
            }
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
        location.reload();
    }
  }
 /* On Modal Close Reset Form */
 modalClose() {
  this.modalRef.close();
  this.editPrescriptionForm.reset();
}
}
