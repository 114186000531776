<section id="ngx-datatable-kitchen-sink" *ngIf="_activatedRoute.children.length === 0">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Old Patients Billing</h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-outline-primary" routerLink="/pro/home/target/total-due-list">Due Payments
                        List</button>
                    <button class="btn btn-outline-primary ml-50" routerLink="/pro/home/no-due-payment-list">No Due
                        List</button>
                    <button class="btn btn-outline-danger ml-50" routerLink="/pro/home/prl/dropouts-manual">Droppedout
                        List</button>
                    <button class="btn btn-primary ml-2" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="oldPatientFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input formControlName="fpatient_unique_id" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input formControlName="fpatient_name" type="text" name="" id=""
                                        class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button [disabled]="filterLoading.value || checkFilterValidity()" type="submit"
                                        class="btn btn-primary"><span *ngIf="filterLoading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button (click)="resetFilter()" type="reset"
                                        class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="oldPatientsBillingData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="180">
                <ng-template ngx-datatable-cell-template let-patient_unique_id="value" let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">{{patient_unique_id ?
                            patient_unique_id : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="180">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doctor_name" [cellClass]="'word-span-el'" [width]="180">
            </ngx-datatable-column>
            <ngx-datatable-column name="due" prop="due_amount" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column name="renewal date" prop="date_of_expire" [width]="150">
                <ng-template let-date_of_expire="value" ngx-datatable-cell-template>
                    <span>{{date_of_expire ? (date_of_expire | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="250" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-success active border__none ml-50" ngbTooltip="Feedback"
                            tooltipClass="fadeInAnimation" (click)="patientFBmodal.openModal(row)" placement="left">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <button *ngIf="row.due_amount > 0" class="btn btn-outline-warning active border__none ml-50"
                            (click)="duePendingModal(duePending, row)" ngbTooltip="Due Pending"
                            tooltipClass="fadeInAnimation" placement="left">
                            <span>
                                <i data-feather='more-horizontal' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <button *ngIf="row.due_amount > 0" class="btn btn-outline-secondary active border__none ml-50"
                            (click)="payDue(row.patient_id)" ngbTooltip="Pay Due" tooltipClass="fadeInAnimation"
                            placement="left">
                            <span>
                                <i class="fa fa-money font-small-4 teal-color"></i>
                            </span>
                        </button>
                        <button *ngIf="row.due_amount == 0" class="btn btn-outline-secondary active border__none ml-50"
                            (click)="payDue(row.patient_id)" ngbTooltip="Renewal" tooltipClass="fadeInAnimation"
                            placement="left">
                            <span>
                                <i class="fa fa-money font-small-4 teal-color"></i>
                            </span>
                        </button>
                        <button class="btn btn-outline-dark active border__none ml-50"
                            (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation"
                            placement="left">
                            <span>
                                <i class="far fa-id-card font-small-4">
                                </i>
                            </span>
                        </button>
                        <button class="btn btn-outline-warning active border__none ml-50"
                            (click)="remarkModal.openModal(row.remarks)" ngbTooltip="Remark"
                            tooltipClass="fadeInAnimation" placement="left">
                            <span>
                                <i data-feather='eye' class="font-small-4">
                                </i>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>

<!-- Patient Info Modal-->
<invoice-modal #invoiceModal></invoice-modal>
<!-- / Patient Info Modal-->

<!-- PATIENT FEEDBACK Modal -->
<feedback-modal #patientFBmodal></feedback-modal>
<!--/ PATIENT FEEDBACK Modal -->

<!-- Remark Modal-->
<remarks-modal #remarkModal></remarks-modal>
<!-- / Remark Modal-->

<!-- Due Pending Modal -->
<ng-template #duePending let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Due Pending</h4>
        <button type="button" class="close" (click)="closeDuePendingForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="duePendingForm" (ngSubmit)="submitDuePendingForm()">
                    <div class="row" *ngIf="selectedPatient">
                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>{{selectedPatient.patient_unique_id ? selectedPatient.patient_unique_id : '-'}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{selectedPatient.patient_name ?
                                        selectedPatient.patient_name : '-'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <label>No. of Days for Medicine(Doctor)</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p>3</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Edit</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control"
                                            formControlName="no_of_days_medicine_pro"
                                            [ngClass]="{ 'is-invalid error': errorMessages.no_of_days_medicine_pro && no_of_days_medicine_pro.errors && no_of_days_medicine_pro.touched }">
                                        <div *ngFor="let error of errorMessages.no_of_days_medicine_pro">
                                            <ng-container
                                                *ngIf="no_of_days_medicine_pro.hasError(error.type) && (no_of_days_medicine_pro.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                        <div
                                            *ngIf="duePendingForm.controls.no_of_days_medicine_pro.hasError('serverValidationError')">
                                            <small class="text-danger">{{
                                                backendValidations['no_of_days_medicine_pro'] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Comments for Pharmacy<span class="text-danger">*</span></label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <textarea rows="3" class="form-control" formControlName="remarks"
                                            [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }"></textarea>
                                        <div *ngFor="let error of errorMessages.remarks">
                                            <ng-container *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                        <div *ngIf="duePendingForm.controls.remarks.hasError('serverValidationError')">
                                            <small class="text-danger">{{
                                                backendValidations['remarks'] }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <button [disabled]="loading | async" type="submit" class="btn btn-primary">
                                <span *ngIf="loading | async"
                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!--/ Due Pending Modal -->