import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditLeadsComponent } from './edit-leads/edit-leads.component';
import { SharedModule } from '@callcenter/shared/shared.module';
import { LeadsFeedbackComponent } from './leads-feedback/leads-feedback.component';

@NgModule({
  declarations: [EditLeadsComponent, LeadsFeedbackComponent],
  imports: [
    CommonModule,

    SharedModule
  ],exports : [
    EditLeadsComponent,
    LeadsFeedbackComponent
  ]
})
export class ComponentModule { }
