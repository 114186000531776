import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { MasterDataService } from '@services/masterData/master-data.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { GmService } from '@gm/services/gm.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();

  public filterForm = this._formBuilder.group({
    freport_type: [null, Validators.required],
    fdate_range: [],
    branch_id: '',
    perpage: this.basicSelectedOption,
    page: 1
  });

  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;
  branches: any;
  fromDate: any;
  toDate: any;
  tableData: any;
  tableKeys: any;
  apTableData: any;
  tsTableData: any;
  kaTableData: any;
  totalRow = [];
  selectedReport: any;
  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  reportTypes = [
    { id: 1, report: 'Summary Report', key: 'summary_report'},
    { id: 2, report: 'DROP LIST (MANUAL)', key: 'dropout-list-manually'},
    { id: 3, report: 'DROP LIST (AUTOMATIC)', key: 'dropout-list-automatically'},
    { id: 4, report: 'MISSED FOLLOWUPS', key: 'missed_followup'},
    { id: 5, report: 'DATE WISE FOLLOWUPS', key: 'datefollowup'},
    { id: 6, report: 'COURIER PRESCRIPTION DETAILS', key: 'courier_prescription', status:''},
    { id: 7, report: 'COURIER PRESCRIPTION SENT', key: 'courier_prescription', status: '1'},
    { id: 8, report: 'COURIER PRESCRIPTION NOT SENT', key: 'courier_prescription', status: '2'},
    { id: 9, report: 'CASE SHEETS STATISFY REPORT', key: 'patient_satisfaction', status: ''},
    { id: 10, report: 'CASE SHEETS FULLY SATISFIED REPORT', key: 'patient_satisfaction', status: 'FULLY SATISFIED'},
    { id: 11, report: 'CASE SHEETS OK SATISFIED REPORT', key: 'patient_satisfaction', status: 'OK SATISFIED'},
    { id: 12, report: 'CASE SHEETS NOT HAPPY REPORT', key: 'patient_satisfaction', status: 'NOT HAPPY'},
    { id: 13, report: 'MONTH WISE RENEWAL LIST', key: 'get_renewals_call'},
    { id: 14, report: 'REFFERAL TARGET (AP)', key: 'doc_referral_target', state_id: 1, state_code: "AP"},
    { id: 15, report: 'REFFERAL TARGET (KA)', key: 'doc_referral_target', state_id: 3, state_code: "KA"},
    { id: 16, report: 'REFFERAL TARGET (TS)', key: 'doc_referral_target', state_id: 2, state_code: "TS"},
    { id: 17, report: 'RPR Report (AP)', key: 'rpr_report', state_id: 1, state_code: "AP" },
    { id: 18, report: 'RPR Report (TS)', key: 'rpr_report', state_id: 2, state_code: "TS" },
    { id: 19, report: 'RPR Report (KA)', key: 'rpr_report', state_id: 3, state_code: "KA" },
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  patient_details: any;
  a_patient_billings: any;
  a_patient_details: any;
  smsreport_total:any;
  renewalCallsData:any;
  date:any
  constructor(
    private _formBuilder: FormBuilder,
    private _service: GmService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private masterService: MasterDataService,
    private _router: Router
  ) { }

  get freport_type() { return this.filterForm.get('freport_type'); }
  get fdate_range() { return this.filterForm.get('fdate_range'); }
  get branch_id() { return this.filterForm.get('branch_id'); }

  errorMessages = {
    freport_type: [{ type: 'required', message: 'The Report Type field is required' }],
    fdate_range: [{ type: 'required', message: 'The Date Range is required' }],
    branch_id: [{ type: 'required', message: 'Please select branch' }]
  }
  ngOnInit(): void {
    this.masterService.masterData('gmapi');
   

    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });

    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {    
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.submitForm();
    });
  }


  submitForm() {
    let api = this.filterForm.value.freport_type.key;
    this.selectedReport = this.filterForm.value.freport_type;

    if(api == 'doc_referral_target' || api == 'rpr_report'){
      this._service.regionWiseReports(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe((res: any[]) => {
        let data = res['data'];
        if (this.selectedReport.key == 'doc_referral_target') {
          this.date = this.filterForm.value.fdate_range, 
          this.tableData = data;
          this.totalRow = this.getrenewalTotal(this.tableData);
        }
        if (this.selectedReport.key == 'rpr_report') {
          this.tableData = data;
          this.totalRow = this.getTotal(this.tableData);
        } 
      });
    }else{
    this._service.branchWiseReports(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe((res: any[]) => {
        let data = res['data'];
        this.tableData = null;

       
        
        if (this.selectedReport.key == 'get_renewals_call') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['renewalCalls'];
        }
        if (this.selectedReport.key == 'total_due_list') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['TotalDuePayment'];
        }
        if (this.selectedReport.key == 'dropout-list-manually') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['DropListManually'];
        }
        if (this.selectedReport.key == 'dropout-list-automatically') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['DropoutAutomatically'];
        }
        if (this.selectedReport.key == 'missed_followup') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['miss_followUps'];
        }
        if (this.selectedReport.key == 'datefollowup') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['getFollowups'];
        }
        if (this.selectedReport.key == 'courier_prescription') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['dateWiseCourierPrescription'];
        }
       
        if (this.selectedReport.key == 'patient_satisfaction') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['patientSatisfactionWithDoctor'];
        }
      
        if (this.selectedReport.key == 'branch_wise_medicine') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['medicinesTakenData'];
        }
        if (this.selectedReport.key == 'branch_wise_notmedicine') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['medicinesNotTakenData'];
        }
        if (this.selectedReport.key == 'sent_stock') {
           this.totalCount = data['total'];
          this.curPage = data['page'];
          this.tableData = data['sentStock'];
        }
        
        
      });
    }
  }

  getRenewals() {
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.fsearch_text = this.search_text;

    // this._service.getDueMonthWise(this.filterForm.value)
    // .pipe(finalize(() => this.filterLoading.next(false)))
    // .subscribe(res => {
    //   let data = res['data'];
    //   this.renewalCallsData = data['renewalCalls'];
    //   this.totalCount = data['total'];
    //   this.curPage = data['page'];
    // }, err => {
    //   this.notifyService.showError(err['message'], err['title']);
    // });
  }

  getrenewalTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {

        g_ren:previousValue.g_ren + currentValue.g_ren,
        ren:previousValue.ren + currentValue.ren,
        pack:previousValue.pack + currentValue.pack,
        paid:previousValue.paid + currentValue.paid,
        target:previousValue.target + currentValue.target,
        // per:previousValue.per + currentValue.per,
        incentives:previousValue.incentives + currentValue.incentives,
       
      }
    });    
  }

  applyFilter() {
    this.filterLoading.next(true);
    this.submitForm();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.submitForm();
  }
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.submitForm();
  }
  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.submitForm();
  }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: "range",
  };

  /* On Date Select */
  onDateSelect() {
    console.log("date");

    let dateRange = this.filterForm.value.fdate_range;
    console.log(this.filterForm.value.fdate_range);
    if (dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
    }
  }
getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        ACQ: previousValue.ACQ + currentValue.ACQ,
        CAMP: previousValue.CAMP + currentValue.CAMP,
        CASH: previousValue.CASH + currentValue.CASH,
        CC: previousValue.CC + currentValue.CC,
        CC1: previousValue.CC1 + currentValue.CC1,
        CHQ: previousValue.CHQ + currentValue.CHQ,
        CHR: previousValue.CHR + currentValue.CHR,
        CON: previousValue.CON + currentValue.CON,
        DNP: previousValue.DNP + currentValue.DNP,
        EMI: previousValue.EMI + currentValue.EMI,
        EXP: previousValue.EXP + currentValue.EXP,
        FNP: previousValue.FNP + currentValue.FNP,
        GT: previousValue.GT + currentValue.GT,
        OC: previousValue.OC + currentValue.OC,
        OL: previousValue.OL + currentValue.OL,
        ONLINE: previousValue.ONLINE + currentValue.ONLINE,
        REF: previousValue.REF + currentValue.REF,
        REG: previousValue.REG + currentValue.REG,
        RGL: previousValue.RGL + currentValue.RGL,
        RMP: previousValue.RMP + currentValue.RMP,
        WK: previousValue.WK + currentValue.WK,


             //RPR report
             tnpr: previousValue.tnpr + currentValue.tnpr,
             rpv: previousValue.rpv + currentValue.rpv,
             rgl: previousValue.rgl + currentValue.rgl,
             rpamt_rgl: previousValue.rpamt_rgl + currentValue.rpamt_rgl,
             rpaid_rgl: previousValue.rpaid_rgl + currentValue.rpaid_rgl,
             rdue_rgl: previousValue.rdue_rgl + currentValue.rdue_rgl,
             rpamt_reg: previousValue.rpamt_reg + currentValue.rpamt_reg,
             rpaid_reg: previousValue.rpaid_reg + currentValue.rpaid_reg,
             rdue_reg: previousValue.rdue_reg + currentValue.rdue_reg,
             rpr: previousValue.rpr + currentValue.rpr,
             rpamt: previousValue.rpamt + currentValue.rpamt,
             rpaid: previousValue.rpaid + currentValue.rpaid,
             rdue: previousValue.rdue + currentValue.rdue,
             rpp: previousValue.rpp + currentValue.rpp,
      }
    });
}

caseSheet(patientId) {
  let link = environment.subdirUrl + '/drcordinator/home/case-sheet/' + patientId;
  this._router.navigate([]).then(result => { window.open(link, '_blank'); });
}
}
