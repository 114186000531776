<section>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="card"  [ngStyle]="{'background-color': (caseSheetData && due == 0) ? '#73c7af' : (caseSheetData && due > 0) ? '#fffb82ad': '#ffffff'}">
                <div class="row align-items-center">
                    <div class="col-6">
                        <div class="card-header">
                            <h4 class="card-title text-uppercase">
                                Patient Case Sheet
                            </h4>
                        </div>
                    </div>
                    <div *ngIf="caseSheetData" class="col-6 py-50 pr-2 text-right">
                        <button style="position: fixed;right: 35px; z-index: 9999;" [disabled]="billingReportloading | async" type="button" class="btn btn-primary"
                            (click)="modalOpen(billingReportModal, 'billing_report')">
                            <span *ngIf="billingReportloading | async" class="spinner-border spinner-border-sm mr-1">
                            </span>Billing Report
                        </button>
                        <!--   <button [disabled]="prescriptionloading | async" type="button" class="btn btn-primary ml-50"
                            (click)="modalOpen(prescriptionModal, 'prescription_modal')">
                            <span *ngIf="prescriptionloading | async" class="spinner-border spinner-border-sm mr-1">
                            </span>Prescription</button>
                            -->
                    </div>
                </div>

                <hr>
                <div class="card-body">
                    <form [formGroup]="caseSheetForm" (ngSubmit)="CSformSubmit()">
                        <div *ngIf="caseSheetData" class="row dynamic-details existingInformation-data">
                            <div class="col-lg-4 col-md-4 col-xl-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Patient Name</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{caseSheetData.patient_name ? caseSheetData.patient_name : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Age</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.age ? patient_info.age : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Gender</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.gender == 'm' ? 'Male' :
                                                    patient_info.gender == 'f' ? 'Female' : 'Others'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Branch Patient ID</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.branch_patient_id ? patient_info.branch_patient_id :
                                                    '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Treatment Request For</label>
                                            </div>
                                            <div *ngIf="patient_info" class="col-lg-6 col-md-6">
                                                <p>{{ patient_info.treatment_for }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-xl-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Patient ID</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.patient_unique_id ? patient_info.patient_unique_id :
                                                    '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Occupation</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.occupation ? patient_info.occupation : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Weight</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.weight ? patient_info.weight : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>First visit Date</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_info.reg_date ? patient_info.reg_date : '-'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <label>Consulting Doctor</label>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <p>{{patient_info.doctor_name ? patient_info.doctor_name: '-'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--                             <div *ngIf="billingsData" class="col-lg-4 col-md-4 col-xl-4 col-12">
                                <div class="row text-right">
                                    <div class="col-12">
                                        <h4><b>Due Amount :&nbsp;RS.</b> {{billingsData.due}}</h4>
                                    </div>
                                </div>
                                <div class="row text-right">
                                    <div class="col-12">
                                        <h4><b>Renewal Date :</b>&nbsp; {{billingsData.renewal_date}} </h4>
                                    </div>
                                </div>
                            </div> -->

                        </div>
                        <div *ngIf="!caseSheetData && patient_info"
                            class="row dynamic-details existingInformation-data">
                            <div class="col-lg-4 col-md-4 col-xl-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Patient Name</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.patient_name ? patient_info.patient_name : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Age</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.age}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Gender</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{ patient_info.gender == 'm' ? 'Male' :
                                                    patient_info.gender == 'f' ? 'FeMale' : 'Others'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Branch Patient ID</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.branch_patient_id ? patient_info.branch_patient_id :
                                                    '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Treatment Request For</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{ patient_info.treatment_for }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4 col-xl-4 col-12">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Patient ID</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.patient_unique_id ? patient_info.patient_unique_id :
                                                    '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Occupation</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.occupation ? patient_info.occupation : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Weight</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.weight ? patient_info.weight : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>First visit Date</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.reg_date ? (patient_info.reg_date | date:'dd-MM-yyyy') :
                                                    '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-6 col-md-6">
                                                <label>Consulting Doctor</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <p>{{patient_info.doctor_name ? patient_info.doctor_name : '-'}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr>
                        <div class="case-com-content">
                            <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <h4><b>Chief Complaint </b> <span class="text-danger">*</span></h4>
                                        <textarea  name="" id="" style="height: 90px!important;" appTextareaAutoresize class="form-control"
                                            formControlName="cheif_complaint"></textarea>
                                        <div *ngIf="cheif_complaint.invalid && cheif_complaint.touched">
                                            <div class="text-danger" *ngIf="cheif_complaint.errors?.required">
                                                This field is required.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <h4><b>Presenting Complaint </b><span class="text-danger">*</span></h4>
                                        <textarea  name="" id="" style="height: 90px!important;" appTextareaAutoresize class="form-control"
                                            formControlName="presenting_complaint"></textarea>
                                        <div *ngIf="presenting_complaint.invalid && presenting_complaint.touched">
                                            <div class="text-danger" *ngIf="presenting_complaint.errors?.required">
                                                This field is required.</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                    <div class="form-group">
                                        <h4><b>Past History </b><span class="text-danger">*</span></h4>
                                        <textarea  name="" id="" style="height: 90px!important;" appTextareaAutoresize class="form-control"
                                            formControlName="past_history"></textarea>
                                        <div *ngIf="past_history.invalid && past_history.touched">
                                            <div class="text-danger" *ngIf="past_history.errors?.required">
                                                This field is required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Family Health History</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Father <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="father">
                                                <div
                                                    *ngIf="caseSheetForm.controls.father.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['father']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="father.invalid && father.touched">
                                                    <div class="text-danger" *ngIf="father.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Mother <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="mother">
                                                <div
                                                    *ngIf="caseSheetForm.controls.mother.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['mother']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="mother.invalid && mother.touched">
                                                    <div class="text-danger" *ngIf="mother.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Paternal Grand Father <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="pgrandfather">
                                                <div
                                                    *ngIf="caseSheetForm.controls.pgrandfather.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['pgrandfather']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="pgrandfather.invalid && pgrandfather.touched">
                                                    <div class="text-danger" *ngIf="pgrandfather.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Paternal Grand Mother <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="pgrandmother">
                                                <div
                                                    *ngIf="caseSheetForm.controls.pgrandmother.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['pgrandmother']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="pgrandmother.invalid && pgrandmother.touched">
                                                    <div class="text-danger" *ngIf="pgrandmother.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Maternal Grand Father <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="mgrandfather">
                                                <div
                                                    *ngIf="caseSheetForm.controls.mgrandfather.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['mgrandfather']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="mgrandfather.invalid && mgrandfather.touched">
                                                    <div class="text-danger" *ngIf="mgrandfather.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Maternal Grand Mother <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="mgrandmother">
                                                <div
                                                    *ngIf="caseSheetForm.controls.mgrandmother.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['mgrandmother']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="mgrandmother.invalid && mgrandmother.touched">
                                                    <div class="text-danger" *ngIf="mgrandmother.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Brothers <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="brothers">
                                                <div
                                                    *ngIf="caseSheetForm.controls.brothers.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['brothers']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="brothers.invalid && brothers.touched">
                                                    <div class="text-danger" *ngIf="brothers.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Sisters <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="sisters">
                                                <div
                                                    *ngIf="caseSheetForm.controls.sisters.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['sisters']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="sisters.invalid && sisters.touched">
                                                    <div class="text-danger" *ngIf="sisters.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Children <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="childrens">
                                                <div
                                                    *ngIf="caseSheetForm.controls.childrens.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['childrens']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="childrens.invalid && childrens.touched">
                                                    <div class="text-danger" *ngIf="childrens.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <h4><b>Life Style</b> <span class="text-danger">*</span></h4>
                                                <textarea name="" id="" rows="3" class="form-control"
                                                    formControlName="life_history_model"></textarea>
                                                <div
                                                    *ngIf="caseSheetForm.controls.father.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['father']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="life_history_model.invalid && life_history_model.touched">
                                                    <div class="text-danger"
                                                        *ngIf="life_history_model.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Personal Health History</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Appetite <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="appetite">
                                                <div
                                                    *ngIf="caseSheetForm.controls.appetite.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['appetite']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="appetite.invalid && appetite.touched">
                                                    <div class="text-danger" *ngIf="appetite.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Thermals<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="thermals">
                                                <div
                                                    *ngIf="caseSheetForm.controls.thermals.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['thermals']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="thermals.invalid && thermals.touched">
                                                    <div class="text-danger" *ngIf="thermals.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Thirst<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="thirst">
                                                <div
                                                    *ngIf="caseSheetForm.controls.thirst.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['thirst']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="thirst.invalid && thirst.touched">
                                                    <div class="text-danger" *ngIf="thirst.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Menstrual History<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="menstrual_history">
                                                <div
                                                    *ngIf="caseSheetForm.controls.menstrual_history.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['menstrual_history'] }}</small>
                                                </div>
                                                <div *ngIf="menstrual_history.invalid && menstrual_history.touched">
                                                    <div class="text-danger" *ngIf="menstrual_history.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Desires<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="desires">
                                                <div
                                                    *ngIf="caseSheetForm.controls.desires.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['desires']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="desires.invalid && desires.touched">
                                                    <div class="text-danger" *ngIf="desires.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Perspiration<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="perspiration">
                                                <div
                                                    *ngIf="caseSheetForm.controls.perspiration.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['perspiration']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="perspiration.invalid && perspiration.touched">
                                                    <div class="text-danger" *ngIf="perspiration.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Aversions<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="aversions">
                                                <div
                                                    *ngIf="caseSheetForm.controls.aversions.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['aversions']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="aversions.invalid && aversions.touched">
                                                    <div class="text-danger" *ngIf="aversions.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Urine<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="urine">
                                                <div
                                                    *ngIf="caseSheetForm.controls.urine.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['urine'] }}</small>
                                                </div>
                                                <div *ngIf="urine.invalid && urine.touched">
                                                    <div class="text-danger" *ngIf="urine.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Sleep<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="sleep">
                                                <div
                                                    *ngIf="caseSheetForm.controls.sleep.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['sleep'] }}</small>
                                                </div>
                                                <div *ngIf="sleep.invalid && sleep.touched">
                                                    <div class="text-danger" *ngIf="sleep.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Stools<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="stools">
                                                <div
                                                    *ngIf="caseSheetForm.controls.stools.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['stools']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="stools.invalid && stools.touched">
                                                    <div class="text-danger" *ngIf="stools.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Sleep position<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="sleep_position">
                                                <div
                                                    *ngIf="caseSheetForm.controls.sleep_position.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['sleep_position']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="sleep_position.invalid && sleep_position.touched">
                                                    <div class="text-danger" *ngIf="sleep_position.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Habits<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="habits">
                                                <div
                                                    *ngIf="caseSheetForm.controls.habits.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['habits']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="habits.invalid && habits.touched">
                                                    <div class="text-danger" *ngIf="habits.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Dreams <span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="dreams">
                                                <div
                                                    *ngIf="caseSheetForm.controls.dreams.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['dreams']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="dreams.invalid && dreams.touched">
                                                    <div class="text-danger" *ngIf="dreams.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Doctor Comments<span class="text-danger">*</span></label>
                                                <textarea name="" id="" rows="3" class="form-control"
                                                    formControlName="doc_comments"></textarea>
                                                <div
                                                    *ngIf="caseSheetForm.controls.doc_comments.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['doc_comments']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="doc_comments.invalid && doc_comments.touched">
                                                    <div class="text-danger" *ngIf="doc_comments.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Observations</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Investigation<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="investigation">
                                                <div
                                                    *ngIf="caseSheetForm.controls.investigation.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['investigation']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="investigation.invalid && investigation.touched">
                                                    <div class="text-danger" *ngIf="investigation.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Journey of disease<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="journey_of_disease">
                                                <div
                                                    *ngIf="caseSheetForm.controls.journey_of_disease.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['journey_of_disease'] }}</small>
                                                </div>
                                                <div *ngIf="journey_of_disease.invalid && journey_of_disease.touched">
                                                    <div class="text-danger"
                                                        *ngIf="journey_of_disease.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Physical Appearance<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="physical_appearance">
                                                <div
                                                    *ngIf="caseSheetForm.controls.physical_appearance.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['physical_appearance'] }}</small>
                                                </div>
                                                <div *ngIf="physical_appearance.invalid && physical_appearance.touched">
                                                    <div class="text-danger"
                                                        *ngIf="physical_appearance.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Speed of patient<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="speed_of_patient">
                                                <div
                                                    *ngIf="caseSheetForm.controls.speed_of_patient.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['speed_of_patient']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="speed_of_patient.invalid && speed_of_patient.touched">
                                                    <div class="text-danger" *ngIf="speed_of_patient.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">PQRS<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="pqrs">
                                                <div
                                                    *ngIf="caseSheetForm.controls.pqrs.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['pqrs'] }}</small>
                                                </div>
                                                <div *ngIf="pqrs.invalid && pqrs.touched">
                                                    <div class="text-danger" *ngIf="pqrs.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Diagnosis</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Disease Diagnosis<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="deasis">
                                                <div
                                                    *ngIf="caseSheetForm.controls.deasis.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['deasis']
                                                        }}</small>
                                                </div>
                                                <div *ngIf="deasis.invalid && deasis.touched">
                                                    <div class="text-danger" *ngIf="deasis.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Miasm<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="miasm">
                                                <div *ngIf="miasm.invalid && miasm.touched">
                                                    <div class="text-danger" *ngIf="miasm.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                                <div
                                                    *ngIf="caseSheetForm.controls.miasm.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['miasm'] }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Treatment</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Opinion of remedies<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="opinion_of_remedies">
                                                <div
                                                    *ngIf="caseSheetForm.controls.opinion_of_remedies.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['opinion_of_remedies'] }}</small>
                                                </div>
                                                <div *ngIf="opinion_of_remedies.invalid && opinion_of_remedies.touched">
                                                    <div class="text-danger"
                                                        *ngIf="opinion_of_remedies.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">potency<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" formControlName="potency">
                                                <div *ngIf="potency.invalid && potency.touched">
                                                    <div class="text-danger" *ngIf="potency.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Date of Prescription<span
                                                        class="text-danger">*</span></label>
                                                <input type="date" class="form-control" readonly
                                                    formControlName="date_of_prescription">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">given prescription<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="given_prescription">
                                                <div
                                                    *ngIf="caseSheetForm.controls.given_prescription.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['given_prescription'] }}</small>
                                                </div>
                                                <div *ngIf="given_prescription.invalid && given_prescription.touched">
                                                    <div class="text-danger"
                                                        *ngIf="given_prescription.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">followup date<span class="text-danger">*</span></label>
                                                <input type="date" readonly class="form-control"
                                                    formControlName="followup_date">
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group mb-0">
                                                <label for="">Prescription Comments<span
                                                        class="text-danger">*</span></label>
                                                <textarea name="" id="" rows="3" class="form-control"
                                                    formControlName="prescription_comments"></textarea>
                                                <div
                                                    *ngIf="prescription_comments.invalid && prescription_comments.touched">
                                                    <div class="text-danger"
                                                        *ngIf="prescription_comments.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br>
                        <br>
                        <h4 *ngIf="caseSheetData"><b>Prescription</b></h4> 
                        <div class="table-responsive" *ngIf="caseSheetData">
                            <table class="table table-bordered text-center align-middle">
                                <thead>
                                    <tr>
                                        <th width="10%">Visit Date</th>
                                        <th width="10%">COB</th>
                                        <th width="10%">SPL</th>
                                        <th width="10%">Bio-chem</th>
                                        <th width="10%">Doctor</th>
                                        <th width="10%">Days</th>
                                        <th width="10%">Drug Name</th>
                                        <th width="10%">Quantity/Potency</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of prescriptions">
                                        <td>{{ item.prescribe_date ? (item.prescribe_date | date:'dd-MM-yyyy') : '-' }}
                                        </td>
                                        <td style="width:150px;white-space: pre-line !important;">{{ item.cob }}</td>
                                        <td style="width:90px;white-space: pre-line !important;">{{ item.spl }}</td>
                                        <td style="width:90px;white-space: pre-line !important;">{{ item.biochem_name }}</td>
                                        <td style="width:50px;white-space: pre-line !important;">{{ item.doctor_name }}</td>
                                        <td>{{ item.days }} Days</td>
                                        <td>{{ item.drug_name }}</td>
                                        <td>{{ item.potency }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <br>
                        <br>
                        <div class="row" *ngIf="caseSheetData">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <h4><b>Clinical Observations</b><span class="text-danger">*</span></h4>
                                                <textarea name="" id="" rows="3" class="form-control"
                                                    formControlName="comments"
                                                    [class.is-invalid]="comments.invalid && comments.touched"></textarea>
                                                <div *ngIf="comments.invalid && comments.touched">
                                                    <div class="text-danger" *ngIf="comments.errors?.required">Please
                                                        enter the clinical observations.</div>
                                                    <div class="text-danger" *ngIf="comments.errors?.maxlength">do not
                                                        enter more than 500 characters.</div>
                                                    <div class="text-danger" *ngIf="comments.errors?.pattern">This field
                                                        can not contain spaces.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Prescription</h4>
                                    <div class="row">
                                        <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Medicine Name <span class="text-danger">*</span></label>
                                                <ng-select [items]="medicines" [searchFn]="medicineNameSearch"  bindLabel="name" bindValue="id"
                                                    placeholder="Select Drug Name" formControlName="medicine_name"
                                                    [class.is-invalid]="medicine.invalid && medicine.touched">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{item.name}}</span>
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="medicine.invalid && medicine.touched">
                                                    <div class="text-danger" *ngIf="medicine.errors?.required">Please
                                                        select the medicine name.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Potency <span class="text-danger">*</span></label>
                                                <ng-select [items]="potencies" bindLabel="potency" bindValue="id"
                                                    placeholder="Select Potency" formControlName="potency_id">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{item.potency}}</span>
                                                    </ng-template>
                                                </ng-select>
                                                <div *ngIf="potency_id.invalid && potency_id.touched">
                                                    <div class="text-danger" *ngIf="potency_id.errors?.required">Please
                                                        select the potency</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-2 col-lg-2 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">biochecm</label>
                                                <ng-select [items]="biochemData" bindLabel="name" bindValue="id"
                                                    placeholder="Select Bio Chemical" [searchFn]="biochemSearch" formControlName="biochem">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{item.name}}</span>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">Special Medicine</label>
                                                <input type="text" class="form-control"
                                                    formControlName="special_medicine"
                                                    [class.is-invalid]="specialMedicine.invalid && specialMedicine.touched">
                                                <div *ngIf="specialMedicine.invalid && specialMedicine.touched">
                                                    <div class="text-danger" *ngIf="specialMedicine.errors?.required">
                                                        This field is required.</div>
                                                    <div class="text-danger" *ngIf="specialMedicine.errors?.pattern">
                                                        This field can not contain spaces.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="">
                                                <label for="">Dose <span class="text-danger">*</span></label>
                                                <div class="demo-inline-spacing">
                                                    <div class="custom-control custom-checkbox mt-50"
                                                        *ngFor="let control of dosesArray.controls; let i = index;">
                                                        <input type="checkbox" class="custom-control-input"
                                                            [formControl]="control" id="customCheck{{i}}"
                                                            (change)="getSelectedDosesValue()" />
                                                        <label class="custom-control-label"
                                                            for="customCheck{{i}}" checked>{{takenDose[i]}}</label>
                                                    </div>
                                                </div>
                                                <div class="text-danger" *ngIf="dose_required">
                                                    This field is required.</div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group">
                                                <label for="">No of days <span class="text-danger">*</span></label>

                                                    <ng-select [items]="no_of_days_list" bindLabel="name" bindValue="value" 
                                                    [compareWith]="compareFn"
                                                    (change)="updateFollowupDate()" placeholder="Select no of days"
                                                    formControlName="no_of_days"
                                                    [class.is-invalid]="no_of_days.invalid && no_of_days.touched">
                                                </ng-select>
                                                <div *ngIf="no_of_days.invalid && no_of_days.touched">
                                                    <div class="text-danger" *ngIf="no_of_days.errors?.required">Please
                                                        enter the no of days for medicine.</div>
                                                    <div class="text-danger" *ngIf="no_of_days.errors?.pattern">This
                                                        field
                                                        can not contain spaces.</div>
                                                </div>
                                                <div
                                                    *ngIf="caseSheetForm.controls.no_of_days.hasError('serverValidationError')">
                                                    <small class="text-danger">{{ backendValidations['no_of_days']
                                                        }}</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12" *ngIf="!caseSheetData">
                                            <div class="form-group">
                                                <label for="">Treatment Duration <span
                                                        class="text-danger">*</span></label>
                                                <input type="text" class="form-control"
                                                    formControlName="treatment_duration"
                                                    [class.is-invalid]="treatment_duration.invalid && treatment_duration.touched">
                                                <div *ngIf="treatment_duration.invalid && treatment_duration.touched">
                                                    <div class="text-danger"
                                                        *ngIf="treatment_duration.errors?.required">This field is
                                                        required.</div>
                                                    <div class="text-danger" *ngIf="treatment_duration.errors?.pattern">
                                                        This field can not contain spaces.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12" *ngIf="!caseSheetData">
                                            <div class="form-group">
                                                <label for="">Comments to Pro <span class="text-danger">*</span></label>
                                                <textarea name="" id="" rows="3" class="form-control"
                                                    formControlName="pro_comments"></textarea>
                                                <div *ngIf="pro_comments.invalid && pro_comments.touched">
                                                    <div class="text-danger" *ngIf="pro_comments.errors?.required">
                                                        This field is required.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-12">
                                <div class="case-com-content">
                                    <h4>Patient Satisfaction for Referral & Renewal</h4>
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                            <div class="form-group mb-0">
                                                <label for="">Patient Satisfaction <span
                                                        class="text-danger">*</span></label>
                                                <ng-select [items]="patientFeedback" bindLabel="value" bindValue="value"
                                                    placeholder="Select Patient Satisfaction"
                                                    formControlName="patient_satisfaction"
                                                    [class.is-invalid]="patientSatisfaction.invalid && patientSatisfaction.touched">
                                                </ng-select>
                                                <div *ngIf="patientSatisfaction.invalid && patientSatisfaction.touched">
                                                    <div
                                                        *ngIf="patientSatisfaction.invalid && patientSatisfaction.touched">
                                                        <div class="text-danger"
                                                            *ngIf="patientSatisfaction.errors?.required">Please select
                                                            the patient satisfaction.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <button [disabled]="loading | async" type="submit" class="btn btn-primary">
                                    <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1">
                                    </span>Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Billing Report Modal-->
<ng-template #billingReportModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Billing Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Duration</th>
                            <th>Total</th>
                            <th>Paid</th>
                            <th>Due</th>
                            <th>Option</th>
                            <th>Discount</th>
                            <th>Status</th>
                            <th>Increase amt</th>
                            <th>Decrease amt</th>
                            <th>Renewal Date</th>
                            <th>Followup Date</th>
                            <th>Signature</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="billing_report_data">
                        <tr *ngFor="let billing of billing_report_data">
                            <td>{{billing.billing_date ? (billing.billing_date | date:'dd-MM-yyyy') : "-"}}</td>
                            <td>{{billing.treatment_duration ? (isNumber(billing.treatment_duration) ?
                                (billing.treatment_duration+' Months'):billing.treatment_duration):
                                '-'}}</td>
                            <td>{{billing.total_amount}}</td>
                            <td>{{billing.received_amount}}</td>
                            <td>{{billing.due_amount}}</td>
                            <td>{{ billing.payment_option == 'c' ? 'Cash' :
                                billing.payment_option == 'cc' ? 'Credit-Card' :
                                billing.payment_option == 'ch' ? 'Cheque' :
                                billing.payment_option == 'o' ? 'Online' :
                                billing.payment_option == 'emi' ? 'Emi' : '-'}}</td>
                            <td>{{billing.discount ? billing.discount : "NO"}}</td>
                            <td>{{billing.package_status ? billing.package_status : "NO"}}</td>
                            <td>{{billing.increase_amount ? billing.increase_amount : "NO"}}</td>
                            <td>{{billing.decrease_amount ? billing.decrease_amount : "NO"}}</td>
                            <td>{{billing.renewal_date ? (billing.renewal_date|date:'dd-MM-yyyy') : '-'}}</td>
                            <td>{{billing.op_date ? (billing.op_date|date:'dd-MM-yyyy') : '-'}}</td>
                            <td>{{billing.signature}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Billing Report Modal-->

<!-- Prescription Modal-->
<ng-template #prescriptionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Prescription Details</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th width="10%">Visit Date</th>
                            <th>COB</th>
                            <th>SPL</th>
                            <th>Doctor</th>
                            <th>Days</th>
                            <th>Drug Name</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="prescription_data">
                        <tr *ngFor="let prescription of prescription_data">
                            <td>{{prescription.prescribe_date ? (prescription.prescribe_date|date:'dd-MM-yyyy' ): '-'}}
                            </td>
                            <td>{{prescription.cob ? prescription.cob : '-'}}</td>
                            <td>{{prescription.spl ? prescription.spl : '-'}}</td>
                            <td>{{prescription.doctor_name ? prescription.doctor_name : '-' }}</td>
                            <td>{{prescription.days ? prescription.days : '-'}}</td>
                            <td>{{prescription.drug_name ? prescription.drug_name : '-'}}</td>
                            <td>{{prescription.quantity ? prescription.quantity : '-'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Prescription Modal-->