<section *ngFor="let key of tableKeys">
    <div class="row" *ngIf="key == state_id">
        <div class="col-xl-12 col-lg-12 col-md-12 col-12">
            <div class="card" *ngIf="key == state_id">
                <div class="card-header">
                    <h4 class="card-title text-uppercase">{{ state_id == 1 ? "AP" :
                        state_id == 2 ? "TS":state_id == 3 ? "KA" :"-" }} Branches ({{currentDate | date:'dd-MM-yyyy'}}) Report</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch Name</th>
                                    <th>Yesterday(GT)</th>
                                    <th>Today(GT)</th>
                                    <th>Target</th>
                                    <th>Nppack</th>
                                    <th>Achieved</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of tableData[key]">
                                    <td>{{item.branch_name}}</td>
                                    <td><a class="dataTable-Link">{{item.yesterday}}</a></td>
                                    <td><a class="dataTable-Link">{{item.today}}</a></td>
                                    <td>{{item.act_target_amt}}</td>
                                    <td style="background: #f8f8f8;">{{item.np_pack}}</td>
                                    <td><a class="dataTable-Link">{{item.achieved_upto}}</a></td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow[key]">
                                    <td>Total</td>
                                    <td>{{ totalRow[key].yesterday }}</td>
                                    <td>{{ totalRow[key].today }}</td>
                                    <td>{{ totalRow[key].act_target_amt }}</td>
                                    <td>{{ totalRow[key].np_pack }}</td>
                                    <td>{{ totalRow[key].achieved_upto }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
