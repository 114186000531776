import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule } from "@angular/forms";

import { ActivatedRoute } from "@angular/router";
import { AppHttpClient } from '@core/http/app-http-client.service';
import { NotificationService } from '@core/services/notification.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

declare var $;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})


export class ProfileComponent implements OnInit {

  public loading = new BehaviorSubject(false);
  passwordTextType: boolean
  experiencesList: any;
  familiesList: any;
  fileName: string = 'Choose File';
  resumeFileName: string = 'Choose File';
  designations: any;
  branches: any;
  exitStatus = [{ 'id': 1, 'value': 'Active' }, { 'id': 0, 'value': 'In-Active' }];
  yesStatus = [{ 'value': 'YES' }, { 'value': 'NO' }];
  activeDiv: any;

  howdoyouKnowUs = [
    { id: "walk-in", value: "Walk-in" },
    { id: "ref", value: "Ref" }
  ]
  public genderList = [
    { value: 'male', name: 'Male' },
    { value: 'female', name: 'Female' },
    { value: 'others', name: 'Others' }
  ];
  public bloodGroupList = [
    { value: 'A+', name: 'A+' },
    { value: 'A-', name: 'A-' },
    { value: 'B+', name: 'B+' },
    { value: 'B-', name: 'B-' },
    { value: 'AB+', name: 'AB+' },
    { value: 'AB-', name: 'AB-' },
    { value: 'O+', name: 'O+' },
    { value: 'O-', name: 'O-' },
  ];
  public casteList = [
    { value: 'OC', name: 'OC' },
    { value: 'OBC', name: 'OBC' },
    { value: 'SC', name: 'SC' },
    { value: 'ST', name: 'ST' },
  ];
  public maritalStatus = [
    { id: 1, value: 'married', name: 'Married' },
    { id: 2, value: 'un_married', name: 'Un-Married' }
  ];

  /* Get Families */
  get families(): FormArray {
    return this.addEmployeeForm.get("families") as FormArray;
  }

  /* Get Experiences */
  get experiences(): FormArray {
    return this.addEmployeeForm.get("experiences") as FormArray;
  }

  constructor(
    private _formBuilder: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    protected httpClient: AppHttpClient,
    private notifyService: NotificationService,
    private masterService: MasterDataService
  ) {
    console.log(window.location.pathname)
    this.masterService.masterData('api');

    this.masterService.rolesModel$.subscribe(val => {
      this.designations = val;
    });
    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });
  }

  public addEmployeeForm = this._formBuilder.group({
    unique_id: [null],
    name: [null],
    father_name: [null],
    gender: [null],
    how_you_came: [null],
    blood_group: [null],
    caste: [null],
    marital_status: [null],
    dob: [null],
    date_of_join: [null],
    role_id: [null],
    branch_id: [null],
    esi_no: [null],
    pf_no: [null],
    phone_number: [null],
    email: [null],
    password: [null],
    passport_no: [null],
    passport_expiry_date: [null],
    photo: [null],
    resume: [null],
    present_address: [null],
    permanent_address: [null],
    gross_salary: [null],
    basic: [null],
    hra: [null],
    esi: [null],
    conveyance: [null],
    special_allowance: [null],
    pf: [null],
    pt: [null],
    otd: [null],
    bank_name: [null],
    bank_no: [null],
    degree_name: [null],
    university: [null],
    year_passing: [null],
    marks: [null],
    reference_name: [null],
    reference_contact_no: [null],
    reference_relation: [null],
    reference_address: [null],
    marriage_aniversary: [null],
    pan_card_no: [null],
    address_proof: [null],
    id_proof: [null],
    emergency_person_name: [null],
    emergency_relation: [null],
    emergency_contact_no: [null],
    exit_status: [null],
    exit_date: [null],
    experiences: this._formBuilder.array([this.buildExperienceForm()]),
    families: this._formBuilder.array([this.buildFamilyForm()]),
  });

  /* Build Experience Form */
  buildExperienceForm() {
    return this._formBuilder.group({
      company_name: [''],
      worked_from: [''],
      worked_to: [''],
      designation: [''],
      remarks: ['']
    });
  }

  /* Build Family Form */
  buildFamilyForm() {
    return this._formBuilder.group({
      name: [''],
      relationship: [''],
      education: [''],
      occupation: [''],
      contact_no: ['']
    });
  }

  ngOnInit(): void {

    this.httpClient.get('api/profileData').subscribe(res => {
      if (res['status'] = "success") {
        this.patchEmployeeForm(res['data']);
      }
    }, err => {

    });

    this.addEmployeeForm.controls['gender'].disable();
    this.addEmployeeForm.controls['blood_group'].disable();
    this.addEmployeeForm.controls['caste'].disable();
    this.addEmployeeForm.controls['marital_status'].disable();
    this.addEmployeeForm.controls['role_id'].disable();
    this.addEmployeeForm.controls['branch_id'].disable();
    this.addEmployeeForm.controls['address_proof'].disable();
    this.addEmployeeForm.controls['id_proof'].disable();
    this.addEmployeeForm.controls['exit_status'].disable();
    this.addEmployeeForm.controls['present_address'].disable();
    this.addEmployeeForm.controls['permanent_address'].disable();
    this.addEmployeeForm.controls['reference_address'].disable();


    // this._service.getProfile()
    // .pipe(finalize(() => this.loading.next(false)))
    //   .subscribe(res => {
    //     if (res['status'] == 'success') {
    //       let data = res['data'];
    //       this.patchEmployeeForm(data);
    //     } else if (res['status'] == 'error') {
    //       this.notifyService.showError(res['message'], res['title']);
    //     }
    //   }, err => {
    //     this.notifyService.showError(err['message'], err['title']);
    //   });
  }

  /* Patch Employee Form */
  patchEmployeeForm(employeeDetails) {
    this.addEmployeeForm.patchValue({
      'unique_id': employeeDetails.unique_id,
      'name': employeeDetails.name,
      'father_name': employeeDetails.father_name,
      'gender': employeeDetails.gender,
      'how_you_came': employeeDetails.how_you_came,
      'blood_group': employeeDetails.blood_group,
      'caste': employeeDetails.caste,
      'marital_status': employeeDetails.marital_status,
      'dob': employeeDetails.dob,
      'date_of_join': employeeDetails.date_of_join,
      'role_id': employeeDetails.role_id,
      'branch_id': employeeDetails.branch_id,
      // 'esi_applicable': employeeDetails.esi_applicable,
      'esi_no': employeeDetails.esi_no,
      'pf_no': employeeDetails.pf_no,
      'phone_number': employeeDetails.phone_number,
      'email': employeeDetails.email,
      'password': employeeDetails.password,
      'passport_no': employeeDetails.passport_no,
      'passport_expiry_date': employeeDetails.passport_expiry_date,
      'present_address': employeeDetails.present_address,
      'permanent_address': employeeDetails.permanent_address,
      'gross_salary': employeeDetails.gross_salary,
      'basic': employeeDetails.basic,
      'hra': employeeDetails.hra,
      'esi': employeeDetails.esi,
      'conveyance': employeeDetails.conveyance,
      'special_allowance': employeeDetails.special_allowance,
      'pf': employeeDetails.pf,
      'pt': employeeDetails.pt,
      'otd': employeeDetails.otd,
      'bank_name': employeeDetails.bank_name,
      'bank_no': employeeDetails.bank_no,
      'degree_name': employeeDetails.degree_name,
      'university': employeeDetails.university,
      'year_passing': employeeDetails.year_passing,
      'marks': employeeDetails.marks,
      'reference_name': employeeDetails.reference_name,
      'reference_contact_no': employeeDetails.reference_contact_no,
      'reference_relation': employeeDetails.reference_relation,
      'reference_address': employeeDetails.reference_address,
      // 'ip_address1': employeeDetails.ip_address1,
      // 'ip_address2': employeeDetails.ip_address2,
      'marriage_aniversary': employeeDetails.marriage_aniversary,
      'pan_card_no': employeeDetails.pan_card_no,
      'address_proof': employeeDetails.address_proof,
      'id_proof': employeeDetails.id_proof,
      'emergency_person_name': employeeDetails.emergency_person_name,
      'emergency_relation': employeeDetails.emergency_relation,
      'emergency_contact_no': employeeDetails.emergency_contact_no,
      'exit_status': employeeDetails.exit_status,
      'exit_date': employeeDetails.exit_date
    });

    /* Patch Experience Form Array */
    let experiences = employeeDetails.experiences;
    this.experiencesList = experiences;
    const formArray = new FormArray([]);
    experiences.forEach(exper => {
      formArray.push(this._formBuilder.group({
        'id': exper.id,
        'company_name': exper.company_name,
        'worked_from': exper.worked_from,
        'worked_to': exper.worked_to,
        'designation': exper.designation,
        'remarks': exper.remarks
      }));
    });
    this.addEmployeeForm.setControl('experiences', formArray);

    /* Patch Families Form Array */
    let families = employeeDetails.families;
    this.familiesList = families;
    const familyFormArray = new FormArray([]);
    families.forEach(family => {
      familyFormArray.push(this._formBuilder.group({
        'id': family.id,
        'name': family.name,
        'relationship': family.relationship,
        'education': family.education,
        'occupation': family.occupation,
        'contact_no': family.contact_no
      }));
    });
    this.addEmployeeForm.setControl('families', familyFormArray);
    this.fileName = employeeDetails.photo;
    this.resumeFileName = employeeDetails.resume;
  }

  /** * Toggle password */
  togglePasswordTextType() {
    this.passwordTextType = !this.passwordTextType
  }
}
