<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Target Report</h4>
                </div>
                <hr>
                <div class="card-body pb-1">
                    <!-- Show Filter -->
                    <div class="row mb-1">
                        <div class="col-12">
                            <!-- <form [formGroup]="targetReportForm"> -->
                            <form [formGroup]="targetReportForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Month Wise</label>
                                            <ng-select [items]="monthWise" bindLabel="title" bindValue="value"
                                                placeholder="Select" formControlName="month_wise">
                                            </ng-select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select State Wise</label>
                                            <ng-select [items]="stateWise" bindLabel="title" bindValue="value"
                                                placeholder="Select" formControlName="state_id"></ng-select>
                                        </div>
                                    </div> -->
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button type="submit" class="btn btn-primary">Submit</button>
                                            <button type="reset" class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Show Filter End-->
                    <div *ngIf="prev_branch_table" class="table-responsive">
                        <div *ngIf="state_id == 1" class="card-header">
                            <h4 class="card-title">Andhra Pradesh</h4>
                        </div>
                        <hr>
                        <table *ngIf="state_id == 1" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch Name</th>
                                    <th>Total Target Amount</th>
                                    <th>Achieved (upto)</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of prev_ap_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.gross_amt }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, null, 'prev_month')">{{
                                            item.achieved }}</a>
                                    </td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td>{{ prev_ap_branch_table_data_total.gross_amt }}</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, 'prev_month', 1)">{{
                                            prev_ap_branch_table_data_total.achieved }}</a></td>
                                    <td>-</td>
                                </tr>
                            </tfoot>
                        </table>
                     
                        <hr>
                        <br />
                        <div *ngIf="state_id == 2" class="card-header">
                            <h4 class="card-title">Telangana</h4>
                        </div>
                        <table *ngIf="state_id == 2" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch Name</th>
                                    <th>Total Target Amount</th>
                                    <th>Achieved (upto)</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of prev_ts_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.gross_amt }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, null, 'prev_month')">{{
                                            item.achieved }}</a>
                                    </td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td>{{ prev_ts_branch_table_data_total.gross_amt }}</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, 'prev_month', 2)">{{
                                            prev_ts_branch_table_data_total.achieved }}</a></td>
                                    <td>-</td>
                                </tr>
                            </tfoot>
                        </table>
                        <hr>
                        <br />
                        <div *ngIf="state_id == 3" class="card-header">
                            <h4 class="card-title">Karnataka</h4>
                        </div>
                        <table *ngIf="state_id == 3" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch Name</th>
                                    <th>Total Target Amount</th>
                                    <th>Achieved (upto)</th>
                                    <th>Percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of prev_ka_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.gross_amt }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, null, 'prev_month')">{{
                                            item.achieved }}</a>
                                    </td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td>{{ prev_ka_branch_table_data_total.gross_amt }}</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, 'prev_month', 3)">{{
                                            prev_ka_branch_table_data_total.achieved }}</a></td>
                                    <td>-</td>
                                </tr>
                            </tfoot>
                        </table>
                        <hr>
                        <br />
                    
                    </div>



                    <div *ngIf="this_branch_table" class="table-responsive">
                        <div *ngIf="state_id == 1" class="card-header">
                            <h4 class="card-title">Andhra Pradesh</h4>
                        </div>
                        <hr>

                        <table *ngIf="state_id == 1" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>Yes</th>
                                    <th>Today</th>
                                    <th>ACT<br /> Target</th>
                                    <th>Per%</th>
                                    <th>Achieved</th>
                                    <th>Emi</th>
                                    <th>Cleared<br /> Emi</th>
                                    <th>Achieved<br />(with emi)</th>
                                    <th>NP Pack</th>
                                    <th>NP<br /> Target</th>
                                    <th>NP Coll</th>
                                    <th>OP<br /> Target</th>
                                    <th>OP Coll</th>
                                    <th>REN Coll</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of this_ap_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'yesterday')">{{
                                            item.yesterday }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'today')">{{
                                            item.today }}</a></td>
                                    <td>{{ item.act_target_amt }}</td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id)">{{
                                            item.achieved_upto }}</a></td>
                                    <td>{{ item.emi }}</td>
                                    <td>{{ item.emi_cleared ? item.emi_cleared : 0 }}</td>
                                    <td>{{ item.achieved_with_emi }}</td>
                                    <td>{{ item.np_pack }}</td>
                                    <td>{{ item.np_target_amt }}</td>
                                    <td>{{ item.np_cool }}</td>
                                    <td>{{ item.op_target_amt }}</td>
                                    <td>{{ item.old_cool }}</td>
                                    <td>{{ item.ren_cool }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'yesterday', 'this_month', 1)">{{
                                            this_ap_branch_table_data_total.yesterday }}</a></td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'today', 'this_month', 1)">{{
                                            this_ap_branch_table_data_total.today }}</a>
                                    </td>
                                    <td>{{ this_ap_branch_table_data_total.act_target_amt }}</td>
                                    <td>-</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, null, 1)">{{
                                            this_ap_branch_table_data_total.achieved_upto }}</a></td>
                                    <td>{{ this_ap_branch_table_data_total.emi }}</td>
                                    <td>{{ this_ap_branch_table_data_total.emi_cleared }}</td>
                                    <td>{{ this_ap_branch_table_data_total.achieved_with_emi }}</td>
                                    <td>{{ this_ap_branch_table_data_total.np_pack }}</td>
                                    <td>{{ this_ap_branch_table_data_total.np_target_amt }}</td>
                                    <td>{{ this_ap_branch_table_data_total.np_cool }}</td>
                                    <td>{{ this_ap_branch_table_data_total.op_target_amt }}</td>
                                    <td>{{ this_ap_branch_table_data_total.old_cool }}</td>
                                    <td>{{ this_ap_branch_table_data_total.ren_cool }}</td>
                                </tr>
                            </tfoot>
                        </table>


                        <hr>
                        <br />
                        <div *ngIf="state_id == 2" class="card-header">
                            <h4 class="card-title">Telangana</h4>
                        </div>
                        <hr>
                        <table *ngIf="state_id == 2" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>Yes</th>
                                    <th>Today</th>
                                    <th>ACT<br /> Target</th>
                                    <th>Per%</th>
                                    <th>Achieved</th>
                                    <th>Emi</th>
                                    <th>Cleared<br /> Emi</th>
                                    <th>Achieved<br />(with emi)</th>
                                    <th>NP Pack</th>
                                    <th>NP<br /> Target</th>
                                    <th>NP Coll</th>
                                    <th>OP<br /> Target</th>
                                    <th>OP Coll</th>
                                    <th>REN Coll</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of this_ts_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'yesterday')">{{
                                            item.yesterday }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'today')">{{
                                            item.today }}</a></td>
                                    <td>{{ item.act_target_amt }}</td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id)">{{
                                            item.achieved_upto }}</a></td>
                                    <td>{{ item.emi }}</td>
                                    <td>{{ item.emi_cleared ? item.emi_cleared : 0 }}</td>
                                    <td>{{ item.achieved_with_emi }}</td>
                                    <td>{{ item.np_pack }}</td>
                                    <td>{{ item.np_target_amt }}</td>
                                    <td>{{ item.np_cool }}</td>
                                    <td>{{ item.op_target_amt }}</td>
                                    <td>{{ item.old_cool }}</td>
                                    <td>{{ item.ren_cool }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'yesterday', 'this_month', 2)">{{
                                            this_ts_branch_table_data_total.yesterday }}</a></td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'today', 'this_month', 2)">{{
                                            this_ts_branch_table_data_total.today }}</a>
                                    </td>
                                    <td>{{ this_ts_branch_table_data_total.act_target_amt }}</td>
                                    <td>-</td>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, 'this_month', 2)">{{
                                            this_ts_branch_table_data_total.achieved_upto }}</a></td>
                                    <td>{{ this_ts_branch_table_data_total.emi }}</td>
                                    <td>{{ this_ts_branch_table_data_total.emi_cleared }}</td>
                                    <td>{{ this_ts_branch_table_data_total.achieved_with_emi }}</td>
                                    <td>{{ this_ts_branch_table_data_total.np_pack }}</td>
                                    <td>{{ this_ts_branch_table_data_total.np_target_amt }}</td>
                                    <td>{{ this_ts_branch_table_data_total.np_cool }}</td>
                                    <td>{{ this_ts_branch_table_data_total.op_target_amt }}</td>
                                    <td>{{ this_ts_branch_table_data_total.old_cool }}</td>
                                    <td>{{ this_ts_branch_table_data_total.ren_cool }}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <hr>

                        <br />
                        <div *ngIf="state_id == 3" class="card-header">
                            <h4 class="card-title">Karnataka</h4>
                        </div>
                        <hr>
                        <table *ngIf="state_id == 3" class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>Yes</th>
                                    <th>Today</th>
                                    <th>ACT<br /> Target</th>
                                    <th>Per%</th>
                                    <th>Achieved</th>
                                    <th>Emi</th>
                                    <th>Cleared<br /> Emi</th>
                                    <th>Achieved<br />(with emi)</th>
                                    <th>NP Pack</th>
                                    <th>NP<br /> Target</th>
                                    <th>NP Coll</th>
                                    <th>OP<br /> Target</th>
                                    <th>OP Coll</th>
                                    <th>REN Coll</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of this_ka_branch_table_data">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'yesterday')">{{
                                            item.yesterday }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id, 'today')">{{
                                            item.today }}</a></td>
                                    <td>{{ item.act_target_amt }}</td>
                                    <td class="target-percentage">{{ item.per }}%</td>
                                    <td><a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', item.branch_id)">{{
                                            item.achieved_upto }}</a></td>
                                    <td>{{ item.emi }}</td>
                                    <td>{{ item.emi_cleared ? item.emi_cleared : 0 }}</td>
                                    <td>{{ item.achieved_with_emi }}</td>
                                    <td>{{ item.np_pack }}</td>
                                    <td>{{ item.np_target_amt }}</td>
                                    <td>{{ item.np_cool }}</td>
                                    <td>{{ item.op_target_amt }}</td>
                                    <td>{{ item.old_cool }}</td>
                                    <td>{{ item.ren_cool }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th id="total" colspan="1">Total :</th>
                                    <td> <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'yesterday', 'this_month', 3)">{{
                                            this_ka_branch_table_data_total.yesterday }}</a></td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, 'today', 'this_month', 3)">{{
                                            this_ka_branch_table_data_total.today }}</a>
                                    </td>
                                    <td>{{ this_ka_branch_table_data_total.act_target_amt }}</td>
                                    <td>-</td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="achievedModal.openModal('agmapi', null, null, 'this_month', 3)">{{
                                            this_ka_branch_table_data_total.achieved_upto }}</a>
                                    </td>
                                    <td>{{ this_ka_branch_table_data_total.emi }}</td>
                                    <td>{{ this_ka_branch_table_data_total.emi_cleared }}</td>
                                    <td>{{ this_ka_branch_table_data_total.achieved_with_emi }}</td>
                                    <td>{{ this_ka_branch_table_data_total.np_pack }}</td>
                                    <td>{{ this_ka_branch_table_data_total.np_target_amt }}</td>
                                    <td>{{ this_ka_branch_table_data_total.np_cool }}</td>
                                    <td>{{ this_ka_branch_table_data_total.op_target_amt }}</td>
                                    <td>{{ this_ka_branch_table_data_total.old_cool }}</td>
                                    <td>{{ this_ka_branch_table_data_total.ren_cool }}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <hr>

                        <br />
                     

                    </div>

                    
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Billing Receipt Modal -->
<app-achieved-upto-modal #achievedModal></app-achieved-upto-modal>
<!-- / Billing Receipt Modal -->