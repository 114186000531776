<section>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Month Report
                        <span *ngIf="selectedMonth">- {{selectedMonth | date: 'MMMM y'}}</span>
                        <span *ngIf="selectedState" class="text-warning">({{selectedState}})</span>
                    </h4>
                </div>
                <hr>
                <div class="card-body">
                    <!-- Show Filter -->
                    <div class="row mb-1">
                        <div class="col-12">
                            <form [formGroup]="monthReportForm" (ngSubmit)="searchReport()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Report by Day Wise</label>
                                            <ng-select [items]="monthWise"
                                                bindLabel="title" bindValue="value" placeholder="Select"
                                                formControlName="fmonth"
                                                [ngClass]="{ 'is-invalid error': errorMessages.fmonth && fmonth.errors && fmonth.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.fmonth">
                                                <ng-container *ngIf="fmonth.hasError(error.type) && (fmonth.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="monthReportForm.controls.fmonth.hasError('serverValidationError')">
                                                <small class="text-danger">{{ backendValidations['fmonth'] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary">
                                                <span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Show Filter End-->
                    <div>
                        <div class="table-responsive">
                            <table class="table table-bordered text-center align-middle mb-0">
                                <thead>
                                    <tr>
                                        <th>Branch</th>
                                        <th>target</th>
                                        <th>midgt</th>
                                        <th>%</th>
                                        <th>np</th>
                                        <th>ccv</th>
                                        <th>wi</th>
                                        <th>ref</th>
                                        <th>rmp</th>
                                        <th>camp</th>
                                        <th>ol</th>
                                        <th>reg</th>
                                        <th>oc</th>
                                        <th>acq</th>
                                        <th>rl</th>
                                        <th>reg %</th>
                                        <th>cash</th>
                                        <th>cc</th>
                                        <th>ol</th>
                                        <th>chq</th>
                                        <th>rchq</th>
                                        <th>con</th>
                                        <th>exp</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="patients-reports-stats" *ngFor="let item of month_status">
                                        <td>{{ item.branch_code }}</td>
                                        <td>{{ item.target }}</td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, '')">{{ item.mid_gt }}</a></td>
                                        <td>{{ item.per }}</td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'patient_type', 'new', report_type, null)">{{ item.np }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'call-center', report_type, null)">{{ item.ccv }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'walk-in', report_type, null)">{{ item.wi }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'ref', report_type, null)">{{ item.ref }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'rmp', report_type, null)">{{ item.rmp }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'camp', report_type, null)">{{ item.camp }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, 'how_came_know', 'online', report_type, null)">{{ item.online }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, null, 'reg', report_type, null)">{{ item.reg }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, null, oc, report_type, null)">{{ item.oc }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, null, 'acq', report_type, null)">{{ item.acq }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, null, 'rgl', report_type, null)">{{ item.rl }}</a></td>
                                        <td>{{ item['reg%'] }}</td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, 'c')">{{ item.cash }}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, 'cc')">{{ item.cc }}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, 'o')">{{ item.ol }}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, 'ch')">{{ item.chq }}</a></td>
                                        <td><a class="dataTable-Link" (click)="midGtPatientsModal.openSmsModal(item, report_type, 'ch')">{{ item.rchq }}</a></td>
                                        <td><a class="dataTable-Link" (click)="patientModal.openSmsModal(item, null, 'con', report_type, null)">{{ item.con }}</a></td>
                                        <td>{{ item.exp }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <ngx-datatable [rows]="month_status" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
                        [summaryRow]="true" [summaryPosition]="'bottom'" [summaryHeight]="55"
                        [columnMode]="ColumnMode.force" [headerHeight]="40" [scrollbarH]="true">
                        <ngx-datatable-column name="Branch" [summaryFunc]="summaryForProp" prop="branch_code"
                            [width]="150"></ngx-datatable-column>
                        <ngx-datatable-column name="Target" [summaryFunc]="summaryForProp1" prop="target" [width]="100">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Mid GT" [summaryFunc]="summaryForProp1" prop="mid_gt" [width]="100">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="%" [summaryFunc]="summaryForProp1" prop="per" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="np" [summaryFunc]="summaryForProp1" prop="np" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="ccv" [summaryFunc]="summaryForProp1" prop="ccv" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="wi" [summaryFunc]="summaryForProp1" prop="wi" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="ref" [summaryFunc]="summaryForProp1" prop="ref" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="rmp" [summaryFunc]="summaryForProp1" prop="rmp" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="camp" [summaryFunc]="summaryForProp1" prop="camp" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="online" [summaryFunc]="summaryForProp1" prop="online" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="reg" [summaryFunc]="summaryForProp1" prop="reg" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="oc" [summaryFunc]="summaryForProp1" prop="oc" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="acq" [summaryFunc]="summaryForProp1" prop="acq" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="rl" [summaryFunc]="summaryForProp1" prop="rl" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="reg%" prop="reg%" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="cash" [summaryFunc]="summaryForProp1" prop="cash" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="cc" [summaryFunc]="summaryForProp1" prop="cc" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Ol" [summaryFunc]="summaryForProp1" prop="ol" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="chq" [summaryFunc]="summaryForProp1" prop="chq" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="rchq" [summaryFunc]="summaryForProp1" prop="rchq" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="con" [summaryFunc]="summaryForProp1" prop="con" [width]="80">
                        </ngx-datatable-column>
                        <ngx-datatable-column name="exp" [summaryFunc]="summaryForProp1" prop="exp" [width]="80">
                        </ngx-datatable-column>
                    </ngx-datatable> -->
                </div>
            </div>
        </div>
    </div>
</section>
<mid-gt-modal #midGtPatientsModal></mid-gt-modal>

<common-patients-modal #patientModal></common-patients-modal>
