import { Component, OnInit, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { CallcenterService } from '@callcenter/services/callcenter.service';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'leads-feedback',
  templateUrl: './leads-feedback.component.html',
  styleUrls: ['./leads-feedback.component.scss']
})
export class LeadsFeedbackComponent implements OnInit {

  @Output() public reloadLeads = new EventEmitter<String>();
  public loading = new BehaviorSubject(false);
  backendValidations: any;
  selectedReportType: any;
  selectedLead: any;
  fbstatus = [
    { type: "Interested for an Appointment", key: "Interest", value: 1 },
    { type: "Not Interested", key: "not_interested", value: 2 },
    { type: "Followup required", key: "followup_req", value: 3 },
  ];
  feedLog = [];

  // FeedBack Form
  leadsFeedbackForm = this.fb.group({
    status: [null, Validators.required],
    appointment_date: [null],
    // appointment_time: [null],
    branch_id: [null],
    remarks: [null],
    followup_date: [null],
  });

  get status() { return this.leadsFeedbackForm.get("status"); }
  get appointment_date() { return this.leadsFeedbackForm.get("appointment_date"); }
  // get appointment_time() { return this.leadsFeedbackForm.get("appointment_time"); }
  get branch_id() { return this.leadsFeedbackForm.get("branch_id"); }
  get remarks() { return this.leadsFeedbackForm.get("remarks"); }
  get followup_date() { return this.leadsFeedbackForm.get("followup_date"); }

  // Based On FeedBack Status Add the Dynamic Validations
  changeFeedBackStatus(event) {
    this.resetDynamicValidations();
    if (event) {
      this.selectedReportType = event.key;
    } else {
      this.selectedReportType = "";
    }
    this.addDynamicFeedBackValidations();
  }

  // Reset All Dynamic Validations
  resetDynamicValidations() {

    this.leadsFeedbackForm.controls.appointment_date.setValidators(null);
    this.leadsFeedbackForm.controls.appointment_date.updateValueAndValidity();

    // this.leadsFeedbackForm.controls.appointment_time.setValidators(null);
    // this.leadsFeedbackForm.controls.appointment_time.updateValueAndValidity();

    this.leadsFeedbackForm.controls.branch_id.setValidators(null);
    this.leadsFeedbackForm.controls.branch_id.updateValueAndValidity();

    this.leadsFeedbackForm.controls.remarks.setValidators(null);
    this.leadsFeedbackForm.controls.remarks.updateValueAndValidity();

    this.leadsFeedbackForm.controls.followup_date.setValidators(null);
    this.leadsFeedbackForm.controls.followup_date.updateValueAndValidity();
  }

  // Based on the FeedBack Status add the Dynamic Validations
  addDynamicFeedBackValidations() {

    if (this.selectedReportType == 'Interest') {

      // If Selected ReportType is Intrest Make appointment_date, appointment_time, branch_id as required
      this.leadsFeedbackForm.controls.appointment_date.setValidators(Validators.required);
      this.leadsFeedbackForm.controls.appointment_date.updateValueAndValidity();

      // this.leadsFeedbackForm.controls.appointment_time.setValidators(Validators.required);
      // this.leadsFeedbackForm.controls.appointment_time.updateValueAndValidity();

      this.leadsFeedbackForm.controls.branch_id.setValidators(Validators.required);
      this.leadsFeedbackForm.controls.branch_id.updateValueAndValidity();
    } else if (this.selectedReportType == 'not_interested') {

      // If Selected ReportType is not_interested Make remarks as required
      this.leadsFeedbackForm.controls.remarks.setValidators(Validators.required);
      this.leadsFeedbackForm.controls.remarks.updateValueAndValidity();
    } else if (this.selectedReportType == 'followup_req') {

      // If Selected ReportType is followup_req Make followup_date as required
      this.leadsFeedbackForm.controls.followup_date.setValidators(Validators.required);
      this.leadsFeedbackForm.controls.followup_date.updateValueAndValidity();
    }
  }

  errorMessages = {
    status: [{ type: 'required', message: 'This field is required' }],
    appointment_date: [{ type: 'required', message: 'This field is required' }],
    // appointment_time: [{ type: 'required', message: 'This field is required' }],
    branch_id: [{ type: 'required', message: 'This field is required' }],
    remarks: [{ type: 'required', message: 'This field is required' }],
    followup_date: [{ type: 'required', message: 'This field is required' }],
  }

  @ViewChild("feedbackModal") feedbackModal: TemplateRef<any>;
  public modalRef: any;
  branches: any;
  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private _service: CallcenterService,
    private masterService: MasterDataService,
    private notifyService: NotificationService,) { }

  ngOnInit(): void {
    this.masterService.masterData('callCenterapi');
    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });
  }

  openModal(selectedLead) {
    this.selectedLead = selectedLead;
    this._service.feedback_log({ "id": selectedLead.id })
      .subscribe(res => {
        this.feedLog = res['data'];
      }, err => {

      });
    this.modalRef = this.modalService.open(this.feedbackModal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true,
      size: "lg"
    });
  }

  closeFeedbackForm() {
    this.leadsFeedbackForm.reset();
    this.modalService.dismissAll();
  }

  // Add Feedback 
  addFeedBack() {

    const invalid = [];
    const controls = this.leadsFeedbackForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    console.log(invalid, this.leadsFeedbackForm.value);

    this.leadsFeedbackForm.markAllAsTouched();

    if (this.leadsFeedbackForm.valid) {
      this.loading.next(true);
      this.leadsFeedbackForm.value.id = this.selectedLead.id;
      this._service.update_feedback(this.leadsFeedbackForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closeLeadForm();
            this.reloadLeads.emit('reload');
          } else if (res['status'] == 'errors') {
            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.leadsFeedbackForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  closeLeadForm() {
    this.leadsFeedbackForm.reset();
    this.modalService.dismissAll();
  }

    /* Get Foe Short Name */
    getShortName(name) {
      return name.split(' ').map(n => n[0]).join('');
    }
}
