import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { GmService } from '@gm/services/gm.service';
import { AgmService } from '../service/agm.service';

@Component({
  selector: 'app-sms-report',
  templateUrl: './sms-report.component.html',
  styleUrls: ['./sms-report.component.scss']
})
export class SmsReportComponent implements OnInit {

  isShown: boolean = false;
  dayStatus: any;
  currentDate = new Date();
  public filterLoading = new BehaviorSubject(false);
  selectedState: any;
  daywiseReportForm = this._formBuilder.group({
    fdate: [null, Validators.required],
  });
  stateWise: any;
  backendValidations: any;
  smsreport_total = [];
  report_type: any;
  get fdate() { return this.daywiseReportForm.get('fdate'); }

  dayWise = [
    {id:1, title:'Today Status', value:'to_day'},
    {id:2, title:'Yesterday Status', value:'yesterday'}
  ];

  day_status = false;

  errorMessages = {
    fdate: [{ type: 'required', message: 'This field is required' }],
  }
  public state_id: any;
  constructor(
    private notifyService: NotificationService,
    private _formBuilder: FormBuilder,
    private masterService: MasterDataService,
    private _service: GmService,
    private service: AgmService,
    ) { }

  ngOnInit(): void {
    this.getProfileDetails();
    // this.masterService.masterData('gmapi');
    // this.masterService.statesModel$.subscribe(val => {
    //   this.stateWise = val;
    // });
  }

  searchReport() {
    this.daywiseReportForm.markAllAsTouched();
    if (this.daywiseReportForm.valid) {
      this.daywiseReportForm.value.stateWiseReport = true;
      this.daywiseReportForm.value.state = this.state_id;
      this._service.dayStatusData(this.daywiseReportForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          let data = res['data'];
          this.dayStatus = data.dayStatus;
          this.smsreport_total = this.getTotal(this.dayStatus);
          this.report_type = this.daywiseReportForm.value.fdate;

          if (this.dayStatus) {
            this.day_status = true;
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  /* Select State */
  selectState(state) {
    this.selectedState = state.name;
  }

  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        ACQ: previousValue.ACQ + currentValue.ACQ,
        CAMP: previousValue.CAMP + currentValue.CAMP,
        CASH: previousValue.CASH + currentValue.CASH,
        CC: previousValue.CC + currentValue.CC,
        IB: previousValue.IB + currentValue.IB,
        OB: previousValue.OB + currentValue.OB,
        CC1: previousValue.CC1 + currentValue.CC1,
        CHQ: previousValue.CHQ + currentValue.CHQ,
        CHR: previousValue.CHR + currentValue.CHR,
        CON: previousValue.CON + currentValue.CON,
        DNP: previousValue.DNP + currentValue.DNP,
        EMI: previousValue.EMI + currentValue.EMI,
        EXP: previousValue.EXP + currentValue.EXP,
        FNP: previousValue.FNP + currentValue.FNP,
        GT: previousValue.GT + currentValue.GT,
        OC: previousValue.OC + currentValue.OC,
        OL: previousValue.OL + currentValue.OL,
        ONLINE: previousValue.ONLINE + currentValue.ONLINE,
        REF: previousValue.REF + currentValue.REF,
        REG: previousValue.REG + currentValue.REG,
        RGL: previousValue.RGL + currentValue.RGL,
        RMP: previousValue.RMP + currentValue.RMP,
        WK: previousValue.WK + currentValue.WK,
        closing: previousValue.closing + currentValue.closing,
        sent: previousValue.sent + currentValue.sent,
        open: previousValue.open + currentValue.open,
      }
    });
  }
  getProfileDetails(){
    this.service.getProfileDetails().subscribe(res => {
      console.log(res['state_id']);
     this.state_id = res['state_id'];
    }, err => {
      console.log(err.error);
    });
  }
}
