import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { MasterDataService } from '@services/masterData/master-data.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuditService } from '../service/audit.service';

@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.scss']
})
export class CallingComponent implements OnInit {
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  public mySearchControl = new FormControl();

  public filterForm = this._formBuilder.group({
    freport_type: [null, Validators.required],
    fdate_range: [],
    branch_id: '',
    perpage: this.basicSelectedOption,
    page: 1
  });
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  search_text: any;
  branches: any;
  fromDate: any;
  toDate: any;
  tableData: any;
  tableKeys: any;
  apTableData: any;
  tsTableData: any;
  kaTableData: any;
  totalRow = [];
  selectedReport: any;
  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;
  reportTypes = [
    { id: 1, report: 'Summary Report', key: 'summary_report'},
    { id: 2, report: 'NEW PATIENT BILLING LIST', key: 'new_patient_list'},
    { id: 3, report: 'OLD PATIENT BILLING LIST', key: 'old_patient_list'},
    { id: 4, report: 'OLD PATIENT MEDICINE LIST', key: 'patient_medicine_reports'},
  ];
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  patient_details: any;
  a_patient_billings: any;
  a_patient_details: any;
  smsreport_total:any;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: AuditService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private masterService: MasterDataService,
    private _router: Router
  ) { }

  get freport_type() { return this.filterForm.get('freport_type'); }
  get fdate_range() { return this.filterForm.get('fdate_range'); }
  get branch_id() { return this.filterForm.get('branch_id'); }

  errorMessages = {
    freport_type: [{ type: 'required', message: 'The Report Type field is required' }],
    fdate_range: [{ type: 'required', message: 'The Date Range is required' }],
    branch_id: [{ type: 'required', message: 'Please select branch' }]
  }

  ngOnInit(): void {
    this.masterService.masterData('gmapi');

    this.masterService.branchModel$.subscribe(val => {
      this.branches = val;
    });

    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {    
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.submitForm();
    });
  }
  submitForm() {
    let api = this.filterForm.value.freport_type.key;

    // if(api != 'summary_report'){
    this._service.docWiseReport(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe((res: any[]) => {
        let data = res['data'];
        this.tableData = null;

        this.selectedReport = this.filterForm.value.freport_type;
        if (this.selectedReport.key == 'summary_report') {
          this.tableData = data;
        }
        if (this.selectedReport.key == 'new_patient_list') {
          this.tableData = data;
        }
        if (this.selectedReport.key == 'old_patient_list') {
          this.tableData = data;
        }
        if (this.selectedReport.key == 'patient_medicine_reports') {
          this.tableData = data;
        }
        
        
      });
    // }
  }


  applyFilter() {
    this.filterLoading.next(true);
    this.submitForm();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.submitForm();
  }
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.submitForm();
  }
  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.submitForm();
  }
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: "range",
  };

  /* On Date Select */
  onDateSelect() {
    console.log("date");

    let dateRange = this.filterForm.value.fdate_range;
    console.log(this.filterForm.value.fdate_range);
    if (dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
    }
  }
getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        
      }
    });
}

caseSheet(patientId) {
  let link = environment.subdirUrl + '/audit/home/case-sheet/' + patientId;
  this._router.navigate([]).then(result => { window.open(link, '_blank'); });
}

modalOpen(modal) {
  this.modalService.open(modal, {
    windowClass: "modal",
    backdrop: 'static',
    centered: true,
  });
}
}
