import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class AgmService {
  constructor(protected httpClient: AppHttpClient) { }
 /* authentication apis */
 login(credentials: object) {
  return this.httpClient.post('agmapi/login', credentials);
}
getProfileDetails() {
  return this.httpClient.get('agmapi/me');
}


docWiseReport(api: string, payload: object) {
  return this.httpClient.post('agmapi/' + api, payload);
}
doctorsDailyReports(payload: object) {
  return this.httpClient.post('agmapi/doctor_report/doctor-daily-reports', payload);
}

submitFeedBackForm(payload: object) {
  return this.httpClient.post('agmapi/add_patient_feedback', payload);
}
}
