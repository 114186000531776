<!-- Renewal Drop List Data -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">
                        <a (click)="goBack()"><span><i data-feather="arrow-left" class="font-medium-2"></i></span></a>
                        Restore List</h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input type="text" name="" id="" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                    <button type="reset" class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="renewalDropListData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="p_id" [width]="180">
                <ng-template ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="patientIDmodal.openModal(modal)">TS08-1648990289</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="bp_id" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="p_name" [cellClass]="'word-span-el'" [width]="200"></ngx-datatable-column>
            <ngx-datatable-column name="Total amount" prop="total_amt" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Due amount" prop="due_amt" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="duration" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Last visiting date" prop="last_visiting_date" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="" [sortable]="false">
                <ng-template ngx-datatable-cell-template>
                    <div class="d-flex align-items-center">
                        <a class="badge badge-light-danger"
                            ngbTooltip="Restore"
                            tooltipClass="fadeInAnimation"
                            (click)="restorePatientDetails()">
                            <span>
                                <i data-feather='rotate-ccw' class="font-small-4">
                                </i>
                            </span>
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-offset="offset"
              >
                <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)" [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
               </datatable-pager>
              </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Renewal Drop List Data -->
