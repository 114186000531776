import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'environments/environment';

import { FoeService } from '@foe/services/foe.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  file_path: any;
  roles_responsibilities: any;
  constructor(private modalService: NgbModal,
    private masterService: MasterDataService,
    private notifyService: NotificationService,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getFoeRoles();
  }

  /* Get Foe Roles and responsibities */
  getFoeRoles() {
    this.masterService.getRoles('foeapi')
      .subscribe(res => {
        if (res['status'] == 'success') {
          this.roles_responsibilities = res['data'];
        } else if (res['status'] == 'error') {
          this.notifyService.showError(res['message'], res['title']);
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  openPdf(modal) {
    this.file_path = this.sanitizer.bypassSecurityTrustResourceUrl(environment.apiUrl + '/' + this.roles_responsibilities.document);
    console.log(this.file_path);
    this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      size: 'xl',
      backdrop: 'static'
    });
  }
}