import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit {

  patient_information: any;
  public search_text: any;
  public basicSelectedOption: number = 500;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  clinical_ob: any;

  patientFilterForm = this._formBuilder.group({
    search_field: [null, Validators.required],
    keyword: [null, Validators.required],
    perpage: this.basicSelectedOption,
    page: 1
  });
  isShown: boolean = false;

  get search_field() { return this.patientFilterForm.get('search_field'); }
  get keyword() { return this.patientFilterForm.get('keyword'); }

  errorMessages = {
    search_field: [{ type: 'required', message: 'The Search Type field is required' }],
    keyword: [{ type: 'required', message: 'The Keyword field is required' }],
  }

  showContent() {
    this.isShown = true;
  }

  public searchType = [
    { id: 1, title: 'Patient ID', value: 'patient_unique_id' },
    { id: 2, title: 'Patient Name', value: 'patient_name' },
    // { id: 3, title: 'Mobile', value: 'mobile' },
    { id: 4, title: 'BPID', value: 'branch_patient_id' }
  ];

  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  public allPatientsData: any[] = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private _router: Router,
    private _service: DocService) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
  }

  /* Get Renewal Calls Listing */
  serarchPatient() {
    this.patientFilterForm.markAllAsTouched();
    if (this.patientFilterForm.valid) {
      this.filterLoading.next(true);
      this.patientFilterForm.value.perpage = this.basicSelectedOption;

      this._service.patientsListing(this.patientFilterForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          let data = res['data'];
          this.isShown = true;
          this.allPatientsData = data['searchPatient'];
          this.totalCount = data['total'];
          this.curPage = data['page'];
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.serarchPatient();
  }

  /* Reset Filter */
  resetFilter() {
    this.isShown = false;
    this.patientFilterForm.reset();
    this.serarchPatient();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.patientFilterForm.value.perpage = this.basicSelectedOption;
    this.patientFilterForm.value.page = 1;
    this.serarchPatient();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.patientFilterForm.value.page = $event.page;
    this.serarchPatient();
  }

  /* Redirect CaseSheet */
  caseSheetUpdate(patientId) {
    let link = environment.subdirUrl + '/doctor/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  clinicalOBModal(modal, patientId) {
    this._service.clinicalOB(patientId)
      .subscribe(res => {
        this.clinical_ob = res['data']['clinicalOB'];
        this.modalService.open(modal, {
          windowClass: "modal",
          centered: true,
          size: 'xl'
        });
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }
}
