import { CoreMenu } from "@core/types";

export const agmMenu: CoreMenu[] = [
    {
        id: "home",
        title: "Home",
        translate: "MENU.HOME",
        type: "item",
        icon: "home",
        url: "hm",
      },
    {
        id: "target",
        type: "section",
        title: "Target",
        translate: "",
        icon: "file",
        children: [
            { id: "1", title: "Target", type: "item", icon: "disc", url: "target" },
            { id: "2", title: "Target NP", type: "item", icon: "disc", url: "target-np" },
            { id: "3", title: "Status", type: "item", icon: "disc", url: "status" },
            { id: "4", title: "Doctor Wise Target", type: "item", icon: "disc", url: "doc-wise" },
            { id: "5", title: "Sms Report", type: "item", icon: "disc", url: "sms-report" },
            { id: "6", title: "Month Report", type: "item", icon: "disc", url: "month-report" },
          ],
    },
    {
        id: "report",
        type: "section",
        title: "Report",
        translate: "",
        icon: "file",
        children: [
            { id: "1", title: "Branch", type: "item", icon: "disc", url: "branch_wise" },
            { id: "2", title: "Region", type: "item", icon: "disc", url: "region_wise" },
          ],
    },
    {
        id: "search",
        title: "Search",
        type: "section",
        icon: "search",
        children: [
          { id: "1", title: "Employee", type: "item", icon: "disc", url: "search/employees" },
          { id: "2", title: "Patients", type: "item", icon: "disc", url: "search/patients" },
        ],
      },
];
