<!-- Feedback Modal -->
<ng-template #feedbackModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1"></h4>
        <button type="button" class="close" (click)="closeFeedbackForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body pb-0 pt-0 px-1" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="text-center my-1">
                    <h4 class="modal-title">Feedbacks Log</h4>
                </div>
                <hr>
                <div class="table__data__overflow">
                    <div class="bt_modal_scroll mt-50 pr-1">
                        <div class="comments-inner-container">
                            <ul class="pl-0">
                                <li class="comment-object" *ngFor="let feed of feedLog">
                                    <div class="avatar bg-light-secondary border-dark">
                                        <div class="avatar-content text-uppercase">{{ getShortName(feed.name) }}</div>
                                    </div>
                                    <div class="comment-text">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <h6 class="username text-dark font-weight-bolder">{{ feed.name }}</h6>
                                                <p class="user__status mb-0">Status &nbsp;:&nbsp;<span
                                                        class="badge badge-pill" [ngClass]="{
                                                        'badge-light-success': feed.status == '1',
                                                        'badge-light-danger': feed.status == '2',
                                                        'badge-light-warning': feed.status == '3'}">{{ feed.status == 1
                                                        ? "Interested for an
                                                        Appointment":
                                                        feed.status == 2 ? "Not Interested":
                                                        feed.status == 3 ? "Followup required":"-"}}</span></p>
                                            </div>
                                            <div>
                                                <div class="timeline-event-time">{{ feed.created_at | date:"dd-MM-yyy
                                                    hh:mm:ss"}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <!-- 
                                <li class="comment-object">
                                    <div class="avatar bg-light-secondary border-dark"><div class="avatar-content text-uppercase">ss</div></div>
                                    <div class="comment-text">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <h6 class="username text-dark font-weight-bolder">Shiva Shankara Vara Prasad</h6>
                                                <p class="user__status mb-0">Status&nbsp;:&nbsp;<span
                                                        class="badge badge-light-danger">Not Interested</span></p>
                                            </div>
                                            <div>
                                                <div class="timeline-event-time">11-05-2022</div>
                                                <div class="timeline-event-time">04:18 PM</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="comment-object">
                                    <div class="avatar bg-light-secondary border-dark"><div class="avatar-content text-uppercase">jn</div></div>
                                    <div class="comment-text">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <h6 class="username text-dark font-weight-bolder">Jagadheesh Naidu</h6>
                                                <p class="user__status mb-0">Status&nbsp;:&nbsp;<span
                                                    class="badge badge-light-success">Interested for an Appointment</span></p>
                                            </div>
                                            <div>
                                                <div class="timeline-event-time">11-05-2022</div>
                                                <div class="timeline-event-time">04:18 PM</div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="comment-object">
                                    <div class="avatar bg-light-secondary border-dark"><div class="avatar-content text-uppercase">as</div></div>
                                    <div class="comment-text">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <h6 class="username text-dark font-weight-bolder">Anirudh Sharma</h6>
                                                <p class="user__status mb-0">Status&nbsp;:&nbsp;<span
                                                        class="badge badge-light-warning">Followup Required</span></p>
                                            </div>
                                            <div>
                                                <div class="timeline-event-time">05-04-2022</div>
                                                <div class="timeline-event-time">06:48 PM</div>
                                            </div>
                                        </div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 bg-light">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center my-1">
                            <h4 class="modal-title">Submit Feedback</h4>
                        </div>
                        <hr>
                        <div class="mt-1">
                            <form [formGroup]="leadsFeedbackForm" (ngSubmit)="addFeedBack()">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Feedback</label>
                                            <ng-select [items]="fbstatus" bindValue="value" bindLabel="type"
                                                placeholder="Select" formControlName="status"
                                                (change)="changeFeedBackStatus($event)"
                                                [ngClass]="{ 'is-invalid error': errorMessages.status && status.errors && status.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.status">
                                                <ng-container *ngIf="status.hasError(error.type) && (status.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.status.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['status']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" *ngIf="selectedReportType == 'Interest'">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Date of appointment</label>
                                            <input formControlName="appointment_date" type="date" class="form-control"
                                                [ngClass]="{ 'is-invalid error': errorMessages.appointment_date && appointment_date.errors && appointment_date.touched }">
                                            <div *ngFor="let error of errorMessages.appointment_date">
                                                <ng-container
                                                    *ngIf="appointment_date.hasError(error.type) && (appointment_date.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.appointment_date.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['appointment_date']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Time of appointment</label>
                                            <input  type="time" formControlName="appointment_time" type="text" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.appointment_time && appointment_time.errors && appointment_time.touched }"
                                                >
                                                <div *ngFor="let error of errorMessages.appointment_time">
                                                    <ng-container
                                                        *ngIf="appointment_time.hasError(error.type) && (appointment_time.touched)">
                                                        <small class="text-danger">{{error.message}}</small>
                                                    </ng-container>
                                                </div>
                                                <div
                                                    *ngIf="leadsFeedbackForm.controls.appointment_time.hasError('serverValidationError')">
                                                    <small class="text-danger">{{
                                                        backendValidations['appointment_time']
                                                        }}</small>
                                                </div>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Branch</label>
                                            <ng-select formControlName="branch_id" [items]="branches" bindLabel="name"
                                                bindValue="id" placeholder="Select"
                                                [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.branch_id">
                                                <ng-container
                                                    *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.branch_id.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['branch_id']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="selectedReportType == 'not_interested'">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Reason</label>
                                            <textarea formControlName="remarks" rows="3" class="form-control"
                                                [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }"></textarea>
                                            <div *ngFor="let error of errorMessages.remarks">
                                                <ng-container *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.remarks.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['remarks']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="selectedReportType == 'followup_req'">
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Remark</label>
                                            <textarea formControlName="remarks" rows="3" class="form-control"
                                                [ngClass]="{ 'is-invalid error': errorMessages.remarks && remarks.errors && remarks.touched }"></textarea>
                                            <div *ngFor="let error of errorMessages.remarks">
                                                <ng-container *ngIf="remarks.hasError(error.type) && (remarks.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.remarks.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['remarks']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-12">
                                        <div class="form-group">
                                            <label for="">Date of followup</label>
                                            <input type="date" class="form-control" formControlName="followup_date">
                                            <div *ngFor="let error of errorMessages.followup_date">
                                                <ng-container
                                                    *ngIf="followup_date.hasError(error.type) && (followup_date.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                            <div
                                                *ngIf="leadsFeedbackForm.controls.followup_date.hasError('serverValidationError')">
                                                <small class="text-danger">{{
                                                    backendValidations['followup_date']
                                                    }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="selectedReportType" class="col-12 text-center">
                                    <button [disabled]="loading | async" type="submit" class="btn btn-primary">
                                        <span *ngIf="loading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button *ngIf="!selectedEmployeId" type="reset"
                                        class="btn btn-secondary ml-1">Reset</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--/ Feedback Modal -->