import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NotificationService } from '@core/services/notification.service';
import { GmService } from '@gm/services/gm.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormControl } from "@angular/forms";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { AgmService } from '../service/agm.service';

@Component({
  selector: 'app-region-wise',
  templateUrl: './region-wise.component.html',
  styleUrls: ['./region-wise.component.scss']
})
export class RegionWiseComponent implements OnInit {
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  public filterForm = this._formBuilder.group({
    freport_type: [null, Validators.required],
    fdate_range: [],
  });
  fromDate: any;
  toDate: any;
  search_text: any;
  date:any;
  tableData: any;
  tableKeys: any;
  apTableData: any;
  tsTableData: any;
  kaTableData: any;
  totalRow = [];
  selectedReport: any;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  state_id: any;
  public ColumnMode = ColumnMode;
  public dcrListingData: any[] = [
    { p_name: 'Hemalatha', p_type: 'Old Patient', reg_no: 'AP03-007853', cash: '0', cc: '0', cc_bet: '-', online: '5000', on_det: '50200001852741 Hdfc paytm', cheque: '0', cheque_det: '-', rt_no: 'P3.1654151760', bal: '0', wpo: '-', dom: '30 d' },
    { p_name: 'Krishna Rao', p_type: 'New Patient', reg_no: 'AP03-007853', cash: '0', cc: '0', cc_bet: '-', online: '5000', on_det: '50200001852741 Hdfc paytm', cheque: '0', cheque_det: '-', rt_no: 'P3.1654151760', bal: '0', wpo: '-', dom: '30 d' },
  ];
  public cashParticularsData: any[] = [
    { details: 'Cash', open_bal: '0', receipts: '2800', receipts_transfer: '0', receipts_self: '0', total: '2800', spent: '1426', other_revenue: '0', closing_bal: '1374', chq_replace: 'No Replace' },
    { details: 'Cheques', open_bal: '0', receipts: '2800', receipts_transfer: '0', receipts_self: '0', total: '2800', spent: '1426', other_revenue: '0', closing_bal: '1374', chq_replace: 'No Replace' },
    { details: 'CC', open_bal: '0', receipts: '2800', receipts_transfer: '0', receipts_self: '0', total: '2800', spent: '1426', other_revenue: '0', closing_bal: '1374', chq_replace: 'No Replace' },
    { details: 'EMI', open_bal: '0', receipts: '2800', receipts_transfer: '0', receipts_self: '0', total: '2800', spent: '1426', other_revenue: '0', closing_bal: '1374', chq_replace: 'No Replace' },
    { details: 'Online', open_bal: '0', receipts: '2800', receipts_transfer: '0', receipts_self: '0', total: '2800', spent: '1426', other_revenue: '0', closing_bal: '1374', chq_replace: 'No Replace' },
  ];
 

  reportTypes = [
    { id: 3, report: 'BR WISE DUE-REN-OP-NP', key: 'due_ren_op_np' },
    { id: 7, report: 'Doctor WISE DUE-REN-OP-NP', key: 'doc_due_ren_op_np' },
    { id: 5, report: 'RPR Report', key: 'rpr_report' },
    { id: 22, report: 'RENEWAL TARGET', key: 'doc_renewal_target'},
    { id: 25, report: 'REFFERAL TARGET', key: 'doc_referral_target'},
    { id: 68, report: 'OLD BILLING STATUS', key:'old_billing_status'},
  ];
 


  patient_details: any;
  a_patient_billings: any;
  a_patient_details: any;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;
  constructor(
    private _formBuilder: FormBuilder,
    private _service: GmService,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    private _router: Router,
    private service: AgmService

  ) { }


  get freport_type() { return this.filterForm.get('freport_type'); }

  errorMessages = {
    freport_type: [{ type: 'required', message: 'The Report Type field is required' }]
  }

  ngOnInit(): void {
    this.getProfileDetails();
    this.mySearchControl.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()
    ).subscribe(val => {    
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.filterForm.value.fsearch_text = this.search_text;
      this.submitForm();
    });
  }

  submitForm() {
    let api = this.filterForm.value.freport_type.key;
    this.filterForm.value.state_id = this.state_id;

    this._service.regionWiseReports(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe((res: any[]) => {
        let data = res['data'];
        this.tableData = null;

        this.selectedReport = this.filterForm.value.freport_type;

        if (this.selectedReport.key == 'due_ren_op_np') {
          this.apTableData = null;
          this.tsTableData = null;
          this.kaTableData = null;
          this.totalRow = [];
          if (this.state_id == 1) {
            this.apTableData = data[1];
            this.totalRow = this.getTotal(this.apTableData);
          } else if (this.state_id == 2) {
            this.tsTableData = data[2];
            this.totalRow = this.getTotal(this.tsTableData);
          } else if (this.state_id == 3) {
            this.kaTableData = data[3];
            this.totalRow = this.getTotal(this.kaTableData);
          }
        } else if (this.selectedReport.key == 'doc_due_ren_op_np') {
          this.tableData = data;
          this.totalRow = this.getTotal(this.tableData);
        } else if (this.selectedReport.key == 'rpr_report') {
          this.tableData = data;
          this.totalRow = this.getTotal(this.tableData);
        } 
        else if (this.selectedReport.key == 'doc_renewal_target') {
          this.date = this.filterForm.value.fdate_range, 
          this.tableData = data;
          this.totalRow = this.getrenewalTotal(this.tableData);

        }
        else if (this.selectedReport.key == 'doc_referral_target') {
          this.date = this.filterForm.value.fdate_range, 
          this.tableData = data;
          this.totalRow = this.getrenewalTotal(this.tableData);

        }else if (this.selectedReport.key == 'old_billing_status') {
          this.date = this.filterForm.value.fdate_range, 
          this.tableData = data;
        }else if (this.selectedReport.key == 'new_patient_list') {
          this.date = this.filterForm.value.fdate_range, 
          this.tableData = data['branchWisePatients'];
          this.totalCount = data['total'];
         this.curPage = data['page'];
        }
      });
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.submitForm();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.submitForm();
  }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: "range",
  };

  /* On Date Select */
  onDateSelect() {
    console.log("date");

    let dateRange = this.filterForm.value.fdate_range;
    console.log(this.filterForm.value.fdate_range);
    if (dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
    }
  }

  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        fnp: previousValue.fnp + currentValue.fnp,
        reg: previousValue.reg + currentValue.reg,
        acq: previousValue.acq + currentValue.acq,
        np_coll: previousValue.np_coll + currentValue.np_coll,
        ren_coll: previousValue.ren_coll + currentValue.ren_coll,
        due_coll: previousValue.due_coll + currentValue.due_coll,
        op_coll: previousValue.op_coll + currentValue.op_coll,
        total_gt: previousValue.total_gt + currentValue.total_gt,
        op_count: previousValue.op_count + currentValue.op_count,
        ref_count: previousValue.ref_count + currentValue.ref_count,
        ren_total: previousValue.ren_total + currentValue.ren_total,
        ren_count: previousValue.ren_count + currentValue.ren_count,
        ren_pending: previousValue.ren_pending + currentValue.ren_pending,
        ren_count_5000: previousValue.ren_count_5000 + currentValue.ren_count_5000,
        state_id: currentValue.state_id,

        target: previousValue.target + currentValue.target,
        emi: previousValue.emi + currentValue.emi,
        achieved: previousValue.achieved + currentValue.achieved,
        per: previousValue.per + currentValue.per,

        //RPR report
        tnpr: previousValue.tnpr + currentValue.tnpr,
        rpv: previousValue.rpv + currentValue.rpv,
        rgl: previousValue.rgl + currentValue.rgl,
        rpamt_rgl: previousValue.rpamt_rgl + currentValue.rpamt_rgl,
        rpaid_rgl: previousValue.rpaid_rgl + currentValue.rpaid_rgl,
        rdue_rgl: previousValue.rdue_rgl + currentValue.rdue_rgl,
        rpamt_reg: previousValue.rpamt_reg + currentValue.rpamt_reg,
        rpaid_reg: previousValue.rpaid_reg + currentValue.rpaid_reg,
        rdue_reg: previousValue.rdue_reg + currentValue.rdue_reg,
        rpr: previousValue.rpr + currentValue.rpr,
        rpamt: previousValue.rpamt + currentValue.rpamt,
        rpaid: previousValue.rpaid + currentValue.rpaid,
        rdue: previousValue.rdue + currentValue.rdue,
        rpp: previousValue.rpp + currentValue.rpp,

        //source total

        NP: previousValue.NP + currentValue.NP, 
        PAK: previousValue.PAK + currentValue.PAK, 
        CCR: previousValue.CCR + currentValue.CCR, 
        TCCR: previousValue.TCCR + currentValue.TCCR, 
        OLD1: previousValue.OLD1 + currentValue.OLD1, 
        DUE1: previousValue.DUE1 + currentValue.DUE1, 
        PAK1: previousValue.PAK1 + currentValue.PAK1, 
        WKR: previousValue.WKR + currentValue.WKR, 
        TWKR: previousValue.TWKR + currentValue.TWKR, 
        OLD2: previousValue.OLD2 + currentValue.OLD2, 
        DUE2: previousValue.DUE2 + currentValue.DUE2, 
        PAK2: previousValue.PAK2 + currentValue.PAK2, 
        REFR: previousValue.REFR + currentValue.REFR, 
        TREFR: previousValue.TREFR + currentValue.TREFR, 
        OLD3: previousValue.OLD3 + currentValue.OLD3, 
        DUE3: previousValue.DUE3 + currentValue.DUE3, 
        PAK3: previousValue.PAK3 + currentValue.PAK3, 
        RMPR: previousValue.RMPR + currentValue.RMPR, 
        TRMPR: previousValue.TRMPR + currentValue.TRMPR, 
        OLD4: previousValue.OLD4 + currentValue.OLD4, 
        DUE4: previousValue.DUE4 + currentValue.DUE4, 
        PAK4: previousValue.PAK4 + currentValue.PAK4, 
        CAR: previousValue.CAR + currentValue.CAR, 
        TCAR: previousValue.TCAR + currentValue.TCAR, 
        OLD5: previousValue.OLD5 + currentValue.OLD5, 
        DUE5: previousValue.DUE5 + currentValue.DUE5, 
        PAK5: previousValue.PAK5 + currentValue.PAK5, 
        OLR: previousValue.OLR + currentValue.OLR, 
        TOLR: previousValue.TOLR + currentValue.TOLR, 
        OLD6: previousValue.OLD6 + currentValue.OLD6, 
        DUE6: previousValue.DUE6 + currentValue.DUE6, 

        count: previousValue.count + currentValue.count,

        total_doc: previousValue.total_doc + currentValue.total_doc,
        male: previousValue.male + currentValue.male,
        female: previousValue.female + currentValue.female,
      }
    });
  }


  getTotalcollection(data){
    return data.reduce(function (previousValue, currentValue) {
      return {
        NP: previousValue.NP + currentValue.NP,
        ACHIEVED: previousValue.ACHIEVED + currentValue.ACHIEVED,
        PAK: previousValue.PAK + currentValue.PAK,
        RENPAID: previousValue.RENPAID + currentValue.RENPAID,
        PAID: previousValue.PAID + currentValue.PAID,
        OLDCOLL: previousValue.OLDCOLL + currentValue.OLDCOLL,
        TILLPAID: previousValue.TILLPAID + currentValue.TILLPAID,
        OLD: previousValue.OLD + currentValue.OLD,
        DUE: previousValue.DUE + currentValue.DUE,
      }
    });    
  }

  getstatusTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {
        NP : previousValue.NP + currentValue.NP,
        IB : previousValue.IB + currentValue.IB,
        OC : previousValue.OC + currentValue.OC,
        REG : previousValue.REG + currentValue.REG,
        PACK : previousValue.PACK + currentValue.PACK,
        IBR : previousValue.IBR + currentValue.IBR,
        TIBR : previousValue.TIBR + currentValue.TIBR,
        OLD : previousValue.OLD + currentValue.OLD,
        DUE : previousValue.DUE + currentValue.DUE,
        ACQ : previousValue.ACQ + currentValue.ACQ,
        R2M6M : previousValue.R2M6M + currentValue.R2M6M,
        less6M : previousValue.less6M + currentValue.less6M,
        R6M : previousValue.R6M + currentValue.R6M,
        R7M12M : previousValue.R7M12M + currentValue.R7M12M,
        lees12M : previousValue.lees12M + currentValue.lees12M,
        R12M : previousValue.R12M + currentValue.R12M,
        R13M18M : previousValue.R13M18M + currentValue.R13M18M,
        less18M : previousValue.less18M + currentValue.less18M,
        R18M : previousValue.R18M + currentValue.R18M,
        R19M23M : previousValue.R19M23M + currentValue.R19M23M,
        less24M : previousValue.less24M + currentValue.less24M,
        R24M : previousValue.R24M + currentValue.R24M,
        R25M36M : previousValue.R25M36M + currentValue.R25M36M,
        less36M : previousValue.less36M + currentValue.less36M,
        R36M : previousValue.R36M + currentValue.R36M,
        R37M48M : previousValue.R37M48M + currentValue.R37M48M,
        less48M : previousValue.less48M + currentValue.less48M,
        R48M : previousValue.R48M + currentValue.R48M,
        R49M60M : previousValue.R49M60M + currentValue.R49M60M,
        less60M : previousValue.less60M + currentValue.less60M,
        R60M : previousValue.R60M + currentValue.R60M,
      }
    });    
  }
  getHigherTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {
        twentytwo: previousValue.twentytwo + currentValue.twentytwo,
        thirtytwo: previousValue.thirtytwo + currentValue.thirtytwo,
        Above40: previousValue.Above40 + currentValue.Above40,
        TOTAL: previousValue.TOTAL + currentValue.TOTAL,
      }
    });    
  }
  getrenewalTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {

        g_ren:previousValue.g_ren + currentValue.g_ren,
        ren:previousValue.ren + currentValue.ren,
        pack:previousValue.pack + currentValue.pack,
        paid:previousValue.paid + currentValue.paid,
        target:previousValue.target + currentValue.target,
        // per:previousValue.per + currentValue.per,
        incentives:previousValue.incentives + currentValue.incentives,
       
      }
    });    
  }
  getpaidauditTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {

        received_amount:previousValue.received_amount + currentValue.received_amount,
       
      }
    });    
  }
  getdoctorTotal(data){
    return data.reduce(function (previousValue, currentValue) {
      return {
        NP: previousValue.NP + currentValue.NP,
        REG1:previousValue.REG1 + currentValue.REG1,
        CC: previousValue.CC + currentValue.CC,
        REG2: previousValue.REG2 + currentValue.REG2,
        PK1: previousValue.PK1 + currentValue.PK1,
        CR: previousValue.CR + currentValue.CR,
        WK: previousValue.WK + currentValue.WK,
        REG3: previousValue.REG3 + currentValue.REG3,
        PK2: previousValue.PK2 + currentValue.PK2,
        WR: previousValue.WR + currentValue.WR,
        REF: previousValue.REF + currentValue.REF,
        REG4: previousValue.REG4 + currentValue.REG4,
        PK3: previousValue.PK3 + currentValue.PK3,
        RR: previousValue.RR + currentValue.RR,
        RMP: previousValue.RMP + currentValue.RMP,
        REG5: previousValue.REG5 + currentValue.REG5,
        PK4: previousValue.PK4 + currentValue.PK4,
        RPR: previousValue.RPR + currentValue.RPR,
        CAMP: previousValue.CAMP + currentValue.CAMP,
        REG6: previousValue.REG6 + currentValue.REG6,
        PK5: previousValue.PK5 + currentValue.PK5,
        CMR: previousValue.CMR + currentValue.CMR,
        OL: previousValue.OL + currentValue.OL,
        REG7: previousValue.REG7 + currentValue.REG7,
        PK6: previousValue.PK6 + currentValue.PK6,
        OLR: previousValue.OLR + currentValue.OLR,
        OP: previousValue.OP + currentValue.OP,
        REN: previousValue.REN + currentValue.REN,
        RENR: previousValue.RENR + currentValue.RENR,
      }
    });    
  }

  openAchievedModal(achievedModal, fbranch_id = null, fstate = null, type = null) {
    let obj = {};

    if (fbranch_id) {
      obj['fbranch_id'] = fbranch_id;
    }
    if (fstate) {
      obj['fstate'] = fstate;
    }
    if (type) {
      obj['type'] = type;
    }
    obj['fdate_range'] = this.filterForm.value.fdate_range;

    this._service.getAchievedModalContent(obj)
      .subscribe(res => {
        let data = res['data'];
        let achievedUpto = data['achievedUpto'];
        if (achievedUpto) {
          this.a_patient_billings = achievedUpto['patient_billings'];
          this.a_patient_details = achievedUpto['patient_details'];
          // if (this.a_patient_billings && this.a_patient_details) {
          this.modalService.open(achievedModal, {
            centered: true,
            size: 'xl'
          });
          // }
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  openModal(modal, api, fdoctor_id = null, fbranch_id = null, fstate = null, fstatus = null) {
    let obj = {};
    if (fdoctor_id) {
      obj['fdoctor_id'] = fdoctor_id;
    }
    if (fbranch_id) {
      obj['fbranch_id'] = fbranch_id;
    }
    if (fstate) {
      obj['fstate'] = fstate;
    }
    if (fstatus) {
      obj['fstatus'] = fstatus;
    }

    obj['fdate_range'] = this.filterForm.value.fdate_range;
    if (fstatus == "Renewal") {
      this._service.getRenPatientList(obj)
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.patient_details = res['data'];
            this.modalService.open(modal, {
              centered: true,
              size: 'xl'
            });
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    } else {
      this._service.getRenPendingPatientList(obj)
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.patient_details = res['data'];
            this.modalService.open(modal, {
              centered: true,
              size: 'xl'
            });
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }

  }


  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.submitForm();
  }
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.submitForm();
  }
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/agm/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => { window.open(link, '_blank'); });
  }
  getProfileDetails(){
    this.service.getProfileDetails().subscribe(res => {
      console.log(res['state_id']);
     this.state_id = res['state_id'];
    }, err => {
      console.log(err.error);
    });
  }

}
