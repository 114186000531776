import { Component, OnInit } from '@angular/core';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private _coreLoadingScreenService: CoreLoadingScreenService,
    private masterService: MasterDataService
  ) {}

  ngOnInit(): void {
    // this.masterService.masterData();
    
  }
}
