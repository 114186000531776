import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss']
})
export class EditPatientComponent implements OnInit {

  public modalRef: any;
  selectedPatient: any;
  feedbackEditForm: FormGroup = this._formBuilder.group({
    comingdate: ["", Validators.required],
    calldate: ["", Validators.required],
    status: [null, Validators.required],
    appointmentdate: ["", Validators.required],
    remarks: [
      "",
      [
        Validators.required,
        Validators.pattern("\\s?\\S+(?: \\S+)*\\s?"),
        Validators.maxLength(250),
      ],
    ],
  });

  get comingdate() {
    return this.feedbackEditForm.get("comingdate");
  }
  get calldate() {
    return this.feedbackEditForm.get("calldate");
  }
  get status() {
    return this.feedbackEditForm.get("status");
  }
  get appointmentdate() {
    return this.feedbackEditForm.get("appointmentdate");
  }
  get remarks() {
    return this.feedbackEditForm.get("remarks");
  }

  @ViewChild("editPatientModal") editPatientModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private _service: DocService) { }
  
  ngOnInit(): void {
  }
  
  submitfbEditForm() {
    this.feedbackEditForm.markAllAsTouched();
  }

  closefbEditForm() {
    this.feedbackEditForm.reset();
    this.modalService.dismissAll();
  }

  /* Open FeedBack Modal */
  openModal(row) {
    this.selectedPatient = row;
    console.log(this.selectedPatient);
    // console.log(this.selectedPatientFeedback);
    // this.purpose = purpose;
    this.modalRef = this.modalService.open(this.editPatientModal, {
      windowClass: "modal",
      backdrop:'static',
      centered: true,
    });
  }
}
