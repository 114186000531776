import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CallcenterRoutingModule } from './callcenter-routing.module';
import { CallcenterComponent } from './callcenter.component';
import { LoginComponent } from './auth/login/login.component';
import { CallcenterGuard } from './auth/callcenter-guard/callcenter.guard';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { CallcenterHomeComponent } from './callcenter-home/callcenter-home.component';
import { LeadsComponent } from './leads/leads.component';
import { ComponentModule } from '@callcenter/component/component.module';
import { ReportsModule } from './reports/reports.module';

@NgModule({
  declarations: [CallcenterComponent, LoginComponent, CallcenterHomeComponent, LeadsComponent],
  imports: [
    CommonModule,
    CallcenterRoutingModule,

    SharedModule,
    LayoutModule,
    ComponentModule,
    ReportsModule
  ],
  providers: [CallcenterGuard]
})
export class CallcenterModule { }
