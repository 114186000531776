import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProRoutingModule } from './pro-routing.module';
import { ProComponent } from './pro.component';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './auth/login/login.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ProHomeComponent } from './pro-home/pro-home.component';
import { NewPatientBillingComponent } from './new-patient-billing/new-patient-billing.component';
import { ComponentsModule } from '@pro/components/components.module';
import { OctoregPatientBillingComponent } from './octoreg-patient-billing/octoreg-patient-billing.component';
import { OldPatientBillingComponent } from '@pro/old-patient-billing/old-patient-billing.component';
import { NoDuePaymentsListComponent } from '@pro/old-patient-billing/no-due-payments-list/no-due-payments-list.component';
import { RenewalDropListComponent } from '@pro/old-patient-billing/renewal-drop-list/renewal-drop-list.component';

@NgModule({
  declarations: [RenewalDropListComponent, NoDuePaymentsListComponent, OldPatientBillingComponent, ProComponent, LoginComponent, ProHomeComponent, NewPatientBillingComponent, OctoregPatientBillingComponent],
  imports: [
    CommonModule,
    ProRoutingModule,

    SharedModule,
    LayoutModule,
    ComponentsModule,
  ]
})
export class ProModule { }
