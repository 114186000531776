import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'billing-comments',
  templateUrl: './billing-comments.component.html',
  styleUrls: ['./billing-comments.component.scss']
})
export class BillingCommentsComponent implements OnInit {

  @Output() reloadCaseSheet: EventEmitter<any> = new EventEmitter();
	caseSheetId: number;
  caseSheet: any;
	commentsForm: FormGroup  = this.fb.group({
		doc_comments: [null],
		pro_comments: [null, [Validators.required]]
	});
  	backendValidations: any;
  public loading = new BehaviorSubject(false);

	get doc_comments() { return this.commentsForm.get('doc_comments'); }
	get pro_comments() { return this.commentsForm.get('pro_comments'); }


	errorMessages = {
		pro_comments: [{ type: 'required', message: 'The Pro Comments Field is required'}]
	}


  public modalRef: any;
  @ViewChild("billingCommentsModal") billingCommentsModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private _service: ProService,
    private notifyService: NotificationService,
  	private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  // Open Billing Comments Modal
  openModal(caseSheetData) {
  	this.caseSheetId = caseSheetData.id;
    this.caseSheet = caseSheetData;
    this.patchCommentsForm();
    this.modalService.open(this.billingCommentsModal, {
      windowClass: "modal",
      centered: true,
      backdrop: "static",
    });
  }

  // Patch Comments Form
  patchCommentsForm() {
    this.commentsForm.patchValue({
      'doc_comments': this.caseSheet.doc_comments,
      'pro_comments': this.caseSheet.pro_comments 
    });
  }

  closeCommentsForm(){
    this.commentsForm.reset();
    this.modalService.dismissAll();
  }

  // Submit Comments Form
  submitCommentsForm() {
  	this.commentsForm.markAllAsTouched();
  	if (this.commentsForm.valid && this.caseSheetId) {
      this.loading.next(true);
  		this.commentsForm.value.id = this.caseSheetId; 
  		this._service.billingComments(this.commentsForm.value)
      .pipe(finalize(() => this.loading.next(false)))
  		.subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closeCommentsForm();
            this.reloadCaseSheet.emit(true);
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.commentsForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
  		}, err => {
  			this.notifyService.showError(err['message'], err['title']);
  		});
  	}
  }
}