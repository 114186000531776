import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss']
})
export class FeedbackModalComponent implements OnInit {

  patientDetails: any;
  backendValidations: any;
  public loading = new BehaviorSubject(false);
  // Feeback Submit Form
  feedbackSubmitForm: FormGroup = this.fb.group({
    remarks: ["", [Validators.required, Validators.pattern('^(?!.*  ).+'), Validators.maxLength(500)]],
  });

  get remarks() {return this.feedbackSubmitForm.get("remarks")}

  errorMessages = {
    remarks: [{ type: 'required', message: 'The Remarks Field is required'}],
  };

  closesubmitfbForm() {
    this.feedbackSubmitForm.reset();
    this.modalService.dismissAll();
  }
  
  @ViewChild("patientFeedbackModal") patientFeedbackModal: TemplateRef<any>;
  constructor(private fb: FormBuilder,
    private _service: ProService,
    private notifyService: NotificationService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }
  /* Open Patient ID Modal */
  openModal(row) {
    this.patientDetails = row;
    this.modalService.open(this.patientFeedbackModal, {
      windowClass: "modal",
      backdrop: "static",
      centered: true
    });
  }

  // Submit FeedBack Form
  submitfbForm() {
    this.feedbackSubmitForm.markAllAsTouched();
    if (this.feedbackSubmitForm.valid) {
      this.feedbackSubmitForm.value.patient_id = this.patientDetails.patient_id;
      this.loading.next(true);
      this._service.addFeedBack(this.feedbackSubmitForm.value)
      .pipe(finalize(() => this.loading.next(false)))
      .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.closesubmitfbForm();
          } else if (res['status'] == 'errors') {
            for (var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.feedbackSubmitForm.controls[key].setErrors({ serverValidationError: true });
            }
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
    }
  }
}
