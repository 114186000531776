<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Branches Wise Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date - To Date</label>
                                            <ng2-flatpickr (ngModelChange)="onDateSelect()"
                                                formControlName="fdate_range" [config]="DateRangeOptions"
                                                placeholder="Pick a date range!"></ng2-flatpickr>

                                            <div *ngFor="let error of errorMessages.fdate_range">
                                                <ng-container
                                                    *ngIf="fdate_range.hasError(error.type) && (fdate_range.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Branch</label>
                                            <ng-select [items]="branches" bindLabel="name" bindValue="id"
                                                placeholder="Select Branch" formControlName="branch_id"
                                                [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.branch_id">
                                                <ng-container
                                                    *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'dropout-list-manually'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
                      <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [columnMode]="ColumnMode.force" [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="180">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">
                            {{patient_unique_id ? patient_unique_id : '-'}}
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total amount" prop="total_amount" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Due amount" prop="due_amount" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="package_name" [width]="180">
                <ng-template let-package_name="value" ngx-datatable-cell-template let-row="row">
                    <span>
                        {{package_name ?package_name +'Months':
                        '-'}}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last visiting date" prop="last_visit_date" [width]="180">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template let-row="row">
                    <span>{{last_visit_date ? (last_visit_date | date:'yy-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'dropout-list-automatically'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">{{patient_unique_id ?
                            patient_unique_id : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visiting_date" [width]="">
                <ng-template let-last_visiting_date="value" ngx-datatable-cell-template>
                    <span>{{last_visiting_date ? (last_visiting_date | date: 'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="pack" prop="pack" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="paid" prop="paid" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="due" prop="due_amount" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="drop_status" [width]="100">
                <ng-template let-drop_status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill" [ngClass]="{
                  'badge-light-primary': drop_status == '0',
                  'badge-light-success': drop_status == '1'
                }">
                        {{
                        drop_status == 0
                        ? 'Active'
                        : drop_status == 1
                        ? 'Drop'
                        : '-'
                        }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Expired" prop="date_of_expire" [width]="200">
                <ng-template let-date_of_expire="value" ngx-datatable-cell-template>
                    <span>{{ date_of_expire ? (date_of_expire | date: 'dd-MM-yyyy'): '-' }}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'missed_followup'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="90" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Unique Id" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Due" prop="due_amount" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Missing Followup date" prop="missing_followup_date" [width]="220">
                <ng-template let-missing_followup_date="value" ngx-datatable-cell-template>
                    <span>{{missing_followup_date ? (missing_followup_date | date:'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Updated Followup date" prop="updated_followup_date" [width]="230">
                <ng-template let-updated_followup_date="value" ngx-datatable-cell-template>
                    <span>{{updated_followup_date ? (updated_followup_date| date:'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Latest Remarks" prop="remarks" [width]="">
                <ng-template ngx-datatable-cell-template let-remarks="value"
                    (click)="remarksModal.openModal(row.remarks)">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two">{{remarks ? remarks : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="230" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-dark active border__none ml-50"
                            (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <!-- <button class="btn btn-outline-light active border__none ml-50"
                            (click)="feedBackModal.openModal(row, 'Daily_follwoup')" ngbTooltip="Feedback"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </button> -->
                        <!-- <button class="btn btn-outline-info active border__none ml-50"
                            (click)="feedBackLogModal.openModal(row.patient_id, 'Daily_follwoup')"
                            ngbTooltip="Feedbacks Log" tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='list' class="font-small-4">
                                </i>
                            </span>
                        </button> -->
                        <!-- <button (click)="followUpDropModal.dropAlert(row)"
                            class="btn btn-outline-danger active border__none ml-50" ngbTooltip="Drop"
                            tooltipClass="fadeInAnimation">
                            <span><i data-feather='x' class="font-small-4"></i></span>
                        </button> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'datefollowup'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                        (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="180">
                <ng-template ngx-datatable-cell-template let-patient_unique_id="value">
                    <span>{{patient_unique_id ? patient_unique_id :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visite Date" prop="visit_date" [width]="150">
                <ng-template ngx-datatable-cell-template let-visit_date="value">
                    <span>{{visit_date ? (visit_date | date:'dd-MM-yyyy') :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Followup Date" prop="next_visit_date" [width]="150">
                <ng-template ngx-datatable-cell-template let-next_visit_date="value">
                    <span>{{next_visit_date ? (next_visit_date | date:'dd-MM-yyyy') :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="180">
                <ng-template ngx-datatable-cell-template let-branch_patient_id="value">
                    <span>{{branch_patient_id ? branch_patient_id :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
                <ng-template ngx-datatable-cell-template let-patient_name="value">
                    <span>{{patient_name ? patient_name :'-'}}</span>
                </ng-template></ngx-datatable-column>
            <ngx-datatable-column name="Due amount" prop="due_amount" [width]=""></ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'courier_prescription'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
        <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Gender" prop="gender" [width]="120">
                <ng-template let-gender="value" ngx-datatable-cell-template>
                    <span>{{gender == 'm' ? 'Male' :
                        gender == 'f' ? 'Female' : 'Others'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="type" prop="patient_type" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="no of days medicines" prop="no_of_days_medicines" [width]="200">
                <ng-template let-no_of_days_medicines="value" ngx-datatable-cell-template>
                    <span>{{no_of_days_medicines ? no_of_days_medicines : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="visiting date" prop="visiting_date" [width]="">
                <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                    <span>{{visiting_date ? (visiting_date | date: 'dd-MM-yyyy h:m:s') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="120">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{ status == 0 ? 'Not-Delivered' : 'Delivered'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="courier sending date" prop="courier_date" [width]="200">
                <ng-template let-courier_date="value" ngx-datatable-cell-template>
                    <span>{{courier_date ? (courier_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="address" prop="full_address" [cellClass]="'word-span-el'" [width]="200">
                <ng-template let-full_address="value" ngx-datatable-cell-template>
                    <span>{{full_address ? full_address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'patient_satisfaction'">
      
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'"
                [width]="170">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient type" prop="patient_type" [width]="120">
                <ng-template let-patient_type="value" ngx-datatable-cell-template>
                    <span>{{patient_type ? patient_type : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visiting_date" [width]="130">
                <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                    <span>{{visiting_date ? (visiting_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="doctor" prop="doctor_name" [cellClass]="'word-span-el'" [width]="170">
                <ng-template let-doctor_name="value" ngx-datatable-cell-template>
                    <span>{{doctor_name ? doctor_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="120">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status ? status : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-light active border__none ml-50"
                            (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <!-- <div>
                            <button class="btn btn-outline-primary active border__none ml-50"
                                (click)="editPatientModal.openModal(row)" ngbTooltip="Edit"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='edit' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                            <button class="btn btn-outline-warning active border__none ml-50"
                                (click)="modalOpen(remark, row.remarks)" ngbTooltip="Remark" tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='eye' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                        </div> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'get_renewals_call'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="BP.ID " prop="branch_patient_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Date Of EXP" prop="date_of_expire" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor" prop="Doctor_Name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Last visit date" prop="last_visit_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="New Visit Date" prop="new_visit_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Coming Date" prop="coming_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Remarks" prop="remarks" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'doc_referral_target'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTORS REFFERAL TARGET REPORT - ( {{ date[0] | date: 'dd-MM-yyyy' }} ) To ( {{date[1] | date: 'dd-MM-yyyy'}} )</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch</th>
                                    <th>Doctor Name</th>
                                    <th>G-REN</th>
                                    <th>REN</th>
                                    <th>PACK</th>
                                    <th>PAID</th>
                                    <th>TARGET</th>
                                    <th>PER%</th>
                                    <th>INCENTIVES</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{item.BR}}</td>
                                    <td>{{item.doctor_name}}</td>
                                    <td>{{item.g_ren}}</td>
                                    <td>{{item.ren}}</td>
                                    <td>{{item.pack}}</td>
                                    <td>{{item.paid}}</td>
                                    <td>{{item.target}}</td>
                                    <td>{{item.per}}</td>
                                    <td>{{item.incentives}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="patients-reports-stats" *ngIf="totalRow">
                                    <td colspan="2"> Total: </td>
                                    <td>{{totalRow.g_ren}}</td>
                                    <td>{{totalRow.ren}}</td>
                                    <td>{{totalRow.pack}}</td>
                                    <td>{{totalRow.paid}}</td>
                                    <td>{{totalRow.target}}</td>
                                    <td></td>
                                    <td>{{totalRow.incentives}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'rpr_report'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">RPR Report- {{selectedReport.state_code}}</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>TNPR</th>
                                    <th>RPV</th>
                                    <th>RGL</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>REG</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>RPR</th>
                                    <th>RPAMT</th>
                                    <th>RPAID</th>
                                    <th>RDUE</th>
                                    <th>RPP</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.tnpr }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null, 'rpv')">{{
                                            item.rpv
                                        }}</a>
                                    </td>
                                    <td>{{ item.rgl }}</td>
                                    <td>{{ item.rpamt_rgl }}</td>
                                    <td>{{ item.rpaid_rgl }}</td>
                                    <td>{{ item.rdue_rgl }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.rpamt_reg }}</td>
                                    <td>{{ item.rpaid_reg }}</td>
                                    <td>{{ item.rdue_reg }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renList, 'gmapi', null, item.branch_id, null, 'Renewal')">{{
                                            item.rpr
                                        }}</a>
                                    </td>
                                    <td>{{ item.rpamt }}</td>
                                    <td>{{ item.rpaid }}</td>
                                    <td>{{ item.rdue }}</td>
                                    <td><a class="dataTable-Link"
                                        (click)="openModal(renPendingList, 'gmapi', null, item.branch_id, null)">{{
                                            item.rpp
                                        }}</a>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th>Total :</th>
                                    <td>{{ totalRow.tnpr }}</td>
                                    <td>{{ totalRow.rpv }}</td>
                                    <td>{{ totalRow.rgl }}</td>
                                    <td>{{ totalRow.rpamt_rgl }}</td>
                                    <td>{{ totalRow.rpaid_rgl }}</td>
                                    <td>{{ totalRow.rdue_rgl }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.rpamt_reg }}</td>
                                    <td>{{ totalRow.rpaid_reg }}</td>
                                    <td>{{ totalRow.rdue_reg }}</td>
                                    <td>{{ totalRow.rpr }}</td>
                                    <td>{{ totalRow.rpamt }}</td>
                                    <td>{{ totalRow.rpaid }}</td>
                                    <td>{{ totalRow.rdue }}</td>
                                    <td>{{ totalRow.rpp }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>