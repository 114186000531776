import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FoeService } from '@foe/services/foe.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MasterDataService } from '@services/masterData/master-data.service';
@Component({
  selector: 'app-circular',
  templateUrl: './circular.component.html',
  styleUrls: ['./circular.component.scss']
})
export class CircularComponent implements OnInit {
  file_path: any;
  circulars: any;
  circularFilePath: any;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    public sanitizer: DomSanitizer,
    private masterService: MasterDataService) { }

  ngOnInit(): void {
    console.log('check');
    this.getCircular();
  }

  /* Get Circulars */
  getCircular() {
    this.masterService.getCircular('foeapi')
      .subscribe(res => {
        if (res['status'] == 'success') {
          this.circulars = res['data'];
        } else if (res['status'] == 'error') {
          this.notifyService.showError(res['message'], res['title']);
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }


}
