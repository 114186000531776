<!-- Courier Prescription Details -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Courier Prescription Details&nbsp;({{ currentDate |
                        date:'dd-MM-yyyy'}})
                    </h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="courierFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient ID</label>
                                    <input type="text" formControlName="fpatient_unique_id" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Name</label>
                                    <input type="text" formControlName="fpatient_name" class="form-control">
                                </div>
                            </div>

                            <!-- <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Patient Type</label>
                                    <ng-select [items]="patientType" formControlName="fpatient_type" bindLabel="title"
                                        bindValue="value" placeholder="Select"></ng-select>
                                </div>
                            </div> -->

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                    <button type="reset" (click)="resetFilter()"
                                        class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                             class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="courierdetailsData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-success active border__none ml-50" ngbTooltip="Prescription"
                            tooltipClass="fadeInAnimation" placement="right"
                            (click)="modalOpen(prescriptionModal, row)">
                            <span>
                                <i data-feather='file-text' class="font-small-4">
                                </i>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Issue Date" prop="issue_date" [width]="150">
                <ng-template let-issue_date="value" ngx-datatable-cell-template>
                    <div>
                        {{issue_date ? (issue_date | date:'dd-MM-yyyy' ): '-'}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="BPID" prop="branch_patient_id" [width]="200" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="No.of Days Medicines" prop="no_of_days_medicines" [width]="180">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Type" prop="patient_type" [width]="180">
            </ngx-datatable-column>
            <ngx-datatable-column name="Ren Date" prop="ren_date" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="doctor" prop="doctor_name" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="time" prop="time" [width]="150">
                <ng-template let-time="value" ngx-datatable-cell-template>
                    <div>
                        {{time ? (time | date: 'hh:mm:ss a' ): '-'}}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Courier Prescription Details -->

<!-- Prescription Modal -->
<ng-template #prescriptionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Prescription</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th title="Medicine Name/Item Name">Medicine/Item Name</th>
                            <th title="No.of days for Medicine's">No.of days</th>
                            <th title="Potency">Potency</th>
                            <th title="Single Dose">Single Dose</th>
                            <th title="Morning">M</th>
                            <th title="Afternoon">A</th>
                            <th title="Night">N</th>
                            <th title="Emergency">SOS</th>
                            <th title="Special Medicine">Spl Medicine</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{selectedRow.medicine_name}}</td>
                            <td>{{selectedRow.no_of_days_medicine_pro}}</td>
                            <td>{{selectedRow.potency}}</td>
                            <td>{{selectedRow.single_dose}}</td>
                            <td>{{selectedRow.morning}}</td>
                            <td>{{selectedRow.afternoon}}</td>
                            <td>{{selectedRow.night}}</td>
                            <td>{{selectedRow.sos}}</td>
                            <td>{{selectedRow.specialmed}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form [formGroup]="updateCourierStatus" (ngSubmit)="updateCourier()">                
                <div class="row">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                            <label for="">Comments from PRO/Manager</label>
                            <textarea formControlName="remarks" rows="3" class="form-control" readonly></textarea>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="form-group">
                            <label for="">Update NO. of Days</label>
                            <input type="text" formControlName="no_of_days_medicines" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <label for="">Patient Full Address</label>
                            <textarea formControlName="full_address" rows="3" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.full_address && full_address.errors && full_address.touched }"></textarea>
                            <div *ngFor="let error of errorMessages.full_address">
                                <ng-container *ngIf="full_address.hasError(error.type) && (full_address.touched)">
                                    <small class="text-danger">{{error.message}}</small>
                                </ng-container>
                            </div>
                            <div
                                *ngIf="updateCourierStatus.controls.full_address.hasError('serverValidationError')">
                                <small class="text-danger">{{ backendValidations['full_address'] }}</small>
                            </div>
                        </div>
                    </div>
                  
                    <div class="col-lg-6 col-12">
                        <div class="form-group">
                            <label for="">Courier Details</label>
                            <textarea formControlName="cdetails" rows="3" class="form-control" [ngClass]="{ 'is-invalid error': errorMessages.cdetails && cdetails.errors && cdetails.touched }"></textarea>
                            <div *ngFor="let error of errorMessages.cdetails">
                                <ng-container *ngIf="cdetails.hasError(error.type) && (cdetails.touched)">
                                    <small class="text-danger">{{error.message}}</small>
                                </ng-container>
                            </div>
                            <div
                                *ngIf="updateCourierStatus.controls.cdetails.hasError('serverValidationError')">
                                <small class="text-danger">{{ backendValidations['cdetails'] }}</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-12">
                        <div class="demo-inline-spacing">
                            <div class="custom-control custom-checkbox mt-0">
                                <input formControlName="received" type="checkbox" id="one" value="" class="custom-control-input" />
                                <div *ngFor="let error of errorMessages.received">
                                    <ng-container *ngIf="received.hasError(error.type) && (received.touched)">
                                        <small class="text-danger">{{error.message}}</small>
                                    </ng-container>
                                </div>
                                <div
                                    *ngIf="updateCourierStatus.controls.received.hasError('serverValidationError')">
                                    <small class="text-danger">{{ backendValidations['received'] }}</small>
                                </div>
                                <label class="custom-control-label" for="one">Received</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-12 text-center my-50">
                        <button [disabled]="loading | async" class="btn btn-primary">
                        <span *ngIf="loading | async"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<!--/ Prescription Modal -->