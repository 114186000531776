<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Doctor Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'doc_renewal_target'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTORS RENEWAL TARGET REPORT - ( {{ fromDate| date: 'dd-MM-yyyy' }} ) To ( {{fromDate| date: 'dd-MM-yyyy'}} )</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Branch</th>
                                    <th>Doctor Name</th>
                                    <th>G-REN</th>
                                    <th>REN</th>
                                    <th>PACK</th>
                                    <th>PAID</th>
                                    <th>TARGET</th>
                                    <th>PER%</th>
                                    <th>INCENTIVES</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{item.BR}}</td>
                                    <td>{{item.doctor_name}}</td>
                                    <td>{{item.g_ren}}</td>
                                    <td>{{item.ren}}</td>
                                    <td>{{item.pack}}</td>
                                    <td>{{item.paid}}</td>
                                    <td>{{item.target}}</td>
                                    <td>{{item.per}}</td>
                                    <td>{{item.incentives}}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr class="patients-reports-stats" *ngIf="totalRow">
                                    <td colspan="2"> Total: </td>
                                    <td>{{totalRow.g_ren}}</td>
                                    <td>{{totalRow.ren}}</td>
                                    <td>{{totalRow.pack}}</td>
                                    <td>{{totalRow.paid}}</td>
                                    <td>{{totalRow.target}}</td>
                                    <td></td>
                                    <td>{{totalRow.incentives}}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'doc_due_ren_op_np'">
        <div class="row" *ngIf="tableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">DOCTOR WISE TOTAL COLLECTION AMOUNT- {{selectedReport.state_code}}</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Doctor<br /> name</th>
                                    <th>Br</th>
                                    <th>NP COLL</th>
                                    <th>OP COLL</th>
                                    <th>GT</th>
                                    <th>REN COLL</th>
                                    <th>DUE COLL</th>
                                    <th>No.of<br /> OP</th>
                                    <th>No.of<br />NP</th>
                                    <th>REG</th>
                                    <th>REF</th>
                                    <th>Total<br /> No.of REN</th>
                                    <th>No.of REN</th>
                                    <th>Pending<br /> REN</th>
                                    <th>REN>5000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                    <td>{{ item.doctor_name }}</td>
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.np_coll }}</td>
                                    <td>{{ item.op_coll }}</td>
                                    <td>{{ item.total_gt }}</td>
                                    <td>{{ item.ren_coll }}</td>
                                    <td>{{ item.due_coll }}</td>
                                    <td>{{ item.op_count }}</td>
                                    <td>{{ item.fnp }}</td>
                                    <td>{{ item.reg }}</td>
                                    <td>{{ item.ref }}</td>
                                    <td>{{ item.ren_total }}</td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', item.doctor_id, null, null, 'Renewal')">{{
                                            item.ren_count
                                            }}</a>
                                    </td>
                                    <td>
                                        <a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', item.doctor_id, null, null)">{{
                                            item.ren_pending
                                            }}
                                        </a>
                                    </td>
                                    <td>{{ item.ren_count_5000 }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="totalRow">
                                    <th colspan="2">Total :</th>
                                    <td>{{ totalRow.np_coll }}</td>
                                    <td>{{ totalRow.op_coll }}</td>
                                    <td>{{ totalRow.total_gt }}</td>
                                    <td>{{ totalRow.ren_coll }}</td>
                                    <td>{{ totalRow.due_coll }}</td>
                                    <td>{{ totalRow.op_count }}</td>
                                    <td>{{ totalRow.fnp }}</td>
                                    <td>{{ totalRow.reg }}</td>
                                    <td>{{ totalRow.ref_count }}</td>
                                    <td>{{ totalRow.ren_total }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renList, 'gmapi', null, null, totalRow.state_id, 'Renewal')">{{
                                            totalRow.ren_count
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(renPendingList, 'gmapi', null, null, totalRow.state_id)">{{
                                            totalRow.ren_pending
                                            }}</a></td>
                                    <td>{{ totalRow.ren_count_5000 }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>