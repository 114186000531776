import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { DoctorRoutingModule } from './doctor-routing.module';
import { LoginComponent } from './auth/login/login.component';
import { DoctorComponent } from './doctor.component';
import { SharedModule } from '@doc/shared/shared.module';
import { DocHomeComponent } from '@doc/doc-home/doc-home.component';
import { LayoutModule } from 'app/layout/layout.module';
import { PatientsComponent } from './patients/patients.component';
import { ComponentsModule } from '@doc/components/components.module';
import { SearchComponent } from './search/search.component';

@NgModule({
  declarations: [LoginComponent, DoctorComponent, DocHomeComponent, PatientsComponent, SearchComponent],
  imports: [
    CommonModule,
    DoctorRoutingModule,
    SharedModule,
    LayoutModule,
    ComponentsModule,
    SweetAlert2Module.forRoot(),
  ]
})
export class DoctorModule { }
