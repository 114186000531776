import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class DocService {

  constructor(protected httpClient: AppHttpClient) { }

  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('doctorapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('doctorapi/me');
  }

  dasboardData() {
    return this.httpClient.get('doctorapi/get_home_content');
  }

  getAttendenceReport(payload: object) {
    return this.httpClient.post('doctorapi/attendance_list', payload);
  }

  updateCases(payload: object) {
    return this.httpClient.post('doctorapi/home/update-cases', payload);
  }

  updateTodayCases(payload: object) {
    return this.httpClient.post('doctorapi/home/update-today-cases', payload);
  }

  // getCasesheetById(caseheetId) {
  //   return this.httpClient.get('doctorapi/get_casesheet_by_id/' + caseheetId);
  // }

  updateCaseSheetByID(payload) {
    return this.httpClient.post('doctorapi/home/update_casesheet_by_id', payload);
  }

  updateFollowUpByID(payload) {
    return this.httpClient.post('doctorapi/home/update_followup_by_id', payload);
  }

  updatePrescription(payload) {
    return this.httpClient.post('doctorapi/home/update_prescription_by_id', payload);
  }

  /* Targets APIS */
  getDuePaymentsList(payload: object) {
    return this.httpClient.post('doctorapi/target/total_due_list', payload);
  }

  getTargetRenewals(payload: object) {
    return this.httpClient.post('doctorapi/target/get_renewals_call', payload)
  }

  getFollowUpsData(payload: object) {
    return this.httpClient.post('doctorapi/target/followup_list', payload);
  }

  getBranchTargetReport() {
    return this.httpClient.post('doctorapi/target/branch_target');
  }

  branchTargetPatientDetails(payload: object) {
    return this.httpClient.post('doctorapi/target/gt-patient-details', payload);
  }

  achievedTargetDetails() {
    return this.httpClient.post('doctorapi/target/achieved-upto');
  }

  getTargetThisMonthStatus() {
    return this.httpClient.post('doctorapi/target/month_status');
  }

  /* Reports APIs */
  getDocRenewalTargets(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/renewal-target', payload);
  }

  getDocReferalTargets(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/referral-target', payload);
  }

  getSpecialTargets(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/special-target', payload);
  }

  getDropouts(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/dropoutList-report', payload);
  }

  getDoctorWiseTotalStatus() {
    return this.httpClient.post('doctorapi/all-reports/doc-wise-reports');
  }

  getOcToRegPatients(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/ocToReg-report', payload);
  }

  getDateWiseSummaryReport(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/dateWise-summary-report', payload);
  }

  getExpenditureReport(payload: object) {
    return this.httpClient.post('doctorapi/all-reports/expenditure-reports', payload);
  }

  getMainReports(api, payload: object) {
    return this.httpClient.post('doctorapi/' + api, payload);
  }

  rpvPatientList() {
    return this.httpClient.post('doctorapi/all-reports/rpr-reg-patient-list-report');
  }

  rprPatientList() {
    return this.httpClient.post('doctorapi/all-reports/rpr-renewalCalls-data');
  }

  openRegStatusModal(payload) {
    return this.httpClient.post('doctorapi/all-reports/reg-patient-list', payload);
  }

  /* Patients APIS */
  getTodaysPatient(filterValues: object) {
    return this.httpClient.post('doctorapi/patient/patient_report', filterValues);
  }

  updateCaseSheet(payload) {
    return this.httpClient.post('doctorapi/patient/update_casesheet', payload);
  }

  getPrescription(patientId) {
    return this.httpClient.get('doctorapi/patient/patient-prescription-report/' + patientId)
  }

  getPatientInvoiceData(patientId) {
    return this.httpClient.get('doctorapi/get_patient_invoice/' + patientId);
  }

  getCaseSheet(patientId) {
    return this.httpClient.get('doctorapi/get_casesheet/' + patientId);
  }

  /* Search APIS */
  patientsListing(payload: object) {
    return this.httpClient.post('doctorapi/search/search-patients', payload);
  }

  clinicalOB(patientId) {
    return this.httpClient.get('doctorapi/search/clinical-ob/' + patientId);
  }
}
