import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { AgmRoutingModule } from './agm-routing.module';
import { LayoutModule } from 'app/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { ComponentsModule } from './components/components.module';

//components
import { LoginComponent } from './auth/login/login.component';
import { AgmComponent } from './agm.component';
import { HomeComponent } from './home/home.component';
import { TargetComponent } from './target/target.component';
import { TargetNpComponent } from './target-np/target-np.component';
import { StatusComponent } from './status/status.component';
import { DocWiseComponent } from './doc-wise/doc-wise.component';
import { SmsReportComponent } from './sms-report/sms-report.component';
import { MonthReportComponent } from './month-report/month-report.component';
import { BranchWiseComponent } from './branch-wise/branch-wise.component';
import { RegionWiseComponent } from './region-wise/region-wise.component';
import { GlobalComponentsModule } from '@global_components/global-components.module';


@NgModule({
  declarations: [LoginComponent,AgmComponent, HomeComponent, TargetComponent, TargetNpComponent, StatusComponent, DocWiseComponent, SmsReportComponent, MonthReportComponent, BranchWiseComponent, RegionWiseComponent],
  imports: [
    CommonModule,
    AgmRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    NgxDatatableModule,
    CardSnippetModule,
    GlobalComponentsModule,
    LayoutModule,
    NgSelectModule,
    SharedModule,
    ComponentsModule,
    NgbTooltipModule
  ]
})
export class AgmModule { }
