import { Injectable } from '@angular/core';
import { AppHttpClient } from '@core/http/app-http-client.service';

@Injectable({
  providedIn: 'root'
})
export class CallcenterService {

  constructor(protected httpClient: AppHttpClient) { }

  /* authentication apis */
  login(credentials: object) {
    return this.httpClient.post('callCenterapi/login', credentials);
  }

  /* Dashboard APIS */
  getProfileDetails() {
    return this.httpClient.get('callCenterapi/me');
  }

  dasboardData() {
    return this.httpClient.get('callCenterapi/get_home_content');
  }

  /* Leads APIS */
  getLeadsData(payload: object) {
    return this.httpClient.post('callCenterapi/leads/get-leads', payload);
  }

  addLead(payload: object) {
    return this.httpClient.post('callCenterapi/leads/add-leads', payload);
  }

  editLead(payload: object) {
    return this.httpClient.post('callCenterapi/leads/edit-leads', payload);
  }

  update_feedback(payload: object) {
    return this.httpClient.post('callCenterapi/leads/update-feedback', payload);
  }

  feedback_log(payload: object) {
    return this.httpClient.post('callCenterapi/leads/feedback-log', payload);
  }

  phStatus(api, payload: object) {
    return this.httpClient.post('callCenterapi/reports/' + api, payload);
  }
}
