import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { environment } from 'environments/environment';
import { Location } from '@angular/common';

import { HrTokenService } from '@hr/auth/hr-token/hr-token.service';
import { FoeTokenService } from '@foe/auth/foe-token/foe-token.service';
import { DocTokenService } from '@doc/auth/doc-token/doc-token.service';
import { ProTokenService } from '@pro/auth/pro-token/pro-token.service';
import { PharmacyTokenService } from '@pharmacy/auth/pharmacy-token/pharmacy-token.service';
import { CallcenterTokenService } from "@callcenter/auth/callcenter-token/callcenter-token.service";
import { AccountsTokenService } from '@accounts/auth/accounts-token/accounts-token.service';
import { StoreTokenService } from '@store/auth/store-token/store-token.service';
import { GmTokenService } from "@gm/auth/gm-token/gm-token.service";
import { AuditTokenService } from "app/modules/audit/auth/audit-token/audit-token.service";
import { DrcordinatorTokenService } from "app/modules/drcordinator/auth/drcordinator-token/drcordinator-token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    currentRoute: string;
    constructor(private hrTokenService: HrTokenService,
        private foeTokenService: FoeTokenService,
        private docTokenService: DocTokenService,
        private proTokenService: ProTokenService,
        private pharmacyTokenService: PharmacyTokenService,
        private callcenterTokenService: CallcenterTokenService,
        private accountsTokenService: AccountsTokenService,
        private storeTokenService: StoreTokenService,
        private gmTokenService: GmTokenService,
        private AuditTokenService: AuditTokenService,
        private DrcordinatorTokenService: DrcordinatorTokenService,
        private Location: Location
    ) { 
        let URL = this.Location.path();
        var pathArray = URL.split('/');
        this.currentRoute = pathArray[1];
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        environment.apiUrl = '';

        let accessToken = '';
        accessToken = localStorage.getItem(`${this.currentRoute}_auth_token`);


        // if (req.urlWithParams.startsWith(environment.apiUrl + 'hrapi')) {

        //     accessToken = this.hrTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'foeapi')) {

        //     accessToken = this.foeTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'doctorapi')) {

        //     accessToken = this.docTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'proapi')) {

        //     accessToken = this.proTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'pharmacyapi')) {

        //     accessToken = this.pharmacyTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'callCenterapi')) {

        //     accessToken = this.callcenterTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'accountapi')) {

        //     accessToken = this.accountsTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'storeManagerapi')) {

        //     accessToken = this.storeTokenService.getToken();
        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'gmapi')) {


        // } else if (req.urlWithParams.startsWith(environment.apiUrl + 'api')) {

        //     accessToken = this.pharmacyTokenService.getToken();
        // }

        req = req.clone({
            setHeaders: {
                Authorization: "Bearer " + accessToken,
            }
        });
        return next.handle(req);
    }
}