<ng-template #editPatientModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Patient Details</h4>
        <button type="button" class="close" (click)="closefbEditForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="feedbackEditForm" (ngSubmit)="submitfbEditForm()">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Purpose</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>Renewal</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient ID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatient.patient_unique_id ? selectedPatient.patient_unique_id : '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>BPID</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatient.branch_patient_id ? selectedPatient.branch_patient_id : '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Patient Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{selectedPatient.patient_name ? selectedPatient.patient_name : '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Branch Name</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatient.branch_name ? selectedPatient.branch_name : '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-5 col-md-6">
                                    <label>Mobile</label>
                                </div>
                                <div class="col-lg-7 col-md-6">
                                    <p>{{selectedPatient.mobile ? selectedPatient.mobile : '-' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Coming Date</p>
                                        <input type="date" class="form-control" formControlName="comingdate"
                                            [class.is-invalid]="comingdate.invalid && comingdate.touched">
                                        <div *ngIf="comingdate.invalid && comingdate.touched">
                                            <div class="text-danger" *ngIf="comingdate.errors?.required">Coming Date
                                                field
                                                is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Call Date</p>
                                        <input type="date" class="form-control" formControlName="calldate"
                                            [class.is-invalid]="calldate.invalid && calldate.touched">
                                        <div *ngIf="calldate.invalid && calldate.touched">
                                            <div class="text-danger" *ngIf="calldate.errors?.required">Call Date
                                                field
                                                is
                                                required.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Status</p>
                                        <ng-select [items]="feedbackStatus" bindLabel="title" bindValue="value" placeholder="Select"
                                            formControlName="status"
                                            [class.is-invalid]="status.invalid && status.touched">
                                        </ng-select>
                                        <div *ngIf="status.invalid && status.touched">
                                            <div class="text-danger" *ngIf="status.errors?.required">Feedback status
                                                is
                                                required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Sub Status</p>
                                        <div class="form-group">
                                            <ng-select [items]="subStatus" bindLabel="value" placeholder="Select">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Appointment Date</p>
                                        <input type="date" class="form-control" formControlName="appointmentdate"
                                            [class.is-invalid]="appointmentdate.invalid && appointmentdate.touched">
                                        <div *ngIf="appointmentdate.invalid && appointmentdate.touched">
                                            <div class="text-danger" *ngIf="appointmentdate.errors?.required">
                                                Appointment Date field is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="form-group">
                                        <p class="mb-0">Remarks</p>
                                        <textarea rows="3" class="form-control" formControlName="remarks"
                                            [class.is-invalid]="remarks.invalid && remarks.touched">
                                        </textarea>
                                        <div *ngIf="remarks.invalid && remarks.touched">
                                            <div class="text-danger" *ngIf="remarks.errors?.required">Remarks field
                                                is
                                                required.</div>
                                            <div class="text-danger" *ngIf="remarks.errors?.pattern">
                                                This field can not contain spaces.
                                            </div>
                                            <div class="text-danger" *ngIf="remarks.errors?.maxlength">
                                                Do not enter more than 250 characters.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" class="btn btn-primary">Submit</button>
                            <button type="reset" class="btn btn-secondary ml-50">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>