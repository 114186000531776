import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PharmacyService } from '@pharmacy/services/pharmacy.service';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrescriptionComponent implements OnInit {

  currentDateValidation = new Date().toISOString().slice(0, 10);
  public search_text: any;
  public basicSelectedOption: number = 500;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public mySearchControl = new FormControl();
  public loading = new BehaviorSubject(false);
  public filterLoading = new BehaviorSubject(false);
  currentDate = new Date();
  count_new = 0;
  count_old = 0;
  selectedRecord: any;
  backendValidations: any;
  public modalRef: any;
  received: boolean = false;
  isShown: boolean = false;
  branch_medicines: any;
  tableData: any;

  toggleShow() {
    this.isShown = !this.isShown;
  }

  public patientType = [
    { title: "New Patient", value: "new" },
    { title: "Old Patient", value: "old" },
  ];
  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
* Search (filter)
*
* @param event
*/
  filterUpdate(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempData.filter(function (d) {
      return d.full_name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.kitchenSinkRows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  /**
   * Row Details Toggle
   *
   * @param row
   */
  rowDetailsToggleExpand(row) {
    this.tableRowDetails.rowDetail.toggleExpandRow(row);
  }

  /**
   * For ref only, log selected values
   *
   * @param selected
   */
  onSelect({ selected }) {
    console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  /**
   * For ref only, log activate events
   *
   * @param selected
   */
  onActivate(event) {
    // console.log('Activate Event', event);
  }

  /**
   * Custom Chkbox On Select
   *
   * @param { selected }
   */
  customChkboxOnSelect({ selected }) {
    this.chkBoxSelected.splice(0, this.chkBoxSelected.length);
    this.chkBoxSelected.push(...selected);
  }

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    public _service: PharmacyService
  ) {
    this._unsubscribeAll = new Subject();
  }

  filterForm = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_name: [],
    fpatient_type: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  ngOnInit(): void {

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.filterForm.value.page = 1;
      this.getTodayPrescriptions();
    });

    this.getBranchMedicines();
    this.getTodayPrescriptions();
  }

  /* Get Branch Medicines */
  getBranchMedicines() {
    this._service.getBranchMedicines()
      .subscribe(res => {
        if (res['status'] == 'success') {
          this.branch_medicines = res['data']['branch_medicines'];
        } else if (res['status'] == 'error') {
          this.notifyService.showError(res['message'], res['title']);
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  getTodayPrescriptions() {
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.fsearch_text = this.search_text;

    this._service.getTodayPrescriptions(this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        this.tableData = res['data'];
        this.totalCount = res['total'];
        this.curPage = res['page'];
        this.count_new = res['count_new'],
          this.count_old = res['count_old']
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Form Group */
  editPrescriptionForm = this._formBuilder.group({
    patient_unique_id: [""],
    patient_name: [""],
    branch_patient_id: [""],
    no_of_days_medicines: [""],
    next_visit_date: [""]
  });

  /* Patch Case Sheet */
  patchPrescription() {
    this.editPrescriptionForm.patchValue({
      'patient_unique_id': this.selectedRecord.patient_unique_id,
      'patient_name': this.selectedRecord.patient_name,
      'branch_patient_id': this.selectedRecord.branch_patient_id,
      'no_of_days_medicines': this.selectedRecord.no_of_days_medicines,
      'next_visit_date': this.selectedRecord.next_visit_date
    });
  }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.filterForm.value.fpatient_unique_id || this.filterForm.value.fpatient_name || this.filterForm.value.fpatient_type) {
      return false;
    } else {
      return true;
    }
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getTodayPrescriptions();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.getTodayPrescriptions();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.filterForm.value.perpage = this.basicSelectedOption;
    this.filterForm.value.page = 1;
    this.getTodayPrescriptions();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.filterForm.value.page = $event.page;
    this.getTodayPrescriptions();
  }

  modalOpen(modal, row = null) {
    if (row) {
      this.selectedRecord = row;
      this.patchPrescription();
    }

    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      backdrop: 'static',
      centered: true
    });
  }

  showPrescriptionModal(modal, row = null) {
    if (row) {
      this.selectedRecord = row;
    }
    this.received = false;
    this.modalRef = this.modalService.open(modal, {
      windowClass: "modal",
      centered: true,
      size: 'lg'
    });
  }

  updatePrescription() {
    this.editPrescriptionForm.markAllAsTouched();
    if (this.editPrescriptionForm.valid) {
      this.loading.next(true);
      this.editPrescriptionForm.value.prescription_id = this.selectedRecord.id;
      this._service.updatePrescription(this.editPrescriptionForm.value)
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.editPrescriptionForm.reset();
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalClose();
            this.getTodayPrescriptions();
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          } else if (res['status'] == 'errors') {

            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.editPrescriptionForm.controls[key].setErrors({ serverValidationError: true });
            }
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }

  /* On Modal Close Reset Form */
  modalClose() {
    this.modalRef.close();
    this.editPrescriptionForm.reset();
  }

  medicine_received() {
    if (this.received) {
      this._service.medicineReceived({ "prescription_id": this.selectedRecord.id, "status": this.received })
        .pipe(finalize(() => this.loading.next(false)))
        .subscribe(res => {
          if (res['status'] == 'success') {
            this.notifyService.showSuccess(res['message'], res['title']);
            this.modalClose();
            this.getTodayPrescriptions();
          } else if (res['status'] == 'error') {
            this.notifyService.showError(res['message'], res['title']);
          } else if (res['status'] == 'errors') {
            for (var key in res['errorVaidations']) {
              let backendValidations = res["errorVaidations"];
              this.backendValidations = backendValidations;
              this.editPrescriptionForm.controls[key].setErrors({ serverValidationError: true });
            }
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }

  }
}
