import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProAuthStateService } from '@pro/auth/pro-token/pro-auth-state.service';
import { ProTokenService } from '@pro/auth/pro-token/pro-token.service';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export abstract class HttpErrorHandler {
    currentRoute: string;

    protected constructor(
        private _router: Router,
        private activatedRoute: ActivatedRoute,
        private Location: Location,
        public _proAuth: ProAuthStateService,
    ) {
        let URL = this.Location.path();
        var pathArray = URL.split('/');
        this.currentRoute = pathArray[1];

        // this.activatedRoute.parent.url.subscribe((urlPath) => {
        //     const url = urlPath[urlPath.length - 1].path;
        //     this.currentRoute = url;
        // });
    }

    public handle(response: HttpErrorResponse, uri?: string, options: { [key: string]: any } = {}): Observable<never> {
        const errResponse = ((typeof response.error === 'object' && response.error !== null) ? response.error : {});
        errResponse.status = response.status;
        errResponse.type = 'http';

        if (!errResponse.errors) {
            errResponse.errors = {};
        }

        Object.keys(errResponse.errors).forEach(key => {
            const message = errResponse.errors[key];
            errResponse.errors[key] = Array.isArray(message) ? message[0] : message;
        });

        if (!options.suppressAuthToast && (errResponse.status === 403 || errResponse.status === 401)) {
            this.handle403Error(errResponse);
        } else if (errResponse.status === 422 && errResponse.message && !Object.keys(errResponse.errors).length) {
            // this.toast.open(errResponse.message, {duration: 15000});
        } else if (errResponse.status === 500) {
            const message = (errResponse?.message).substring(0, 200);
            // this.toast.open(message, {duration: 15000});
        }

        return throwError(errResponse);
    }

    /**
     * Redirect user to login page or show toast informing
     * user that he does not have required permissions.
     */
    handle403Error(response) {
        if (response.status == 401) {
            console.log(this.currentRoute)
            // this.logout();
            // this.nav_bar.logout();
        }
    }

    logout() {
        localStorage.removeItem(`${this.currentRoute}_auth_token`);
        this._router.navigate([this.currentRoute + '/login']);
    }
}