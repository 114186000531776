import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DrcordinatorService } from '../service/drcordinator.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-doctor-daily',
  templateUrl: './doctor-daily.component.html',
  styleUrls: ['./doctor-daily.component.scss']
})
export class DoctorDailyComponent implements OnInit {
  public filterLoading = new BehaviorSubject(false);
  public basicSelectedOption: number = 500;
  tableData: any;
  totalRow = [];
  stateFilterForm = this._formBuilder.group({
    fstates: [null, Validators.required],
  });

  errorMessages = {
    fstates: [{ type: 'required', message: 'This state field is required' }]
  }

  get fstates() { return this.stateFilterForm.get('fstates'); }

  stateWise = [
    {title: 'Andhra Pradesh', value: '1'},
    {title: 'Telangana', value: '2'},
    {title: 'Karnataka', value: '3'},
  ]

  constructor(
    private _service: DrcordinatorService,
    private _formBuilder: FormBuilder,
  ) { }


  ngOnInit(): void {
  }

  getDoctorDailyReport(){
    this._service.doctorsDailyReports(this.stateFilterForm.value)
    .pipe(finalize(() => this.filterLoading.next(false)))
    .subscribe((res: any[]) => {
      let data = res['data'];
      this.tableData = data['data'];
      this.totalRow = this.getTotal(this.tableData);

    });

  }
    /* Apply Transfers Filter */
    applyFilter() {
      this.filterLoading.next(true);
      this.getDoctorDailyReport();
    }

    getTotal(data) {
      return data.reduce(function (previousValue, currentValue) {
        return {
          np: previousValue.np + currentValue.np,
          reg: previousValue.reg + currentValue.reg,
          reg_per: previousValue.reg_per + currentValue.reg_per,
          gt: previousValue.gt + currentValue.gt,
          REF: previousValue.REF + currentValue.REF,
          TNP: previousValue.TNP + currentValue.TNP,
          TREG: previousValue.TREG + currentValue.TREG,
          TREG_PER: previousValue.TREG_PER + currentValue.TREG_PER,
          TGT: previousValue.TGT + currentValue.TGT,
          TREF: previousValue.TREF + currentValue.TREF,
        }
      });
    }

}
