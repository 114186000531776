<!-- Stock Request -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Stock Request</h4>
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-foursquare" (click)="StockRequestModal(requestItems)"><span><i
                                data-feather="arrow-up-circle" class="font-small-4"></i>
                            Request Medicine / Item</span></button>
                </div>
            </div>
        </div>
        <hr>

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-primary active border__none ml-50" ngbTooltip="Edit"
                            (click)="StockRequestModal(requestItems, row)" tooltipClass="fadeInAnimation"
                            placement="right">
                            <span>
                                <i data-feather='edit' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <button class="btn btn-outline-danger active border__none ml-50" ngbTooltip="Remove"
                            tooltipClass="fadeInAnimation" (click)="deletestock_item(row.id)" placement="right">
                            <span>
                                <i data-feather='x' class="font-small-4">
                                </i>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="Medicine / Item Name" prop="medicine_name"
                [width]="250">
                <ng-template let-requested_time="value" ngx-datatable-cell-template let-row="row">
                    <span>{{row.medicine_name ? row.medicine_name : row.item_name}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="potency" prop="potency" [width]="150">
                <ng-template let-potency="value" ngx-datatable-cell-template>
                    <span>{{potency ? potency : "-"}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="quantity" prop="quantity" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column name="pharmacy type" [width]="180">
                <ng-template ngx-datatable-cell-template let-row='row'>
                    <span>{{row.medicine_name ? "Medicines" : "Items"}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="150">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill" [ngClass]="{
                      'badge-light-primary': status == '2',
                      'badge-light-success': status == '3',
                      'badge-light-warning': status == '1',
                      'badge-light-danger': status == '4'
                    }">
                        {{
                        status == 1
                        ? 'Requested'
                        : status == 2
                        ? 'Dispatched'
                        : status == 3
                        ? 'Received'
                        : status == 4
                        ? 'Rejected' : '-'
                        }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Req Date" prop="requested_time" [width]="150">
                <ng-template let-requested_time="value" ngx-datatable-cell-template>
                    <span>{{requested_time ? (requested_time | date: 'dd-MM-yyyy hh:mm:ss') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="from" prop="from" [width]="150"></ngx-datatable-column> -->
            <ngx-datatable-column name="Store Send Date" prop="sent_time" [width]="200">
                <ng-template let-sent_time="value" ngx-datatable-cell-template>
                    <span>{{sent_time ? (sent_time | date: 'dd-MM-yyyy hh:mm:ss') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Received Date" prop="received_time" [width]="200">
                <ng-template let-received_time="value" ngx-datatable-cell-template>
                    <span>{{received_time ? (received_time | date: 'dd-MM-yyyy hh:mm:ss') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (change)="onFooterPage($event)" [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'" [(page)]="curPage" [size]="basicSelectedOption"
                        [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Stock Request -->

<!-- Request Items Modal -->
<ng-template #requestItems let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Request Medicine / Item</h4>
        <button type="button" class="close" (click)="closeRequestItemsForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row">
            <div class="col-12">
                <form [formGroup]="requestItemsForm" (ngSubmit)="submitRequestItemsForm()">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <h5>Pharmacy Item <span class="text-danger">*</span></h5>
                                <ng-select [items]="pharmacy_items" bindLabel="name" bindValue="id"
                                    placeholder="Select Item" (change)="showMedicinesDiv($event.name)"
                                    formControlName="stock_item"
                                    [ngClass]="{ 'is-invalid error': errorMessages.stock_item && stock_item.errors && stock_item.touched }">
                                </ng-select>
                                <div *ngFor="let error of errorMessages.stock_item">
                                    <ng-container *ngIf="stock_item.hasError(error.type) && (stock_item.touched)">
                                        <small class="text-danger">{{error.message}}</small>
                                    </ng-container>
                                </div>
                                <div *ngIf="requestItemsForm.controls.stock_item.hasError('serverValidationError')">
                                    <small class="text-danger">{{ backendValidations['stock_item'] }}</small>
                                </div>
                            </div>
                            <div class="form-group" *ngIf="!medicinesList">
                                <h5>Quantity <span class="text-danger">*</span></h5>
                                <input required type="text"
                                    class="form-control" formControlName="quantity"
                                    [ngClass]="{ 'is-invalid error': errorMessages.quantity && quantity.errors && quantity.touched }">
                                <div *ngFor="let error of errorMessages.quantity">
                                    <ng-container *ngIf="quantity.hasError(error.type) && (quantity.touched)">
                                        <small class="text-danger">{{error.message}}</small>
                                    </ng-container>
                                </div>
                                <div *ngIf="requestItemsForm.controls.quantity.hasError('serverValidationError')">
                                    <small class="text-danger">{{ backendValidations['quantity'] }}</small>
                                </div>
                            </div>

                            <div *ngIf="medicinesList">
                                <div class="form-group">
                                    <h5>Medicine Name <span class="text-danger">*</span></h5>
                                    <ng-select [items]="medicines" bindLabel="name" bindValue="id" placeholder="Select"
                                        formControlName="medicine_name"
                                        [ngClass]="{ 'is-invalid error': errorMessages.medicine_name && medicine_name.errors && medicine_name.touched }">
                                    </ng-select>
                                    <div *ngFor="let error of errorMessages.medicine_name">
                                        <ng-container
                                            *ngIf="medicine_name.hasError(error.type) && (medicine_name.touched)">
                                            <small class="text-danger">{{error.message}}</small>
                                        </ng-container>
                                    </div>
                                    <div
                                        *ngIf="requestItemsForm.controls.medicine_name.hasError('serverValidationError')">
                                        <small class="text-danger">{{ backendValidations['medicine_name'] }}</small>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <h5>Potency <span class="text-danger">*</span></h5>
                                    <ng-select [items]="potencies" bindValue="id" bindLabel="potency"
                                        placeholder="Select" formControlName="potency_id"
                                        [ngClass]="{ 'is-invalid error': errorMessages.potency_id && potency_id.errors && potency_id.touched }">
                                    </ng-select>
                                    <div *ngFor="let error of errorMessages.potency_id">
                                        <ng-container *ngIf="potency_id.hasError(error.type) && (potency_id.touched)">
                                            <small class="text-danger">{{error.message}}</small>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="requestItemsForm.controls.potency_id.hasError('serverValidationError')">
                                        <small class="text-danger">{{ backendValidations['potency_id'] }}</small>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-4">
                                        <div class="form-group">
                                            <h5>10ML</h5>
                                            <input (keypress)="allowNumbersOnly($event)" (change)="updateMedicineQuantity($event)" type="text"
                                                formControlName="ml_10" class="form-control">
                                            <div
                                                *ngIf="requestItemsForm.controls.ml_10.hasError('serverValidationError')">
                                                <small class="text-danger">{{ backendValidations['ml_10'] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-4">
                                        <div class="form-group">
                                            <h5>30ML</h5>
                                            <input (keypress)="allowNumbersOnly($event)" (change)="updateMedicineQuantity($event)" type="text"
                                                formControlName="ml_30" class="form-control">
                                            <div
                                                *ngIf="requestItemsForm.controls.ml_30.hasError('serverValidationError')">
                                                <small class="text-danger">{{ backendValidations['ml_30'] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-4">
                                        <div class="form-group">
                                            <h5>100ML</h5>
                                            <input (keypress)="allowNumbersOnly($event)" (change)="updateMedicineQuantity($event)" type="text"
                                                formControlName="ml_100" class="form-control">
                                            <div
                                                *ngIf="requestItemsForm.controls.ml_100.hasError('serverValidationError')">
                                                <small class="text-danger">{{ backendValidations['ml_100'] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let error of errorMessages.quantity">
                                    <ng-container
                                        *ngIf="quantity.hasError(error.type) && (quantity.touched)">
                                        <small class="text-danger">{{error.message}}</small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-12 col-12" *ngIf="selectedStock.status == 2">
                                <div class="demo-inline-spacing">
                                    <div class="custom-control custom-checkbox mt-0">
                                        <input required type="checkbox" id="one" formControlName="received_check"
                                            class="custom-control-input" />
                                        <label class="custom-control-label" for="one">Received</label>
                                        <div *ngFor="let error of errorMessages.received_check">
                                            <ng-container
                                                *ngIf="received_check.hasError(error.type) && (received_check.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center mb-50 mt-50" *ngIf="!selectedStock || selectedStock.status < 3">
                            <button [disabled]="requestLoading | async" type="submit" class="btn btn-primary">
                                <span *ngIf="requestLoading | async"
                                    class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                            <button *ngIf="!selectedStock" type="reset" class="btn btn-secondary ml-50">Reset</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Request Items Modal -->

<!-- Edit Modal -->
<ng-template #editItems let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Edit</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <div class="row existingInformation-data">
            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                <form [formGroup]="updateRequestForm" (ngSubmit)="submitUpdateRequest()">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <label>Medicine / Item</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p class="txt-ovrflow-wrap">{{ selectedRow.medicine_name ? selectedRow.medicine_name
                                        : selectedRow.item_name ? selectedRow.item_name : '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="row ">
                                <div class="col-lg-6 col-md-6">
                                    <label>Status</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <p> {{
                                        selectedRow.status == 1
                                        ? 'Requested'
                                        : selectedRow.status == 2
                                        ? 'Dispatched'
                                        : selectedRow.status == 3
                                        ? 'Received' : '-'
                                        }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <label>Quantity</label>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input *ngIf="selectedRow.status == 1" type="text"
                                            formControlName="quantity_update" class="form-control"
                                            (keypress)="allowNumbersOnly($event)">
                                        <input *ngIf="selectedRow.status != 1" type="text"
                                            formControlName="quantity_update" class="form-control" readonly>
                                        <div *ngFor="let error of errorMessages.quantity_update">
                                            <ng-container
                                                *ngIf="quantity_update.hasError(error.type) && (quantity_update.touched)">
                                                <small class="text-danger">{{error.message}}</small>
                                            </ng-container>
                                        </div>
                                        <div
                                            *ngIf="updateRequestForm.controls.quantity_update.hasError('serverValidationError')">
                                            <small class="text-danger">{{ backendValidations['quantity_update'] }}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 text-center">
                            <div class="col-lg-12 col-12" *ngIf="selectedRow.status == 2">
                                <div class="demo-inline-spacing">
                                    <div class="custom-control custom-checkbox mt-0">
                                        <input type="checkbox" id="one" formControlName="received_check"
                                            class="custom-control-input" />
                                        <label class="custom-control-label" for="one">Received</label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<!-- / Edit Modal -->