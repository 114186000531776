import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FoeService } from '@foe/services/foe.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'app-visits-modal',
  templateUrl: './visits-modal.component.html',
  styleUrls: ['./visits-modal.component.scss']
})
export class VisitsModalComponent implements OnInit {

  selectedPatientId: any;
  public modalRef: any;
  visitDetails: any;


  @ViewChild("visitsModal") visitsModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: FoeService) { }

  ngOnInit(): void {
  }
  openModal(patient_id) {
  	this.selectedPatientId = patient_id;
  	this.getPatientInvoiceData();
  	this.modalRef = this.modalService.open(this.visitsModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
      	size: "xl",
  	});
  }

  getPatientInvoiceData() {
  	this._service.getPatientVisitData(this.selectedPatientId)
  	.subscribe(res => {
  	    let data = res['data'];
  	    this.visitDetails = data['visit'];
        
  	}, err => {
  	  this.notifyService.showError(err['message'], err['title']);
  	});
  }

}
