<!-- Leads Data -->
<section id="ngx-datatable-kitchen-sink">
    <core-card-snippet [snippetCode]="_snippetCodeKitchenSink">
        <div class="card-header d-block">
            <ng-content select=".card-title"></ng-content>
            <div class="row align-items-center">
                <div class="col-6">
                    <h4 class="card-title">Leads</h4>
                </div>
                <div class="col-6 text-right">
                    <!-- <a csvLink [data]="exportCSVData" class="btn btn-outline-secondary" rippleEffect><i
                            data-feather="file-text"></i> Export</a> -->
                    <button class="btn btn-foursquare ml-1 p-50" (click)="modalOpen(leadsModal, 'ADD')">
                        <span><i class="fa fa-plus-square"></i> Add</span>
                    </button>
                    <button class="btn btn-primary ml-1" (click)="toggleShow()"><span><i data-feather="filter"></i>
                            Filters</span></button>
                </div>
            </div>
        </div>
        <hr>
        <!-- Show Filter -->
        <div class="p-1" *ngIf="isShown">
            <div class="row">
                <div class="col-12">
                    <form [formGroup]="leadsFilterForm" (ngSubmit)="applyFilter()">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Name</label>
                                    <input formControlName="fname" type="text" name="" id="" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Mobile</label>
                                    <input formControlName="fmobile" type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Followup Date</label>
                                    <input formControlName="ffollowup_date" type="date" class="form-control">
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                <div class="form-group">
                                    <label for="">Feedback Status</label>
                                    <ng-select formControlName="fstatus" [items]="fbstatus" bindLabel="type"
                                        bindValue="value" placeholder="Select"></ng-select>
                                </div>
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                <div>
                                    <button [disabled]="filterLoading.value" type="submit" class="btn btn-primary"><span
                                            *ngIf="filterLoading | async"
                                            class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    <button (click)="resetFilter()" type="reset"
                                        class="btn btn-secondary ml-50">Reset</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Show Filter End-->

        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="leadsData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-primary active border__none ml-50" ngbTooltip="Edit"
                            tooltipClass="fadeInAnimation" placement="right" (click)="editModal.openModal(row)">
                            <span>
                                <i data-feather='edit' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <button class="btn btn-outline-success active border__none ml-50" ngbTooltip="Feedback"
                            tooltipClass="fadeInAnimation" placement="right"
                            (click)="leadsFeedBackModal.openModal(row)">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="mobile" prop="mobile" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column name="email id" prop="email" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="address" prop="address" [width]="200">
                <ng-template ngx-datatable-cell-template let-address="value" let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two" (click)="feedbackModal(latest_address, row.address)">{{address ?
                            address : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [cellClass]="'word-span-el'" name="Feedback" prop="feedback" [width]="200">
                <ng-template ngx-datatable-cell-template let-feedback="value" let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two" (click)="feedbackModal(latest_feedback, row.feedback)">{{feedback ?
                            feedback : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [cellClass]="'word-span-el'" [width]="250">
                <ng-template ngx-datatable-cell-template let-status="value" let-row="row">
                    <span class="badge badge-pill" [ngClass]="{
                        'badge-light-success': status == '1',
                        'badge-light-danger': status == '2',
                        'badge-light-warning': status == '3'
                                          }">{{ status == 1 ? "Interested" :
                        status == 2 ? "Not interested":
                        status == 3 ? "Followup required":"-"}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Appointment Date" prop="appointment_date" [width]="200">
                <ng-template let-appointment_date="value" ngx-datatable-cell-template>
                    <div>{{appointment_date ? (appointment_date | date: 'dd-MM-yyyy') : '-'}}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Followup Date" prop="followup_date" [width]="200">
                <ng-template let-followup_date="value" ngx-datatable-cell-template>
                    <div>{{followup_date ? (followup_date | date: 'dd-MM-yyyy') : '-'}}</div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </core-card-snippet>
</section>
<!--/ Leads Data -->


<!-- Latest Feedback -->
<ng-template #latest_feedback let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Latest Feedback</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
        <p class="txt-ovrflow-wrap">{{selectedFeedback}}.</p>
    </div>
</ng-template>
<!-- / Latest Feedback -->

<!--Address -->
<ng-template #latest_address let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Address</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body txt-ovrflow-wrap" tabindex="0" ngbAutofocus>
        <address class="txt-ovrflow-wrap">{{selectedFeedback}}.</address>
    </div>
</ng-template>
<!-- Address-->

<!-- Add -->
<ng-template #leadsModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">{{action}} Lead</h4>
        <button type="button" class="close" (click)="closeLeadForm()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" tabindex="0" ngbAutofocus>
        <form [formGroup]="leadForm" (ngSubmit)="addLeads()">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Name <span class="text-danger">*</span></label>
                        <input formControlName="name" type="text" class="form-control"
                            [ngClass]="{ 'is-invalid error': errorMessages.name && name.errors && name.touched }">
                        <div *ngFor="let error of errorMessages.name">
                            <ng-container *ngIf="name.hasError(error.type) && (name.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.name.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['name'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Mobile Number <span class="text-danger">*</span></label>
                        <input formControlName="mobile" type="text" class="form-control"
                            onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="10"
                            oninput="this.value=this.value.slice(0,this.maxLength)"
                            [ngClass]="{ 'is-invalid error': errorMessages.mobile && mobile.errors && mobile.touched }">
                        <div *ngFor="let error of errorMessages.mobile">
                            <ng-container *ngIf="mobile.hasError(error.type) && (mobile.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.mobile.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['mobile'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Email</label>
                        <input formControlName="email" type="email" class="form-control"
                            [ngClass]="{ 'is-invalid error': errorMessages.email && email.errors && email.touched }">
                        <div *ngFor="let error of errorMessages.email">
                            <ng-container *ngIf="email.hasError(error.type) && (email.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.email.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['email'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Address <span class="text-danger">*</span></label>
                        <textarea formControlName="address" rows="3" class="form-control"
                            [ngClass]="{ 'is-invalid error': errorMessages.address && address.errors && address.touched }"></textarea>
                        <div *ngFor="let error of errorMessages.address">
                            <ng-container *ngIf="address.hasError(error.type) && (address.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.address.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['address'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Branch <span class="text-danger">*</span></label>
                        <ng-select formControlName="branch_id" [items]="branches" bindLabel="name" bindValue="id"
                            placeholder="Select"
                            [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                        </ng-select>
                        <div *ngFor="let error of errorMessages.branch_id">
                            <ng-container *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.branch_id.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['branch_id'] }}</small>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="form-group mb-50">
                        <label for="">Visit Date <span class="text-danger">*</span></label>
                        <input formControlName="appointment_date" type="date" class="form-control"
                            [ngClass]="{ 'is-invalid error': errorMessages.appointment_date && appointment_date.errors && appointment_date.touched }">
                        <div *ngFor="let error of errorMessages.appointment_date">
                            <ng-container *ngIf="appointment_date.hasError(error.type) && (appointment_date.touched)">
                                <small class="text-danger">{{error.message}}</small>
                            </ng-container>
                        </div>
                        <div *ngIf="leadForm.controls.appointment_date.hasError('serverValidationError')">
                            <small class="text-danger">{{ backendValidations['appointment_date'] }}</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center">
                <button [disabled]="loading | async" class="btn btn-primary" type="submit">
                    <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1"></span>Add
                </button>
                <button class="btn btn-secondary ml-1" type="reset">Reset</button>
            </div>
        </form>
    </div>
</ng-template>
<!--/ Add -->

<!-- Edit -->
<edit-leads #editModal (reloadLeads)="getLeadsData()"></edit-leads>
<!--/ Edit -->

<!-- FeedBack Modal -->
<leads-feedback #leadsFeedBackModal (reloadLeads)="getLeadsData()"></leads-feedback>
<!--/ FeedBack Modal -->