<ng-template #auditModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Audit & BR Patient Feedback Report</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="table-responsive">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.NO</th>
                            <th>Patient ID</th>
                            <th>Patient Name</th>
                            <th>Due</th>
                            <th>Tenure</th>
                            <th>LV Date</th>
                            <th>NV Date</th>
                            <th>Call Date</th>
                            <th>Coming Date</th>
                            <th>Purpose</th>
                            <th>Status</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let feedback of patientFeedbacks; let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{feedback.patient_unique_id}}</td>
                            <td>{{feedback.patient_name}}</td>
                            <td>{{ feedback.due_amount }}</td>
                            <td>{{ feedback.package_name }}</td>
                            <td>{{ feedback.latest_visit_date }}</td>
                            <td>{{ feedback.latest_next_visit_date }}</td>
                            <td>{{ feedback.call_date }}</td>
                            <td>{{ feedback.recall_date }}</td>
                            <td>{{ feedback.purpose }}</td>
                            <td>{{ feedback.status_per }}</td>
                            <td>{{ feedback.remarks }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>