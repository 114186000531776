import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PharmacyRoutingModule } from './pharmacy-routing.module';
import { PharmacyComponent } from './pharmacy.component';
import { LoginComponent } from './auth/login/login.component';
import { SharedModule } from '@pharmacy/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PharmacyHomeComponent } from '@pharmacy/pharmacy-home/pharmacy-home.component';
import { StockRequestComponent } from './stock-request/stock-request.component';
import { CourierComponent } from './courier/courier.component';
import { MyAccountComponent } from './my-account/my-account.component';

@NgModule({
  declarations: [PharmacyComponent, LoginComponent, PrescriptionComponent, PharmacyHomeComponent, StockRequestComponent, CourierComponent, MyAccountComponent],
  imports: [
    CommonModule,
    PharmacyRoutingModule,

    SharedModule,
    LayoutModule
  ]
})
export class PharmacyModule { }
