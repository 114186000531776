import { AccountsService } from '@accounts/services/accounts.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { GmService } from '@gm/services/gm.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AgmService } from "../service/agm.service";

@Component({
  selector: 'app-month-report',
  templateUrl: './month-report.component.html',
  styleUrls: ['./month-report.component.scss']
})
export class MonthReportComponent implements OnInit {
  report_type: any;
  isShown: boolean = false;
  month_status: any;
  currentDate = new Date();
  public filterLoading = new BehaviorSubject(false);
  selectedState: any;
  selectedMonth: any;
  monthReportForm = this._formBuilder.group({
    fmonth: [null, Validators.required],
  });
  stateWise: any;
  backendValidations: any;
  public ColumnMode = ColumnMode;
  state_id: any;
  get fmonth() { return this.monthReportForm.get('fmonth'); }
  smsreport_total:any;
  monthWise = [
    { id: 1, title: 'Previous Month', value: 'prev_month' },
    { id: 2, title: 'This Month', value: 'this_month' }
  ];

  day_status = false;

  errorMessages = {
    fmonth: [{ type: 'required', message: 'This field is required' }],
  }

  constructor(
    private notifyService: NotificationService,
    private _formBuilder: FormBuilder,
    private masterService: MasterDataService,
    private _service: GmService,
    private service: AgmService,
    ) { }

  ngOnInit(): void {
    this.getProfileDetails();
  }

  searchReport() {
    this.monthReportForm.markAllAsTouched();
    if (this.monthReportForm.valid) {
      this.monthReportForm.value.stateWiseReport = true;
      this.monthReportForm.value.state = this.state_id;
      this._service.monthStatusData(this.monthReportForm.value)
        .pipe(finalize(() => this.filterLoading.next(false)))
        .subscribe(res => {
          this.month_status = res['data'];
          this.report_type = this.monthReportForm.value.fmonth;
          this.smsreport_total = this.getTotal(this.month_status);
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }
  }



  selectMonth(month) {
    if (month.value == "prev_month") {
      this.selectedMonth = new Date();
      this.selectedMonth.setMonth(this.selectedMonth.getMonth() - 1);
    } else if (month.value == "this_month") {
      this.selectedMonth = new Date();
    }
  }

  summaryForProp1(cells: any[]) {
    const filteredCells = cells.filter(cell => !!cell);
    return filteredCells.reduce((sum, cell) => (sum += parseFloat(cell)), 0);
  }

  summaryForProp() {
    return "Total";
  }
  getProfileDetails(){
    this.service.getProfileDetails().subscribe(res => {
      console.log(res['state_id']);
     this.state_id = res['state_id'];
    }, err => {
      console.log(err.error);
    });
  }
  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        acq: previousValue.acq + currentValue.acq,
        camp: previousValue.camp + currentValue.camp,
        cash: previousValue.cash + currentValue.cash,
        cc: previousValue.cc + currentValue.cc,
        ccv: previousValue.ccv + currentValue.ccv,
        chq: previousValue.chq + currentValue.chq,
        con: previousValue.con + currentValue.con,
        exp: previousValue.exp + currentValue.exp,
        mid_gt: previousValue.mid_gt + currentValue.mig_gt,
        np: previousValue.np + currentValue.np,
        oc: previousValue.oc + currentValue.oc,
        ol: previousValue.ol + currentValue.ol,
        online: previousValue.online + currentValue.online,
        rchq: previousValue.rchq + currentValue.rchq,
        ref: previousValue.ref + currentValue.ref,
        reg: previousValue.reg + currentValue.reg,
        rl: previousValue.rl + currentValue.rl,
        rmp: previousValue.rmp + currentValue.rmp,
        target: previousValue.target + currentValue.target,
        wi: previousValue.wi + currentValue.wi,
      }
    });
  }
}
