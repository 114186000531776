import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { CsvModule } from '@ctrl/ngx-csv';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { SingleValidationModule } from './single-validation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

const myCoreComponents = [
  FormsModule,
  ReactiveFormsModule,
  // SingleValidationModule,
  NgbModule,
  NgxDatatableModule,
  CardSnippetModule,
  CsvModule,
  NgbTooltipModule,
  NgSelectModule,
  CoreDirectivesModule,
  Ng2FlatpickrModule
];

@NgModule({
  imports: [myCoreComponents],
  exports: [myCoreComponents]
})
export class SharedModule { }
