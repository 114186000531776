import { Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { finalize } from 'rxjs/operators';

import { NotificationService } from '@core/services/notification.service';
import { ProService } from '@pro/services/pro.service';

@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  @Output() reloadDataTable = new EventEmitter<any>();
  feedbackUpdateLoading = new BehaviorSubject(false);
  selectedPatientFeedback: any;
  backendValidations: any;
  purpose: any;
  currentDate = new Date().toISOString().slice(0, 10);
  public modalRef: any;

  // Feeback Submit Form
  feedbackSubmitForm : FormGroup = this._formBuilder.group({
    feedback_status : [null, Validators.required],
    feedback_sub_status: [null],
    appointment_date: [null, Validators.required],
    remarks: [null, [Validators.required, Validators.maxLength(500)]]
  });

  get feedback_status(){ return this.feedbackSubmitForm.get('feedback_status'); }
  get feedback_sub_status(){ return this.feedbackSubmitForm.get('feedback_sub_status'); }
  get appointment_date(){ return this.feedbackSubmitForm.get('appointment_date'); }
  get remarks(){ return this.feedbackSubmitForm.get('remarks'); }

  errorMessages = {
    feedback_status: [{ type:'required',message:'The FeedBack Status field is required'}],
    feedback_sub_status: [{ type:'required',message:'The FeedBack Sub Status field is required'}],
    appointment_date: [{ type:'required',message:'The Appointment Date field is required'}],
    remarks: [{ type:'required',message:'The Remarks field is required'},
              { type: 'maxlength', message: 'The Remarks Max length is 500' }]
  }

  public feedbackStatus = [
    { id: 1, value: "Switch off/NR Report", title: "Switch Off" },
    { id: 2, value: "Invalid Number/Out of Service Report", title: "Invalid Number" },
    { id: 3, value: "Wrong Number Report", title: "Wrong Number" },
    { id: 4, value: "Out of Station Report", title: "Out of Station" },
    { id: 5, value: "Busy/Cal disconnect Report", title: "Busy" },
    { id: 6, value: "Cured&Satisfied Report", title: "Satisfied" },
    { id: 7, value: "Unsatisfied Report", title: "Unsatisfied" },
    { id: 8, value: "Call&Come Report", title: "Call&Come" },
    { id: 9, value: "Interested Report", title: "Interested" },
    { id: 10, value: "Refund & Dont Disturb Report", title: "Refund" },
    { id: 11, value: "Courier Report", title: "Courier" },
  ];

  public subStatus = [
    {id:1,value:'Paid Amt Not Matched'},
    {id:2,value:'Paid Method Not Matched'},
    {id:3,value:'High Prize'},
    {id:4,value:'Service'},
    {id:5,value:'Unsatisfy With Doctor'},
    {id:6,value:'Unsatisfy With Pro'},
    {id:7,value:'Insuficent Funds'},
    {id:8,value:'Poor Treatment'},
    {id:9,value:'Service'},
    {id:10,value:'Shifted to Another Location'},
    {id:11,value:'Refunded Issued Patient'},
  ];

	@ViewChild("feedBackModal") feedBackModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private _service: ProService) { }

  ngOnInit(): void {
    this.feedbackSubmitForm.controls['feedback_sub_status'].disable();
  }

  /* Open FeedBack Modal */
  openModal(row, purpose) {
    this.selectedPatientFeedback = row;
    this.purpose = purpose;
  	this.modalRef = this.modalService.open(this.feedBackModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
  	});
  }

  /* Close Feedback Modal */
  closeModal() {
    this.feedbackSubmitForm.reset();
    this.modalService.dismissAll();
  }

  /* Submit FeedBack */
  submitFeedBack() {
    this.feedbackSubmitForm.markAllAsTouched();
    if (this.feedbackSubmitForm.valid) {
      this.feedbackUpdateLoading.next(true);
      this.feedbackSubmitForm.value.patient_id = this.selectedPatientFeedback.patient_id;
      this.feedbackSubmitForm.value.id = this.selectedPatientFeedback.id;
      this.feedbackSubmitForm.value.purpose = this.purpose;
      this._service.submitFeedBackForm(this.feedbackSubmitForm.value)
      .pipe(finalize(() => this.feedbackUpdateLoading.next(false)))
      .subscribe(res => {
        if(res['status'] == 'success') {

          this.feedbackSubmitForm.reset();
          this.notifyService.showSuccess(res['message'], res['title']);
          this.modalRef.close();
          this.reloadDataTable.emit(true);
        }else if(res['status'] == 'errors') {

          for(var key in res['errorValidations']) {
              let backendValidations = res["errorValidations"];
              this.backendValidations = backendValidations;
              this.feedbackSubmitForm.controls[key].setErrors({serverValidationError: true});
           }
        }else if(res['status'] == 'error') {
          this.notifyService.showError(res['message'], res['title']);
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
    }
  }

  /* Based On Status Enable Sub-Status */
  changeStatus($event) {
    if ($event.value != "Unsatisfied") {

      this.feedbackSubmitForm.controls.feedback_sub_status.setValidators(null)
      this.feedbackSubmitForm.controls.feedback_sub_status.updateValueAndValidity();
      this.feedbackSubmitForm.controls['feedback_sub_status'].disable();
    } else {

      this.feedbackSubmitForm.controls.feedback_sub_status.setValidators(Validators.required)
      this.feedbackSubmitForm.controls.feedback_sub_status.updateValueAndValidity();
      this.feedbackSubmitForm.controls['feedback_sub_status'].enable();
    }
  }
}
