<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Branches Wise Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date - To Date</label>
                                            <ng2-flatpickr (ngModelChange)="onDateSelect()"
                                                formControlName="fdate_range" [config]="DateRangeOptions"
                                                placeholder="Pick a date range!"></ng2-flatpickr>

                                            <div *ngFor="let error of errorMessages.fdate_range">
                                                <ng-container
                                                    *ngIf="fdate_range.hasError(error.type) && (fdate_range.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Branch</label>
                                            <ng-select [items]="branches" bindLabel="name" bindValue="id"
                                                placeholder="Select Branch" formControlName="branch_id"
                                                [ngClass]="{ 'is-invalid error': errorMessages.branch_id && branch_id.errors && branch_id.touched }">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.branch_id">
                                                <ng-container
                                                    *ngIf="branch_id.hasError(error.type) && (branch_id.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'summary_report'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Summary Reports</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Revenue</th>
                                <th>Expentiture</th>
                                <th>By Cash</th>
                                <th>By Cheque</th>
                                <th>By CC</th>
                                <th>Total Amount</th>
                        </thead>
                        <tbody>
                            <tr class="patients-reports-stats" *ngFor="let item of tableData">
                                <td>{{ item.date }}</td>
                                <td>{{ item.total }}</td>
                                <td>{{ item.expense }}</td>
                                <td>{{ item.cash }}</td>
                                <td>{{ item.cheque }}</td>
                                <td>{{ item.cc }}</td>
                                <td>{{ item.diffrence }}</td>

                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'pharmacy_stock'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="id" prop="id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Medicine Name" prop="medicine_name" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Potency" prop="potency" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Quantity" prop="quantity" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="10 ML" prop="tenml" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="30 ML" prop="thirtyml" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="100 ML" prop="hundredml" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'biochemical_list'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="id" prop="id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Medicine Name" prop="medicine_name" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Quantity" prop="quantity" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'doc-wise-reports'">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">Doctor wise total status</h4>
                </div>
                <hr>
                <div class="card-body pb-1 table-responsive">
                    <table class="table table-bordered text-center align-middle">
                        <thead>
                            <tr>
                                <th>BR</th>
                                <th>Doctor Name</th>
                                <th>NP</th>
                                <th>REG</th>
                                <th>OC</th>
                                <th>CC</th>
                                <th>IB</th>
                                <th>IB-Reg</th>
                                <th>OB</th>
                                <th>OB-Reg</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>CCPAK</th>
                                <th>CR</th>
                                <th>IBR</th>
                                <th>OBR</th>
                                <th>WK</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>WKPAK</th>
                                <th>WR</th>
                                <th>REF</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>REFPAK</th>
                                <th>RR</th>
                                <th>RMP</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>RMPPAK</th>
                                <th>RPR</th>
                                <th>CAMP</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>CMPPAK</th>
                                <th>CAPR</th>
                                <th>ONLINE</th>
                                <th>OC</th>
                                <th>REG</th>
                                <th>ONPAK</th>
                                <th>OLPR</th>
                                <th>OP</th>
                                <th>REN</th>
                                <th>RENR</th>
                                <th>DROP</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tableData">
                                <td><b>{{item.branch_name}}</b></td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.np }}</td>
                                <td>{{ item.reg }}</td>
                                <td>{{ item.oc }}</td>
                                <td>{{ item.cc }}</td>
                                <td>{{ item.ib }}</td>
                                <td>0</td>
                                <td>{{ item.ob }}</td>
                                <td></td>
                                <td>{{ item.oc_cc }}</td>
                                <td>{{ item.reg_cc }}</td>
                                <td>{{ item.package_cc }}</td>
                                <td>{{ item.received_cc }}</td>
                                <td>0</td>
                                <td>0</td>
                                <td>{{ item.wk }}</td>
                                <td>{{ item.oc_wk }}</td>
                                <td>{{ item.reg_wk }}</td>
                                <td>{{ item.package_wk }}</td>
                                <td>{{ item.received_wk }}</td>
                                <td>{{ item.ref }}</td>
                                <td>{{ item.oc_ref }}</td>
                                <td>{{ item.reg_ref }}</td>
                                <td>{{ item.package_ref }}</td>
                                <td>{{ item.received_ref }}</td>
                                <td>{{ item.rmp }}</td>
                                <td>{{ item.oc_rmp }}</td>
                                <td>{{ item.reg_rmp }}</td>
                                <td>{{ item.package_rmp }}</td>
                                <td>{{ item.received_rmp }}</td>
                                <td>{{ item.camp }}</td>
                                <td>{{ item.oc_camp }}</td>
                                <td>{{ item.reg_camp }}</td>
                                <td>{{ item.package_camp }}</td>
                                <td>{{ item.received_camp }}</td>
                                <td>{{ item.online }}</td>
                                <td>{{ item.oc_online }}</td>
                                <td>{{ item.reg_online }}</td>
                                <td>{{ item.package_online }}</td>
                                <td>{{ item.received_online }}</td>
                                <td>{{ item.op }}</td>
                                <td>{{ item.renewal_cnt }}</td>
                                <td>{{ item.renewal_amount }}</td>
                                <td>{{ item.drop_cnt }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'sms-status'">
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle mb-0">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>FNP</th>
                        <th>DNP</th>
                        <th>REG</th>
                        <th>ACQ</th>
                        <th>RGL</th>
                        <th>OC</th>
                        <th>WK</th>
                        <th>REF</th>
                        <th>CC</th>
                        <th>RMP</th>
                        <th>CAMP</th>
                        <th>ONLINE</th>
                        <th>GT</th>
                        <th>CON</th>
                        <th>CASH</th>
                        <th>CHQ</th>
                        <th>CC</th>
                        <th>EMI</th>
                        <th>OL</th>
                        <th>CHR</th>
                        <th>EXP</th>
                    </tr>
                </thead>
                <tbody>
                   <tr>
                    <td>{{tableData.branch_code}}</td>
                    <td>{{tableData.FNP}}</td>
                    <td>{{tableData.DNP}}</td>
                    <td>{{tableData.REG}}</td>
                    <td>{{tableData.ACQ}}</td>
                    <td>{{tableData.RGL}}</td>
                    <td>{{tableData.OC}}</td>
                    <td>{{tableData.WK}}</td>
                    <td>{{tableData.REF}}</td>
                    <td>{{tableData.CC}}</td>
                    <td>{{tableData.RMP}}</td>
                    <td>{{tableData.CAMP}}</td>
                    <td>{{tableData.ONLINE}}</td>
                    <td>{{tableData.GT}}</td>
                    <td>{{tableData.CON}}</td>
                    <td>{{tableData.CASH}}</td>
                    <td>{{tableData.CHQ}}</td>
                    <td>{{tableData.CC1}}</td>
                    <td>{{tableData.EMI}}</td>
                    <td>{{tableData.OL}}</td>
                    <td>{{tableData.CHR}}</td>
                    <td>{{tableData.EXP}}</td>
                   </tr>
                </tbody>
                <!-- <tfoot>
                    <tr>
                        <th id="total" colspan="1">Total :</th>
                        <td>{{smsreport_total.FNP}}</td>
                        <td>{{smsreport_total.DNP}}</td>
                        <td>{{smsreport_total.REG}}</td>
                        <td>{{smsreport_total.ACQ}}</td>
                        <td>{{smsreport_total.RGL}}</td>
                        <td>{{smsreport_total.OC}}</td>
                        <td>{{smsreport_total.WK}}</td>
                        <td>{{smsreport_total.REF}}</td>
                        <td>{{smsreport_total.CC}}</td>
                        <td>{{smsreport_total.RMP}}</td>
                        <td>{{smsreport_total.CAMP}}</td>
                        <td>{{smsreport_total.ONLINE}}</td>
                        <td>{{smsreport_total.GT}}</td>
                        <td>{{smsreport_total.CON}}</td>
                        <td>{{smsreport_total.CASH}}</td>
                        <td>{{smsreport_total.CHQ}}</td>
                        <td>{{smsreport_total.CC1}}</td>
                        <td>{{smsreport_total.EMI}}</td>
                        <td>{{smsreport_total.OL}}</td>
                        <td>{{smsreport_total.CHR}}</td>
                        <td>{{smsreport_total.EXP}}</td>
                    </tr>
                </tfoot> -->
            </table>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'incentive-reports'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient id" prop="patient_id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Unique ID" prop="patient_unique_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="BID" prop="branch_patient_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Quantity" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doctor_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Package" prop="duration" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="relation" prop="relation" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="total_amount" prop="total_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="paid_amt" prop="paid_amt" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="due_amount" prop="due_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="billing_date" prop="billing_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Mode" prop="Mode" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="receipt_no" prop="receipt_no" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="package_status" prop="package_status" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="increase_amount" prop="increase_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'referralPatient-reports'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
           <ngx-datatable-column name="Patient Unique ID" prop="patient_unique_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="BID" prop="branch_patient_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="Address" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="From Branch" prop="branches_from" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Paitient Type" prop="patient_type" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor" prop="Doctor" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Referred" prop="referred" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total" prop="total_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Paid" prop="total_paid" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Due" prop="due_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'renewalBilling-reports'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient id" prop="patient_id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Unique ID" prop="patient_unique_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="BID" prop="branch_patient_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doctor_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Package" prop="duration" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total Amount" prop="total_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Paid Amount" prop="received_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Billing Date" prop="billing_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Payment option" prop="payment_option" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Discount" prop="discount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="receipt_no" prop="receipt_no" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="package_status" prop="package_status" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="increase_amount" prop="increase_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'ocToReg-reports'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient id" prop="patient_id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Unique ID" prop="patient_unique_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doctor_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Package" prop="duration" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total maount" prop="total_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Paid amount" prop="paid_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Billing date" prop="billing_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Payment Option" prop="mode" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Receipt No" prop="receipt_no" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Pack Status" prop="status" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Inc Amount" prop="increase_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Consultation fee" prop="consultation_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'check_status'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch" prop="branch" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Receipt No" prop="receipt_no" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Bill Date" prop="bill_date" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Paid" prop="ch_paid" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Payment Option" prop="payment_option" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Cheque No." prop="cheque_no" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Bank" prop="bank" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Cheque Valid" prop="cheque_valid" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="drop_status" [width]="100">
                <ng-template let-drop_status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill" [ngClass]="{
                  'badge-light-primary': drop_status == '0',
                  'badge-light-success': drop_status == '1'
                }">
                        {{
                        drop_status == 0
                        ? 'Active'
                        : drop_status == 1
                        ? 'Drop'
                        : '-'
                        }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Cheque Status" prop="chequestatus" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'get_renewals_call'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="BP.ID " prop="branch_patient_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Date Of EXP" prop="date_of_expire" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor" prop="Doctor_Name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Last visit date" prop="last_visit_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="New Visit Date" prop="new_visit_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Coming Date" prop="coming_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Remarks" prop="remarks" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <!-- START MONTH WISE DUE LIST -->
    <div *ngIf="selectedReport && selectedReport.key == 'total_due_list'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input [formControl]='mySearchControl' type="search"
                            placeholder="Search" class="form-control ml-25" /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption">
            <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="PID" prop="patient_id" [width]="100"></ngx-datatable-column> -->
            <!-- <ngx-datatable-column name="BP.ID " prop="branch_patient_id" [width]="150" [cellClass]="'word-span-el'">
            </ngx-datatable-column> -->
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="150">
               
            </ngx-datatable-column>
            <ngx-datatable-column name="PNAME" prop="patient_name" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="MOBILE" prop="mobile" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="TOTAL" prop="total_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="PAID" prop="paid" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column> -->
            <ngx-datatable-column name="DUE" prop="due_amount" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="DURATION" prop="treatment_duration" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="LV DATE" prop="last_visit_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="DOCTOR" prop="doctor_name" [width]="250">
            </ngx-datatable-column>
            <!-- <ngx-datatable-column name="Followup date" prop="followup_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="Coming Date" prop="coming_date" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column> -->
            <!-- <ngx-datatable-column name="STATUS" prop="status" [width]="100">
                <ng-template let-drop_status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill" [ngClass]="{
                  'badge-light-primary': status == '0',
                  'badge-light-success': status == '1'
                }">
                        {{
                        status == 0
                        ? 'Active'
                        : status == 1
                        ? 'Drop'
                        : '-'
                        }}
                    </div>
                </ng-template>
            </ngx-datatable-column> -->
            <!-- <ngx-datatable-column name="Remarks" prop="remarks" [cellClass]="'word-span-el'" [width]="150">
            </ngx-datatable-column> -->
            <ngx-datatable-column name="Actions" [width]="150" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">

                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-warning active border__none ml-50"  (click)="caseSheet(row.patient_id)" ngbTooltip="Casesheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4"></i>
                            </span>
                        </button>
                        <!-- <button class="btn btn-outline-danger active border__none ml-50" ngbTooltip="Drop"
                            tooltipClass="fadeInAnimation">
                            <span><i data-feather='trash' class="font-small-4"></i></span>
                        </button> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <!-- END MONTH WISE DUE LIST -->

    <div *ngIf="selectedReport && selectedReport.key == 'dropout-list-manually'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
                      <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [columnMode]="ColumnMode.force" [limit]="10" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="180">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">
                            {{patient_unique_id ? patient_unique_id : '-'}}
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="180"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Total amount" prop="total_amount" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Due amount" prop="due_amount" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Paid" prop="received_amount" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="package_name" [width]="180">
                <ng-template let-package_name="value" ngx-datatable-cell-template let-row="row">
                    <span>
                        {{package_name ?package_name +'Months':
                        '-'}}
                    </span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last visiting date" prop="last_visit_date" [width]="180">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template let-row="row">
                    <span>{{last_visit_date ? (last_visit_date | date:'yy-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'dropout-list-automatically'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" (click)="invoiceModal.openModal(row.patient_id)">{{patient_unique_id ?
                            patient_unique_id : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visiting_date" [width]="">
                <ng-template let-last_visiting_date="value" ngx-datatable-cell-template>
                    <span>{{last_visiting_date ? (last_visiting_date | date: 'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="pack" prop="pack" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="paid" prop="paid" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="due" prop="due_amount" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="drop_status" [width]="100">
                <ng-template let-drop_status="value" ngx-datatable-cell-template>
                    <div class="badge badge-pill" [ngClass]="{
                  'badge-light-primary': drop_status == '0',
                  'badge-light-success': drop_status == '1'
                }">
                        {{
                        drop_status == 0
                        ? 'Active'
                        : drop_status == 1
                        ? 'Drop'
                        : '-'
                        }}
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Expired" prop="date_of_expire" [width]="200">
                <ng-template let-date_of_expire="value" ngx-datatable-cell-template>
                    <span>{{ date_of_expire ? (date_of_expire | date: 'dd-MM-yyyy'): '-' }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="150" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">

                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-warning active border__none ml-50"  (click)="caseSheet(row.patient_id)" ngbTooltip="Casesheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4"></i>
                            </span>
                        </button>
                        <!-- <button class="btn btn-outline-danger active border__none ml-50" ngbTooltip="Drop"
                            tooltipClass="fadeInAnimation">
                            <span><i data-feather='trash' class="font-small-4"></i></span>
                        </button> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'missed_followup'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                    </label>
                    <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="90" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Unique Id" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="Due" prop="due_amount" [width]="100"></ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Missing Followup date" prop="missing_followup_date" [width]="220">
                <ng-template let-missing_followup_date="value" ngx-datatable-cell-template>
                    <span>{{missing_followup_date ? (missing_followup_date | date:'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Updated Followup date" prop="updated_followup_date" [width]="230">
                <ng-template let-updated_followup_date="value" ngx-datatable-cell-template>
                    <span>{{updated_followup_date ? (updated_followup_date| date:'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Latest Remarks" prop="remarks" [width]="">
                <ng-template ngx-datatable-cell-template let-remarks="value"
                    (click)="remarksModal.openModal(row.remarks)">
                    <div class="d-flex align-items-center">
                        <a class="feed-max-two">{{remarks ? remarks : '-'}}</a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="230" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-dark active border__none ml-50"
                            (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet" tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <!-- <button class="btn btn-outline-light active border__none ml-50"
                            (click)="feedBackModal.openModal(row, 'Daily_follwoup')" ngbTooltip="Feedback"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='message-square' class="font-small-4">
                                </i>
                            </span>
                        </button> -->
                        <!-- <button class="btn btn-outline-info active border__none ml-50"
                            (click)="feedBackLogModal.openModal(row.patient_id, 'Daily_follwoup')"
                            ngbTooltip="Feedbacks Log" tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='list' class="font-small-4">
                                </i>
                            </span>
                        </button> -->
                        <!-- <button (click)="followUpDropModal.dropAlert(row)"
                            class="btn btn-outline-danger active border__none ml-50" ngbTooltip="Drop"
                            tooltipClass="fadeInAnimation">
                            <span><i data-feather='x' class="font-small-4"></i></span>
                        </button> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'datefollowup'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                        (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable" [limit]="10"
            [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                    <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                        {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                    </ng-template>
                </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="180">
                <ng-template ngx-datatable-cell-template let-patient_unique_id="value">
                    <span>{{patient_unique_id ? patient_unique_id :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visite Date" prop="visit_date" [width]="150">
                <ng-template ngx-datatable-cell-template let-visit_date="value">
                    <span>{{visit_date ? (visit_date | date:'dd-MM-yyyy') :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Followup Date" prop="next_visit_date" [width]="150">
                <ng-template ngx-datatable-cell-template let-next_visit_date="value">
                    <span>{{next_visit_date ? (next_visit_date | date:'dd-MM-yyyy') :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="180">
                <ng-template ngx-datatable-cell-template let-branch_patient_id="value">
                    <span>{{branch_patient_id ? branch_patient_id :'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
                <ng-template ngx-datatable-cell-template let-patient_name="value">
                    <span>{{patient_name ? patient_name :'-'}}</span>
                </ng-template></ngx-datatable-column>
            <ngx-datatable-column name="Due amount" prop="due_amount" [width]=""></ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'courier_prescription'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
        <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Gender" prop="gender" [width]="120">
                <ng-template let-gender="value" ngx-datatable-cell-template>
                    <span>{{gender == 'm' ? 'Male' :
                        gender == 'f' ? 'Female' : 'Others'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="type" prop="patient_type" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="no of days medicines" prop="no_of_days_medicines" [width]="200">
                <ng-template let-no_of_days_medicines="value" ngx-datatable-cell-template>
                    <span>{{no_of_days_medicines ? no_of_days_medicines : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="visiting date" prop="visiting_date" [width]="">
                <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                    <span>{{visiting_date ? (visiting_date | date: 'dd-MM-yyyy h:m:s') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="120">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{ status == 0 ? 'Not-Delivered' : 'Delivered'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="courier sending date" prop="courier_date" [width]="200">
                <ng-template let-courier_date="value" ngx-datatable-cell-template>
                    <span>{{courier_date ? (courier_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="address" prop="full_address" [cellClass]="'word-span-el'" [width]="200">
                <ng-template let-full_address="value" ngx-datatable-cell-template>
                    <span>{{full_address ? full_address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'branch_consultpat'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Receipt No." prop="receipt_no" [width]="200">
                <ng-template let-receipt_no="value" ngx-datatable-cell-template>
                    <span>{{receipt_no ? receipt_no : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult fee" prop="consultation_fee" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Drop status" prop="branch_drop_status" [width]="120">
                <ng-template let-branch_drop_status="value" ngx-datatable-cell-template>
                    <span>{{branch_drop_status?branch_drop_status:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="Type" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                      <span>{{consult_type == 'f' ? 'free' :
                        consult_type == 'c' ? 'Cost' : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult date" prop="consult_date" [width]="">
                <ng-template let-consult_date="value" ngx-datatable-cell-template>
                    <span>{{consult_date ? (consult_date | date: 'dd-MM-yyyy h:m:s') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'patient_satisfaction'">
      
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50" class="bootstrap core-bootstrap myDatatable"
            [limit]="10" [columnMode]="ColumnMode.force" [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [selectionType]="SelectionType.checkbox" [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'"
                [width]="170">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient type" prop="patient_type" [width]="120">
                <ng-template let-patient_type="value" ngx-datatable-cell-template>
                    <span>{{patient_type ? patient_type : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visiting_date" [width]="130">
                <ng-template let-visiting_date="value" ngx-datatable-cell-template>
                    <span>{{visiting_date ? (visiting_date | date:'dd-MM-yyyy'): '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="doctor" prop="doctor_name" [cellClass]="'word-span-el'" [width]="170">
                <ng-template let-doctor_name="value" ngx-datatable-cell-template>
                    <span>{{doctor_name ? doctor_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="120">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status ? status : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Actions" [width]="100" [sortable]="false">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <button class="btn btn-outline-light active border__none ml-50"
                            (click)="caseSheet(row.patient_id)" ngbTooltip="Case Sheet"
                            tooltipClass="fadeInAnimation">
                            <span>
                                <i data-feather='user-minus' class="font-small-4">
                                </i>
                            </span>
                        </button>
                        <!-- <div>
                            <button class="btn btn-outline-primary active border__none ml-50"
                                (click)="editPatientModal.openModal(row)" ngbTooltip="Edit"
                                tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='edit' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                            <button class="btn btn-outline-warning active border__none ml-50"
                                (click)="modalOpen(remark, row.remarks)" ngbTooltip="Remark" tooltipClass="fadeInAnimation">
                                <span>
                                    <i data-feather='eye' class="font-small-4">
                                    </i>
                                </span>
                            </button>
                        </div> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
   
    <div *ngIf="selectedReport && selectedReport.key == 'branch_comments'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="EMP CODE" prop="emp_code" [width]="120">
                <ng-template let-emp_code="value" ngx-datatable-cell-template>
                    <span>{{emp_code?emp_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch" prop="branch_name" [width]="120"></ngx-datatable-column>
            <ngx-datatable-column name="Comment Date" prop="doctor_comments_date" [width]="200">
                <ng-template let-doctor_comments_date="value" ngx-datatable-cell-template>
                    <span>{{doctor_comments_date ? (doctor_comments_date | date: 'dd-MM-yyyy h:m:s') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="comments" prop="doctor_comments" [width]="">
                <ng-template let-doctor_comments="value" ngx-datatable-cell-template>
                    <span>{{doctor_comments ?doctor_comments : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PRO comments" prop="pro_comments" [width]="">
                <ng-template let-pro_comments="value" ngx-datatable-cell-template>
                    <span>{{pro_comments ?pro_comments : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [width]="">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'walkin_patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [width]="">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'residence_patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [width]="">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'website_patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [width]="">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'callcenter_patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [cellClass]="'word-span-el'"
            [width]="170">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'docconfirmed_patients_list'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_name" [width]="120">
                <ng-template let-doctor_name="value" ngx-datatable-cell-template>
                    <span>{{doctor_name ? doctor_name:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="Address" [cellClass]="'word-span-el'"
            [width]="170">
                <ng-template let-Address="value" ngx-datatable-cell-template>
                    <span>{{Address ? Address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'doc_patient'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="patient_type" prop="patient_type" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Visit Date" prop="visit_date" [width]="200">
                <ng-template let-visit_date="value" ngx-datatable-cell-template>
                    <span>{{visit_date ? (visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Address" prop="address" [cellClass]="'word-span-el'"
            [width]="170">
                <ng-template let-address="value" ngx-datatable-cell-template>
                    <span>{{address ? address : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'transfer_today_patient'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
                 <span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch PID" prop="branch_patient_id" [width]="200" [cellClass]="'word-span-el'">
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Type" prop="patient_type" [width]="150"></ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [cellClass]="'word-span-el'" [width]="200">
            </ngx-datatable-column>
            <ngx-datatable-column name="address" prop="address" [cellClass]="'word-span-el'" [width]="250">
            </ngx-datatable-column>
            <ngx-datatable-column name="mobile" prop="mobile" [width]="100">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor code" prop="doctor_code" [width]="150">
            </ngx-datatable-column>
            <ngx-datatable-column name="to" prop="branch_name" [width]="100">
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template
                    let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)"
                        (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'"
                        [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'"
                        [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption"
                        [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'patient_feedback'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch Name" prop="branch_name" [cellClass]="'word-span-el'"
                [width]="170">
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor CODE" prop="doctor_code" [width]="120">
                <ng-template let-doctor_code="value" ngx-datatable-cell-template>
                    <span>{{doctor_code ? doctor_code:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Prescribe Date" prop="prescribe_date" [width]="200">
                <ng-template let-prescribe_date="value" ngx-datatable-cell-template>
                    <span>{{prescribe_date ? (prescribe_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
          

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_trans_emp'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PID" prop="unique_id" [width]="200">
                <ng-template let-unique_id="value" ngx-datatable-cell-template>
                    <span>{{unique_id ? unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor NAme" prop="doctor_name" [width]="120">
                <ng-template let-doctor_name="value" ngx-datatable-cell-template>
                    <span>{{doctor_name ? doctor_name:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Next Visit Date" prop="next_visit_date" [width]="200">
                <ng-template let-next_visit_date="value" ngx-datatable-cell-template>
                    <span>{{next_visit_date ? (next_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="prev_visit_date" [width]="200">
                <ng-template let-prev_visit_date="value" ngx-datatable-cell-template>
                    <span>{{prev_visit_date ? (prev_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="branch_name" prop="branch_name" [width]="">
                <ng-template let-branch_name="value" ngx-datatable-cell-template>
                    <span>{{branch_name ? branch_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Mobile" prop="mobile" [width]="">
                <ng-template let-mobile="value" ngx-datatable-cell-template>
                    <span>{{mobile ? mobile : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_ocList'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Receipt No" prop="receipt_no" [width]="200">
                <ng-template let-receipt_no="value" ngx-datatable-cell-template>
                    <span>{{receipt_no ? receipt_no : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Paitient Name" prop="patient_name" [width]="120">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Type" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                    <span>{{consult_type == 'f' ? 'free' :
                      consult_type == 'c' ? 'Cost' : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Date" prop="consult_date" [width]="200">
                <ng-template let-consult_date="value" ngx-datatable-cell-template>
                    <span>{{consult_date ? (consult_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == '45'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Receipt No" prop="receipt_no" [width]="200">
                <ng-template let-receipt_no="value" ngx-datatable-cell-template>
                    <span>{{receipt_no ? receipt_no : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fees" prop="consultation_fee" [width]="120">
                <ng-template let-consultation_fee="value" ngx-datatable-cell-template>
                    <span>{{consultation_fee ? consultation_fee:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Drop Status Type" prop="branch_drop_status" [width]="200">
                <ng-template let-branch_drop_status="value" ngx-datatable-cell-template>
                    <span>{{branch_drop_status ? branch_drop_status : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Type" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                    <span>{{consult_type == 'f' ? 'free': consult_type == 'c' ? 'Cost': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Date" prop="consult_date" [width]="200">
                <ng-template let-consult_date="value" ngx-datatable-cell-template>
                    <span>{{consult_date ? (consult_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_registerpat'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Receipt No" prop="receipt_no" [width]="200">
                <ng-template let-receipt_no="value" ngx-datatable-cell-template>
                    <span>{{receipt_no ? receipt_no : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Fees" prop="consultation_fee" [width]="120">
                <ng-template let-consultation_fee="value" ngx-datatable-cell-template>
                    <span>{{consultation_fee ? consultation_fee:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Drop Status Type" prop="branch_drop_status" [width]="200">
                <ng-template let-branch_drop_status="value" ngx-datatable-cell-template>
                    <span>{{branch_drop_status ? branch_drop_status : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Type" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                    <span>{{consult_type == 'f' ? 'free': consult_type == 'c' ? 'Cost': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult Date" prop="consult_date" [width]="200">
                <ng-template let-consult_date="value" ngx-datatable-cell-template>
                    <span>{{consult_date ? (consult_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_duepat'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Total Amount" prop="total_amount" [width]="120">
                <ng-template let-total_amount="value" ngx-datatable-cell-template>
                    <span>{{total_amount ? total_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [width]="120">
                <ng-template let-due_amount="value" ngx-datatable-cell-template>
                    <span>{{due_amount ? due_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="treatment_duration" [width]="200">
                <ng-template let-treatment_duration="value" ngx-datatable-cell-template>
                    <span>{{treatment_duration ? treatment_duration : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="200">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status == '0' ? 'Active': status == '1' ? 'Deactive': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="200">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_noduepat'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Total Amount" prop="total_amount" [width]="120">
                <ng-template let-total_amount="value" ngx-datatable-cell-template>
                    <span>{{total_amount ? total_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [width]="120">
                <ng-template let-due_amount="value" ngx-datatable-cell-template>
                    <span>{{due_amount ? due_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="treatment_duration" [width]="200">
                <ng-template let-treatment_duration="value" ngx-datatable-cell-template>
                    <span>{{treatment_duration ? treatment_duration : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="200">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status == '0' ? 'Active': status == '1' ? 'Deactive': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="200">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_monthly'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Total Amount" prop="total_amount" [width]="120">
                <ng-template let-total_amount="value" ngx-datatable-cell-template>
                    <span>{{total_amount ? total_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [width]="120">
                <ng-template let-due_amount="value" ngx-datatable-cell-template>
                    <span>{{due_amount ? due_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="treatment_duration" [width]="200">
                <ng-template let-treatment_duration="value" ngx-datatable-cell-template>
                    <span>{{treatment_duration ? treatment_duration : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="200">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status == '0' ? 'Active': status == '1' ? 'Deactive': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="200">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_act'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP.ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Total Amount" prop="total_amount" [width]="120">
                <ng-template let-total_amount="value" ngx-datatable-cell-template>
                    <span>{{total_amount ? total_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Due Amount" prop="due_amount" [width]="120">
                <ng-template let-due_amount="value" ngx-datatable-cell-template>
                    <span>{{due_amount ? due_amount:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Treatment Duration" prop="treatment_duration" [width]="200">
                <ng-template let-treatment_duration="value" ngx-datatable-cell-template>
                    <span>{{treatment_duration ? treatment_duration : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name  : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Status" prop="status" [width]="200">
                <ng-template let-status="value" ngx-datatable-cell-template>
                    <span>{{status == '0' ? 'Active': status == '1' ? 'Deactive': '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Last Visit Date" prop="last_visit_date" [width]="200">
                <ng-template let-last_visit_date="value" ngx-datatable-cell-template>
                    <span>{{last_visit_date ? (last_visit_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_medicine'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="ID" prop="id" [width]="200">
                <ng-template let-id="value" ngx-datatable-cell-template>
                    <span>{{id ? id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BPID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doc_name" [width]="200">
                <ng-template let-doc_name="value" ngx-datatable-cell-template>
                    <span>{{doc_name ? doc_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Prescribe Date" prop="prescribe_date" [width]="200">
                <ng-template let-prescribe_date="value" ngx-datatable-cell-template>
                    <span>{{prescribe_date ? (prescribe_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Medicine" prop="medicine" [width]="200">
                <ng-template let-medicine="value" ngx-datatable-cell-template>
                    <span>{{medicine ? medicine : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Morning" prop="morning" [width]="120">
                <ng-template let-morning="value" ngx-datatable-cell-template>
                    <span>{{morning ? morning:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Afternoon" prop="afternoon" [width]="120">
                <ng-template let-afternoon="value" ngx-datatable-cell-template>
                    <span>{{afternoon ? afternoon:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Night" prop="night" [width]="120">
                <ng-template let-night="value" ngx-datatable-cell-template>
                    <span>{{night ? night:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SOS" prop="sos" [width]="120">
                <ng-template let-sos="value" ngx-datatable-cell-template>
                    <span>{{sos ? sos:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Single Dose" prop="single_dose" [width]="120">
                <ng-template let-single_dose="value" ngx-datatable-cell-template>
                    <span>{{single_dose ? single_dose:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Special Med" prop="specialmed" [width]="120">
                <ng-template let-specialmed="value" ngx-datatable-cell-template>
                    <span>{{specialmed ? specialmed:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Potency" prop="potency" [width]="120">
                <ng-template let-potency="value" ngx-datatable-cell-template>
                    <span>{{potency ? potency:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branch_wise_notmedicine'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="ID" prop="id" [width]="200">
                <ng-template let-id="value" ngx-datatable-cell-template>
                    <span>{{id ? id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_id" [width]="200">
                <ng-template let-patient_id="value" ngx-datatable-cell-template>
                    <span>{{patient_id ? patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient UID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BPID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Doctor Name" prop="doc_name" [width]="200">
                <ng-template let-doc_name="value" ngx-datatable-cell-template>
                    <span>{{doc_name ? doc_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Prescribe Date" prop="prescribe_date" [width]="200">
                <ng-template let-prescribe_date="value" ngx-datatable-cell-template>
                    <span>{{prescribe_date ? (prescribe_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Medicine" prop="medicine" [width]="200">
                <ng-template let-medicine="value" ngx-datatable-cell-template>
                    <span>{{medicine ? medicine : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Morning" prop="morning" [width]="120">
                <ng-template let-morning="value" ngx-datatable-cell-template>
                    <span>{{morning ? morning:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Afternoon" prop="afternoon" [width]="120">
                <ng-template let-afternoon="value" ngx-datatable-cell-template>
                    <span>{{afternoon ? afternoon:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Night" prop="night" [width]="120">
                <ng-template let-night="value" ngx-datatable-cell-template>
                    <span>{{night ? night:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SOS" prop="sos" [width]="120">
                <ng-template let-sos="value" ngx-datatable-cell-template>
                    <span>{{sos ? sos:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Single Dose" prop="single_dose" [width]="120">
                <ng-template let-single_dose="value" ngx-datatable-cell-template>
                    <span>{{single_dose ? single_dose:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Special Med" prop="specialmed" [width]="120">
                <ng-template let-specialmed="value" ngx-datatable-cell-template>
                    <span>{{specialmed ? specialmed:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Potency" prop="potency" [width]="120">
                <ng-template let-potency="value" ngx-datatable-cell-template>
                    <span>{{potency ? potency:'-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'sent_stock'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Medicine name" prop="medicine_name" [width]="200">
                <ng-template let-medicine_name="value" ngx-datatable-cell-template>
                    <span>{{medicine_name ? medicine_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Potency" prop="potency" [width]="200">
                <ng-template let-potency="value" ngx-datatable-cell-template>
                    <span>{{potency ? potency : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type" [width]="200">
                <ng-template  ngx-datatable-cell-template>
                Medicine
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Quantity" prop="stock" [width]="200">
                <ng-template let-stock="value" ngx-datatable-cell-template>
                    <span>{{stock ? stock : '0'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch" prop="branch_name" [width]="200">
                <ng-template let-branch_name="value" ngx-datatable-cell-template>
                    <span>{{branch_name ? branch_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Sent Date" prop="send_date" [width]="200">
                <ng-template let-send_date="value" ngx-datatable-cell-template>
                    <span>{{send_date ? (send_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Recivied Date" prop="received_date" [width]="200">
                <ng-template let-received_date="value" ngx-datatable-cell-template>
                    <span>{{received_date ? (received_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'given_discount'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Discount" prop="discount" [width]="200">
                <ng-template let-discount="value" ngx-datatable-cell-template>
                    <span>{{discount ? discount : '0'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Package Status" prop="package_status" [width]="200">
                <ng-template let-package_status="value" ngx-datatable-cell-template>
                    <span>{{package_status ? package_status : '0'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Billing Date" prop="billing_date" [width]="200">
                <ng-template let-received_date="value" ngx-datatable-cell-template>
                    <span>{{billing_date ? (billing_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Emp Name" prop="emp_name" [width]="200">
                <ng-template let-emp_name="value" ngx-datatable-cell-template>
                    <span>{{emp_name ? emp_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'given_permission_todue_pending'">
        
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BPID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Type" prop="patient_type" [width]="200">
                <ng-template let-patient_type="value" ngx-datatable-cell-template>
                    <span>{{patient_type ? patient_type : '0'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Remarks" prop="remarks" [width]="200">
                <ng-template let-remarks="value" ngx-datatable-cell-template>
                    <span>{{remarks ? remarks : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date" prop="created_at" [width]="200">
                <ng-template let-created_at="value" ngx-datatable-cell-template>
                    <span>{{created_at ? (created_at | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Emp Code" prop="emp_name" [width]="200">
                <ng-template let-emp_name="value" ngx-datatable-cell-template>
                    <span>{{emp_name ? emp_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'exitDateReport'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column name="Employee ID" prop="Employee_ID" [width]="200">
                <ng-template let-Employee_ID="value" ngx-datatable-cell-template>
                    <span>{{Employee_ID ? Employee_ID : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Employee Name" prop="Employee_Name" [width]="200">
                <ng-template let-Employee_Name="value" ngx-datatable-cell-template>
                    <span>{{Employee_Name ? Employee_Name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Designation" prop="Designation" [width]="200">
                <ng-template let-Designation="value" ngx-datatable-cell-template>
                    <span>{{Designation ? Designation : '0'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Exit Date" prop="Exit_Date" [width]="200">
                <ng-template let-Exit_Date="value" ngx-datatable-cell-template>
                    <span>{{Exit_Date ? (Exit_Date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="DOJ" prop="date_of_join" [width]="200">
                <ng-template let-date_of_join="value" ngx-datatable-cell-template>
                    <span>{{date_of_join ? (date_of_join | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch Name" prop="Branch_Name" [width]="200">
                <ng-template let-Branch_Name="value" ngx-datatable-cell-template>
                    <span>{{Branch_Name ? Branch_Name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branchwise_visit'">
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle mb-0">
                <thead>
                    <tr>
                        <th>Branch</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="patients-reports-stats" *ngFor="let item of tableData">
                    <td>{{item.branch_code}}</td>
                    <td>{{item.count}}</td>
                   </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'branchwise_payslips'">
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle mb-0">
                <thead>
                    <tr>
                        <th>EMP ID</th>
                        <th>Month</th>
                        <th>Print</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr class="patients-reports-stats" *ngFor="let item of tableData">
                    <td>{{item.employee_id}}</td>
                    <td>{{item.month}}</td>
                    <td>-</td>
                   </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'closing_report'">
        <div class="table-responsive patient-table">
            <table class="table table-bordered text-center align-middle mb-0">
                <thead>
                    <tr>
                        <th>Billing Date</th>
                        <th>cash</th>
                        <th>cheque</th>
                        <th>credit</th>
                        <th>online</th>
                        <th>income</th>
                        <th>expense</th>
                        <th>send</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="patients-reports-stats" *ngFor="let item of tableData">
                    <td>{{item.billing_date}}</td>
                    <td>{{item.cash}}</td>
                    <td>{{item.cheque}}</td>
                    <td>{{item.credit}}</td>
                    <td>{{item.online}}</td>
                    <td>{{item.income}}</td>
                    <td>{{item.expense}}</td>
                    <td>{{item.send}}</td>
                   </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'chequeReplace'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column name="Patient ID" prop="patient_unique_id" [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template>
                    <span>{{patient_unique_id ? patient_unique_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="BP ID" prop="branch_patient_id" [width]="200">
                <ng-template let-branch_patient_id="value" ngx-datatable-cell-template>
                    <span>{{branch_patient_id ? branch_patient_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient Name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Package status" prop="package_status" [width]="200">
                <ng-template let-package_status="value" ngx-datatable-cell-template>
                    <span>{{package_status ? package_status : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Paymant Options" prop="payment_option" [width]="200">
                <ng-template let-payment_option="value" ngx-datatable-cell-template>
                    <span>{{payment_option ? payment_option : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Billing Date" prop="billing_date" [width]="200">
                <ng-template let-billing_date="value" ngx-datatable-cell-template>
                    <span>{{billing_date ? (billing_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Duration" prop="duration" [width]="200">
                <ng-template let-duration="value" ngx-datatable-cell-template>
                    <span>{{duration ? duration : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="EMP Name" prop="emp_name" [width]="200">
                <ng-template let-emp_name="value" ngx-datatable-cell-template>
                    <span>{{emp_name ? emp_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Package Amount" prop="package_amount" [width]="200">
                <ng-template let-package_amount="value" ngx-datatable-cell-template>
                    <span>{{package_amount ? package_amount : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="More"  [width]="200">
                <ng-template  ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           More
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'expenditure'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
         
            <ngx-datatable-column name="ID" prop="voucher_id" [width]="200">
                <ng-template let-voucher_id="value" ngx-datatable-cell-template>
                    <span>{{voucher_id ? voucher_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Date" prop="voucher_date" [width]="200">
                <ng-template let-voucher_date="value" ngx-datatable-cell-template>
                    <span>{{voucher_date ? (voucher_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Pay to" prop="pay_to" [width]="200">
                <ng-template let-pay_to="value" ngx-datatable-cell-template>
                    <span>{{pay_to ? pay_to : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="purpose" prop="purpose" [width]="200">
                <ng-template let-purpose="value" ngx-datatable-cell-template>
                    <span>{{purpose ? purpose : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-column name="More"  [width]="200">
                <ng-template  ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           More
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'sent_amount'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column name="ID" prop="voucher_id" [width]="200">
                <ng-template let-voucher_id="value" ngx-datatable-cell-template>
                    <span>{{voucher_id ? voucher_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
         
            <ngx-datatable-column name="Date" prop="voucher_date" [width]="200">
                <ng-template let-voucher_date="value" ngx-datatable-cell-template>
                    <span>{{voucher_date ? (voucher_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Pay to" prop="pay_to" [width]="200">
                <ng-template let-pay_to="value" ngx-datatable-cell-template>
                    <span>{{pay_to ? pay_to : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Amount" prop="amount" [width]="200">
                <ng-template let-amount="value" ngx-datatable-cell-template>
                    <span>{{amount ? amount : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'np_billing'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column name="Billing Date" prop="billing_date" [width]="200">
                <ng-template let-billing_date="value" ngx-datatable-cell-template>
                    <span>{{billing_date ? (billing_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cash" prop="cash" [width]="200">
                <ng-template let-pay_to="value" ngx-datatable-cell-template>
                    <span>{{pay_to ? pay_to : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cheque" prop="cheque" [width]="200">
                <ng-template let-cheque="value" ngx-datatable-cell-template>
                    <span>{{cheque ? cheque : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cc" prop="cc" [width]="200">
                <ng-template let-cc="value" ngx-datatable-cell-template>
                    <span>{{cc ? cc : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="ol" prop="ol" [width]="200">
                <ng-template let-ol="value" ngx-datatable-cell-template>
                    <span>{{ol ? ol : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rec Amt" prop="rec_amt" [width]="200">
                <ng-template let-rec_amt="value" ngx-datatable-cell-template>
                    <span>{{rec_amt ? rec_amt : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="More"  [width]="200">
                <ng-template  ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           More
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'op_billing'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            <ngx-datatable-column name="Billing Date" prop="billing_date" [width]="200">
                <ng-template let-billing_date="value" ngx-datatable-cell-template>
                    <span>{{billing_date ? (billing_date | date: 'dd-MM-yyyy') : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cash" prop="cash" [width]="200">
                <ng-template let-pay_to="value" ngx-datatable-cell-template>
                    <span>{{pay_to ? pay_to : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cheque" prop="cheque" [width]="200">
                <ng-template let-cheque="value" ngx-datatable-cell-template>
                    <span>{{cheque ? cheque : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="cc" prop="cc" [width]="200">
                <ng-template let-cc="value" ngx-datatable-cell-template>
                    <span>{{cc ? cc : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="ol" prop="ol" [width]="200">
                <ng-template let-ol="value" ngx-datatable-cell-template>
                    <span>{{ol ? ol : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Rec Amt" prop="rec_amt" [width]="200">
                <ng-template let-rec_amt="value" ngx-datatable-cell-template>
                    <span>{{rec_amt ? rec_amt : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="More"  [width]="200">
                <ng-template  ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           More
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'receipts'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>

            </ngx-datatable-column>
            
            <ngx-datatable-column name="Reciept No" prop="receipt_no" [width]="200">
                <ng-template let-receipt_no="value" ngx-datatable-cell-template>
                    <span>{{receipt_no ? receipt_no : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Patient name" prop="patient_name" [width]="200">
                <ng-template let-patient_name="value" ngx-datatable-cell-template>
                    <span>{{patient_name ? patient_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult date" prop="consult_date" [width]="200">
                <ng-template let-consult_date="value" ngx-datatable-cell-template>
                    <span>{{consult_date ? consult_date : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Consult type" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                    <span>{{consult_type == 'f' ? 'free' :
                      consult_type == 'c' ? 'Cost' : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="OC/REG" prop="consult_type" [width]="200">
                <ng-template let-consult_type="value" ngx-datatable-cell-template>
                    <span>{{consult_type == 'f' ? 'REG' :
                      consult_type == 'c' ? 'OC' : '-'}}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Print"  [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           Print
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <div *ngIf="selectedReport && selectedReport.key == 'voucher_sent_amount'">
        <div class="row">
            <div class="col-md-6 col-12">
                <div class="d-flex align-items-center m-1">
                    <label class="d-flex align-items-center">Show
                        <select class="form-control mx-25" [(ngModel)]="basicSelectedOption"
                            (ngModelChange)="listingLimit($event)">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
<option value="500">500</option>
                        </select>
                        </label>
<span>entries of <b>{{totalCount}}</b></span>
                </div>
            </div>
            <div class="col-md-6 col-12 d-flex justify-content-md-end">
                <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pl-1 pl-md-0 pb-md-0">
                    <label class="d-flex align-items-center">Search<input type="search" placeholder="Search"
                            class="form-control ml-25" [formControl]='mySearchControl' /></label>
                </div>
            </div>
        </div>

        <ngx-datatable [rows]="tableData" [rowHeight]="50"
            class="bootstrap core-bootstrap myDatatable" [limit]="10" [columnMode]="ColumnMode.force"
            [headerHeight]="40" [footerHeight]="50" [scrollbarH]="true" [selectionType]="SelectionType.checkbox"
            [limit]="basicSelectedOption"> <ngx-datatable-column name="S.No" [width]="40">
                <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    {{ basicSelectedOption * (curPage - 1) + rowIndex + 1 }}
                </ng-template>
            
            </ngx-datatable-column>
            <ngx-datatable-column name="Voucher ID" prop="voucher_id" [width]="200">
                <ng-template let-voucher_id="value" ngx-datatable-cell-template>
                    <span>{{voucher_id ? voucher_id : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Branch Name" prop="branch_name" [width]="200">
                <ng-template let-branch_name="value" ngx-datatable-cell-template>
                    <span>{{branch_name ? branch_name : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="HOA" prop="head_of_accounts" [width]="200">
                <ng-template let-head_of_accounts="value" ngx-datatable-cell-template>
                    <span>{{head_of_accounts ? head_of_accounts : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
           
            <ngx-datatable-column name="Amount" prop="amount" [width]="200">
                <ng-template let-amount="value" ngx-datatable-cell-template>
                    <span>{{amount ? amount : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Voucher date" prop="voucher_date" [width]="200">
                <ng-template let-voucher_date="value" ngx-datatable-cell-template>
                    <span>{{voucher_date ? voucher_date : '-'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Print"  [width]="200">
                <ng-template let-patient_unique_id="value" ngx-datatable-cell-template let-row="row">
                    <div class="d-flex align-items-center">
                        <a class="dataTable-Link" >
                           Print
                        </a>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-offset="offset">
                    <datatable-pager (onFooterPage)="handleF($event)" (change)="onFooterPage($event)"
                        [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'"
                        [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                        [(page)]="curPage" [size]="basicSelectedOption" [count]="totalCount">
                    </datatable-pager>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>

   <!--  Missied Followups popoups-->
    <!-- <feedback (reloadDataTable)="getMissingFollowups()" #feedBackModal></feedback>

    <feedbacklog-modal #feedBackLogModal></feedbacklog-modal>

    <followup-drop-modal (reloadDataTable)="getMissingFollowups()" #followUpDropModal></followup-drop-modal> -->


</section>