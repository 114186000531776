import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoreTokenService {

  private issuer = {
    login: environment.apiUrl + '/storeManagerapi/login',
  };

  constructor() {}

  handleData(token: any) {
    localStorage.setItem('store_auth_token', token);
  }

  getToken() {
    return localStorage.getItem('store_auth_token');
  }

  // Verify the token
  isValidToken() {
    const token = this.getToken();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    }
  }

  payload(token: any) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }

  // Remove token
  removeToken() {
    localStorage.removeItem('store_auth_token');
  }
}