import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'feedback-edit-modal',
  templateUrl: './feedback-edit-modal.component.html',
  styleUrls: ['./feedback-edit-modal.component.scss']
})
export class FeedbackEditModalComponent implements OnInit {

  public feedbackStatus = [
    { id: 1, value: "Switch off/NR Report", title: "Switch Off" },
    { id: 2, value: "Invalid Number/Out of Service Report", title: "Invalid Number" },
    { id: 3, value: "Wrong Number Report", title: "Wrong Number" },
    { id: 4, value: "Out of Station Report", title: "Out of Station" },
    { id: 5, value: "Busy/Cal disconnect Report", title: "Busy" },
    { id: 6, value: "Cured&Satisfied Report", title: "Satisfied" },
    { id: 7, value: "Unsatisfied Report", title: "Unsatisfied" },
    { id: 8, value: "Call&Come Report", title: "Call&Come" },
    { id: 9, value: "Interested Report", title: "Interested" },
    { id: 10, value: "Refund & Dont Disturb Report", title: "Refund" },
    { id: 11, value: "Courier Report", title: "Courier" },
  ];

  public subStatus = [
    { id: 1, value: "Paid Amt Not Matched" },
    { id: 2, value: "Paid Method Not Matched" },
    { id: 3, value: "High Prize" },
    { id: 4, value: "Service" },
    { id: 5, value: "Unsatisfy With Doctor" },
    { id: 6, value: "Unsatisfy With Pro" },
    { id: 7, value: "Insuficent Funds" },
    { id: 8, value: "Poor Treatment" },
    { id: 9, value: "Service" },
    { id: 10, value: "Shifted to Another Location" },
    { id: 11, value: "Refunded Issued Patient" },
  ];

  @ViewChild("feedbackEditModal") feedbackEditModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private fb:FormBuilder) {}

    feedbackEditForm: FormGroup = this.fb.group({
      comingdate: ["", Validators.required],
      calldate: ["", Validators.required],
      status: [null, Validators.required],
      appointmentdate: ["", Validators.required],
      remarks: ["", [Validators.required, Validators.pattern('\\s?\\S+(?: \\S+)*\\s?'), Validators.maxLength(500)]],
    });
    selectedPatient:any;
  
    get comingdate() {
      return this.feedbackEditForm.get("comingdate");
    }
    get calldate() {
      return this.feedbackEditForm.get("calldate");
    }
    get status() {
      return this.feedbackEditForm.get("status");
    }
    get appointmentdate() {
      return this.feedbackEditForm.get("appointmentdate");
    }
    get remarks() {
      return this.feedbackEditForm.get("remarks");
    }
  
    submitfbEditForm() {
      this.feedbackEditForm.markAllAsTouched();
    }
    closefbEditForm() {
      this.feedbackEditForm.reset();
      this.modalService.dismissAll();
    }

  ngOnInit(): void {}

  /* Feedback Edit Modal */
  openModal(row) {
    this.selectedPatient = row;
    this.modalService.open(this.feedbackEditModal, {
      windowClass: "modal",
      backdrop: "static",
      centered: true,
    });
  }
}
