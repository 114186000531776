import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import * as snippet from 'app/main/tables/datatables/datatables.snippetcode';
import { FormBuilder, FormControl } from "@angular/forms";
import { finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { ProService } from '@pro/services/pro.service';
import { NotificationService } from '@core/services/notification.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-new-patient-billing',
  templateUrl: './new-patient-billing.component.html',
  styleUrls: ['./new-patient-billing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewPatientBillingComponent implements OnInit {

  public search_text: any;
  public basicSelectedOption: number = 500;
  curPage: any;
  rowCount: any;
  pageSize: any;
  totalCount: number;
  public mySearchControl = new FormControl();
  public filterLoading = new BehaviorSubject(false);
  isShown: boolean = false;
  pageHeading: any;

  toggleShow() {
    this.isShown = !this.isShown;
  }

  patientBillingFilterForm = this._formBuilder.group({
    fpatient_unique_id: [],
    fpatient_name: [],
    perpage: this.basicSelectedOption,
    page: 1
  });

  // Private
  private _unsubscribeAll: Subject<any>;
  private tempData = [];

  // public
  public rows: any;
  public selected = [];
  public kitchenSinkRows: any;
  public ColumnMode = ColumnMode;
  public expanded = {};
  public chkBoxSelected = [];
  public SelectionType = SelectionType;

  public newPatientsBillingData: any[] = [];

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('tableRowDetails') tableRowDetails: any;

  // snippet code variables
  public _snippetCodeKitchenSink = snippet.snippetCodeKitchenSink;
  public _snippetCodeCustomCheckbox = snippet.snippetCodeCustomCheckbox;

  /**
   * Constructor
   */
  constructor(
    private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    public _activatedRoute: ActivatedRoute,
    private notifyService: NotificationService,
    private _router: Router,
    private _service: ProService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    this.getpatientBillings();

    /* DataTable Search Filter */
    this.mySearchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()
    ).subscribe(val => {
      this.search_text = this.mySearchControl.value;
      this.table.offset = 0;
      this.patientBillingFilterForm.value.page = 1;
      this.getpatientBillings();
    });
  }

  /* Get New Patient Billing Listing */
  getpatientBillings() {
    this.patientBillingFilterForm.value.perpage = this.basicSelectedOption;
    this.patientBillingFilterForm.value.fsearch_text = this.search_text;

    this._service.getpatientBillings(this.patientBillingFilterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        let data = res['data'];
        this.newPatientsBillingData = data['npList'];
        this.totalCount = data['total'];
        this.curPage = data['page'];
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Check atleast has one value in filter Form */
  checkFilterValidity() {
    if (this.patientBillingFilterForm.value.fpatient_unique_id || this.patientBillingFilterForm.value.fpatient_name) {
      return false;
    } else {
      return true;
    }
  }

  /* Redirect CaseSheet */
  caseSheet(patientId) {
    let link = environment.subdirUrl + '/pro/home/case-sheet/' + patientId;
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.getpatientBillings();
  }

  /* Reset Filter */
  resetFilter() {
    this.patientBillingFilterForm.reset();
    this.getpatientBillings();
  }

  /* Manage Listing Limit */
  listingLimit($event) {
    this.basicSelectedOption = $event;
    this.patientBillingFilterForm.value.perpage = this.basicSelectedOption;
    this.patientBillingFilterForm.value.page = 1;
    this.getpatientBillings();
  }

  /* Paginate records */
  onFooterPage($event) {
    this.patientBillingFilterForm.value.page = $event.page;
    this.getpatientBillings();
  }

  /* Redirect Update Billing */
  billing(patientId) {
    let link = environment.subdirUrl + '/pro/home/update-billing/' + patientId;
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }

  /* Redirect Update Billing */
  updateBilling(patientId) {
    let link = environment.subdirUrl + '/pro/home/pay-due-billing/' + patientId;
    this._router.navigate([]).then(result => {  window.open(link, '_blank'); });
  }
}
