import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';

@Component({
  selector: 'invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {

  selectedPatientId: any;
  is_show: any;
  public modalRef: any;
  invoiceDetails: any;
  overall_payments: any;
  consultation_fee: any;
  patient_billings: any;
  @ViewChild("invoiceModal") invoiceModal: TemplateRef<any>;

  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: DocService) { }

  ngOnInit(): void {
  }

  ConvertToInt(val){
    val = val ? val : 0;
    return parseInt(val);
  }

  isNumber(val) {
    return !isNaN(val);
  }
  
  /* Open Patient Information Modal */
  openModal(patient_id) {
  	this.selectedPatientId = patient_id;
  	this.getPatientInvoiceData();
  	this.modalRef = this.modalService.open(this.invoiceModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
      	size: "xl",
  	});
    this.is_show = 1;
  }
  openModal2(patient_id) {
  	this.selectedPatientId = patient_id;
  	// this.getPatientInvoiceData();
  	this.modalRef = this.modalService.open(this.invoiceModal, {
  		windowClass: "modal",
  		backdrop:'static',
  		centered: true,
      	size: "xl",
  	});
    this.is_show = 2;
  }

  /* Get Patient Invoice Data */
  getPatientInvoiceData() {
  	this._service.getPatientInvoiceData(this.selectedPatientId)
  	.subscribe(res => {
  	  if (res['status'] == 'success') {
  	    let data = res['data'];
  	    this.invoiceDetails = data['invoice'];
        if (this.invoiceDetails) {        
          this.overall_payments = this.invoiceDetails['overall_payments'];
          this.consultation_fee = this.invoiceDetails['consultation_fee'];
          this.patient_billings = this.invoiceDetails['patient_billings'];
        }
  	  }
  	}, err => {
  	  this.notifyService.showError(err['message'], err['title']);
  	});
  }
}
