import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CallcenterService } from '@callcenter/services/callcenter.service';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentUserService } from '@services/current-user/current-user.service';
import { MasterDataService } from '@services/masterData/master-data.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  patient_details: any;
  currentDate = new Date();
  y = this.currentDate.getFullYear();
  m = this.currentDate.getMonth();
  firstDay = new Date(this.y, this.m, 1);
  public modalRef: any;
  selectedReport: any;

  public filterLoading = new BehaviorSubject(false);
  public filterForm = this._formBuilder.group({
    freport_type: [null, Validators.required],
    fdate_range: [],
  });
  fromDate: any;
  toDate: any;

  apTableData: any;
  tsTableData: any;
  kaTableData: any;
  allBranchTableData: any;
  apTotalRow = [];
  tsTotalRow = [];
  kaTotalRow = [];

  reportTypes = [
    { id: 1, report: 'AP & KA & TS STATUS(PH)', key: 'cc_ph_status' }
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private _service: CallcenterService,
    private modalService: NgbModal,
    private _masterService: MasterDataService,
    public currentUser: CurrentUserService
  ) {
    this._service.getProfileDetails().subscribe(res => {
      this.currentUser.init(res);
      if (this.currentUser.getRoleId() == 16) {
        this.reportTypes = [{ id: 1, report: 'AP & KA & TS STATUS(Online)', key: 'online_status' }
        ];
      }
    }, err => {
      console.log(err.error);
    });

  }


  get freport_type() { return this.filterForm.get('freport_type'); }

  errorMessages = {
    freport_type: [{ type: 'required', message: 'The Report Type field is required' }]
  }

  ngOnInit(): void {

  }

  submitForm() {
    let api = this.filterForm.value.freport_type.key;
    this._service.phStatus(api, this.filterForm.value)
      .pipe(finalize(() => this.filterLoading.next(false)))
      .subscribe(res => {
        if (res['status'] == 'success') {
          let data = res['data'];
          this.selectedReport = this.filterForm.value.freport_type;
          if (this.selectedReport.key == 'cc_ph_status') {
            this.apTableData = null;
            this.tsTableData = null;
            this.kaTableData = null;
            this.allBranchTableData = null;
            this.apTotalRow = [];
            this.tsTotalRow = [];
            this.kaTotalRow = [];

            this.apTableData = data[1];
            this.apTotalRow = this.getTotal(this.apTableData);
            this.tsTableData = data[2];
            this.tsTotalRow = this.getTotal(this.tsTableData);
            this.kaTableData = data[3];
            this.kaTotalRow = this.getTotal(this.kaTableData);
            this.allBranchTableData = this.getALLBranchTotal(this.apTotalRow, this.tsTotalRow, this.kaTotalRow);
          } else if (this.selectedReport.key == 'online_status') {
            this.apTableData = null;
            this.tsTableData = null;
            this.kaTableData = null;
            this.allBranchTableData = null;
            this.apTotalRow = [];
            this.tsTotalRow = [];
            this.kaTotalRow = [];

            this.apTableData = data[1];
            this.apTotalRow = this.getTotalOnline(this.apTableData);
            this.tsTableData = data[2];
            this.tsTotalRow = this.getTotalOnline(this.tsTableData);
            this.kaTableData = data[3];
            this.kaTotalRow = this.getTotalOnline(this.kaTableData);
            this.allBranchTableData = this.getALLBranchTotalOnline(this.apTotalRow, this.tsTotalRow, this.kaTotalRow);
          }
        } else if (res['status'] == 'error') {
          this.notifyService.showError(res['message'], res['title']);
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }

  /* Apply Transfers Filter */
  applyFilter() {
    this.filterLoading.next(true);
    this.submitForm();
  }

  /* Reset Filter */
  resetFilter() {
    this.filterForm.reset();
    this.submitForm();
  }

  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: "range",
  };

  /* On Date Select */
  onDateSelect() {
    let dateRange = this.filterForm.value.fdate_range;
    if (dateRange) {
      this.fromDate = dateRange[0];
      this.toDate = dateRange[1];
    }
  }

  getTotal(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        np: previousValue.np + currentValue.np,
        cc: previousValue.cc + currentValue.cc,
        ib: previousValue.ib + currentValue.ib,
        ob: previousValue.ob + currentValue.ob,
        ib_reg: previousValue.ib_reg + currentValue.ib_reg,
        ob_reg: previousValue.ob_reg + currentValue.ob_reg,
        reg_total: previousValue.reg_total + currentValue.reg_total,
        oc_total: previousValue.oc_total + currentValue.oc_total,
        pak: previousValue.pak + currentValue.pak,
        ccr: previousValue.ccr + currentValue.ccr,
        state_id: currentValue.state_id
      }
    });
  }

  getALLBranchTotal(ap, ts, ka) {
    return {
      np: ap.np + ts.np + ka.np,
      cc: ap.cc + ts.cc + ka.cc,
      ib: ap.ib + ts.ib + ka.ib,
      ob: ap.ob + ts.ob + ka.ob,
      ib_reg: ap.ib_reg + ts.ib_reg + ka.ib_reg,
      ob_reg: ap.ob_reg + ts.ob_reg + ka.ob_reg,
      reg_total: ap.reg_total + ts.reg_total + ka.reg_total,
      oc_total: ap.oc_total + ts.oc_total + ka.oc_total,
      pak: ap.pak + ts.pak + ka.pak,
      ccr: ap.ccr + ts.ccr + ka.ccr,
    }
  }

  getTotalOnline(data) {
    return data.reduce(function (previousValue, currentValue) {
      return {
        ol: previousValue.ol + currentValue.ol,
        ol_reg: previousValue.ol_reg + currentValue.ol_reg,
        ol_oc: previousValue.ol_oc + currentValue.ol_oc,
        pak: previousValue.pak + currentValue.pak,
        olr: previousValue.olr + currentValue.olr,
        state_id: currentValue.state_id
      }
    });
  }

  getALLBranchTotalOnline(ap, ts, ka) {
    return {
      ol: ap.ol + ts.ol + ka.ol,
      ol_reg: ap.ol_reg + ts.ol_reg + ka.ol_reg,
      ol_oc: ap.ol_oc + ts.ol_oc + ka.ol_oc,
      pak: ap.pak + ts.pak + ka.pak,
      olr: ap.olr + ts.olr + ka.olr
    }
  }

  openModal(modal, api, fbranch_id = null, fstate = null, ftype = null, freg = null) {
    console.log(fstate)
    let obj = {};
    if (fbranch_id) {
      obj['fbranch_id'] = fbranch_id;
    }
    if (fstate) {
      obj['fstate'] = fstate;
    }
    if (ftype) {
      obj['ftype'] = ftype;
    }
    if (freg) {
      obj['freg'] = freg;
    }
    obj['fdate_range'] = this.filterForm.value.fdate_range;
    if (this.selectedReport.key == 'cc_ph_status') {
      this._masterService.ccPhPatients(obj, api)
        .subscribe(res => {
          let data = res['data'];
          if (data['patients']) {
            this.patient_details = data['patients'];
            this.modalService.open(modal, {
              centered: true,
              size: 'xl'
            });
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    } else if (this.selectedReport.key == 'online_status') {
      this._masterService.onlinePatients(obj, api)
        .subscribe(res => {
          let data = res['data'];
          if (data['patients']) {
            this.patient_details = data['patients'];
            this.modalService.open(modal, {
              centered: true,
              size: 'xl'
            });
          }
        }, err => {
          this.notifyService.showError(err['message'], err['title']);
        });
    }

  }
}
