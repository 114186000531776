import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.scss']
})
export class ClosingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
