import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterDataService } from '@services/masterData/master-data.service';

@Component({
  selector: 'app-cc-ph-patient-details',
  templateUrl: './cc-ph-patient-details.component.html',
  styleUrls: ['./cc-ph-patient-details.component.scss']
})
export class CcPhPatientDetailsComponent implements OnInit {
  patient_details: any;
  currentDate = new Date();
  y = this.currentDate.getFullYear();
  m = this.currentDate.getMonth();
  firstDay = new Date(this.y, this.m, 1);
  public modalRef: any;
  @ViewChild("ccPhPatients") achievedModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private notifyService: NotificationService,
    private _service: MasterDataService) { }

  ngOnInit(): void {
  }

  openModal(api, fbranch_id = null, fstate = null, ftype = null, freg = null) {
    console.log(fstate)
    let obj = {};
    if (fbranch_id) {
      obj['fbranch_id'] = fbranch_id;
    }
    if (fstate) {
      obj['fstate'] = fstate;
    }
    if (ftype) {
      obj['ftype'] = ftype;
    }
    if (freg) {
      obj['freg'] = freg;
    }

    this._service.ccPhPatients(obj, api)
      .subscribe(res => {
        let data = res['data'];
        if (data['patients']) {
          this.patient_details = data['patients'];
          this.modalService.open(this.achievedModal, {
            centered: true,
            size: 'xl'
          });
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
  }
}
