<div class="auth-wrapper auth-v2">
    <div class="auth-inner row m-0">
        <!-- Brand logo--><a class="brand-logo" href="javascript:void(0);">
            <!-- <img src="assets/images/logo/positivehomeopathy_logo.jpg" alt="brand-logo" height="50" /> -->
            <!-- <h2 class="brand-text text-primary ml-1">Positive Homeopathy</h2> -->
        </a>
        <!-- /Brand logo-->
        <!-- Left Text-->
        <div class="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <img class="img-fluid" src='assets/images/pages/login-v2.svg' alt="Forgot password" />
            </div>
        </div>
        <!-- /Left Text-->
        <!-- Forgot password-->
        <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title font-weight-bold mb-1">Forgot Password? 🔒</h2>
                <p class="card-text">Enter your email address and we'll send you a system generated password.</p>
                <form [formGroup]="resetPasswordForm" class="auth-forgot-password-form  auth-form" (ngSubmit)="onSubmit()" #authForm="ngForm">
                    <div class="form-group">
                        <label class="form-label" for="forgot-password-email">Email Address<span class="text-danger">*</span></label>
                        <input formControlName="email" class="form-control" id="forgot-password-email" type="text" name="forgot-password-email"
                            placeholder="Email Address*" aria-describedby="forgot-password-email" autofocus=""
                            tabindex="1" [class.error]="authForm.submitted && f.email.invalid" [(ngModel)]="emailVar"
                             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" required />
                        <span *ngIf="authForm.submitted && f.email.invalid" class="invalid-form">
                            <small class="form-text text-danger" *ngIf="f.email.errors.required">This field is
                                required!</small>
                        </span>
                        <span *ngIf="f.email.errors && (f.email.touched || f.email.dirty)" class="invalid-form">
                            <small class="form-text text-danger" [hidden]="!f.email.errors?.pattern"> Invalid email
                            </small>
                        </span>
                    </div>
                    <button [disabled]="loading | async" class="btn btn-primary btn-block" tabindex="2" rippleEffect>
                    <span *ngIf="loading | async" class="spinner-border spinner-border-sm mr-1"></span>Send Password</button>
                </form>
                <p class="text-center mt-2">
                    <a routerLink="/foe/login"><i data-feather="chevron-left"></i> Back to login</a>
                </p>
            </div>
        </div>
        <!-- /Forgot password-->
    </div>
</div>