<!-- fblist Modal-->
<ng-template #feedBackLogModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Feedbacks Log</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body bt_modal_scroll" tabindex="0" ngbAutofocus>
        <div class="comments-inner-container">

            <ul class="pl-0">

                <li class="comment-object" *ngFor="let log of feedbacklog">
                    <div class="person-avatarPlaceholder color-1 js-avatarPlaceholder">
                        <span class="js-initials">{{getShortName(log.foe_name)}}</span>
                    </div>
                    <div class="comment-text">
                        <h2 class="username">{{log.foe_name}} <span class="time">{{log.created_at | date: 'dd-MM-YYYY h:mm a'}}</span></h2>
                        <p class="comment txt-ovrflow-wrap">{{log.remarks}}.</p>
                    </div>
                </li>

            </ul>
        </div>
    </div>
</ng-template>
<!-- / fblist Modal-->