import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { DocService } from '@doc/services/doc.service';
import { NotificationService } from '@core/services/notification.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuditService } from '../../service/audit.service';

@Component({
  selector: 'edit-patient',
  templateUrl: './edit-patient.component.html',
  styleUrls: ['./edit-patient.component.scss']
})
export class EditPatientComponent implements OnInit {
  feedbackUpdateLoading = new BehaviorSubject(false);

  public modalRef: any;
  selectedPatient: any;
  feedbackEditForm: FormGroup = this._formBuilder.group({
    status: [null, Validators.required],
    appointmentdate: ["", Validators.required],
    remarks: [
      "",
      [
        Validators.required,
        Validators.pattern("\\s?\\S+(?: \\S+)*\\s?"),
        Validators.maxLength(250),
      ],
    ],
  });



  get status() {
    return this.feedbackEditForm.get("status");
  }
  get appointmentdate() {
    return this.feedbackEditForm.get("appointmentdate");
  }
  get remarks() {
    return this.feedbackEditForm.get("remarks");
  }

  public feedbackStatus = [
    { id: 1, value: "Switch off/NR Report", title: "Switch Off" },
    { id: 2, value: "Invalid Number/Out of Service Report", title: "Invalid Number" },
    { id: 3, value: "Wrong Number Report", title: "Wrong Number" },
    { id: 4, value: "Out of Station Report", title: "Out of Station" },
    { id: 5, value: "Busy/Cal disconnect Report", title: "Busy" },
    { id: 6, value: "Cured&Satisfied Report", title: "Satisfied" },
    { id: 7, value: "Unsatisfied Report", title: "Unsatisfied" },
    { id: 8, value: "Call&Come Report", title: "Call&Come" },
    { id: 9, value: "Interested Report", title: "Interested" },
    { id: 10, value: "Refund & Dont Disturb Report", title: "Refund" },
    { id: 11, value: "Courier Report", title: "Courier" },
  ];
  public subStatus = [
    { id: 1, value: "Paid Amt Not Matched" },
    { id: 2, value: "Paid Method Not Matched" },
    { id: 3, value: "High Prize" },
    { id: 4, value: "Service" },
    { id: 5, value: "Unsatisfy With Doctor" },
    { id: 6, value: "Unsatisfy With Pro" },
    { id: 7, value: "Insufficient Funds" },
    { id: 8, value: "Poor Treatment" },
    { id: 9, value: "Shifted to Another Location" },
    { id: 10, value: "Refunded Issued Patient" },
  ];

  @ViewChild("editPatientModal") editPatientModal: TemplateRef<any>;
  constructor(private modalService: NgbModal,
    private _formBuilder: FormBuilder,
    private notifyService: NotificationService,
    private _service: AuditService) { }
  
  ngOnInit(): void {
  }
  
  submitfbEditForm() {
    this.feedbackEditForm.markAllAsTouched();
    if (this.feedbackEditForm.valid) {
      this.feedbackUpdateLoading.next(true);
      this.feedbackEditForm.value.patient_id = this.selectedPatient.patient_id;
      this.feedbackEditForm.value.id = this.selectedPatient.id;
      this.feedbackEditForm.value.branch_id = this.selectedPatient.branch_id;
      this.feedbackEditForm.value.purpose = 'Audit';
      this._service.submitFeedBackForm(this.feedbackEditForm.value)
      .pipe(finalize(() => this.feedbackUpdateLoading.next(false)))
      .subscribe(res => {
        if(res['status'] == 'success') {

          this.feedbackEditForm.reset();
          this.notifyService.showSuccess(res['message'], res['title']);
          this.modalRef.close();
        }
      }, err => {
        this.notifyService.showError(err['message'], err['title']);
      });
    }
  }

  closefbEditForm() {
    this.feedbackEditForm.reset();
    this.modalService.dismissAll();
  }

  /* Open FeedBack Modal */
  openModal(row) {
    this.selectedPatient = row;
    this.modalRef = this.modalService.open(this.editPatientModal, {
      windowClass: "modal",
      backdrop:'static',
      centered: true,
    });
  }
}
