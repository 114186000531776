<section>
    <div class="row">
        <div class="col-12">
            <div class="card mb-1">
                <div class="card-header">
                    <h4 class="card-title">All Branches Reports</h4>
                </div>
                <hr>
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <form [formGroup]="filterForm" (ngSubmit)="submitForm()">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">Select Type</label>
                                            <ng-select formControlName="freport_type"
                                                [ngClass]="{ 'is-invalid error': errorMessages.freport_type && freport_type.errors && freport_type.touched }"
                                                bindLabel="report" [items]="reportTypes" placeholder="Select">
                                            </ng-select>
                                            <div *ngFor="let error of errorMessages.freport_type">
                                                <ng-container
                                                    *ngIf="freport_type.hasError(error.type) && (freport_type.touched)">
                                                    <small class="text-danger">{{error.message}}</small>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12">
                                        <div class="form-group">
                                            <label for="">From Date - To Date</label>
                                            <ng2-flatpickr (ngModelChange)="onDateSelect()"
                                                formControlName="fdate_range" [config]="DateRangeOptions"
                                                placeholder="Pick a date range!"></ng2-flatpickr>
                                        </div>
                                    </div>

                                    <div class="col-xl-4 col-lg-4 col-md-6 col-12 d-flex align-items-center mt-50">
                                        <div>
                                            <button [disabled]="filterLoading | async" type="submit"
                                                class="btn btn-primary"><span *ngIf="filterLoading | async"
                                                    class="spinner-border spinner-border-sm mr-1"></span>Search</button>
                                            <button (click)="resetFilter()" type="reset"
                                                class="btn btn-secondary ml-50">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'cc_ph_status'">
        <div class="row" *ngIf="apTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">AP Branches PH Status (CC)</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>CC</th>
                                    <th>IB</th>
                                    <th>REG</th>
                                    <th>OB</th>
                                    <th>REG</th>
                                    <th>TREG (IB+OB)</th>
                                    <th>TOC (IB+OB)</th>
                                    <th>PAK</th>
                                    <th>CCR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of apTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id)">{{
                                            item.cc
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Inbound')">{{
                                            item.ib
                                            }}</a></td>
                                    <td>{{ item.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Outbound')">{{
                                            item.ob
                                            }}</a></td>
                                    <td>{{ item.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.reg_total
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'oc')">{{
                                            item.oc_total
                                            }}</a></td>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.ccr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="apTotalRow">
                                    <th>Total :</th>
                                    <td>{{ apTotalRow.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, apTotalRow.state_id, null, null)">{{
                                            apTotalRow.cc }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, apTotalRow.state_id, 'Inbound', null)">{{
                                            apTotalRow.ib }}</a></td>
                                    <td>{{ apTotalRow.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, apTotalRow.state_id, 'Outbound', null)">{{
                                            apTotalRow.ob }}</a></td>
                                    <td>{{ apTotalRow.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, apTotalRow.state_id, null, 'reg')">{{
                                            apTotalRow.reg_total }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, apTotalRow.state_id, null, 'oc')">{{
                                            apTotalRow.oc_total }}</a></td>
                                    <td>{{ apTotalRow.pak }}</td>
                                    <td>{{ apTotalRow.ccr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tsTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">TS Branches PH Status (CC)</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>CC</th>
                                    <th>IB</th>
                                    <th>REG</th>
                                    <th>OB</th>
                                    <th>REG</th>
                                    <th>TREG (IB+OB)</th>
                                    <th>TOC (IB+OB)</th>
                                    <th>PAK</th>
                                    <th>CCR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tsTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id)">{{
                                            item.cc
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Inbound')">{{
                                            item.ib
                                            }}</a></td>
                                    <td>{{ item.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Outbound')">{{
                                            item.ob
                                            }}</a></td>
                                    <td>{{ item.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.reg_total
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'oc')">{{
                                            item.oc_total
                                            }}</a></td>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.ccr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="tsTotalRow">
                                    <th>Total :</th>
                                    <td>{{ tsTotalRow.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, tsTotalRow.state_id, null, null)">{{
                                            tsTotalRow.cc }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, tsTotalRow.state_id, 'Inbound', null)">{{
                                            tsTotalRow.ib }}</a></td>
                                    <td>{{ tsTotalRow.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, tsTotalRow.state_id, 'Outbound', null)">{{
                                            tsTotalRow.ob }}</a></td>
                                    <td>{{ tsTotalRow.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, tsTotalRow.state_id, null, 'reg')">{{
                                            tsTotalRow.reg_total }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, tsTotalRow.state_id, null, 'oc')">{{
                                            tsTotalRow.oc_total }}</a></td>
                                    <td>{{ tsTotalRow.pak }}</td>
                                    <td>{{ tsTotalRow.ccr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="kaTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">KA Branches PH Status (CC)</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>NP</th>
                                    <th>CC</th>
                                    <th>IB</th>
                                    <th>REG</th>
                                    <th>OB</th>
                                    <th>REG</th>
                                    <th>TREG (IB+OB)</th>
                                    <th>TOC (IB+OB)</th>
                                    <th>PAK</th>
                                    <th>CCR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of kaTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td>{{ item.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id)">{{
                                            item.cc
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Inbound')">{{
                                            item.ib
                                            }}</a></td>
                                    <td>{{ item.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, 'Outbound')">{{
                                            item.ob
                                            }}</a></td>
                                    <td>{{ item.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.reg_total
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', item.branch_id, null, null, 'oc')">{{
                                            item.oc_total
                                            }}</a></td>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.ccr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="kaTotalRow">
                                    <th>Total :</th>
                                    <td>{{ kaTotalRow.np }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, kaTotalRow.state_id, null, null)">{{
                                            kaTotalRow.cc }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, kaTotalRow.state_id, 'Inbound', null)">{{
                                            kaTotalRow.ib }}</a></td>
                                    <td>{{ kaTotalRow.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, kaTotalRow.state_id, 'Outbound', null)">{{
                                            kaTotalRow.ob }}</a></td>
                                    <td>{{ kaTotalRow.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, kaTotalRow.state_id, null, 'reg')">{{
                                            kaTotalRow.reg_total }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, kaTotalRow.state_id, null, 'oc')">{{
                                            kaTotalRow.oc_total }}</a></td>
                                    <td>{{ kaTotalRow.pak }}</td>
                                    <td>{{ kaTotalRow.ccr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="allBranchTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">ALL Branches PH Status (CC)</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>NP</th>
                                    <th>CC</th>
                                    <th>IB</th>
                                    <th>REG</th>
                                    <th>OB</th>
                                    <th>REG</th>
                                    <th>TREG (IB+OB)</th>
                                    <th>TOC (IB+OB)</th>
                                    <th>PAK</th>
                                    <th>CCR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngIf="allBranchTableData">
                                    <td>Total</td>
                                    <td>{{ allBranchTableData.np }}</td>
                                    <td><a class="dataTable-Link" (click)="openModal(ccPhPatients, 'callCenterapi')">{{
                                            allBranchTableData.cc
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, null, 'Inbound')">{{
                                            allBranchTableData.ib
                                            }}</a></td>
                                    <td>{{ allBranchTableData.ib_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, null, 'Outbound')">{{
                                            allBranchTableData.ob
                                            }}</a></td>
                                    <td>{{ allBranchTableData.ob_reg }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, null, null, 'reg')">{{
                                            allBranchTableData.reg_total
                                            }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(ccPhPatients, 'callCenterapi', null, null, null, 'oc')">{{
                                            allBranchTableData.oc_total
                                            }}</a></td>
                                    <td>{{ allBranchTableData.pak }}</td>
                                    <td>{{ allBranchTableData.ccr }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="selectedReport && selectedReport.key == 'online_status'">
        <div class="row" *ngIf="apTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">AP Branches Online Status</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>OL</th>
                                    <th>REG</th>
                                    <th>OC</th>
                                    <th>PAK</th>
                                    <th>OLR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of apTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, null)">{{
                                            item.ol
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.ol_reg
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null,null, 'oc')">{{
                                            item.ol_oc
                                            }}</a>
                                    </td>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.olr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="apTotalRow">
                                    <th>Total :</th>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, apTotalRow.state_id, null, null)">{{
                                            apTotalRow.ol }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, apTotalRow.state_id, null, 'reg')">{{
                                            apTotalRow.ol_reg }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, apTotalRow.state_id, null, 'oc')">{{
                                            apTotalRow.ol_oc }}</a></td>
                                    <td>{{ apTotalRow.pak }}</td>
                                    <td>{{ apTotalRow.olr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="tsTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">TS Branches Online Status</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>OL</th>
                                    <th>REG</th>
                                    <th>OC</th>
                                    <th>PAK</th>
                                    <th>OLR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of tsTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, null)">{{
                                            item.ol
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.ol_reg
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',item.branch_id, null, null, 'oc')">{{
                                            item.ol_oc
                                            }}</a>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.olr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="tsTotalRow">
                                    <th>Total :</th>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, tsTotalRow.state_id, null, null)">{{
                                            tsTotalRow.ol }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, tsTotalRow.state_id, null, 'reg')">{{
                                            tsTotalRow.ol_reg }}</a></td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, tsTotalRow.state_id, null, 'oc')">{{
                                            tsTotalRow.ol_oc }}</a></td>
                                    <td>{{ tsTotalRow.pak }}</td>
                                    <td>{{ tsTotalRow.olr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="kaTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">KA Branches Online Status</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Br</th>
                                    <th>OL</th>
                                    <th>REG</th>
                                    <th>OC</th>
                                    <th>PAK</th>
                                    <th>OLR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngFor="let item of kaTableData">
                                    <td>{{ item.branch_name }}</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, null)">{{
                                            item.ol
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', item.branch_id, null, null, 'reg')">{{
                                            item.ol_reg
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',item.branch_id, null, null, 'oc')">{{
                                            item.ol_oc
                                            }}</a>
                                    <td>{{ item.pak }}</td>
                                    <td>{{ item.olr }}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="kaTotalRow">
                                    <th>Total :</th>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, kaTotalRow.state_id, null, null)">{{
                                            kaTotalRow.ol }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi', null, kaTotalRow.state_id, null, 'reg')">{{
                                            kaTotalRow.ol_reg }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',null, kaTotalRow.state_id, null, 'oc')">{{
                                            kaTotalRow.ol_oc
                                            }}</a>
                                    </td>
                                    <td>{{ kaTotalRow.pak }}</td>
                                    <td>{{ kaTotalRow.olr }}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="allBranchTableData">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">ALL Branches Online Status</h4>
                    </div>
                    <hr>
                    <div class="card-body pb-1 table-responsive">
                        <table class="table table-bordered text-center align-middle">
                            <thead>
                                <tr>
                                    <th>Total</th>
                                    <th>OL</th>
                                    <th>REG</th>
                                    <th>OC</th>
                                    <th>PAK</th>
                                    <th>OLR</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="patients-reports-stats" *ngIf="allBranchTableData">
                                    <td>Total</td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',null, null, null, null)">{{
                                            allBranchTableData.ol
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',null, null, null, 'reg')">{{
                                            allBranchTableData.ol_reg
                                            }}</a>
                                    </td>
                                    <td><a class="dataTable-Link"
                                            (click)="openModal(olPatients, 'callCenterapi',null, null, null, 'oc')">{{
                                            allBranchTableData.ol_oc
                                            }}</a>
                                    </td>
                                    <td>{{ allBranchTableData.pak }}</td>
                                    <td>{{ allBranchTableData.olr }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Billing Receipt Modal -->
<!-- <app-cc-ph-patient-details #ccPhPatients></app-cc-ph-patient-details> -->
<!-- / Billing Receipt Modal -->

<ng-template #ccPhPatients let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">PH CC Patient Details From ({{fromDate | date: 'dd-MM-yyyy'}}) To
            ({{toDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>REF No</th>
                            <th>Patient<br /> ID</th>
                            <th>BPID</th>
                            <th>Patient<br /> Name</th>
                            <th>Address</th>
                            <th>IB/OB</th>
                            <th>SMS<br /> Code</th>
                            <th>PROB</th>
                            <th>Doctor<br /> Name</th>
                            <th>BR</th>
                            <th>Visit<br /> Date</th>
                            <th>OC/REG</th>
                            <th>Consultation<br />Amount</th>
                            <th>DUR</th>
                            <th>Pack</th>
                            <th>T-Paid</th>
                            <th>T-Due</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of patient_details;  let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{details.ref_sno ? details.ref_sno : '-'}}</td>
                            <td>{{details.patient_unique_id ? details.patient_unique_id : '-'}}</td>
                            <td>{{details.branch_patient_id ? details.branch_patient_id : '-'}}</td>
                            <td>{{details.patient_name ? details.patient_name : '-'}}</td>
                            <td style="width:300px;white-space: break-spaces;">{{details.house_no ? (details.house_no+',
                                ' ): '-'}}
                                {{details.street ? (details.street+', ' ): '-'}}
                                {{details.district ? (details.district+', ' ): '-'}}
                                {{details.pincode ? (details.pincode): '-'}}</td>
                            <td>{{details.cc_from}}</td>
                            <td>{{details.cc_agent_code ? details.cc_agent_code : '-'}}</td>
                            <td>{{details.treatment_for ? details.treatment_for : '-'}}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.branch_name}}</td>
                            <td>{{details.visit_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.reg_status == 1 ? "REG" : "OC"}}</td>
                            <td>{{(details.consultation_fee && details.consultation_fee !=0) ? details.consultation_fee
                                :
                                "FREE" }}</td>
                            <td>{{details.package_name ? (details.package_name+' Months') : '-'}}</td>
                            <td>{{details.total_amount}}</td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.due_amount ? details.due_amount : '-'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #olPatients let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Online Patient Details From ({{fromDate | date: 'dd-MM-yyyy'}}) To
            ({{toDate | date: 'dd-MM-yyyy'}})</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="table__data__overflow">
        <div class="modal-body" tabindex="0" ngbAutofocus>
            <div class="">
                <table class="table table-bordered text-center align-middle">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Patient ID</th>
                            <th>BPID</th>
                            <th>Patient<br /> Name</th>
                            <th>Doctor<br /> Name</th>
                            <th>PROB</th>
                            <th>Consultation<br />Amount</th>
                            <th>Pack</th>
                            <th>total</th>
                            <th>Paid</th>
                            <th>DUE</th>
                            <th>BR</th>
                            <th>Billing<br />Date</th>
                            <th>Renewal</th>
                            <th>Followup<br />date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let details of patient_details; let indexOfelement = index">
                            <td>{{indexOfelement+1}}</td>
                            <td>{{details.patient_unique_id ? details.patient_unique_id : '-'}}</td>
                            <td>{{details.branch_patient_id ? details.branch_patient_id : '-'}}</td>
                            <td>{{details.patient_name ? details.patient_name : '-'}}</td>
                            <td>{{details.doctor_name}}</td>
                            <td>{{details.treatment_for ? details.treatment_for : '-'}}</td>
                            <td>{{(details.consultation_fee && details.consultation_fee !=0) ? details.consultation_fee
                                : "FREE"}}</td>
                            <td>{{details.package_name ? (details.package_name+' Months') : '-'}} </td>
                            <td>{{details.total_amount}}</td>
                            <td>{{details.received_amount}}</td>
                            <td>{{details.due_amount ? details.due_amount : '-'}}</td>
                            <td>{{details.branch_name}}</td>
                            <td>{{details.billing_date | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.date_of_expire | date: 'dd-MM-YYYY'}}</td>
                            <td>{{details.next_visiting_date | date: 'dd-MM-YYYY'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>